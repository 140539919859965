import { colorTones, fonts } from 'constants/designSystem';

import { Box, Typography, styled } from '@mui/material';
import { Icon } from 'components/atoms';
import { FeedbackButtonIconType } from 'components/atoms/Icons/types';
import { FeedbackSvgIconButton } from 'components/molecules';
import {
  CompactExpertLike,
  CompactFavoriteFeedback,
  FavoriteFeedback,
  ResultRatingFeedback,
} from 'components/organism';
import { Dispatch, SetStateAction } from 'react';
import { mapJourneyResultFeedbackToIconType } from 'sdk/constants';
import { JourneyResultFeedback } from 'sdk/types/result';

type Props = {
  index: number;
  id?: number;
  name: string;
  rationale: string;
  feedback: JourneyResultFeedback;
  expertReviewLabel: string;
  expertFeedback: string | null;
  expertLike: boolean | null;
  erroneous: boolean;
  favorite: boolean;
  ratingLabel: string;
  onClickRating?: (id: number, feedback: JourneyResultFeedback) => void;
  onClickFavorite?: (favorite: boolean) => void;
  favoriteDefaultLabel: string;
  favoredLabel: string;
  removeAsFavoriteLabel: string;
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<number[]>>;
};

export const StyledWrapper = styled(Box)({
  boxSizing: 'border-box',
  background: colorTones.neutralsWhite,
  border: `1px solid ${colorTones.neutralsSlightGray}`,
  borderRadius: '6px',
  width: '100%',
  marginBottom: '8px',
});

export const StyledBaseFlex = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  verticalAlign: 'center',
});

export const StyledHeaderWrapper = styled(StyledBaseFlex)({
  padding: '12px 16px',
  justifyContent: 'space-between',
  gap: '10px',
  width: '100%',
});

const StyledFeedbackWrapper = styled(StyledBaseFlex)({
  paddingTop: '24px',
  paddingBottom: '24px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '@media (min-width: 600px)': {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
});

export const StyledIndex = styled(Typography)({
  fontFamily: fonts.primeFont,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '150%',
  textAlign: 'right',
  color: colorTones.nightSky100,
  paddingTop: '2px',
  paddingRight: '8px',
});

export const StyledName = styled(Typography)({
  fontFamily: fonts.primeFont,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  maxWidth: '732px',
  width: '100%',
  color: colorTones.primaryNightSky,
  wordBreak: 'break-all',
});

export const StyledButtonExpand = styled(Box)({
  '&:hover': {
    cursor: 'pointer',
  },
  transform: 'rotate(0deg)',
  '&[data-animation="expanded"]': {
    transform: 'rotate(180deg)',
    transition: 'transform 0.3s ease-in-out',
  },
  '&[data-animation="decrease"]': {
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s ease-in-out',
  },
});

export const StyledIconWrapper = styled(Box)({
  paddingLeft: '8px',
  paddingRight: '8px',
  minWidth: '16px',
});

export const StyledCardContent = styled(Box)({
  width: '100%',
  minHeight: '200px',
  '&[data-animation="expanded"]': {
    display: 'block',
    transition: 'transform 0.3s ease-in-out',
  },
  '&[data-animation="decrease"]': {
    display: 'none',
    transition: 'transform 0.3s ease-in-out',
  },
  padding: '24px',
});

export const StyledRationale = styled(Typography)({
  color: colorTones.primaryNightSky,
  fontFamily: fonts.primeFont,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '28px',
});

const StyledRatingTitle = styled(Typography)({
  color: colorTones.nightSky100,
  fontFamily: fonts.primeFont,
  fontStyle: 'italic',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
});
const StyledExpertFeedbackTitle = styled(Typography)({
  color: colorTones.nightSky80,
  fontFamily: fonts.primeFont,
  fontStyle: 'italic',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '150%',
});

export const RatedResultCard = ({
  id,
  index,
  favorite,
  feedback,
  expertReviewLabel,
  expertFeedback,
  expertLike,
  name,
  rationale,
  ratingLabel,
  onClickRating,
  favoriteDefaultLabel,
  favoredLabel,
  removeAsFavoriteLabel,
  onClickFavorite,
  expanded,
  setExpanded,
}: Props) => {
  const handleExpandButtonClick = () => {
    id && setExpanded((prev) => (prev.includes(id) ? prev.filter((el) => el !== id) : [...prev, id]));
  };

  const handleExpandButtonClose = () => {
    id && setExpanded((prev) => prev.filter((el) => el !== id));
  };

  const handleClickRating = (feedback: JourneyResultFeedback) => {
    id && onClickRating?.(id, feedback);
  };

  return (
    <StyledWrapper>
      <StyledHeaderWrapper
        sx={{
          background: expanded ? colorTones.neutralsSlightGray : colorTones.neutralsWhite,
        }}
      >
        <StyledBaseFlex>
          <StyledIndex>{index}.</StyledIndex>
          <StyledName>{name}</StyledName>
        </StyledBaseFlex>
        <StyledBaseFlex>
          {expertLike && <CompactExpertLike favorite={true} />}
          {favorite && <CompactFavoriteFeedback favorite={favorite} onClickFavorite={onClickFavorite} />}
          {feedback !== JourneyResultFeedback.NoFeedback && mapJourneyResultFeedbackToIconType[feedback] && (
            <FeedbackSvgIconButton
              width={24}
              height={24}
              active={true}
              withRect={false}
              type={
                Object.entries(JourneyResultFeedback)
                  .find(([, value]) => value === feedback)?.[0]
                  .toLowerCase() as FeedbackButtonIconType
              }
            />
          )}
          <StyledIconWrapper>
            <StyledButtonExpand
              data-animation={expanded ? 'expanded' : 'decrease'}
              onClick={expanded ? handleExpandButtonClose : handleExpandButtonClick}
            >
              <Icon type="interfaceArrowButtonUp" width={16} height={16} stroke={colorTones.nightSky100} />
            </StyledButtonExpand>
          </StyledIconWrapper>
        </StyledBaseFlex>
      </StyledHeaderWrapper>
      <StyledCardContent data-animation={expanded ? 'expanded' : 'decrease'}>
        <StyledRationale>{rationale}</StyledRationale>
        <StyledFeedbackWrapper>
          <Box>
            <StyledRatingTitle>{ratingLabel}</StyledRatingTitle>
            <Box
              sx={{
                marginTop: '12px',
                padding: '8px',
                background: colorTones.neutralsSlightGray,
                borderRadius: '14px',
                maxWidth: '168px',
              }}
            >
              <ResultRatingFeedback showFeedbackName={false} feedback={feedback} handleFeedback={handleClickRating} />
            </Box>
          </Box>
          <Box
            sx={{
              p: 0,
              paddingTop: '50px',
              maxWidth: '157px',
            }}
          >
            <FavoriteFeedback
              favoriteDefaultLabel={favoriteDefaultLabel}
              favoredLabel={favoredLabel}
              removeAsFavoriteLabel={removeAsFavoriteLabel}
              favorite={favorite}
              onClickFavorite={onClickFavorite}
            />
          </Box>
        </StyledFeedbackWrapper>
        {expertFeedback && (
          <>
            <StyledExpertFeedbackTitle gap={1} display={'flex'} alignItems={'center'}>
              <Icon
                type="interfaceFavoriteHeart"
                width={16}
                height={16}
                stroke={colorTones.nightSky80}
                fill={colorTones.nightSky80}
              />
              {expertReviewLabel}
            </StyledExpertFeedbackTitle>
            <StyledRationale>{expertFeedback}</StyledRationale>
          </>
        )}
      </StyledCardContent>
    </StyledWrapper>
  );
};
