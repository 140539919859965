import { colorTones } from 'constants/designSystem';

import { Icon } from 'components/atoms/Icons';

import { CarouselNavProps, CarouselProps, SanitizedCarouselNavProps, SanitizedCarouselProps } from './types';

export const sanitizeNavProps = (props: CarouselNavProps | undefined): SanitizedCarouselNavProps => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, style, ...rest } = props ?? {};

  return props !== undefined
    ? {
        style: props.style !== undefined ? props.style : {},
        className: props.className ?? '',
        ...rest,
      }
    : { style: {}, className: '', ...rest };
};

export const sanitizeProps = (props: CarouselProps): SanitizedCarouselProps => {
  return {
    children: props.children ? props.children : [],
    sx: props.sx !== undefined ? props.sx : {},
    className: props.className ?? '',

    navButtonsWrapperProps: sanitizeNavProps(props.navButtonsWrapperProps),
    navButtonsProps: sanitizeNavProps(props.navButtonsProps),

    NextIcon:
      props.NextIcon !== undefined ? (
        props.NextIcon
      ) : (
        <Icon type="interfaceArrowsRight" stroke={colorTones.neutralsBlack} />
      ),
    PrevIcon:
      props.PrevIcon !== undefined ? (
        props.PrevIcon
      ) : (
        <Icon type="interfaceArrowsLeft" stroke={colorTones.neutralsBlack} />
      ),
  };
};
