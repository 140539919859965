import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import React from 'react';

type Props = {
  flexDirection?: 'row' | 'row-reverse' | 'column';
  alignItems?: 'center' | 'end' | 'flex-end' | 'flex-start' | 'self-end' | 'self-start' | 'start';
  justifyContent?: 'flex-start' | 'flex-end' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  children: React.ReactNode;
  padding?: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  sx?: SxProps;
};

export const FlexBox: React.FC<Props> = ({
  flexDirection,
  alignItems,
  justifyContent,
  marginBottom = 2,
  marginLeft = 2,
  marginRight = 2,
  marginTop = 2,
  children,
  padding,
  onClick,
  sx,
}) => (
  <Box
    onClick={onClick}
    sx={{
      padding,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      display: 'flex',
      flexDirection,
      alignItems,
      justifyContent,
      ...sx,
    }}
  >
    {children}
  </Box>
);
