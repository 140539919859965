import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const MailSmileyHappy: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9998 23.1429C18.1539 23.1429 23.1426 18.1541 23.1426 12C23.1426 5.84598 18.1539 0.857147 11.9998 0.857147C5.84576 0.857147 0.856934 5.84598 0.856934 12C0.856934 18.1541 5.84576 23.1429 11.9998 23.1429Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <path
      d="M17.7853 13.7078H6.15967C6.15967 15.3686 7.82047 18.6902 11.9725 18.6902C16.1245 18.6902 17.7853 15.3686 17.7853 13.7078Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7437 9.34286C15.9803 9.34286 16.1722 9.15098 16.1722 8.91429C16.1722 8.6776 15.9803 8.48572 15.7437 8.48572"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.744 9.34286C15.5073 9.34286 15.3154 9.15098 15.3154 8.91429C15.3154 8.6776 15.5073 8.48572 15.744 8.48572"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.20103 9.34286C7.96434 9.34286 7.77246 9.15098 7.77246 8.91429C7.77246 8.6776 7.96434 8.48572 8.20103 8.48572"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.20068 9.34286C8.43739 9.34286 8.62926 9.15098 8.62926 8.91429C8.62926 8.6776 8.43739 8.48572 8.20068 8.48572"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
