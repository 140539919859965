import { env } from 'env';
import { RequestError } from 'sdk/internalApi/request';
import { DtoAnswers } from 'sdk/questionnaire/types';

type DraftRequest = {
  user: number;
  token: string;
  draftId: string;
};

type DraftResponse = {
  answers: DtoAnswers;
  created: string;
  modified: string;
  id: number | string;
  name: string;
  parent?: number;
  last_edited_question?: number;
  user_has_payments?: boolean;
};

export const getDraft = async (request: DraftRequest): Promise<DraftResponse> => {
  const url = `${env.API_URL}/draft/${request.draftId}/`;
  const token = request.token;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!res.ok) {
    throw new RequestError(res.status, null);
  }
  return res.json();
};
