import React, { createContext, useEffect, useState } from 'react';
import { ExpertPlusReview, JourneyResult } from 'sdk/types/result';

export type ResultsDetailsState = {
  activeResultIndex?: number;
  results: JourneyResult[];
  expertPlusReviews?: ExpertPlusReview[];
};

export type ResultsDetailsContextType = {
  resultsDetailsState: ResultsDetailsState;
  updateResultItem: (resultItem: JourneyResult, newActiveResultIndex?: number) => void;
};

type Props = {
  initialResultsDetailsState?: ResultsDetailsState;
  children: (props: ResultsDetailsContextType) => React.ReactElement;
};

export const ResultsDetailsContext = createContext<ResultsDetailsContextType>({
  updateResultItem: () => null,
  resultsDetailsState: {
    results: [],
  },
});

const getResultsDetailsState = () => {
  return {
    results: [],
  };
};

export const ResultsDetailsProvider: React.FC<Props> = ({ initialResultsDetailsState, children }) => {
  const [resultsDetailsState, setResultsDetailsState] = useState<ResultsDetailsState>({
    results: [],
    expertPlusReviews: [],
  });

  useEffect(() => {
    if (initialResultsDetailsState) {
      setResultsDetailsState(initialResultsDetailsState);
      return;
    }
    const newResultsDetailsState = getResultsDetailsState();
    setResultsDetailsState(newResultsDetailsState);
  }, [initialResultsDetailsState]);

  const updateResultItem = (resultItem: JourneyResult, newActiveResultIndex?: number) => {
    const results = resultsDetailsState.results.map((item, index) => {
      if (index !== resultsDetailsState.activeResultIndex) {
        return item;
      }
      return resultItem;
    });

    setResultsDetailsState({
      ...resultsDetailsState,
      results,
      expertPlusReviews: resultsDetailsState.expertPlusReviews,
      activeResultIndex: newActiveResultIndex,
    });
  };

  return (
    <ResultsDetailsContext.Provider
      value={{
        resultsDetailsState,
        updateResultItem,
      }}
    >
      {children({ resultsDetailsState, updateResultItem })}
    </ResultsDetailsContext.Provider>
  );
};
