import { env } from 'env';
import { RequestError } from 'sdk/internalApi/request';
import { mapQuestionnaireAnswersToDtoAnswers } from 'sdk/questionnaire';
import { Question } from 'sdk/questionnaire/enums/question';
import { DtoAnswers, DtoDrafts } from 'sdk/questionnaire/types';
import { QuestionnaireAnswers } from 'sdk/types';

type Request = {
  user: number;
  token: string;
};

type DraftResponse = {
  user: number;
  answers: DtoAnswers;
  created: string;
  modified: string;
  id: number | string;
  name: string;
  user_has_payments?: boolean;
};

const initialAnswers: QuestionnaireAnswers = {
  [Question.Question1]: {
    CN1: '',
    CN2: '',
  },
  [Question.Question2]: {
    offering: '',
  },
  [Question.Question2Part2]: {},
  [Question.Question3]: {
    V2: '',
  },
  [Question.Question4]: {
    FA: [],
    FN: [],
  },
  [Question.Question5]: {
    DRA: [],
    DN: [],
  },
  [Question.Question6]: {
    EA: [],
    EN: [],
  },
  [Question.Question7]: {
    EDA: [],
  },
  [Question.Question8]: {
    MA: [],
  },
  [Question.Question9]: {
    UCA: [],
    UCN: [],
  },
};

export const postDraft = async (request: Request): Promise<DraftResponse> => {
  const url = `${env.API_URL}/draft/create/`;
  const token = request.token;
  const dtoAnswers: DtoDrafts = mapQuestionnaireAnswersToDtoAnswers(initialAnswers);
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      user: request.user,
      answers: dtoAnswers,
    }),
  });
  if (!(res.status >= 200 && res.status < 400)) {
    throw new RequestError(res.status, null);
  }

  return res.json();
};
