import { RequestError } from 'sdk/internalApi/request';

import { CommonPostQuestionnaireRequest, CommonPostQuestionnaireResponse } from './types';

export const commonPostQuestionnaire = async (
  request: CommonPostQuestionnaireRequest,
  url: string,
): Promise<CommonPostQuestionnaireResponse> => {
  const name = request.name;
  const answers = request.answers;
  const user = request.user;
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.token}`,
    },
    body: JSON.stringify({
      user,
      answers,
      name,
    }),
  });

  if (!(res.status >= 200 && res.status < 400)) {
    const { errors } = await res.json();
    throw new RequestError(res.status, errors ?? {});
  }

  return res.json();
};
