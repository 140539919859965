import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const MailSmileySadFace: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9998 23.1429C18.1539 23.1429 23.1426 18.1541 23.1426 12C23.1426 5.84598 18.1539 0.857147 11.9998 0.857147C5.84576 0.857147 0.856934 5.84598 0.856934 12C0.856934 18.1541 5.84576 23.1429 11.9998 23.1429Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.85693 17.1429C7.62452 14.6947 10.5414 13.1987 13.4582 13.8787C15.1469 14.2867 16.5286 15.5108 17.1426 17.1429"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8573 9.34286C15.6206 9.34286 15.4287 9.15098 15.4287 8.91429C15.4287 8.6776 15.6206 8.48572 15.8573 8.48572"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8574 9.34286C16.0941 9.34286 16.286 9.15098 16.286 8.91429C16.286 8.6776 16.0941 8.48572 15.8574 8.48572"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.31431 9.34286C8.07762 9.34286 7.88574 9.15098 7.88574 8.91429C7.88574 8.6776 8.07762 8.48572 8.31431 8.48572"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.31445 9.34286C8.55114 9.34286 8.74302 9.15098 8.74302 8.91429C8.74302 8.6776 8.55114 8.48572 8.31445 8.48572"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
