import { CommonAnswer, Question9FormValues } from 'components/questionnaire/types';
import { DtoAnswer8 } from 'sdk/questionnaire/types';

export const mapQuestion9ToDtoAnswer8 = ({ responses }: Question9FormValues): DtoAnswer8 => {
  const uca: string[] = [];
  const ucn: string[] = [];

  responses.forEach(({ noun, adjective }: CommonAnswer) => {
    uca.push(adjective);
    ucn.push(noun);
  });

  return {
    UCA: uca,
    UCN: ucn,
  };
};
