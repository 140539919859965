import { colorTones, fonts } from 'constants/designSystem';

import styled from '@emotion/styled';
import { InputProps, Input as MuiInput } from '@mui/material';
import React from 'react';

type Props = InputProps;

const StyledInput = styled(MuiInput)({
  input: {
    fontFamily: fonts.primeFont,
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '150%',
    minWidth: '38px',
    ninHeight: '30px',
    background: '#FFFFFF',
    boxShadow: 'inset 0px 2px 1px rgba(0, 0, 0, 0.08)',
    borderRadius: '4px',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 8,
    marginBottom: 8,
    color: colorTones.ocean200,
    textAlign: 'center',
    '::placeholder': {
      color: colorTones.sunflower300,
      fontFamily: fonts.primeFont,
      fontWeight: 500,
      opacity: 1,
      fontStyle: 'italic',
      fontSize: '15px',
      ninHeight: '23px',
    },
    '&:focus': {
      boxShadow: 'inset 0px 2px 1px rgba(0, 0, 0,  0.15)',
    },

    '&[aria-invalid="true"]': {
      color: colorTones.primaryRed,
      background: colorTones.primaryRed10,
    },
  },
});

export const Input: React.FC<Props> = ({ ...rest }) => <StyledInput {...rest} disableUnderline={true} />;
