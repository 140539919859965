import { MenuNavigation } from 'components/organism/Navigation';
import { NavigationItem } from 'components/templates/types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  pages: NavigationItem[];
};

export const TopNavigation: React.FC<Props> = ({ pages }) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const navigate = useNavigate();

  return (
    <MenuNavigation
      anchorEl={anchorElNav}
      onOpenNavMenu={handleOpenNavMenu}
      pages={pages}
      onClick={(url, newTab) => {
        handleCloseNavMenu();
        if (newTab) {
          window?.open(url, '_blank')?.focus();
          return;
        }
        navigate(url);
      }}
    />
  );
};
