import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Button } from 'components/atoms';
import { DraftTile } from 'components/organism/Tiles';
import { TilesGrid } from 'components/organism/TilesGrid/TilesGrid';
import * as React from 'react';
import { ContinueQuestionnaireType } from 'sdk/internalApi';
import { Draft } from 'sdk/types';

type Props = {
  title?: string;
  drafts?: Draft[];
  handleStartJourney?: () => void;
  startJourneyLabel?: string;
  noDraftsPlaceHolder?: string;
  loadMoreLabel?: string;
  handleLoadMore?: () => void;
  loadMoreVisible?: boolean;
  onClickItem?: (id: number, itemType: ContinueQuestionnaireType) => void;
};

const StyledWrapper = styled(Box)({
  paddingTop: '24px',
  paddingBottom: '24px',
});

const initializeItems = (
  drafts: Draft[],
  onClickItem?: (id: number, itemType: ContinueQuestionnaireType) => void,
): React.ReactNode[] =>
  drafts.map((item: Draft, index: number) => {
    const draftKey = `draft-item-${index}`;
    return (
      <DraftTile
        key={draftKey}
        id={item.id}
        name={item.name}
        completion={item.completion}
        type={item.type}
        onClick={onClickItem}
        formattedCreateDate={item.created}
      />
    );
  });

export const DashboardDraftSection: React.FC<Props> = ({
  title,
  drafts,
  noDraftsPlaceHolder,
  loadMoreLabel,
  handleLoadMore,
  loadMoreVisible,
  onClickItem,
}) => (
  <StyledWrapper>
    <TilesGrid
      title={title || ''}
      noItemsPlaceHolder={noDraftsPlaceHolder || ''}
      items={initializeItems(drafts || [], onClickItem)}
    />
    {loadMoreVisible && (
      <StyledWrapper>
        <Button color="primary" onClick={handleLoadMore}>
          {loadMoreLabel || ''}
        </Button>
      </StyledWrapper>
    )}
  </StyledWrapper>
);
