import { Grid, Tooltip, styled } from '@mui/material';
import { Box } from '@mui/system';
import { Icon, Props as IconProps } from 'components/atoms/Icons/Icon';
import { FlatButtonWithIcon } from 'components/molecules';
import React from 'react';

type Props = {
  previousButtonLabel: string;
  nextButtonLabel: string;
  hideNext?: boolean;
  hidePrev?: boolean;
  onClickPrev?: () => void;
  disablePrev?: boolean;
  onClickNext?: () => void;
  disableNext?: boolean;
  variant?: 'questionnaire' | 'results';
  handleEndPresentation?: () => void;
  showViewSummary?: boolean;
  disabledViewSummary?: boolean;
  prevButtonProps?: React.CSSProperties;
  nextButtonProps?: React.CSSProperties;
  prevIconProps?: Omit<IconProps, 'type'>;
  nextIconProps?: Omit<IconProps, 'type'>;
  sx?: React.ComponentProps<typeof StyledGridContainer>['sx'];
};

const StyledGridContainer = styled(Grid)({
  bottom: 0,
  position: 'fixed',
  marginTop: '0px',
  marginBottom: '0px',
  width: '100%',
  height: '50px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: '0 16px',
  '@media (max-width: 600px)': {
    marginBottom: '55px',
    marginTop: '20px',
    position: 'relative',
  },
});

const StyledIconWrapper = styled(Box)({
  paddingTop: '4px',
});

export const IconLeft: React.FC<Omit<IconProps, 'type'>> = (iconProps) => {
  return (
    <StyledIconWrapper>
      <Icon type="interfaceArrowsLeft" {...iconProps} />
    </StyledIconWrapper>
  );
};

export const IconRight: React.FC<Omit<IconProps, 'type'>> = (iconProps) => {
  return (
    <StyledIconWrapper>
      <Icon type="interfaceArrowsRight" {...iconProps} />
    </StyledIconWrapper>
  );
};

export const SlidesControls: React.FC<Props> = ({
  disablePrev,
  previousButtonLabel,
  nextButtonLabel,
  disableNext,
  hideNext,
  hidePrev,
  onClickPrev,
  onClickNext,
  variant = 'questionnaire',
  handleEndPresentation,
  showViewSummary,
  disabledViewSummary,
  prevButtonProps,
  nextButtonProps,
  prevIconProps,
  nextIconProps,
  sx,
}) => {
  return (
    <StyledGridContainer container sx={sx}>
      <Grid
        item
        xs={4}
        display="flex"
        justifyContent={variant === 'questionnaire' ? 'center' : 'left'}
        alignItems="center"
      >
        {!hidePrev && (
          <FlatButtonWithIcon
            disabled={Boolean(disablePrev)}
            icon={<IconLeft {...prevIconProps} />}
            text={previousButtonLabel}
            iconPlacement="left"
            onClick={onClickPrev}
            wrapperProps={prevButtonProps}
          />
        )}
      </Grid>
      <Grid item xs={4} display="flex" justifyContent="right" alignItems="center"></Grid>
      <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
        {!hideNext ? (
          disableNext ? (
            <Box
              sx={{
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Tooltip title="Provide feedback first please" placement="top" arrow>
                <Box>
                  <FlatButtonWithIcon
                    onClick={onClickNext}
                    icon={<IconRight {...nextIconProps} />}
                    text={nextButtonLabel}
                    disabled={disableNext}
                    wrapperProps={nextButtonProps}
                  />
                </Box>
              </Tooltip>
            </Box>
          ) : (
            <FlatButtonWithIcon
              onClick={onClickNext}
              icon={<IconRight {...nextIconProps} />}
              text={nextButtonLabel}
              disabled={disableNext}
              wrapperProps={nextButtonProps}
            />
          )
        ) : (
          showViewSummary && (
            <FlatButtonWithIcon
              onClick={handleEndPresentation}
              icon={<IconRight {...nextIconProps} />}
              text={'View Summary'}
              disabled={disabledViewSummary}
            />
          )
        )}
      </Grid>
    </StyledGridContainer>
  );
};
