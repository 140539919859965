import {
  Question1FormValues,
  Question2FormValues,
  Question2Part2FormValues,
  Question3FormValues,
  Question4FormValues,
  Question5FormValues,
  Question6FormValues,
  Question7FormValues,
  Question8FormValues,
  Question9FormValues,
  QuestionnaireDtoValues,
} from 'components/questionnaire/types';
import { FormikProps } from 'formik';
import { createRef } from 'react';
import {
  mapQuestion4ToDtoAnswer3,
  mapQuestion5ToDtoAnswer4,
  mapQuestion6ToDtoAnswer5,
  mapQuestion7ToDtoAnswer6,
  mapQuestion8ToDtoAnswer7,
  mapQuestion9ToDtoAnswer8,
} from 'sdk/questionnaire';
import { Question } from 'sdk/questionnaire/enums';

const getQuestionRefs = () => ({
  [Question.Question1]: createRef<FormikProps<Question1FormValues>>(),
  [Question.Question2]: createRef<FormikProps<Question2FormValues>>(),
  [Question.Question2Part2]: createRef<FormikProps<Question2Part2FormValues>>(),
  [Question.Question3]: createRef<FormikProps<Question3FormValues>>(),
  [Question.Question4]: createRef<FormikProps<Question4FormValues>>(),
  [Question.Question5]: createRef<FormikProps<Question5FormValues>>(),
  [Question.Question6]: createRef<FormikProps<Question6FormValues>>(),
  [Question.Question7]: createRef<FormikProps<Question7FormValues>>(),
  [Question.Question8]: createRef<FormikProps<Question8FormValues>>(),
  [Question.Question9]: createRef<FormikProps<Question9FormValues>>(),
});

export const useFormsQuestionnaire = () => {
  const refs = getQuestionRefs();
  const getFormValues = (question: Question): QuestionnaireDtoValues | undefined => {
    if (question === Question.Question1) {
      return refs?.[question]?.current?.values;
    }
    if (question === Question.Question2) {
      return refs?.[question]?.current?.values;
    }
    if (question === Question.Question2Part2) {
      return refs?.[question]?.current?.values;
    }
    if (question === Question.Question3) {
      return refs?.[question]?.current?.values;
    }
    if (question === Question.Question4) {
      const question4FormValues: Question4FormValues | undefined = refs?.[question]?.current?.values;
      return question4FormValues && mapQuestion4ToDtoAnswer3(question4FormValues);
    }
    if (question === Question.Question5) {
      const question5FormValues: Question5FormValues | undefined = refs?.[question]?.current?.values;
      return question5FormValues && mapQuestion5ToDtoAnswer4(question5FormValues);
    }
    if (question === Question.Question6) {
      const question6FormValues: Question6FormValues | undefined = refs?.[question]?.current?.values;
      return question6FormValues && mapQuestion6ToDtoAnswer5(question6FormValues);
    }
    if (question === Question.Question7) {
      const question7FormValues: Question7FormValues | undefined = refs?.[question]?.current?.values;
      return question7FormValues && mapQuestion7ToDtoAnswer6(question7FormValues);
    }
    if (question === Question.Question8) {
      const question8FormValues: Question8FormValues | undefined = refs?.[question]?.current?.values;
      return question8FormValues && mapQuestion8ToDtoAnswer7(question8FormValues);
    }
    if (question === Question.Question9) {
      const question9FormValues: Question9FormValues | undefined = refs?.[question]?.current?.values;
      return question9FormValues && mapQuestion9ToDtoAnswer8(question9FormValues);
    }
  };

  return { getFormValues, refs };
};
