import { env } from 'env';
import { RequestError } from 'sdk/internalApi/request';
import { User } from 'sdk/types';

type Request = {
  user: Omit<User, 'id'>;
  token: string;
};

export const putUser = async (request: Request): Promise<User> => {
  const url = `${env.API_URL}/user/`;
  const token = request.token;
  const res = await fetch(url, {
    method: 'PUT',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...request.user }),
  });

  if (!res.ok) {
    throw new RequestError(res.status, null);
  }

  return res.json();
};
