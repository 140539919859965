import styled from '@emotion/styled';
import { Button as MuiButton } from '@mui/material';

const StyledFlatButton = styled(MuiButton)({
  textTransform: 'none',
  border: 'none',
  padding: 0,
  margin: 0,
  width: '24px',
  boxShadow: 'none',
  justifyContent: 'right',
  '&:hover': {
    textTransform: 'none',
    border: 'none',
    boxShadow: 'none',
    background: 'none',
  },
});

export const FlatButton = StyledFlatButton;
