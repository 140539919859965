import { colorTones } from 'constants/designSystem';

import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SvgLogo } from 'components/atoms/Icons';
import { TopNavigation, UserMenu } from 'components/organism';
import { NavigationItem } from 'components/templates/types';
import { Link } from 'react-router-dom';
import { ContextMenuItem, SessionUser } from 'sdk/types';

type Props = {
  pages: NavigationItem[];
  turnOffMenu: boolean;
  user?: SessionUser;
  userContextMenuItems?: ContextMenuItem[];
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  position: 'sticky',
  top: 0,
  backgroundColor: colorTones.primaryNightSky,
  height: '56px',
  p: 2,
}));

export const Header: React.FC<Props> = ({ pages, user, userContextMenuItems, turnOffMenu }: Props) => (
  <StyledGrid container>
    <Grid
      item
      xs={1}
      sm={0}
      display="flex"
      justifyContent="left"
      alignItems="center"
      sx={{
        display: { xs: 'block', sm: 'none' },
      }}
    >
      {!turnOffMenu && (
        <Box sx={{ marginTop: 1 }}>
          <TopNavigation pages={pages} />
        </Box>
      )}
    </Grid>
    <Grid item xs={7} sm={6} display="flex" justifyContent="left" alignItems="center" sx={{ p: 0, paddingLeft: 4 }}>
      <Link to="/dashboard">
        <SvgLogo />
      </Link>
    </Grid>
    <Grid item xs={4} sm={6} display="flex" justifyContent="right" alignItems="center" sx={{ p: 0, paddingRight: 4 }}>
      <UserMenu user={user} items={userContextMenuItems} />
    </Grid>
  </StyledGrid>
);
