import { commonAnswersWithOptionalResponseFormSchema } from 'components/questionnaire/constants';
import { Question9FormValues, Question9Props } from 'components/questionnaire/types';
import React from 'react';
import { isValidStringAnswer, mapDtoAnswer8ToQuestion9, mapQuestion9ToDtoAnswer8 } from 'sdk/questionnaire';
import { Question } from 'sdk/questionnaire/enums';

import { CommonAdjectivesAndNounsQuestion } from './CommonAdjectivesAndNounsQuestion';

type Props = Question9Props;

const maxRows = 6;

const innitAdjectiveAndNounsError = ({ uca, ucn }: { uca: string[]; ucn: string[] }) => {
  if (uca.length === 0 && ucn.length === 0) {
    return [];
  }
  const EaEnErrors = [];
  const count = Math.max(uca.length, ucn.length);
  for (let i = 0; i < count; i++) {
    EaEnErrors.push({
      adjective: uca?.[i],
      noun: ucn?.[i],
    });
  }

  return EaEnErrors;
};

export const FormQuestion9: React.FC<Props> = ({ answers, formRef, requestError, questionError, onClick }) => {
  const initialValues: Question9FormValues = mapDtoAnswer8ToQuestion9(answers);
  const onSubmitForm = (values: Question9FormValues) => {
    const responses = values.responses.filter(
      ({ noun, adjective }) => isValidStringAnswer(noun) || isValidStringAnswer(adjective),
    );

    onClick?.({ [Question.Question9]: mapQuestion9ToDtoAnswer8({ responses }) }, Question.Question9);
  };

  const questionErrors = innitAdjectiveAndNounsError({ uca: questionError?.UCA ?? [], ucn: questionError?.UCN ?? [] });

  return (
    <CommonAdjectivesAndNounsQuestion
      formRef={formRef}
      initialValues={initialValues}
      onSubmitForm={onSubmitForm}
      mainTitle={'Fill in the blanks.'}
      itemTitle={'My users identify as:'}
      maxRows={maxRows}
      inputConcatenation={'+'}
      punctuationMark={'.'}
      validationSchema={commonAnswersWithOptionalResponseFormSchema}
      requestError={requestError}
      questionErrors={questionErrors}
    />
  );
};
