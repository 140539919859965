import Container from '@mui/material/Container';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

export const PageContainer: React.FC<Props> = ({ children }) => (
  <Container sx={{ position: 'relative', marginTop: 2 }} component="main">
    {children}
  </Container>
);
