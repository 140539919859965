import { colorTones, fonts } from 'constants/designSystem';

import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CustomAlert } from 'components/organism';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getGiftCardEmailWithPromoCodes } from 'sdk/internalApi';

type Props = {
  redirectUrl: string;
  setRequestError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const StyledTextWrapper = styled(Box)({
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '27px',
  paddingBottom: '24px',
  width: '100%',
  maxWidth: '720px',
});

const StyledButton = styled(Button)({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  marginTop: '0px',
  textTransform: 'none',
  textAlign: 'center',
  backgroundColor: colorTones.plant200,
  color: colorTones.neutralsWhite,
  fontFamily: fonts.primeFont,
  '&:hover': {
    backgroundColor: colorTones.plant100,
  },
});

export const GiftCardPaymentInfo: React.FC<Props> = ({ redirectUrl, setRequestError }: Props) => {
  const navigate = useNavigate();

  const [destinationEmail, setDestinationEmail] = useState<string>();

  useEffect(() => {
    const getDestinationEmail = async () => {
      try {
        const { email_with_promo_codes } = await getGiftCardEmailWithPromoCodes({
          stripe_id: localStorage.getItem('stripe_id') ?? '',
        });
        setDestinationEmail(email_with_promo_codes);
      } catch (e: unknown) {
        if (e instanceof Error && 'message') {
          setRequestError(e.message);
          return;
        }
        setRequestError('Server error');
      }
    };

    getDestinationEmail();
  }, [setRequestError]);

  return (
    <Box>
      <StyledTextWrapper>
        <Box sx={{ maxWidth: '720px', paddingTop: { xs: 2, md: 8 } }}>
          <CustomAlert variant="success" title="Complete payment." />
        </Box>
        <p>
          Thanks for your gift card purchase!
          <br />
          You will receive your purchased gift card via email{' '}
          <span style={{ fontWeight: 600 }}> {destinationEmail}</span> as soon as the payment gets processed.
          <br />
          Something went wrong? Please, contact us at{' '}
          <a href="mailto: support@namesbyaesop.com"> support@namesbyaesop.com</a>.
          <br />
          You can go back to Aesop using the button at the bottom.
          <br />
          Or, if you wish to purchase another gift card, click the button below:
        </p>
      </StyledTextWrapper>
      <StyledButton
        onClick={() => {
          navigate(`/gift-cards/buy?redirect_url=${redirectUrl}`);
        }}
      >
        I want another one!
      </StyledButton>
    </Box>
  );
};
