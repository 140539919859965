import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceFavoriteHeart: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={'none'} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 21.3429L2.53719 12.7714C-2.60567 7.62859 4.95433 -2.2457 12 5.74287C19.0458 -2.2457 26.5715 7.66287 21.4629 12.7714L12 21.3429Z"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
