import { colorTones } from 'constants/designSystem';

import { Box, Typography } from '@mui/material';
import { Button, Icon } from 'components/atoms';
import { ModalWindow } from 'components/organism';
import { QuestionnaireSection } from 'components/questionnaire/QuestionnaireSection';
import { QuestionnaireContextProvider } from 'context/questionnaireForm';
import { useAuthUser } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const QuestionnairePage: React.FC = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { isAuthenticated, token, user } = useAuthUser();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    history.pushState(null, '', location.href);
    const handlePopstate = () => {
      history.go(1);
      setShowConfirmationModal(true);
    };
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };
  const handleConfirm = () => {
    navigate('/dashboard');
  };

  if (!isAuthenticated || !token || !user) {
    return null;
  }

  return (
    <>
      <QuestionnaireContextProvider>
        {({ questionnaireState, updateQuestionnaireState }) => {
          return (
            <QuestionnaireSection
              token={token}
              user={user}
              draftId={params.draftId}
              updateQuestionnaireState={updateQuestionnaireState}
              questionnaireState={questionnaireState}
            />
          );
        }}
      </QuestionnaireContextProvider>
      {
        <ModalWindow
          open={showConfirmationModal}
          onClose={handleCancel}
          title={'Are you sure?'}
          variant="dark"
          ariaLabelledby={'modal-confirm-goBack-title'}
        >
          <Typography variant="body1">{'Are you sure you need to go?'}</Typography>
          <Box sx={{ p: 4 }}>
            <Button color="success" onClick={handleConfirm}>
              Exit journey
              <Box m={0} p={0} marginTop={1}>
                <Icon type="interfaceDeleteCircle" stroke={colorTones.neutralsWhite} width={22} height={22} />
              </Box>
            </Button>
          </Box>
        </ModalWindow>
      }
    </>
  );
};
