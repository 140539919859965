import { colorTones } from 'constants/designSystem';

import { Box, Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PrimaryTextBold } from 'components/atoms/Text/PrimaryTextBold';
import { ScrollButton } from 'components/molecules';
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';

type Props = {
  intro?: React.ReactNode;
  paragraphsToAnimate: React.ReactNode[];
  form: React.ReactNode;
  fomTitle: string;
  questionVisited: boolean;
  tipSection?: React.ReactNode;
  onFormInView?: (isInView: boolean) => void;
};

type IntroProps = {
  children: React.ReactNode;
  onInit?: () => void;
};

const Intro: React.FC<IntroProps> = ({ children }) => {
  return (
    <Grid
      container
      sx={{
        direction: 'column',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: { md: '100dvh' },
        padding: { xs: '50px 0', md: 0 },
        width: '100%',
      }}
    >
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

const StyledStickyParagraph = styled(Box)({
  zIndex: 10,
  paddingTop: '14px',
  backgroundColor: colorTones.neutralsWhite,
});

type ParagraphSectionProps = {
  paragraphsToAnimate: React.ReactNode[];
};

const ParagraphSection: React.FC<ParagraphSectionProps> = ({ paragraphsToAnimate }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const paragraphRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handleScroll = () => {
      if (wrapperRef?.current?.getBoundingClientRect && paragraphRef?.current?.getBoundingClientRect) {
        const wrapperRect = wrapperRef.current.getBoundingClientRect();
        const paragraphRect = paragraphRef.current.getBoundingClientRect();
        const topNavigationOffset = 122.5;
        const bottom = wrapperRect.bottom - topNavigationOffset;

        if (!isMobile && bottom - paragraphRect.height <= 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box ref={wrapperRef}>
      <StyledStickyParagraph
        sx={{
          '& *': { marginBottom: '0px' },
          '@media (min-width: 600px)': {
            position: isSticky ? 'fixed' : 'sticky',
          },
          maxWidth: '654px',
          top: '100px',
        }}
        ref={paragraphRef}
      >
        {paragraphsToAnimate[0] || ''}
      </StyledStickyParagraph>
      {paragraphsToAnimate.map((item, index) => {
        const paragraphToAnimateKey = `${index}-paragraph-to-animateKey`;
        return index > 0 && <Box key={paragraphToAnimateKey}>{item}</Box>;
      })}
    </Box>
  );
};

type Func = (...args: unknown[]) => unknown;
const debounce = (func: Func, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: unknown[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export const QuestionIntroduction: React.FC<Props> = ({
  intro,
  paragraphsToAnimate,
  form,
  fomTitle,
  questionVisited,
  tipSection,
  onFormInView,
}) => {
  const paragraphsIndex = intro ? 1 : 0;
  const formIndex = intro ? 2 : 1;
  const introRef = useRef<HTMLDivElement>(null);
  const paragraphsRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLDivElement>(null);
  const sectionRefs: RefObject<HTMLDivElement>[] = useMemo(
    () => (intro ? [introRef, paragraphsRef, formRef] : [paragraphsRef, formRef]),
    [intro],
  );

  const [visibleSection, setVisibleSection] = useState<number>(0);
  const [hideScrollButton, setHideScrollButton] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    questionVisited
      ? window.scrollTo(0, document?.body?.scrollHeight || 0)
      : window.scroll({
          top: 0,
        });
  }, [questionVisited]);

  useEffect(() => {
    const isRefElementInView = (ref?: React.RefObject<HTMLDivElement>): boolean => {
      if (ref?.current?.getBoundingClientRect) {
        const { top, bottom } = ref.current.getBoundingClientRect();
        const visible = top < window.innerHeight && bottom > 0;
        return visible;
      }
      return false;
    };
    const handleScroll = debounce(() => {
      const res = sectionRefs.map((ref) => isRefElementInView(ref));
      const visibleSectionId = Math.max(res.indexOf(true), 0);
      setVisibleSection(visibleSectionId);
      setHideScrollButton(visibleSectionId === formIndex);
      onFormInView?.(res[formIndex]);
    }, 100);

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sectionRefs, formIndex, onFormInView]);

  const handleScrollToButtom = () => {
    const ref = sectionRefs?.[visibleSection + 1];

    window.scrollTo({
      top: ref.current?.id === 'form' ? document.body.scrollHeight : ref?.current?.offsetTop || 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Grid
        container
        sx={{
          direction: 'column',
        }}
      >
        <Grid item xs={12}>
          <Box maxWidth={'654px'}>
            {intro && (
              <Box id="intro" ref={sectionRefs[0]}>
                <Intro>{intro}</Intro>
              </Box>
            )}
            <Box
              ref={sectionRefs[paragraphsIndex]}
              id="paragraphs"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: { md: '100vh' },
                paddingTop: '24px',
              }}
            >
              <ParagraphSection paragraphsToAnimate={paragraphsToAnimate} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ width: '80%' }}>
          <Box
            sx={{
              width: {
                xs: '100%',
                sm: '90%',
                md: '80%',
              },
              p: 2,
              paddingTop: {
                xs: 0,
                sm: '50dvh',
              },
            }}
          >
            <PrimaryTextBold
              sx={{
                fontSize: '24px',
                letterSpacing: '-0.01em',
                marginBottom: 2,
              }}
            >
              {fomTitle}
            </PrimaryTextBold>
            <Box
              id="form"
              ref={sectionRefs[formIndex]}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: { md: '100vh' },
              }}
            >
              {form}
            </Box>
            <Grid
              item
              xs={12}
              md={0}
              sx={{
                display: {
                  xs: 'initial',
                  sm: 'none',
                },
              }}
            >
              {tipSection}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box>
        {!hideScrollButton && !isMobile && (
          <ScrollButton
            sx={{
              position: 'fixed',
              bottom: '56px',
              right: '56px',
              zIndex: 20,
            }}
            tooltipLabel="Scroll down"
            onClick={handleScrollToButtom}
          />
        )}
      </Box>
    </>
  );
};
