import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const MailChatBubbleOvalQuestion: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9998 0.857143C9.9842 0.857771 8.00653 1.4051 6.27741 2.44082C4.54828 3.47655 3.13247 4.96189 2.18076 6.73864C1.22906 8.5154 0.777101 10.517 0.873029 12.5303C0.968958 14.5436 1.60918 16.4932 2.72551 18.1714L0.856934 23.1429L7.11408 22.0114C8.62078 22.7477 10.2742 23.1344 11.9512 23.1427C13.6281 23.151 15.2853 22.7807 16.7993 22.0594C18.3132 21.3381 19.6448 20.2843 20.6948 18.9768C21.7447 17.6692 22.4861 16.1415 22.8634 14.5075C23.2408 12.8735 23.2445 11.1755 22.8743 9.53988C22.504 7.90428 21.7694 6.37335 20.7251 5.0612C19.6808 3.74905 18.3539 2.68952 16.8431 1.96161C15.3323 1.23369 13.6768 0.856153 11.9998 0.857143Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6742 17.0844C11.7692 17.0209 11.8808 16.9866 11.995 16.9857C12.1505 16.989 12.2988 17.0521 12.4088 17.1622C12.5189 17.2722 12.5821 17.4205 12.5853 17.576C12.5844 17.6902 12.5501 17.8018 12.4866 17.8968C12.4222 17.9932 12.3308 18.0682 12.2237 18.1126C12.1167 18.1569 11.9989 18.1685 11.8853 18.1459C11.7717 18.1233 11.6673 18.0675 11.5854 17.9856C11.5035 17.9037 11.4477 17.7993 11.4251 17.6857C11.4025 17.5721 11.4141 17.4543 11.4585 17.3473C11.5028 17.2403 11.5779 17.1488 11.6742 17.0844Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.42822 9.42857C9.42822 8.91999 9.57903 8.42283 9.86159 7.99996C10.1441 7.57709 10.5457 7.24751 11.0156 7.05288C11.4855 6.85826 12.0025 6.80733 12.5013 6.90655C13.0001 7.00577 13.4583 7.25068 13.8179 7.6103C14.1775 7.96992 14.4225 8.4281 14.5217 8.92691C14.6209 9.42572 14.57 9.94275 14.3753 10.4126C14.1807 10.8825 13.8511 11.2841 13.4283 11.5666C13.0054 11.8492 12.5082 12 11.9997 12V13.7143"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
