import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TipBox } from 'components/atoms';
import { TipTitleSection } from 'components/molecules';
import React from 'react';

type Props = {
  children: React.ReactNode;
  title: string;
};

const StyledBox = styled(TipBox)(({ theme }) => ({
  minWidth: '250px',
  maxWidth: '400px',
  minHeight: '175px',
  zIndex: 10,
  [theme.breakpoints.up('xs')]: { marginRight: '0px' },
  [theme.breakpoints.up('sm')]: { marginRight: '0px' },
  [theme.breakpoints.up('md')]: { marginRight: '0px' },
  [theme.breakpoints.up('lg')]: { marginRight: '0px' },
}));

const StyledWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
});

export const QuestionExampleBox: React.FC<Props> = ({ title, children }) => (
  <StyledWrapper>
    <StyledBox>
      <Box p={2}>
        <TipTitleSection title={title} tipType="strategy" />
      </Box>
      <Box>{children}</Box>
    </StyledBox>
  </StyledWrapper>
);
