import { colorTones, fonts } from 'constants/designSystem';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { TipBox } from 'components/atoms';
import { TipTitleSection } from 'components/molecules';
import { ResultFlagErroneous, ResultRatingFeedback } from 'components/organism';
import { useWindowDimensions } from 'hooks';
import React, { useEffect } from 'react';
import { ReactFitty } from 'react-fitty';
import { JourneyResultFeedback } from 'sdk/types/result';

type Pros = {
  currentItem: string;
  name: string;
  rationale: string;
  strategy: string;
  expertBoxLabel: string;
  expertFeedback: string;
  strategyBoxLabel: string;
  positiveLabel: string;
  neutralLabel: string;
  negativeLabel: string;
  ratingLabel: string;
  feedback: JourneyResultFeedback;
  erroneous: boolean;
  flagErroneousLabel: string;
  flagNameLabel: string;
  unFlagNameLabel: string;
  flaggedNameLabel: string;
  handleFeedback?: (req: { feedback?: JourneyResultFeedback; erroneous?: boolean }) => void;
};

const BasePreviewTitle = styled(Typography)({
  lineHeight: '1em',
  fontWeight: 600,
  paddingBottom: 30,
  color: colorTones.primaryNightSky,
  fontFamily: fonts.primeFont,
});

const SizedPreviewTitle = styled(BasePreviewTitle)({
  fontSize: '48px',
  wordBreak: 'normal',
  overflowWrap: 'anywhere',
  '@media (min-width: 600px)': {
    fontSize: '96px',
  },
});

const StyledFitty = styled(ReactFitty)({
  lineHeight: '1em',
  // Difference between 'leading' and 'line height' causes the vertical
  // offset to change because of the changing font size. So need to
  // adjust padding to keep offset more or less stable
  paddingTop: 'calc(0.3 * (48px - 1em))',
  paddingBottom: 'calc(0.2 * (48px - 1em))',
  '@media (min-width: 600px)': {
    paddingTop: 'calc(0.3 * (96px - 1em))',
    paddingBottom: 'calc(0.2 * (96px - 1em))',
  },
});

const AutoscalingPreviewTitle = ({ children }: { children: React.ReactNode }) => {
  const { windowWidth } = useWindowDimensions();

  let maxFontSize = 48;
  if (windowWidth !== null && windowWidth > 600) {
    maxFontSize = 96;
  }

  return (
    <BasePreviewTitle>
      <StyledFitty minSize={20} maxSize={maxFontSize}>
        {children}
      </StyledFitty>
    </BasePreviewTitle>
  );
};

const StyledInnerWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row-reverse',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
});

const StyledTipBox = styled(TipBox)({
  height: '100%',
  width: '100%',
});

const StyledSecondaryBox = styled(Box)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.up('xs')]: { width: '100%' },
  [theme.breakpoints.up('sm')]: { width: '100%' },
  [theme.breakpoints.up('md')]: { width: '30%' },
  [theme.breakpoints.up('lg')]: { maxWidth: '300px' },
}));

const StyledMainBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: { width: '100%' },
  [theme.breakpoints.up('sm')]: { width: '100%' },
  [theme.breakpoints.up('md')]: { width: '70%', paddingRight: 30 },
  [theme.breakpoints.up('lg')]: { width: '670px' },
}));

const StyledStrategyText = styled(Typography)({
  fontFamily: fonts.secondaryFont,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '24px',
  letterSpacing: '0.02em',
  color: colorTones.ocean200,
  paddingTop: '8px',
});

const StyledCurrentItemLabel = styled(Typography)({
  lineHeight: '27px',
  fontSize: '16px',
  color: colorTones.ocean200,
  fontFamily: fonts.primeFont,
  fontStyle: 'normal',
  fontWeight: 400,
  paddingTop: '30px',
});

const StyledRationaleText = styled(Typography)({
  fontFamily: fonts.primeFont,
  paddingTop: 2,
  paddingBottom: 2,
});

export const ResultPresentationPreview: React.FC<Pros> = ({
  currentItem,
  name,
  rationale,
  strategy,
  expertBoxLabel,
  expertFeedback,
  strategyBoxLabel,
  positiveLabel,
  neutralLabel,
  negativeLabel,
  ratingLabel,
  erroneous,
  flagErroneousLabel,
  flagNameLabel,
  unFlagNameLabel,
  flaggedNameLabel,
  feedback = JourneyResultFeedback.NoFeedback,
  handleFeedback,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    isMobile && window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <StyledInnerWrapper sx={{ opacity: erroneous ? '25%' : '100%' }}>
      <StyledSecondaryBox>
        {!currentItem.includes('Expert') && (
          <StyledTipBox marginTop={'50px'}>
            <Box p={2}>
              <TipTitleSection title={strategyBoxLabel} tipType="strategy"></TipTitleSection>
              <StyledStrategyText>{strategy}</StyledStrategyText>
            </Box>
          </StyledTipBox>
        )}
        {expertFeedback && (
          <StyledTipBox marginTop={'50px'}>
            <Box p={2}>
              <TipTitleSection title={expertBoxLabel} tipType="expert"></TipTitleSection>
              <StyledStrategyText>{expertFeedback}</StyledStrategyText>
            </Box>
          </StyledTipBox>
        )}
      </StyledSecondaryBox>
      <StyledMainBox>
        <StyledCurrentItemLabel>{currentItem}.</StyledCurrentItemLabel>
        {name.includes(' ') ? (
          // If it's two words then apply overflow and font size
          <SizedPreviewTitle>{name}</SizedPreviewTitle>
        ) : (
          // If it's one long word then it should be scaled to fit into one line
          <AutoscalingPreviewTitle>{name}</AutoscalingPreviewTitle>
        )}
        <StyledRationaleText variant="body1">{rationale}</StyledRationaleText>
        {!currentItem.includes('Expert') && (
          <>
            <ResultRatingFeedback
              disabled={erroneous}
              handleFeedback={(feedback: JourneyResultFeedback) => {
                handleFeedback?.({ feedback });
              }}
              feedback={feedback}
              ratingLabel={ratingLabel}
              positiveLabel={positiveLabel}
              neutralLabel={neutralLabel}
              negativeLabel={negativeLabel}
            />
            <ResultFlagErroneous
              handleFeedback={() => {
                handleFeedback?.({ erroneous: !erroneous });
              }}
              flagErroneousLabel={flagErroneousLabel}
              active={erroneous}
              flagNameLabel={flagNameLabel}
              flaggedNameLabel={flaggedNameLabel}
              unFlagNameLabel={unFlagNameLabel}
            />
          </>
        )}
      </StyledMainBox>
    </StyledInnerWrapper>
  );
};
