import styled from '@emotion/styled';

import { PrimaryText } from './PrimaryText';

interface QuestionExampleTextProps {
  fs?: string;
  mobileFS?: string;
  desktopMargin?: string;
}

export const QuestionExampleText = styled(PrimaryText)<QuestionExampleTextProps>(({ fs, mobileFS, desktopMargin }) => ({
  fontWeight: 400,
  fontSize: mobileFS || '14px',
  lineHeight: '150%',
  marginLeft: desktopMargin || '10px',
  marginRight: desktopMargin || '10px',
  letterSpacing: '0.02em',
  color: '#000D2E',
  '@media (min-width: 600px)': {
    fontSize: fs || '18px',
  },
}));
