import { colorTones, fonts } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import { FlatButton } from 'components/atoms';
import { Icon } from 'components/atoms/Icons';
import { BadgeExpertReviewStatus, BadgeFailed, BadgeJourneyStatus } from 'components/molecules';
import React from 'react';
import { ExpertReviewStatus, JourneyStatus } from 'sdk/types';

type Props = {
  id: number;
  name: string;
  formattedCreateDate?: string;
  failed?: boolean;
  status?: JourneyStatus;
  seenResults?: boolean;
  commonExpertReviewStatus?: ExpertReviewStatus;
  onClick?: (id: number) => void;
};
const arrowRight = (
  <Icon type="interfaceArrowsRight" stroke={colorTones.plant200} width={16} height={16} viewBox="0 0 16 16" />
);

const StyledDateSection = styled(Box)({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '150%',
  color: colorTones.neutralsGray,
});

const StyledTitleSection = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '150%',
  color: colorTones.neutralsBlack,
  fontFamily: fonts.primeFont,
});

const StyledInnerCompletedMarker = styled(Box)({
  background: colorTones.plant200,
  width: '6px',
  height: '6px',
  borderRadius: '50%',
});

const StyledOuterCompletedMarker = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  width: '8.5px',
  height: '8.5px',
  borderRadius: '50%',
  border: `2.5px solid ${colorTones.plant30}`,
  overflow: 'hidden',
});

export const CompletedJourneyTile: React.FC<Props> = ({
  formattedCreateDate = 'dd / mm / yyyy',
  id,
  name,
  status,
  failed,
  seenResults,
  commonExpertReviewStatus,
  onClick,
}) => {
  const handleClick = () => {
    onClick?.(id);
  };

  return (
    <Box>
      <Grid container spacing={0} sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 2, paddingRight: 2 }}>
        <Grid item xs={9} md={10} lg={11} display="flex" justifyContent="left" alignItems="center" p={0} m={0}>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'row', paddingBottom: 2 }}>
              {!seenResults && !failed && status && status === 'completed' && (
                <Box marginTop={1} marginRight={1}>
                  <StyledOuterCompletedMarker>
                    <StyledInnerCompletedMarker />
                  </StyledOuterCompletedMarker>
                </Box>
              )}

              <Box display="flex" flexDirection="column" gap={2}>
                <StyledTitleSection>{name}</StyledTitleSection>
                {(!seenResults && !failed && status && status === 'completed' && (
                  <Box marginLeft={-2.2}>
                    <StyledDateSection>{formattedCreateDate}</StyledDateSection>
                  </Box>
                )) || <StyledDateSection>{formattedCreateDate}</StyledDateSection>}
              </Box>

              {!failed && !seenResults && status && commonExpertReviewStatus && (
                <Box marginLeft={1} gap={1} display="flex" flexDirection="column" alignItems="center">
                  <BadgeJourneyStatus status={status} />
                  <BadgeExpertReviewStatus status={commonExpertReviewStatus} />
                </Box>
              )}
              {failed && (
                <Box marginLeft={1}>
                  <BadgeFailed />
                </Box>
              )}
              {!failed && commonExpertReviewStatus && seenResults && (
                <Box marginLeft={1}>
                  <BadgeExpertReviewStatus status={commonExpertReviewStatus} />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3} md={2} lg={1} display="flex" justifyContent="right" alignItems="center" p={0} m={0}>
          <FlatButton onClick={handleClick}>{arrowRight}</FlatButton>
        </Grid>
      </Grid>
    </Box>
  );
};
