import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const LightBulbOn: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 13.7143C18.004 12.6445 17.7218 11.593 17.1828 10.6689C16.6438 9.74472 15.8675 8.98151 14.9343 8.4583C14.0011 7.93509 12.945 7.67091 11.8754 7.69313C10.8058 7.71534 9.76156 8.02315 8.85091 8.58466C7.94026 9.14617 7.19631 9.94095 6.69613 10.8867C6.19594 11.8324 5.95773 12.8947 6.00615 13.9634C6.05457 15.0322 6.38788 16.0685 6.97154 16.9651C7.5552 17.8618 8.36798 18.586 9.32567 19.0629V22.0114C9.33014 22.1858 9.40255 22.3514 9.52745 22.4731C9.65235 22.5948 9.81986 22.6629 9.99424 22.6629H14.0057C14.1801 22.6629 14.3476 22.5948 14.4725 22.4731C14.5974 22.3514 14.6698 22.1858 14.6742 22.0114V19.0114C15.6621 18.5197 16.4952 17.765 17.0819 16.8305C17.6687 15.8959 17.9863 14.8177 18 13.7143Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 1.38855V3.95998"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.8572 3.83997L17.04 5.67425"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.7658 9.15424H20.1943"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.14307 3.83997L6.96021 5.67425"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.23438 9.15424H3.8058"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
