import { questionToStepNumber } from 'sdk/constants';
import { Question } from 'sdk/questionnaire/enums/question';

export const getDecreasedCompletionByQuestion = (question?: Question): number => {
  if (!question) {
    return 0;
  }
  const questionCount = Object.keys(questionToStepNumber).length;
  const currentQuestion = questionToStepNumber[question] - 1;

  if (currentQuestion === 0) {
    return 0;
  }

  return Math.round((currentQuestion / questionCount) * 100);
};
