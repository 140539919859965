export enum Question {
  Question1 = 'question1',
  Question2 = 'question2',
  Question2Part2 = 'question2Part2',
  Question3 = 'question3',
  Question4 = 'question4',
  Question5 = 'question5',
  Question6 = 'question6',
  Question7 = 'question7',
  Question8 = 'question8',
  Question9 = 'question9',
}
