import { Question8FormValues } from 'components/questionnaire/types';
import { DtoAnswer7 } from 'sdk/questionnaire/types';
import { Question8Answer } from 'sdk/types';

export const mapQuestion8ToDtoAnswer7 = ({ responses }: Question8FormValues): DtoAnswer7 => {
  const ma: string[] = [];
  responses.forEach(({ adjective1, adjective2 }: Question8Answer) => {
    ma.push(adjective1);
    ma.push(adjective2);
  });

  return {
    MA: ma,
  };
};
