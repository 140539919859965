import { colorTones } from 'constants/designSystem';

import { Box } from '@mui/system';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Icon } from 'components/atoms';
import React from 'react';
import { DtoAnswers } from 'sdk/questionnaire/types';
import { ExpertPlusReview, JourneyResult } from 'sdk/types/result';

import { ExportDocument } from './ExportDocument';

type Props = {
  disabled?: boolean;
  results: JourneyResult[];
  expertPlusReviews: ExpertPlusReview[];
  answers: DtoAnswers;
  questionnaireName: string | undefined;
};

export const ExportPdf: React.FC<Props> = ({ disabled, expertPlusReviews, results, answers, questionnaireName }) => {
  const fileName = `made_by_aesop_${questionnaireName}.pdf`;

  return (
    <Box
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <PDFDownloadLink
        document={
          <ExportDocument
            expertPlusReviews={expertPlusReviews}
            results={results}
            answers={answers}
            questionnaireName={questionnaireName}
          />
        }
        fileName={fileName}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '12px 24px',
          gap: '12px',
          width: '216px',
          height: '48px',
          backgroundColor: '#FFFFFF',
          border: '1.4px solid #D6D6D6',
          borderRadius: '8px',
          textDecoration: 'none',
          color: colorTones.nightSky200,
          opacity: disabled ? 0.2 : 1,
        }}
      >
        <Box m={0} p={0} marginTop={1} sx={{ opacity: disabled ? 0.2 : 1 }}>
          <Icon type="interfaceUploadButton1" />
        </Box>
        Export
      </PDFDownloadLink>
    </Box>
  );
};
