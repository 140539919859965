import { colorTones, fonts } from 'constants/designSystem';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FlagErroneousBadge } from 'components/molecules';
import React, { useState } from 'react';

type Props = {
  flagErroneousLabel: string;
  flagNameLabel: string;
  flaggedNameLabel: string;
  unFlagNameLabel: string;
  active?: boolean;
  handleFeedback?: () => void;
};

const StyledWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  paddingTop: '5px',
  paddingBottom: '75px',
});

const StyledLabel = styled(Typography)({
  fontFamily: fonts.primeFont,
  fontStyle: 'italic',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '100%',
  letterSpacing: '-0.02em',
  marginTop: '4px',
  marginRight: '8px',
});

export const ResultFlagErroneous: React.FC<Props> = ({
  active,
  flagErroneousLabel,
  flagNameLabel,
  flaggedNameLabel,
  unFlagNameLabel,
  handleFeedback,
}) => {
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <Box
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <StyledWrapper>
        <StyledLabel
          sx={{
            color: hovered ? colorTones.primaryNightSky : colorTones.nightSky100,
          }}
        >
          {flagErroneousLabel}
        </StyledLabel>
      </StyledWrapper>
      <StyledWrapper>
        <FlagErroneousBadge
          active={active}
          flagNameLabel={flagNameLabel}
          flaggedNameLabel={flaggedNameLabel}
          unFlagNameLabel={unFlagNameLabel}
          hover={hovered}
          handleFeedback={handleFeedback}
        />
      </StyledWrapper>
    </Box>
  );
};
