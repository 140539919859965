import { colorTones } from 'constants/designSystem';

import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { JourneyResultFeedback } from 'sdk/types/result';

import { InterfaceFavoriteHeart } from './ExportIcons';

type Props = {
  id?: number;
  name: string;
  rationale: string;
  feedback: JourneyResultFeedback;
  erroneous: boolean;
  expertFeedback: string | null;
};

const styles = StyleSheet.create({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    border: `1px solid ${colorTones.neutralsSlightGray}`,
    borderRadius: '6px',
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 8,
    backgroundColor: colorTones.neutralsSlightGray,
  },
  headerWrapper: {
    padding: '12px 16px',
    justifyContent: 'space-between',
    gap: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colorTones.neutralsGray,
  },
  titleId: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '150%',
    textAlign: 'right',
    color: colorTones.nightSky100,
    paddingTop: '2px',
    paddingRight: '8px',
  },
  titleName: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    maxWidth: '732px',
    width: '100%',
    color: colorTones.primaryNightSky,
  },
  cardContent: {
    width: '100%',
    minHeight: '50px',
    padding: '12px 24px',
  },
  rationale: {
    color: colorTones.primaryNightSky,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
  },
  expertFeedbackLabel: {
    color: colorTones.primaryNightSky,
    fontStyle: 'italic',
    fontWeight: 800,
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    gap: '6px',
    paddingBottom: '6px',
  },
  expertCardContent: {
    width: '100%',
    minHeight: '50px',
    padding: '12px 24px',
    backgroundColor: colorTones.neutralsSlightGray,
  },
});

export const ExportResultCard: React.FC<Props> = ({ id, name, rationale, expertFeedback }) => {
  return (
    <View style={styles.cardContainer} wrap={false}>
      <View style={styles.flexContent}>
        <View style={styles.headerWrapper}>
          <View style={styles.containerRow}>
            <Text style={styles.titleId}>{id}.</Text>
            <Text style={styles.titleName}>{name}</Text>
          </View>
        </View>
      </View>
      <View style={styles.cardContent}>
        <Text style={styles.rationale}>{rationale}</Text>
      </View>
      {expertFeedback && (
        <View style={styles.expertCardContent}>
          <View style={styles.expertFeedbackLabel}>
            <InterfaceFavoriteHeart />
            <Text>Expert feedback:</Text>
          </View>
          <Text style={styles.rationale}>{expertFeedback}</Text>
        </View>
      )}
    </View>
  );
};
