import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceArrowsButtonRightDouble: React.FC<Props> = ({
  stroke,
  strokeWidth,
  width,
  height,
  viewBox,
  fill,
}) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1538 2L20.763 11.4615C20.8378 11.5306 20.8975 11.614 20.9383 11.7066C20.9791 11.7992 21.0001 11.8991 21.0001 12C21.0001 12.1009 20.9791 12.2008 20.9383 12.2934C20.8975 12.386 20.8378 12.4694 20.763 12.5385L11.1538 22"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 2L12.6248 11.4615C12.7682 11.6053 12.8485 11.7987 12.8485 12C12.8485 12.2013 12.7682 12.3947 12.6248 12.5385L3 22"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
