import { Stack } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
};

export const StackContainer: React.FC<Props> = ({ children, direction, justifyContent }) => (
  <Stack sx={{ width: '100%' }} spacing={2} direction={direction} justifyContent={justifyContent}>
    {children}
  </Stack>
);
