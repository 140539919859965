import { colorTones } from 'constants/designSystem';

import { Box } from '@mui/material';
import { Icon } from 'components/atoms';
import { QuestionTipItem } from 'components/questionnaire/types';

const lightBulbOnIcon = (
  <Icon
    type="lightBulbOn"
    stroke={colorTones.sunflower200}
    strokeWidth="1.1"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  />
);

const validationCheckCircleIcon = (
  <Icon
    type="interfaceValidationCheckCircle"
    stroke={colorTones.primaryNightSky}
    strokeWidth="1.1"
    width={16}
    height={16}
    fill="none"
  />
);

const tipsData = [
  {
    id: 'sit-with-the-unexpected',
    title: 'SIT WITH THE UNEXPECTED',
    text: 'Names that feel odd or offbeat often need a moment to “set in”. Give them a bit of time they have the power to really make an impact.',
  },
  {
    id: 'deprioritize-personal-associations',
    title: 'DEPRIORITIZE PERSONAL ASSOCIATIONS',
    text: 'Put subjective, highly personal reactions and associations aside as they may not extend to your target audience.',
  },
  {
    id: 'dont-expect-a-name-to-say-it-all',
    title: 'DON’T EXPECT A NAME TO “SAY IT ALL”',
    text: 'Prioritize the singular message you want to communicate through your name, and let the rest of your messaging speak to a broader set of value propositions.',
  },
  {
    id: 'there-is-always-a-good-url',
    title: 'THERE IS ALWAYS A GOOD URL',
    text: 'It’s very easy to find productive workarounds for a great URL, social handle, or trademark/registration. Just look at Peloton, an amazing spin-bike/fitness brand that uses Peloton Interactive for their company name and onepeloton.com for the URL.',
  },
];

export const EvaluationTips: QuestionTipItem = {
  id: 'tip-for-name-evaluation',
  title: 'Tips for Name Evaluation',
  titleIcon: lightBulbOnIcon,
  textItems: [
    {
      id: 'heck-out',
      text: <></>,
    },
  ],
  boxContents: tipsData.map(({ id, title, text }) => ({
    id,
    title,
    titleIcon: validationCheckCircleIcon,
    text: (
      <Box sx={{ marginBottom: 2 }}>
        <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
          <Box component="li">{text}</Box>
        </Box>
      </Box>
    ),
  })),
};
