import { colorTones } from 'constants/designSystem';

import { Box, Typography, styled } from '@mui/material';
import { Icon } from 'components/atoms';
import { IconType } from 'components/atoms/Icons/types';
import React from 'react';

type Props = {
  giftIcon: IconType;
  iconWidth: number;
  title: string;
  price: number;
  items: string[];
  children: React.ReactNode;
};

const StyledBoldText = styled(Typography)({
  fontWeight: 600,
  lineHeight: '24px',
  marginTop: '5px',
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledContentBox = styled(Box)({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '150px',
  width: '220px',
  background: colorTones.neutralsWhite,
  border: '3px solid #F7F7F7',
  boxShadow: '0px 2px 6px rgba(0, 13, 46, 0.06)',
  order: 0,
  padding: '10px',
  flexGrow: 1,
});

export const GiftCardPaySection: React.FC<Props> = ({ giftIcon, iconWidth, title, price, items, children }) => {
  return (
    <StyledContentBox>
      <StyledBoldText fontSize={'16px'}>
        {title}
        <Icon type={giftIcon} stroke={colorTones.plant100} width={iconWidth} height={40} />
      </StyledBoldText>
      <br />
      <StyledBoldText fontSize={'24px'}>${price} USD</StyledBoldText>
      {children}
      <ul style={{ paddingLeft: '15px', fontSize: '14px' }}>
        {items.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </StyledContentBox>
  );
};
