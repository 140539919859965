import MenuIcon from '@mui/icons-material/Menu';
import { Menu, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';
import { NavigationItem } from 'components/templates/types';
import React from 'react';

type Props = {
  pages: NavigationItem[];
  anchorEl: null | HTMLElement;
  onClick?: (url: string, newTab: boolean) => void;
  onOpenNavMenu?: (event: React.MouseEvent<HTMLElement>) => void;
};

const MenuNavigationWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const MenuNavigation: React.FC<Props> = ({ anchorEl, pages, onClick, onOpenNavMenu }) => (
  <MenuNavigationWrapper>
    <IconButton
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={onOpenNavMenu}
      sx={{ color: '#fff' }}
    >
      <MenuIcon />
    </IconButton>
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={Boolean(anchorEl)}
      sx={{
        display: { xs: 'block', md: 'none' },
      }}
    >
      {pages.map((page, index) => {
        const menuItem = `menu-item-${index}${page.url}`;
        return (
          <MenuItem key={menuItem} onClick={() => onClick?.(page.url, page.newTab)}>
            <Typography textAlign="center">{page.name}</Typography>
          </MenuItem>
        );
      })}
    </Menu>
  </MenuNavigationWrapper>
);
