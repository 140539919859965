import { colorTones } from 'constants/designSystem';

import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { SvgLogoSmall } from 'components/atoms';
import { Header, MainNavigation } from 'components/organism';
import { MenuItem } from 'components/organism/Navigation/types/menuItem';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContextMenuItem, SessionUser } from 'sdk/types';

import { NavigationItem } from './types';

type Props = {
  children: React.ReactNode;
  user?: SessionUser;
  userContextMenuItems?: ContextMenuItem[];
  pages: NavigationItem[];
  supportPage: NavigationItem;
  pagesWithNoMenu: string[];
  pagesWithoutLogoBackground: string[];
};

const StyledWrapper = styled(Box)({
  color: colorTones.nightSky80,
  position: 'relative',
  zIndex: 1,
});

const StyledTemplateWrapper = styled(Box)({
  position: 'relative',
  minHeight: '100vh',
});

const StyledBackground = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  opacity: 0.06,
});

const StyledIconWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
});

export const MainTemplate: React.FC<Props> = ({
  children,
  user,
  pages,
  supportPage,
  userContextMenuItems,
  pagesWithNoMenu,
  pagesWithoutLogoBackground,
}) => {
  const [turnOffMenu, setTurnOffMenu] = useState<boolean>(false);
  const [showBackgroundLogo, setShowBackgroundLogo] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [menuSupport, setMenuSupport] = useState<MenuItem | undefined>();
  const [pathName, setPathName] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  useEffect(() => {
    let turnOff = false;
    pagesWithNoMenu.forEach((path) => {
      if (pathName.includes(path)) {
        turnOff = true;
        return;
      }
    });
    setTurnOffMenu(turnOff);
  }, [pathName, pagesWithNoMenu]);

  useEffect(() => {
    let showBackground = true;
    pagesWithoutLogoBackground.forEach((path) => {
      if (pathName.includes(path)) {
        showBackground = false;
      }
    });
    setShowBackgroundLogo(showBackground);
  }, [pathName, pagesWithoutLogoBackground]);

  useEffect(() => {
    setMenuSupport({
      name: supportPage.name,
      iconType: supportPage.iconType,
      isActive: pathName.includes(supportPage.url),
      onClick: () => {
        if (supportPage.newTab) {
          window?.open(supportPage.url, '_blank')?.focus();
          return;
        }
        navigate(supportPage.url);
      },
    });
    setMenuItems(
      pages.map(({ name, iconType, url, newTab }) => ({
        name,
        iconType,
        isActive: pathName.includes(url),
        onClick: () => {
          if (newTab) {
            window?.open(url, '_blank')?.focus();
            return;
          }
          navigate(url);
        },
      })),
    );
  }, [pages, supportPage, pathName, navigate]);

  const content = turnOffMenu ? (
    <>{children}</>
  ) : (
    <Box
      sx={{
        marginLeft: { xs: '0px', sm: '60px' },
      }}
    >
      {children}
    </Box>
  );
  return (
    <StyledTemplateWrapper>
      <StyledBackground>
        <StyledIconWrapper>
          {showBackgroundLogo && <SvgLogoSmall viewBox="29 5 31.5 20" width={'100%'} height={'100%'} />}
        </StyledIconWrapper>
      </StyledBackground>

      <StyledWrapper>
        <Header
          pages={[...pages, supportPage]}
          user={user}
          userContextMenuItems={userContextMenuItems}
          turnOffMenu={turnOffMenu}
        />
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <MainNavigation menuItems={menuItems} menuSupport={menuSupport} turnOffMenu={turnOffMenu} />
        </Box>
        {content}
      </StyledWrapper>
    </StyledTemplateWrapper>
  );
};
