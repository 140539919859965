import { colorTones } from 'constants/designSystem';

import React from 'react';

import { SvgLogoImageProps } from './types';

type Props = SvgLogoImageProps;

export const SvgLogoSmall: React.FC<Props> = ({
  width = 63,
  height = 40,
  viewBox = '0 0 63 40',
  fill = 'none',
  colorBelt1 = colorTones.sunflower200,
  colorBelt2 = colorTones.ocean200,
  colorLetters = colorTones.plant200,
}) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M29.7026 10.2949H33.2896L40.8662 28.0863H36.8067L35.1881 24.1239H27.7166L26.098 28.0863H22.1348L29.7113 10.2949H29.7026ZM33.7883 20.6951L31.4436 14.9658L29.0989 20.6951H33.7971H33.7883Z"
        fill={colorLetters}
      />
      <path
        d="M2.21347 29.0925C4.30446 26.5559 6.20298 23.7918 8.08399 21.0453C14.2082 12.1233 20 3.69998 31.4961 3.69998C42.5372 3.69998 48.3115 11.4761 54.182 19.9956C54.4182 19.6457 55.923 17.4677 56.4305 16.733C50.4725 8.16969 43.8758 0 31.4961 0C18.049 0 11.4348 9.63044 5.03062 18.946C3.36833 21.3602 1.73228 23.7481 0 25.9348L2.21347 29.0925Z"
        fill={colorBelt1}
      />
      <path
        d="M31.4959 39.9998C44.943 39.9998 51.5572 30.3693 57.9614 21.0538C59.6237 18.6396 61.2597 16.2604 62.992 14.0737L60.7785 10.916C58.6963 13.4526 56.789 16.2167 54.908 18.9545C48.7838 27.8764 42.992 36.2998 31.4959 36.2998C20.4548 36.2998 14.6805 28.5237 8.80999 20.0041C8.57377 20.354 7.06896 22.532 6.56152 23.2668C12.5283 31.8388 19.1162 39.9998 31.4959 39.9998Z"
        fill={colorBelt2}
      />
    </g>
  </svg>
);
