import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const MailInboxPost: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.71415 6H21.4284V16.2857H2.57129V11.1429C2.57129 9.77889 3.11312 8.47078 4.0776 7.50631C5.04207 6.54184 6.35018 6 7.71415 6Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 16.2857V23.1428"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2855 11.1428V0.857117H12.8569"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
