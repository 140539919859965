import { colorTones } from 'constants/designSystem';

import { Box, useMediaQuery, useTheme } from '@mui/material';
import { QuestionnaireNavigation, SlidesControls } from 'components/organism';
import {
  CompletedQuestionnaire,
  Intro,
  Question1,
  Question2,
  Question2Part2,
  Question3,
  Question4,
  Question5,
  Question6,
  Question7,
  Question8,
  Question9,
} from 'components/questionnaire/Phase1';
import { QuestionnaireContextType, QuestionnaireState } from 'context/questionnaireForm';
import { useFormsQuestionnaire, useQuery, useQuestionnaire } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { questionToStepNumber } from 'sdk/constants';
import { RequestError, postDraftComplete, postPaymentsCreate } from 'sdk/internalApi';
import { mapQuestionnaireAnswersToDtoAnswers } from 'sdk/questionnaire';
import { Question } from 'sdk/questionnaire/enums';
import { QuestionError, QuestionnaireAnswers } from 'sdk/types';
import { User } from 'sdk/types/user';
import { getCompletionByQuestion, getGetPaymentStatusFromQueryParams } from 'utils';

import { ExportPdf } from './ExportPdf';
import { getNextQuestionName } from './utils';

const isFirstQuestion = (question: Question) => questionToStepNumber[question] === 1;

type Props = {
  token: string;
  user: User;
  draftId?: string;
} & QuestionnaireContextType;

const updatedPartialState = (partialAnswers: Partial<QuestionnaireAnswers>, question: Question) => {
  const nextQuestion = getNextQuestionName(question);
  const completion = getCompletionByQuestion(question);
  return {
    partialAnswers,
    nextQuestion,
    completion,
  };
};

export const QuestionnaireSection: React.FC<Props> = ({
  questionnaireState,
  updateQuestionnaireState,
  token,
  user,
  draftId,
}) => {
  const { refs, getFormValues } = useFormsQuestionnaire();
  const prevNextBtnRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const query = useQuery();
  const paymentStatus = getGetPaymentStatusFromQueryParams(query);
  const [showNexPrevBtn, setShowNexPrevBtn] = useState<boolean>(false);
  const [isPrevOrNextClick, setIsPrevOrNextClick] = useState<boolean>(false);

  const {
    loader,
    handleSubmitEditTitleModal,
    handleConfirmEndQuestionnaireModal,
    onCompleteQuestion,
    onPrevClick,
    onCompleteIntro,
    progress,
    initializer,
    startNewQuestionnaire,
    questionnaireErrors,
    setQuestionnaireErrors,
    requestError,
    setRequestError,
  } = useQuestionnaire({
    token,
    user,
    questionnaireState,
    updateQuestionnaireState,
    draftId,
    paymentStatus,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isWideScreen = useMediaQuery(`(min-width: 900px)`);
  const payButtonText = isMobile ? "Let's pay" : "I'm ready to pay";

  useEffect(() => {
    !isPrevOrNextClick && setShowNexPrevBtn(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (prevNextBtnRef?.current) {
        const { bottom } = prevNextBtnRef.current.getBoundingClientRect();
        const screenHeight = window.innerHeight || document.documentElement.clientHeight;
        const visible = bottom <= screenHeight * 3.0;
        setShowNexPrevBtn(visible);
        setIsPrevOrNextClick(false);
        return;
      }
      setShowNexPrevBtn(false);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevNextBtnRef]);

  const onSubmitQuestion = (partialAnswers: Partial<QuestionnaireAnswers>, question: Question) => {
    const values = updatedPartialState(partialAnswers, question);

    (async () => {
      await onCompleteQuestion(values);
    })();
  };

  return (
    <>
      {!loader && !initializer && (
        <QuestionnaireNavigation
          disableEditName={Boolean(questionnaireState?.parent)}
          hideEditName={!(questionnaireState.questionnaireId || questionnaireState.draftId)}
          completed={questionnaireState.formCompleted || progress === 100}
          title={questionnaireState.title || ''}
          questionnaireCompletion={progress}
          questionnaireCompletedText={'Discovery Questionnaire'}
          handleSubmitEditTitleModal={handleSubmitEditTitleModal}
          handleConfirmEndQuestionnaireModal={async (): Promise<void> => {
            if (!(questionnaireState.questionnaireId || questionnaireState.draftId)) {
              navigate('/dashboard');
              return;
            }
            if (questionnaireState.currentQuestion) {
              handleConfirmEndQuestionnaireModal({
                question: questionnaireState.currentQuestion,
                values: getFormValues(questionnaireState.currentQuestion),
              });
            }
          }}
          modalTitle="Edit Journey Title"
          modalSubTitle="Title is limited to 50 characters"
          confirmEndNamingJourneyButtonText="Exit journey"
          endNamingJourneyText="So you can jump back in and finish up whenever you&lsquo;re ready."
          endNamingJourneySubText=""
          endNamingJourneyModalTitle="Sure you need to go?"
          endNamingJourneyModalSubTitle="Don&lsquo;t worry - your journey will be automatically saved."
        />
      )}
      <Box>
        <>
          {startNewQuestionnaire && !questionnaireState.formCompleted && !questionnaireState.currentQuestion && (
            <Intro onClick={onCompleteIntro} />
          )}

          {questionnaireState.currentQuestion === Question.Question1 && (
            <Question1
              disabled={Boolean(questionnaireState.parent)}
              isChild={Boolean(questionnaireState.parent)}
              answers={questionnaireState?.answers?.[Question.Question1]}
              phaseName="Step 1"
              formRef={refs?.[Question.Question1]}
              onClick={onSubmitQuestion}
              questionVisited={questionnaireState.visitedQuestions.includes(Question.Question1)}
              questionError={questionnaireErrors}
              requestError={requestError}
            />
          )}
          {questionnaireState.currentQuestion === Question.Question2 && (
            <Question2
              disabled={Boolean(questionnaireState.parent)}
              isChild={Boolean(questionnaireState.parent)}
              answer={questionnaireState?.answers?.[Question.Question2]}
              phaseName="Step 2 (Part a)"
              formRef={refs?.[Question.Question2]}
              onClick={onSubmitQuestion}
              questionVisited={questionnaireState.visitedQuestions.includes(Question.Question2)}
              questionError={questionnaireErrors}
              requestError={requestError}
            />
          )}
          {questionnaireState.currentQuestion === Question.Question2Part2 && (
            <Question2Part2
              answer={questionnaireState?.answers?.[Question.Question2Part2]}
              phaseName="Step 2 (Part b)"
              formRef={refs?.[Question.Question2Part2]}
              onClick={onSubmitQuestion}
              questionVisited={questionnaireState.visitedQuestions.includes(Question.Question2Part2)}
              questionError={questionnaireErrors}
              requestError={requestError}
            />
          )}
          {questionnaireState.currentQuestion === Question.Question3 && (
            <Question3
              answer={questionnaireState?.answers?.[Question.Question3]}
              inputVerbFromQuestion2={questionnaireState?.answers?.[Question.Question2Part2]?.V1 || ''}
              phaseName="Step 3"
              formRef={refs?.[Question.Question3]}
              onClick={onSubmitQuestion}
              questionVisited={questionnaireState.visitedQuestions.includes(Question.Question3)}
              questionError={questionnaireErrors}
              requestError={requestError}
            />
          )}
          {questionnaireState.currentQuestion === Question.Question4 && (
            <Question4
              answers={questionnaireState?.answers?.[Question.Question4]}
              phaseName="Step 4"
              formRef={refs?.[Question.Question4]}
              onClick={onSubmitQuestion}
              questionVisited={questionnaireState.visitedQuestions.includes(Question.Question4)}
              questionError={questionnaireErrors}
              requestError={requestError}
            />
          )}
          {questionnaireState.currentQuestion === Question.Question5 && (
            <Question5
              answer={questionnaireState?.answers?.[Question.Question5]}
              phaseName="Step 5"
              formRef={refs?.[Question.Question5]}
              onClick={onSubmitQuestion}
              questionVisited={questionnaireState.visitedQuestions.includes(Question.Question5)}
              questionError={questionnaireErrors}
              requestError={requestError}
            />
          )}
          {questionnaireState.currentQuestion === Question.Question6 && (
            <Question6
              answers={questionnaireState?.answers?.[Question.Question6]}
              phaseName="Step 6"
              formRef={refs?.[Question.Question6]}
              onClick={onSubmitQuestion}
              questionVisited={questionnaireState.visitedQuestions.includes(Question.Question6)}
              questionError={questionnaireErrors}
              requestError={requestError}
            />
          )}
          {questionnaireState.currentQuestion === Question.Question7 && (
            <Question7
              answers={questionnaireState?.answers?.[Question.Question7]}
              phaseName="Step 7"
              formRef={refs?.[Question.Question7]}
              onClick={onSubmitQuestion}
              questionVisited={questionnaireState.visitedQuestions.includes(Question.Question7)}
              questionError={questionnaireErrors}
              requestError={requestError}
            />
          )}
          {questionnaireState.currentQuestion === Question.Question8 && (
            <Question8
              answers={questionnaireState?.answers?.[Question.Question8]}
              phaseName="Step 8"
              formRef={refs?.[Question.Question8]}
              onClick={onSubmitQuestion}
              questionVisited={questionnaireState.visitedQuestions.includes(Question.Question8)}
              questionError={questionnaireErrors}
              requestError={requestError}
            />
          )}
          {!questionnaireState.formCompleted && questionnaireState.currentQuestion === Question.Question9 && (
            <Question9
              answers={questionnaireState?.answers?.[Question.Question9]}
              phaseName="Step 9"
              formRef={refs?.[Question.Question9]}
              onClick={onSubmitQuestion}
              questionVisited={questionnaireState.visitedQuestions.includes(Question.Question9)}
              questionError={questionnaireErrors}
              requestError={requestError}
            />
          )}
          {(questionnaireState.draftId || questionnaireState.questionnaireId) && questionnaireState.formCompleted && (
            <CompletedQuestionnaire
              exportButton={
                questionnaireState.dtoAnswers ? (
                  <ExportPdf
                    expertPlusReviews={[]}
                    results={[]}
                    answers={questionnaireState.dtoAnswers}
                    questionnaireName={questionnaireState.title}
                  />
                ) : null
              }
              paymentStatus={questionnaireState.paymentStatus}
              userHasPayments={questionnaireState.userHasPayments}
            />
          )}
        </>
      </Box>
      <Box sx={{ minHeight: '56px', marginBottom: { xs: '40px', md: 0 } }} ref={prevNextBtnRef}>
        {(showNexPrevBtn || isMobile) && (
          <SlidesControls
            sx={{ position: 'relative' }}
            previousButtonLabel="Previous Step"
            nextButtonLabel={questionnaireState.formCompleted ? payButtonText : 'Next Step'}
            hidePrev={
              !questionnaireState.currentQuestion ||
              Boolean(!questionnaireState.draftId && questionnaireState.questionnaireId)
            }
            hideNext={!questionnaireState.currentQuestion}
            disablePrev={
              questionnaireState.currentQuestion ? isFirstQuestion(questionnaireState.currentQuestion) : false
            }
            nextButtonProps={
              questionnaireState.formCompleted
                ? {
                    backgroundColor: colorTones.plant80,
                    borderRadius: '8px',
                    color: colorTones.neutralsWhite,
                    fontFamily: 'Work Sans',
                    padding: '8px 2px 0px 8px',
                    position: 'fixed',
                    bottom: isWideScreen ? '10px' : '50px',
                    zIndex: '20',
                  }
                : {}
            }
            nextIconProps={questionnaireState.formCompleted ? { stroke: colorTones.neutralsWhite } : {}}
            onClickPrev={() => {
              if (questionnaireState.currentQuestion) {
                const question = questionnaireState.currentQuestion;
                const values = getFormValues(questionnaireState.currentQuestion);
                onPrevClick?.({ question, values });
                setIsPrevOrNextClick(true);
              }
            }}
            onClickNext={
              !questionnaireState.formCompleted
                ? () => {
                    questionnaireState.currentQuestion &&
                      refs?.[questionnaireState.currentQuestion]?.current?.submitForm();
                    setIsPrevOrNextClick(true);
                  }
                : () => {
                    (async () => {
                      try {
                        const getQuestionnaireId = async (questionnaireState: QuestionnaireState) => {
                          if (questionnaireState.questionnaireId) {
                            return questionnaireState.questionnaireId;
                          }
                          if (!questionnaireState.draftId || !questionnaireState.answers) {
                            return;
                          }
                          const answersApi = mapQuestionnaireAnswersToDtoAnswers(questionnaireState.answers);
                          const res = await postDraftComplete({
                            id: Number(questionnaireState.draftId),
                            answers: answersApi,
                            name: questionnaireState.title ?? '',
                            user: user.id,
                            token,
                          });
                          return res.id;
                        };

                        const questionnaireId = await getQuestionnaireId(questionnaireState);

                        if (questionnaireId) {
                          const { checkout_url } = await postPaymentsCreate({ token, questionnaireId });
                          window.location.replace(checkout_url);
                        }
                      } catch (e: unknown) {
                        if (e instanceof Error && 'status' in e && 'body' in e) {
                          const customError: RequestError = e as RequestError;
                          const questionError = customError.body as QuestionError;
                          setQuestionnaireErrors({ ...(questionError || {}) });
                          return;
                        }
                        if (e instanceof Error && 'message') {
                          setRequestError(e.message);
                          return;
                        }
                        setRequestError('Api error');
                      }
                    })();
                  }
            }
          />
        )}
      </Box>
    </>
  );
};
