import { AnimatePresence, MotionProps, PanInfo, motion } from 'framer-motion';
import { ReactNode, useCallback, useEffect, useRef } from 'react';

import { StyledItem } from './Styled';

export interface CarouselItemProps {
  next?: (event?: React.MouseEvent) => void;
  prev?: (event?: React.MouseEvent) => void;
  state: {
    active: number;
    prevActive: number;
    next: boolean;
  };
  index: number;
  maxIndex: number;
  child: ReactNode;
  setHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const CarouselItem = ({ next, prev, state, index, maxIndex, child, setHeight }: CarouselItemProps) => {
  const dragProps: MotionProps = {
    drag: 'x',
    layout: true,
    onDragEnd: (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo): void => {
      if (Math.abs(info.offset.y) > Math.abs(info.offset.x)) return;
      if (info.offset.x > 0 && prev) prev();
      else if (info.offset.x < -0 && next) next();

      event.stopPropagation();
    },
    dragElastic: 0,
    dragConstraints: { left: 0, right: 0 },
  };

  const divRef = useRef<HTMLDivElement>(null);

  const checkAndSetHeight = useCallback(() => {
    if (index !== state.active) return;
    if (!divRef.current) return;

    if (divRef.current.offsetHeight === 0) {
      setTimeout(() => checkAndSetHeight(), 100);
    } else {
      setHeight(divRef.current.offsetHeight);
    }
  }, [setHeight, state.active, index, divRef]);

  // Set height on every child change
  useEffect(() => {
    checkAndSetHeight();
  }, [checkAndSetHeight]);

  const variants = {
    leftwardExit: {
      x: '-100%',
      zIndex: 0,
    },
    leftOut: {
      x: '-100%',
      display: 'none',
      zIndex: 0,
    },
    rightwardExit: {
      x: '100%',
      zIndex: 0,
    },
    rightOut: {
      x: '100%',
      display: 'none',
      zIndex: 0,
    },
    center: {
      x: 0,
      opacity: 1,
      zIndex: 1,
    },
  };

  // Handle animation directions based on active, prevActive and this item's index
  const { active, next: isNext, prevActive } = state;
  let animate = 'center';
  if (index !== active) {
    if (index === prevActive) {
      animate = isNext ? 'leftwardExit' : 'rightwardExit';
      if (active === maxIndex && index === 0) animate = 'rightwardExit';
      if (active === 0 && index === maxIndex) animate = 'leftwardExit';
    } else {
      animate = index < active ? 'leftOut' : 'rightOut';
      if (active === maxIndex && index === 0) animate = 'rightOut';
      if (active === 0 && index === maxIndex) animate = 'leftOut';
    }
  }

  return (
    <StyledItem>
      <AnimatePresence custom={isNext}>
        <motion.div {...dragProps} style={{ height: '100%' }}>
          <motion.div
            custom={isNext}
            variants={variants}
            animate={animate}
            transition={{
              x: { type: 'tween', duration: 0.2, delay: 0 },
            }}
            style={{ position: 'relative', height: '100%' }}
          >
            <div ref={divRef} style={{ height: 'fit-content' }}>
              {child}
            </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </StyledItem>
  );
};
