const getFormattedHours = (date: Date): string => {
  const minutes = date.getHours();
  if (minutes <= 9) {
    return `0${minutes}`;
  }
  return `${minutes}`;
};

const getFormattedMinutes = (date: Date): string => {
  const minutes = date.getMinutes();
  if (minutes <= 9) {
    return `0${minutes}`;
  }
  return `${minutes}`;
};

const formatDate = (date: Date) => {
  return date.toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' });
};

export const getFormattedDate = (date: Date, withHours?: boolean): string => {
  const formattedDate = formatDate(date);

  if (!withHours) {
    return formattedDate;
  }
  return `${formattedDate} — ${getFormattedHours(date)}:${getFormattedMinutes(date)}`;
};

export const getStringifiedTime = (date: Date): string => {
  const formattedDate = formatDate(date);

  return `${formattedDate}_${getFormattedHours(date)}_${getFormattedMinutes(date)}`;
};
