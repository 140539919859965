import { colorTones } from 'constants/designSystem';

import { SmileyButtonDontLike, SmileyButtonLike, SmileyButtonNeutral } from 'components/atoms/Icons';
import { FeedbackButtonIconType, SvgImageProps, SvgRatingButtonsProps } from 'components/atoms/Icons/types';
import React, { FunctionComponent, createElement } from 'react';

type Props = SvgImageProps & {
  type: FeedbackButtonIconType;
  active: boolean;
  withRect?: boolean;
};

const neutralColors = {
  borderColor: colorTones.neutralsWhite,
  strokeLineColor: colorTones.neutralsGray,
  backgroundColor: colorTones.neutralsSlightGray,
};

const feedbackTypeToActiveBackground = {
  positive: {
    backgroundColor: colorTones.plant300,
  },
  neutral: {
    backgroundColor: colorTones.ocean300,
  },
  negative: {
    backgroundColor: colorTones.sunflower300,
  },
};

const mapFeedbackTypeToSvgButton: Record<FeedbackButtonIconType, FunctionComponent<SvgRatingButtonsProps>> = {
  positive: SmileyButtonLike,
  neutral: SmileyButtonNeutral,
  negative: SmileyButtonDontLike,
};

export const FeedbackSvgIconButton: React.FC<Props> = ({ type, active, ...rest }) => {
  const svgIcon = mapFeedbackTypeToSvgButton[type];
  const props = active ? feedbackTypeToActiveBackground[type] : neutralColors;
  const IconByType = createElement<SvgRatingButtonsProps>(svgIcon, { ...props, ...rest });

  return <>{IconByType}</>;
};
