import { Question5FormValues } from 'components/questionnaire/types';
import { DtoAnswer4 } from 'sdk/questionnaire/types';
import { removeEmptyValuesFromList } from 'utils';

export const mapQuestion5ToDtoAnswer4 = ({ adjective, noun }: Question5FormValues): DtoAnswer4 => {
  const dra: string[] = removeEmptyValuesFromList(adjective.split(' '));
  const dn: string[] = removeEmptyValuesFromList(noun.split(' '));

  return {
    DRA: dra,
    DN: dn,
  };
};
