import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfacePresentationBoard: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.4286 0.857117H2.57146C1.62469 0.857117 0.857178 1.62463 0.857178 2.5714V16.2857C0.857178 17.2325 1.62469 18 2.57146 18H21.4286C22.3754 18 23.1429 17.2325 23.1429 16.2857V2.5714C23.1429 1.62463 22.3754 0.857117 21.4286 0.857117Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.857178 6H23.1429"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 18V23.1429" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.4285 18L4.28564 23.1429"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5715 18L19.7144 23.1429"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
