import { fonts } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box } from '@mui/material';

interface QuestionExampleBlueTextProps {
  fs?: string;
  mobileFS?: string;
}

export const QuestionExampleBlueText = styled(Box)<QuestionExampleBlueTextProps>(({ fs, mobileFS }) => ({
  fontFamily: fonts.secondaryFont,
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: mobileFS || '14px',
  lineHeight: '150%',
  letterSpacing: '0.02em',
  color: '#418FDE',
  '@media (min-width: 600px)': {
    fontSize: fs || '18px',
  },
}));
