import { colorTones } from 'constants/designSystem';

import * as React from 'react';

import { SvgRatingButtonsProps } from './types';

type Props = SvgRatingButtonsProps;

export const SmileyButtonDontLike: React.FC<Props> = ({
  strokeLineColor = colorTones.neutralsWhite,
  strokeWidth = '1.4',
  width = 40,
  height = 40,
  backgroundColor,
  borderColor,
  viewBox = '0 0 40 40',
  withRect = true,
}) => (
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    {withRect && (
      <rect
        width={width}
        height={height}
        rx={Number(width) / 2}
        fill={borderColor || backgroundColor}
        fillOpacity="1"
      />
    )}
    <path
      d="M20.1933 35.0534C28.4003 35.0534 35.0532 28.4003 35.0532 20.1934C35.0532 11.9864 28.4003 5.33337 20.1933 5.33337C11.9864 5.33337 5.33333 11.9864 5.33333 20.1934C5.33333 28.4003 11.9864 35.0534 20.1933 35.0534Z"
      fill={backgroundColor}
      stroke={backgroundColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.7195 23.9995C25.888 23.7733 24.5355 24.1014 23.414 24.7489C22.2926 25.3963 21.3322 26.4036 21.1123 27.2367"
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.9551 16.1594C26.1027 16.1594 26.2223 16.0398 26.2223 15.8923C26.2223 15.7447 26.1027 15.6251 25.9551 15.6251"
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.954 16.1594C25.8065 16.1594 25.6868 16.0398 25.6868 15.8923C25.6868 15.7447 25.8065 15.6251 25.954 15.6251"
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8308 16.1593C13.9783 16.1593 14.0979 16.0398 14.0979 15.8922C14.0979 15.7447 13.9783 15.6251 13.8308 15.6251"
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8295 16.1593C13.682 16.1593 13.5624 16.0398 13.5624 15.8922C13.5624 15.7447 13.682 15.6251 13.8295 15.6251"
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
