import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const QuestionStepLabel = styled(Typography)({
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '24px',
  color: '#418FDE',
});
