import React from 'react';

import { BaseErrorBadge } from './BaseErrorBadge';

type Props = {
  label?: string;
};

export const BadgePaymentNeeded: React.FC<Props> = ({ label = 'Payment needed!' }) => {
  return <BaseErrorBadge label={label} />;
};
