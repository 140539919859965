import { colorTones } from 'constants/designSystem';

import styled from '@emotion/styled';

import { PrimaryText } from './PrimaryText';

export const IntroSubParagraphText = styled(PrimaryText)({
  fontSize: '16px',
  letterSpacing: '-0.01em',
  color: colorTones.nightSky200,
});
