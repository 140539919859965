import { colorTones } from 'constants/designSystem';

import * as React from 'react';

import { SvgRatingButtonsProps } from './types';

type Props = SvgRatingButtonsProps;

export const SmileyButtonLike: React.FC<Props> = ({
  strokeLineColor = colorTones.neutralsWhite,
  strokeWidth = '1.4',
  width = 40,
  height = 40,
  backgroundColor,
  borderColor,
  viewBox = '0 0 40 40',
  withRect = true,
}) => (
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    {withRect && (
      <rect
        width={width}
        height={height}
        rx={Number(width) / 2}
        fill={borderColor || backgroundColor}
        fillOpacity="1"
      />
    )}
    <path
      d="M20 34.8571C28.2055 34.8571 34.8571 28.2055 34.8571 20C34.8571 11.7946 28.2055 5.14282 20 5.14282C11.7946 5.14282 5.14285 11.7946 5.14285 20C5.14285 28.2055 11.7946 34.8571 20 34.8571Z"
      fill={backgroundColor}
      stroke={backgroundColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9878 22.977H26.9386C26.7644 23.8579 26.2787 24.9778 25.3941 25.9607C24.2751 27.2041 22.5237 28.2202 19.9632 28.2202C17.4027 28.2202 15.6513 27.2041 14.5323 25.9607C13.6477 24.9778 13.162 23.8579 12.9878 22.977Z"
      fill={strokeLineColor}
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.9918 16.4572C25.3074 16.4572 25.5632 16.2013 25.5632 15.8858C25.5632 15.5702 25.3074 15.3143 24.9918 15.3143"
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.9918 16.4572C24.6762 16.4572 24.4203 16.2013 24.4203 15.8858C24.4203 15.5702 24.6762 15.3143 24.9918 15.3143"
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9346 16.4572C14.619 16.4572 14.3631 16.2013 14.3631 15.8858C14.3631 15.5702 14.619 15.3143 14.9346 15.3143"
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9346 16.4572C15.2502 16.4572 15.506 16.2013 15.506 15.8858C15.506 15.5702 15.2502 15.3143 14.9346 15.3143"
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
