import { Question7FormValues, Question7Props } from 'components/questionnaire/types';
import React from 'react';
import { isValidStringAnswer, mapDtoAnswer6ToQuestion7, mapQuestion7ToDtoAnswer6 } from 'sdk/questionnaire';
import { Question } from 'sdk/questionnaire/enums';

import { CommonAdjectivesQuestion } from './CommonAdjectivesQuestion';

type Props = Question7Props;

const maxRows = 6;

const initEdaErrors = (eda?: string[]) => {
  if (!eda || eda?.length === 0) {
    return [];
  }
  const edaErrors = [];
  for (let i = 0; i < eda.length; i += 2) {
    edaErrors.push({ adjective1: eda?.[i], adjective2: eda?.[i + 1] });
  }

  return edaErrors;
};

export const FormQuestion7: React.FC<Props> = ({ answers, formRef, requestError, questionError, onClick }) => {
  const initialValues: Question7FormValues = mapDtoAnswer6ToQuestion7(answers);
  const onSubmitForm = (values: Question7FormValues) => {
    const responses = values.responses.filter(
      ({ adjective1, adjective2 }) => isValidStringAnswer(adjective1) || isValidStringAnswer(adjective2),
    );
    onClick?.({ [Question.Question7]: mapQuestion7ToDtoAnswer6({ responses }) }, Question.Question7);
  };
  const questionErrors = initEdaErrors(questionError?.EDA);

  return (
    <CommonAdjectivesQuestion
      initialValues={initialValues}
      onSubmitForm={onSubmitForm}
      maxRows={maxRows}
      formRef={formRef}
      mainTitle={'Fill in the blanks: When using my offering, I want people to say:'}
      itemTitle={'Wow! This is really:'}
      inputConcatenation={'and'}
      punctuationMark={'.'}
      requestError={requestError}
      questionErrors={questionErrors}
    />
  );
};
