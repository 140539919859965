import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { env } from 'env';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { redirectDefaultPath } from 'utils';

type Props = {
  children?: React.ReactNode;
};

export const Auth0ProviderWithHistory: React.FC<Props> = ({ children }) => {
  const domain = env.AUTH0_DOMAIN;
  const clientId = env.AUTH0_CLIENT_ID;
  const audience = env.AUTH0_AUDIENCE;
  const navigate = useNavigate();
  const onRedirectCallback = (appState: AppState | undefined): void => {
    navigate(appState?.returnTo || redirectDefaultPath);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
