import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const CleanerStars: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="m 12.531588,6.5196639 c -3.68,0.72 -4.8,1.84 -5.52,5.5200001 -0.72,-3.6800001 -1.84,-4.8000001 -5.52,-5.5200001 3.68,-0.72 4.8,-1.84 5.52,-5.51999999 0.72,3.67999999 1.84,4.79999999 5.52,5.51999999 z"
      />
    </g>
    <g>
      <path
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="m 15.079679,19.639679 c -2.56,0.48 -3.28,1.28 -3.84,3.84 -0.48,-2.56 -1.2800004,-3.28 -3.8400004,-3.84 2.56,-0.48 3.2800004,-1.28 3.8400004,-3.84 0.48,2.56 1.28,3.36 3.84,3.84 z"
      />
    </g>
    <g>
      <path
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="m 23.039657,10.419657 c -2.08,0.4 -2.72,1.04 -3.12,3.12 -0.4,-2.08 -1.04,-2.72 -3.12,-3.12 2.08,-0.4 2.72,-1.0399999 3.12,-3.12 0.4,2.0800001 1.04,2.72 3.12,3.12 z"
      />
    </g>
    <line
      stroke={stroke}
      strokeWidth={2}
      strokeLinejoin="round"
      strokeLinecap="round"
      strokeMiterlimit={10}
      x1="2.411588"
      y1="16.119589"
      x2="2.411588"
      y2="16.119589"
    />
    <line
      stroke={stroke}
      strokeWidth={2}
      strokeLinejoin="round"
      strokeLinecap="round"
      strokeMiterlimit={10}
      x1="17.311588"
      y1="2.219588"
      x2="17.311588"
      y2="2.219588"
    />
  </svg>
);
