import { colorTones } from 'constants/designSystem';

import React from 'react';

import { SvgLogoImageProps } from './types';

type Props = SvgLogoImageProps;

export const SvgLogo: React.FC<Props> = ({
  width = 130,
  height = 24,
  viewBox = '0 0 130 24',
  fill = 'none',
  colorBelt1 = colorTones.sunflower200,
  colorBelt2 = colorTones.ocean200,
  colorLetters = colorTones.plant200,
}) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M121.097 6.69922H125.427C127.954 6.69922 129.487 8.19641 129.487 10.3635V10.395C129.487 12.8498 127.58 14.1177 125.202 14.1177H123.431V17.2964H121.097V6.69922ZM125.279 12.045C126.443 12.045 127.126 11.3482 127.126 10.4399V10.4085C127.126 9.36539 126.398 8.80338 125.234 8.80338H123.431V12.045H125.279Z"
        fill={colorLetters}
      />
      <path
        d="M4.54103 6.66406H6.69015L11.2312 17.3377H8.7943L7.82315 14.9593H3.34507L2.37392 17.3377H0L4.54103 6.66406ZM6.99588 12.9001L5.58861 9.46511L4.18134 12.9001H6.99588Z"
        fill={colorLetters}
      />
      <path
        d="M31.5039 6.69922H39.4979V8.77191H33.8194V10.921H38.8145V12.9937H33.8194V15.2193H39.5743V17.292H31.5039V6.69922Z"
        fill={colorLetters}
      />
      <path
        d="M61.2632 15.7535L62.639 14.1034C63.5922 14.8902 64.5903 15.3893 65.8042 15.3893C66.7574 15.3893 67.3329 15.0116 67.3329 14.3911V14.3597C67.3329 13.7707 66.9687 13.465 65.1972 13.0109C63.0616 12.4668 61.6858 11.8733 61.6858 9.76919V9.73772C61.6858 7.8134 63.2325 6.54102 65.3951 6.54102C66.9417 6.54102 68.2546 7.02659 69.3336 7.88984L68.1242 9.6478C67.1845 8.99587 66.2628 8.60471 65.3681 8.60471C64.4734 8.60471 64.0058 9.01385 64.0058 9.5264V9.55788C64.0058 10.2548 64.4599 10.4796 66.2943 10.9517C68.4434 11.5137 69.6573 12.2825 69.6573 14.1304V14.1618C69.6573 16.266 68.0522 17.4485 65.7637 17.4485C64.1587 17.4485 62.5401 16.8865 61.2677 15.7535H61.2632Z"
        fill={colorLetters}
      />
      <path
        d="M89.7417 12.0317V12.0002C89.7417 8.98787 92.1201 6.51953 95.3888 6.51953C98.6574 6.51953 101.004 8.9564 101.004 11.9688V12.0002C101.004 15.0126 98.6259 17.4809 95.3573 17.4809C92.0886 17.4809 89.7417 15.0441 89.7417 12.0317ZM98.5675 12.0317V12.0002C98.5675 10.1838 97.2366 8.66865 95.3573 8.66865C93.4779 8.66865 92.1786 10.1524 92.1786 11.9688V12.0002C92.1786 13.8166 93.5094 15.3318 95.3888 15.3318C97.2681 15.3318 98.5675 13.8481 98.5675 12.0317Z"
        fill={colorLetters}
      />
      <path
        d="M109.416 11.3706C105.738 16.7209 102.267 21.7744 95.3749 21.7744C88.4824 21.7744 85.2857 17.112 81.7653 12C81.6214 12.2113 80.7222 13.5152 80.4165 13.9558C83.9954 19.0948 87.9474 24 95.3749 24C103.441 24 107.411 18.2181 111.251 12.6294C114.928 7.27913 118.399 2.22555 125.292 2.22555V0C117.226 0 113.256 5.77745 109.416 11.3706Z"
        fill={colorBelt1}
      />
      <path
        d="M65.4538 2.22555C72.0766 2.22555 75.543 6.88797 79.0634 12C79.2073 11.7887 80.1065 10.4848 80.4123 10.0442C76.8379 4.90071 72.8813 0 65.4538 0C57.3924 0 53.4179 5.78194 49.5782 11.3706C45.9005 16.7209 42.4295 21.7744 35.537 21.7744C28.6446 21.7744 25.4479 17.112 21.9274 12C21.7836 12.2113 20.8843 13.5152 20.5786 13.9558C24.1575 19.0948 28.1095 24 35.537 24C43.603 24 47.573 18.2181 51.4126 12.6294C55.0904 7.27913 58.5614 2.22555 65.4538 2.22555Z"
        fill={colorBelt1}
      />
      <path
        d="M5.62012 2.22555C12.2428 2.22555 15.7093 6.88797 19.2297 12C19.3736 11.7887 20.2728 10.4848 20.5785 10.0442C16.9997 4.90071 13.0431 0 5.62012 0V2.22555Z"
        fill={colorBelt1}
      />
      <path
        d="M21.4957 12.6294C25.1735 7.27913 28.6445 2.22555 35.5369 2.22555C42.4294 2.22555 45.6261 6.88797 49.1465 12C49.2904 11.7887 50.1896 10.4848 50.4953 10.0442C46.9165 4.90071 42.9644 0 35.5369 0C27.471 0 23.501 5.78194 19.6613 11.3706C15.9835 16.7209 12.5126 21.7744 5.62012 21.7744V24C13.6861 24 17.6561 18.2226 21.4957 12.6294Z"
        fill={colorBelt2}
      />
      <path
        d="M65.4535 21.7744C58.8308 21.7744 55.3644 17.112 51.8439 12C51.7001 12.2113 50.8008 13.5152 50.4951 13.9558C54.074 19.0948 58.026 24 65.4535 24C73.5195 24 77.4895 18.2226 81.3291 12.6294C85.0069 7.27913 88.4779 2.22555 95.3703 2.22555C102.263 2.22555 105.46 6.88797 108.98 12C109.124 11.7887 110.023 10.4848 110.329 10.0442C106.75 4.90521 102.798 0 95.3703 0C87.3044 0 83.3344 5.78194 79.4947 11.3706C75.817 16.7209 72.346 21.7744 65.4535 21.7744Z"
        fill={colorBelt2}
      />
      <path
        d="M125.292 21.7744C118.669 21.7744 115.203 17.112 111.682 12C111.538 12.2113 110.639 13.5152 110.333 13.9558C113.912 19.0948 117.864 24 125.292 24V21.7744Z"
        fill={colorBelt2}
      />
    </g>
  </svg>
);
