import { Typography } from '@mui/material';
import { CenteredContentBox, PageContainer } from 'components/atoms';
import React from 'react';

export const NotFoundPage: React.FC = () => {
  return (
    <PageContainer>
      <CenteredContentBox>
        <Typography variant="h1"> 404 </Typography>
        <Typography variant="body1" marginTop={2} marginBottom={6}>
          Sorry, the page doesn&#39;t exist.
        </Typography>
      </CenteredContentBox>
    </PageContainer>
  );
};
