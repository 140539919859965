import { ExpertReviewStatus } from 'sdk/types';

export enum JourneyResultFeedback {
  NoFeedback,
  Positive,
  Neutral,
  Negative,
}

export interface ExpertReview {
  expert_feedback: string | null;
  expert_like: boolean;
}

export interface JourneyResult {
  id?: number;
  name: string;
  rationale: string;
  feedback: JourneyResultFeedback;
  strategy?: string;
  erroneous: boolean;
  favorite: boolean;
  expert_review: ExpertReview;
  common_expert_review_status?: ExpertReviewStatus;
  slideNumber?: string;
}

export interface ExpertPlusReview {
  id: number;
  suggested_name: string;
  name_rationale: string;
  expert_feedback: string;
}
