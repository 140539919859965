import { useCallback, useState } from 'react';

import { CarouselItem } from './CarouselItem';
import {
  ActiveDot,
  StyledButtonWrapper,
  StyledDot,
  StyledDotsWrapper,
  StyledIconButton,
  StyledItemWrapper,
  StyledRoot,
} from './Styled';
import { CarouselProps } from './types';
import { sanitizeProps } from './utils';

export const Carousel = (props: CarouselProps) => {
  const [state, setState] = useState({
    active: 0,
    prevActive: 0,
    next: true,
  });

  /** Used to set carousel's height. It is being set by the CarouselItems */
  const [childrenHeight, setChildrenHeight] = useState<number>();

  const sanitizedProps = sanitizeProps(props);

  const next = (event?: React.MouseEvent) => {
    const { children } = sanitizedProps;

    const last = Array.isArray(children) ? children.length - 1 : 0;
    const nextActive = state.active + 1 > last ? 0 : state.active + 1;

    setNext(nextActive, true);

    if (event) event.stopPropagation();
  };

  const prev = (event?: React.MouseEvent) => {
    const { children } = sanitizedProps;

    const last = Array.isArray(children) ? children.length - 1 : 0;
    const nextActive = state.active - 1 < 0 ? last : state.active - 1;

    setNext(nextActive, false);

    if (event) event.stopPropagation();
  };

  const setNext = useCallback(
    (index: number, isNext: boolean) => {
      const { children } = sanitizedProps;

      if (Array.isArray(children)) {
        if (index > children.length - 1) index = children.length - 1;
        if (index < 0) index = 0;
      } else {
        index = 0;
      }

      if (isNext === undefined) {
        isNext = index > state.active;
      }

      setState({
        active: index,
        prevActive: state.active,
        next: isNext,
      });
    },
    [sanitizedProps, state.active],
  );

  const {
    children,
    sx,
    className,

    navButtonsProps,
    navButtonsWrapperProps,

    NextIcon,
    PrevIcon,
  } = sanitizedProps;

  return (
    <StyledRoot sx={sx} className={className}>
      <StyledItemWrapper style={{ height: childrenHeight }}>
        {Array.isArray(children) ? (
          children.map((child, index) => {
            return (
              <CarouselItem
                key={`carousel-item${index}`}
                state={state}
                index={index}
                maxIndex={children.length - 1}
                child={child}
                next={next}
                prev={prev}
                setHeight={setChildrenHeight}
              />
            );
          })
        ) : (
          <CarouselItem
            key={`carousel-item0`}
            state={state}
            index={0}
            maxIndex={0}
            child={children}
            setHeight={setChildrenHeight}
          />
        )}
      </StyledItemWrapper>

      <StyledDotsWrapper
        onKeyDown={(e) => {
          if (Array.isArray(children)) {
            if (e.key === 'ArrowRight' && state.active < children.length - 1) {
              setNext(state.active + 1, true);
            } else if (e.key === 'ArrowLeft' && state.active > 0) {
              setNext(state.active - 1, false);
            }
          }
        }}
        tabIndex={0}
      >
        {Array.isArray(children) &&
          children.map((_, index) => (
            <div
              key={`dot-${index}`}
              onClick={() => setNext(index, index > state.active)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setNext(index, index > state.active);
                }
              }}
              tabIndex={0}
            >
              {index === state.active ? <ActiveDot></ActiveDot> : <StyledDot></StyledDot>}
            </div>
          ))}
      </StyledDotsWrapper>

      <StyledButtonWrapper $next $prev={false} {...navButtonsWrapperProps}>
        <StyledIconButton onClick={next} {...navButtonsProps}>
          {NextIcon}
        </StyledIconButton>
      </StyledButtonWrapper>

      <StyledButtonWrapper $next={false} $prev {...navButtonsWrapperProps}>
        <StyledIconButton onClick={prev} {...navButtonsProps}>
          {PrevIcon}
        </StyledIconButton>
      </StyledButtonWrapper>
    </StyledRoot>
  );
};
