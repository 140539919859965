import { PairsAdjectives, Question7FormValues } from 'components/questionnaire/types';
import { DtoAnswer6 } from 'sdk/questionnaire/types';

export const mapQuestion7ToDtoAnswer6 = ({ responses }: Question7FormValues): DtoAnswer6 => {
  const eda: string[] = [];

  responses.forEach(({ adjective1, adjective2 }: PairsAdjectives) => {
    eda.push(adjective1);
    eda.push(adjective2);
  });

  return {
    EDA: eda,
  };
};
