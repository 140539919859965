import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const QualityEducation: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00024 7.48154C8.85438 6.84956 10.136 5.74365 13.8093 5.74365V13.6569C10.136 13.6569 8.85438 14.7628 8.00024 15.3948V7.48154Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.9999 7.48154C7.14576 6.84956 5.86418 5.74365 2.19092 5.74365V13.6569C5.86418 13.6569 7.14576 14.7628 7.9999 15.3948V7.48154Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3008 0.595581H4.66998C4.4568 0.595581 4.24787 0.65521 4.06679 0.76773L2.05811 2.01593L4.06679 3.26411C4.24787 3.37663 4.4568 3.43626 4.66998 3.43626H12.3008C13.0852 3.43626 13.7212 2.80035 13.7212 2.01593C13.7212 1.23149 13.0852 0.595581 12.3008 0.595581Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2695 0.656677V3.43628"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
