import { questionToStepNumber } from 'sdk/constants';
import { Question } from 'sdk/questionnaire/enums/question';

import { isFirstQuestion } from './isFirstQuestion';

export const getPreviousQuestion = (question: Question): Question | undefined => {
  if (isFirstQuestion(question)) {
    return;
  }
  const index = questionToStepNumber[question] - 1;
  const result = Object.entries(questionToStepNumber).find((entry) => entry[1] === index);

  return result && (result[0] as Question);
};
