import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceHelpCustomerSupport2: React.FC<Props> = ({
  stroke,
  strokeWidth,
  width,
  height,
  viewBox,
  fill,
}) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.42871 8V4.99429C3.43764 4.40347 3.56306 3.82021 3.79778 3.27795C4.03251 2.73568 4.37193 2.24506 4.79659 1.8342C5.22126 1.42333 5.72282 1.1003 6.27255 0.883615C6.82227 0.666926 7.40935 0.560839 8.00014 0.571431C8.59093 0.560839 9.17801 0.666926 9.72773 0.883615C10.2775 1.1003 10.779 1.42333 11.2037 1.8342C11.6283 2.24506 11.9678 2.73568 12.2025 3.27795C12.4372 3.82021 12.5626 4.40347 12.5716 4.99429V8"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.71439 6.28572H2.85725C3.0088 6.28572 3.15414 6.34592 3.26131 6.45309C3.36847 6.56025 3.42868 6.7056 3.42868 6.85715V10.2857C3.42868 10.4373 3.36847 10.5826 3.26131 10.6898C3.15414 10.7969 3.0088 10.8571 2.85725 10.8571H1.71439C1.41129 10.8571 1.1206 10.7367 0.906268 10.5224C0.691941 10.3081 0.571533 10.0174 0.571533 9.71429V7.42858C0.571533 7.12547 0.691941 6.83478 0.906268 6.62046C1.1206 6.40613 1.41129 6.28572 1.71439 6.28572Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2859 10.8571H13.143C12.9914 10.8571 12.8461 10.7969 12.7389 10.6898C12.6318 10.5826 12.5716 10.4373 12.5716 10.2857V6.85715C12.5716 6.7056 12.6318 6.56025 12.7389 6.45309C12.8461 6.34592 12.9914 6.28572 13.143 6.28572H14.2859C14.589 6.28572 14.8796 6.40613 15.094 6.62045C15.3083 6.83478 15.4287 7.12547 15.4287 7.42858V9.71429C15.4287 10.0174 15.3083 10.3081 15.094 10.5224C14.8796 10.7367 14.589 10.8571 14.2859 10.8571Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2859 14C10.8921 14 11.4735 13.7592 11.9021 13.3305C12.3308 12.9019 12.5716 12.3205 12.5716 11.7143V9.14286"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2858 14C10.2858 14.3789 10.1353 14.7422 9.86737 15.0102C9.59946 15.2781 9.23609 15.4286 8.85721 15.4286H7.14293C6.76405 15.4286 6.40068 15.2781 6.13277 15.0102C5.86487 14.7422 5.71436 14.3789 5.71436 14C5.71436 13.6211 5.86487 13.2578 6.13277 12.9899C6.40068 12.7219 6.76405 12.5714 7.14293 12.5714H8.85721C9.23609 12.5714 9.59946 12.7219 9.86737 12.9899C10.1353 13.2578 10.2858 13.6211 10.2858 14Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
