import { Question6FormValues } from 'components/questionnaire/types';
import { DtoAnswer5 } from 'sdk/questionnaire/types';
import { Question6Answer } from 'sdk/types';

export const mapDtoAnswer5ToQuestion6 = (dtoAnswer5?: DtoAnswer5): Question6FormValues => {
  if (!dtoAnswer5?.EA?.length) {
    return { responses: [{ noun: '', adjective: '' }] };
  }

  const responses: Question6Answer[] = dtoAnswer5?.EA?.map((ea, index) => ({
    adjective: ea,
    noun: dtoAnswer5?.EN?.[index] || '',
  }));
  return { responses };
};
