import { commonAnswersFormSchema } from 'components/questionnaire/constants';
import { Question6FormValues, Question6Props } from 'components/questionnaire/types';
import React from 'react';
import { isValidStringAnswer, mapDtoAnswer5ToQuestion6, mapQuestion6ToDtoAnswer5 } from 'sdk/questionnaire';
import { Question } from 'sdk/questionnaire/enums';

import { CommonAdjectivesAndNounsQuestion } from './CommonAdjectivesAndNounsQuestion';

type Props = Question6Props;

const maxRows = 2;

const innitAdjectiveAndNounsError = ({ ea, en }: { ea: string[]; en: string[] }) => {
  if (ea.length === 0 && en.length === 0) {
    return [];
  }
  const EaEnErrors = [];
  const count = Math.max(ea.length, en.length);
  for (let i = 0; i < count; i++) {
    EaEnErrors.push({
      adjective: ea?.[i],
      noun: en?.[i],
    });
  }

  return EaEnErrors;
};

export const FormQuestion6: React.FC<Props> = ({ answers, formRef, requestError, questionError, onClick }) => {
  const initialValues: Question6FormValues = mapDtoAnswer5ToQuestion6(answers);

  const onSubmitForm = (values: Question6FormValues) => {
    const responses = values.responses.filter(
      ({ noun, adjective }) => isValidStringAnswer(noun) || isValidStringAnswer(adjective),
    );

    onClick?.({ [Question.Question6]: mapQuestion6ToDtoAnswer5({ responses }) }, Question.Question6);
  };

  const questionErrors = innitAdjectiveAndNounsError({ ea: questionError?.EA ?? [], en: questionError?.EN ?? [] });

  return (
    <CommonAdjectivesAndNounsQuestion
      formRef={formRef}
      initialValues={initialValues}
      onSubmitForm={onSubmitForm}
      mainTitle={`Fill in the blanks.`}
      itemTitle={'My offering will change people’s life by giving them:'}
      maxRows={maxRows}
      inputConcatenation={'+'}
      punctuationMark={'.'}
      validationSchema={commonAnswersFormSchema}
      requestError={requestError}
      questionErrors={questionErrors}
    />
  );
};
