import { Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { QuestionExampleBlueText } from 'components/atoms';
import React, { useCallback, useEffect, useState } from 'react';

type TestimonialsData = {
  feedback: string;
  name: string;
};

const exampleData: TestimonialsData[] = [
  {
    feedback: 'I thought the names it generated were very cool. I like how it explains them.',
    name: 'Amy S.',
  },
  {
    feedback: 'What a wonderful idea!',
    name: 'Sophie J.',
  },
  {
    feedback: 'I love this product concept.',
    name: 'Esus',
  },
  {
    feedback:
      'The concept of guiding users on a Discovery Journey to uncover the perfect name is particularly intriguing.',
    name: 'Qiufeng',
  },
];

const CarouselContainer = styled(Box)`
  overflow: hidden;
  position: relative;
  background: transparent;
  width: 100%;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  background: transparent;
  border: none;
  margin: auto;
`;

export const Testimonials: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = exampleData.length;
  const intervalTime = 6000;

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  }, [totalSlides]);

  useEffect(() => {
    const slideInterval = setInterval(goToNext, intervalTime);

    return () => clearInterval(slideInterval);
  }, [goToNext, intervalTime]);

  return (
    <CarouselContainer>
      <StyledCard sx={{ height: '100px' }}>
        <Typography variant="h5" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '22px' }}>
          “{exampleData[currentIndex].feedback}”
        </Typography>
        <QuestionExampleBlueText>- {exampleData[currentIndex].name}</QuestionExampleBlueText>
      </StyledCard>
    </CarouselContainer>
  );
};
