import { ResultPresentationContainer } from 'components/atoms';
import {
  PresentationTopNavigation,
  ResultPresentationIntro,
  ResultPresentationPreview,
  SlidesControls,
} from 'components/organism';
import { ResultsDetailsState } from 'context/resultsDetail/resultsDetailContext';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { patchFeedback } from 'sdk/internalApi/feedback';
import { JourneyResult, JourneyResultFeedback } from 'sdk/types/result';

type Props = {
  journeyName?: string;
  journeyId?: string;
  notRatedNameIndex: number | undefined;
  changeRatedNameIndex: (activeResultIndex: number | undefined) => void;
  resultsDetailsState: ResultsDetailsState;
  updateResultItem: (resultItem: JourneyResult, newActiveResultIndex?: number) => void;
  token?: string;
};

const getPrevIndex = (activeResultIndex: number) =>
  activeResultIndex === 0 ? activeResultIndex : activeResultIndex - 1;

const getNextIndex = (totalResultsNumber: number, activeResultIndex: number) =>
  totalResultsNumber === activeResultIndex + 1 ? activeResultIndex : activeResultIndex + 1;

export const PresentationSection: React.FC<Props> = ({
  journeyName,
  journeyId,
  resultsDetailsState,
  notRatedNameIndex,
  changeRatedNameIndex,
  token,
  updateResultItem,
}) => {
  const navigate = useNavigate();
  const handleFeedback = async (req: { feedback?: JourneyResultFeedback; erroneous?: boolean }) => {
    const result = resultsDetailsState?.results?.[resultsDetailsState?.activeResultIndex || 0];
    if (!token || !result?.id) {
      return;
    }
    changeRatedNameIndex(resultsDetailsState.activeResultIndex);
    updateResultItem({ ...result, ...req }, resultsDetailsState.activeResultIndex || 0);
    await patchFeedback({
      resultId: result.id,
      token,
      ...req,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEndPresentation = () => {
    return navigate(`/journey/${journeyId}`);
  };

  return (
    <>
      <PresentationTopNavigation
        handleEndPresentation={handleEndPresentation}
        title={journeyName || ''}
        endPresentationButtonLabel="Exit presentation"
      />
      <ResultPresentationContainer>
        {resultsDetailsState.activeResultIndex === undefined && (
          <ResultPresentationIntro
            title="One name. That’s our mission."
            subTitle="To deliver you one powerful name. That's all you need to start the right conversation around your brand, business, or product."
            body1="You probably won’t like everything you see... And that’s okay."
            body2="We explored a really wide range of names because different naming approaches click with different people."
          />
        )}
        {resultsDetailsState.activeResultIndex !== undefined && (
          <ResultPresentationPreview
            erroneous={resultsDetailsState?.results[resultsDetailsState.activeResultIndex || 0]?.erroneous || false}
            handleFeedback={handleFeedback}
            feedback={resultsDetailsState?.results[resultsDetailsState.activeResultIndex || 0]?.feedback}
            positiveLabel="Great"
            neutralLabel="Neutral"
            negativeLabel="Not for me"
            strategyBoxLabel="Note on Naming strategy"
            ratingLabel="Tell us what you think of this name."
            strategy={resultsDetailsState?.results[resultsDetailsState.activeResultIndex || 0]?.strategy || ''}
            expertBoxLabel="Expert Feedback"
            expertFeedback={
              resultsDetailsState?.results[resultsDetailsState.activeResultIndex || 0]?.expert_review
                ?.expert_feedback || ''
            }
            currentItem={resultsDetailsState?.results[resultsDetailsState.activeResultIndex || '0']?.slideNumber || '0'}
            name={resultsDetailsState?.results[resultsDetailsState.activeResultIndex]?.name || ''}
            rationale={resultsDetailsState?.results[resultsDetailsState.activeResultIndex]?.rationale || ''}
            flagNameLabel="Flag name as nonsensical"
            flaggedNameLabel="Flagged"
            unFlagNameLabel="Unflag name"
            flagErroneousLabel=""
          />
        )}

        <SlidesControls
          variant="results"
          hidePrev={!resultsDetailsState?.activeResultIndex}
          previousButtonLabel="Previous Name"
          hideNext={resultsDetailsState?.results?.length === (resultsDetailsState?.activeResultIndex || 0) + 1}
          nextButtonLabel={resultsDetailsState.activeResultIndex === undefined ? 'See Names' : 'Next Name'}
          disableNext={
            resultsDetailsState.activeResultIndex === undefined
              ? false
              : resultsDetailsState?.results[resultsDetailsState.activeResultIndex]?.feedback === 0 &&
                resultsDetailsState?.results[resultsDetailsState.activeResultIndex]?.erroneous !== true
          }
          onClickPrev={() => {
            if (resultsDetailsState.activeResultIndex !== undefined) {
              updateResultItem(
                resultsDetailsState.results[resultsDetailsState.activeResultIndex],
                getPrevIndex(resultsDetailsState.activeResultIndex),
              );
            }
          }}
          onClickNext={() => {
            if (resultsDetailsState.activeResultIndex === undefined) {
              updateResultItem(resultsDetailsState.results[0], notRatedNameIndex);
            } else {
              updateResultItem(
                resultsDetailsState.results[resultsDetailsState.activeResultIndex],
                getNextIndex(resultsDetailsState.results.length, resultsDetailsState.activeResultIndex),
              );
            }
          }}
          handleEndPresentation={handleEndPresentation}
          showViewSummary={true}
          disabledViewSummary={notRatedNameIndex !== 0}
        />
      </ResultPresentationContainer>
    </>
  );
};
