import { Box, Select as MuiSelect, SelectChangeEvent, Theme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { SxProps } from '@mui/system';
import { FormHelperText } from 'components/atoms/Text/FormHelperText';
import * as React from 'react';
import { SelectItems } from 'sdk/types';

type Props = {
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  items?: SelectItems;
  label: string;
  name: string;
  value?: string | number;
  required?: boolean;
  sx?: SxProps<Theme>;
  onChange?: (event: SelectChangeEvent<string | number>) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const defaultValue = '';

export const Select: React.FC<Props> = ({
  disabled,
  error,
  helperText,
  items,
  label,
  name,
  required,
  sx,
  value,
  onChange,
}) => {
  const [selectedOption, setSelectedOption] = React.useState(value ?? defaultValue);
  const handleChange = (event: SelectChangeEvent<string | number>) => {
    setSelectedOption(event.target.value);
    if (event.target.value !== defaultValue) {
      onChange?.(event);
    }
  };
  return (
    <Box sx={{ ...sx, width: '100%', paddingTop: 4 }}>
      <FormControl sx={{ width: '100%' }} error={error} disabled={disabled} required={required}>
        <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
        <MuiSelect
          labelId={`${name}-select-label`}
          id={`${name}-select`}
          value={selectedOption}
          label={label}
          onChange={handleChange}
        >
          {items?.map(({ value, label }, index) => {
            const menuKey = `${index}-${value}`;
            return (
              <MenuItem key={menuKey} value={value}>
                {label}
              </MenuItem>
            );
          })}
        </MuiSelect>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
