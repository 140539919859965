import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceArrowButtonUp: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 17L11.4615 7.24045C11.5306 7.16454 11.614 7.10405 11.7066 7.06269C11.7992 7.02134 11.8991 7 12 7C12.1009 7 12.2008 7.02134 12.2934 7.06269C12.386 7.10405 12.4694 7.16454 12.5385 7.24045L22 17"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
