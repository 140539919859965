import { colorTones } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const TipBox = styled(Box)({
  background: colorTones.neutralsWhite,
  zIndex: 2,
  borderTop: `4px solid ${colorTones.sunflower200}`,
  boxShadow: '0px 2px 6px rgba(0, 13, 46, 0.08)',
});
