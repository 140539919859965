import { colorTones } from 'constants/designSystem';

import { Grid, Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { CustomAlert, ExampleResults } from 'components/organism';
import React, { useEffect } from 'react';
import { PaymentStatus } from 'sdk/types';

type Props = {
  exportButton?: React.ReactNode;
  paymentStatus?: PaymentStatus;
  userHasPayments?: boolean;
  onErrorPayments?: () => void;
};

const StyledTextWrapper = styled(Box)({
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '27px',
  paddingBottom: '24px',
  width: '100%',
  maxWidth: '720px',
});

const StyledBoldText = styled(Typography)({
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '27px',
});

export const CompletedQuestionnaire: React.FC<Props> = ({ paymentStatus, userHasPayments }) => {
  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Grid
        container
        p={0}
        m={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: 'calc(100vh - 102px)',
          width: '100%',
        }}
      >
        <Grid item sm={12} md={8} lg={9} p={0} m={0} paddingLeft={2} paddingRight={2}>
          <StyledTextWrapper>
            {paymentStatus === 'cancelled' && (
              <Box sx={{ maxWidth: '720px', paddingTop: { xs: 2, md: 8 } }}>
                <CustomAlert
                  variant="error"
                  title="Incomplete payment."
                  text="Oops something went wrong! Don't worry, just complete your payment to receive your results."
                />
              </Box>
            )}
            <br />
            One last thing!
            <br />
          </StyledTextWrapper>
          {userHasPayments === false ? (
            <StyledTextWrapper>
              <StyledBoldText sx={{ color: colorTones.ocean300 }}>
                Remember, your first round&apos;s on us.
              </StyledBoldText>
              <br />
              Simply apply the promo code <b>ILOVENAMING</b> next in the checkout to receive your first round of names
              free.
            </StyledTextWrapper>
          ) : (
            <>
              <StyledTextWrapper>
                <StyledBoldText sx={{ color: colorTones.ocean300 }}>
                  It&apos;s the distinctiveness of Aesop&apos;s names that makes them stand out.
                </StyledBoldText>
                <br />
                While there are lots of online name generators out there, Aesop was built to deliver the names they
                can&apos;t. Take a minute to check out some real-life examples created by Aesop below.
                <br />
                <br />
                Because now that you&apos;ve completed your Discovery Journey, Aesop is ready to get to work on creating
                these kinds of names tailored just for you.
              </StyledTextWrapper>
              <ExampleResults />
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
