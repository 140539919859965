import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceArrowsButtonLeftDouble: React.FC<Props> = ({
  stroke,
  strokeWidth,
  width,
  height,
  viewBox,
  fill,
}) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8463 2L3.23713 11.4615C3.16227 11.5306 3.10261 11.614 3.06183 11.7066C3.02104 11.7992 3 11.8991 3 12C3 12.1009 3.02104 12.2008 3.06183 12.2934C3.10261 12.386 3.16227 12.4694 3.23713 12.5385L12.8463 22"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9999 2L11.3751 11.4615C11.2317 11.6053 11.1514 11.7987 11.1514 12C11.1514 12.2013 11.2317 12.3947 11.3751 12.5385L20.9999 22"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
