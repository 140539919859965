import { colorTones, fonts } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { Icon } from 'components/atoms/Icons';
import * as React from 'react';

type AlertVariant = 'success' | 'error';

type Props = {
  title?: string;
  text?: string;
  variant: AlertVariant;
};

const mpaVariantToColor: Record<AlertVariant, string> = {
  success: colorTones.plant80,
  error: colorTones.primaryRed80,
};

const mpaVariantToBorderColor: Record<AlertVariant, string> = {
  success: colorTones.plant50,
  error: colorTones.primaryRed50,
};

const mapAlertVariantToIconType: Record<
  AlertVariant,
  'interfaceValidationCheckCircle' | 'interfaceAlertWarningCircle'
> = {
  success: 'interfaceValidationCheckCircle',
  error: 'interfaceAlertWarningCircle',
};

const StyledWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px 16px',
  marginBottom: '32px',
  gap: '16px',
  minWidth: '100%',
  minHeight: '70px',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
  backdropFilter: 'blur(3px)',
  color: colorTones.neutralsWhite,
  borderRadius: '6px',
  fontFamily: fonts.primeFont,
  fontStyle: 'normal',
});

const StyledIconWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
});

const getIconByVariant = (alertVariant: AlertVariant) => (
  <StyledIconWrapper
    sx={{
      border: `4px solid ${mpaVariantToBorderColor[alertVariant]}`,
    }}
  >
    <Icon
      type={mapAlertVariantToIconType[alertVariant]}
      fill={mpaVariantToColor[alertVariant]}
      width="22"
      height="22"
      strokeWidth="1.4"
      stroke="white"
    />
  </StyledIconWrapper>
);

const StyledText = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  flex: 'none',
  order: 0,
  alignSelf: 'stretch',
  flexGrow: 0,
});

const StyledTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '150%',
  flex: 'none',
  order: 0,
  alignSelf: 'stretch',
  flexGrow: 0,
});

export const CustomAlert: React.FC<Props> = ({ title, text, variant }) => (
  <StyledWrapper
    sx={{
      background: mpaVariantToColor[variant],
    }}
  >
    <Box>{getIconByVariant(variant)}</Box>
    <Box>
      {title && <StyledTitle>{title}</StyledTitle>}
      {text && <StyledText>{text}</StyledText>}
    </Box>
  </StyledWrapper>
);
