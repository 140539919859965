import { env } from 'env';
import { RequestError } from 'sdk/internalApi/request';
import { BaseListResponse, BaseRequest } from 'sdk/internalApi/types';
import { BaseQuestionnaire } from 'sdk/questionnaire/types';
import { ExpertReviewStatus } from 'sdk/types';

type Request = BaseRequest & {
  page?: number;
  pageSize?: number;
  payed?: boolean;
};

type Questionnaire = BaseQuestionnaire & {
  has_results?: boolean;
  seen_results?: boolean;
  failed?: boolean;
  common_expert_review_status?: ExpertReviewStatus;
};

type getQuestionnairesResponse = BaseListResponse<Questionnaire>;

const getUrl = (request: Request) => {
  if (request.next) {
    return request.next;
  }
  const page = request?.page || 1;
  const pageSize = request?.pageSize || 15;
  const baseUrl = `${env.API_URL}/questionnaire/list/?page=${page}&page_size=${pageSize}`;

  if (!request.payed) {
    return baseUrl;
  }

  return `${baseUrl}&payed=true`;
};

export const getQuestionnaires = async (request: Request): Promise<getQuestionnairesResponse> => {
  const url = getUrl(request);
  const token = request.token;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.ok) {
    throw new RequestError(res.status, null);
  }

  return res.json();
};
