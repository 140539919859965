import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const LightText = styled(Typography)({
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '150%',
  letterSpacing: '0.04em',
  color: '#666E81',
});
