import { CircularProgress } from '@mui/material';
import {
  StyledCardsWrapper,
  StyledHeaderBox,
  StyledLoadingWrapper,
  StyledTitle,
  StyledWrapper,
} from 'components/organism/RatedResults/RatedResults';
import React, { useEffect, useState } from 'react';
import { ExpertPlusReview } from 'sdk/types/result';

import { ExpertPlusReviewCard } from './ExpertPlusReviewCard';

type Props = {
  title: string;
  expertPlusReviews?: ExpertPlusReview[];
  loading?: boolean;
};

export const ExpertPlusReviews: React.FC<Props> = ({ title, expertPlusReviews, loading = false }) => {
  const [localExpertPlusReviews, setLocalExpertPlusReviews] = useState<ExpertPlusReview[]>([]);
  const [expanded, setExpanded] = React.useState<number[]>([]);

  useEffect(() => {
    const initialExpanded = (expertPlusReviews || []).map((item) => item.id || 0);
    setExpanded(initialExpanded);
    setLocalExpertPlusReviews([...(expertPlusReviews || [])]);
  }, [expertPlusReviews]);

  return (
    <StyledWrapper>
      {loading && (
        <StyledLoadingWrapper>
          <CircularProgress thickness={6.5} />
        </StyledLoadingWrapper>
      )}
      {!loading && localExpertPlusReviews.length > 0 && (
        <StyledHeaderBox>
          <StyledTitle variant="h3">{title}</StyledTitle>
        </StyledHeaderBox>
      )}
      <StyledCardsWrapper>
        {!loading &&
          localExpertPlusReviews.map((item, index) => {
            const key = `expert-item-${index}`;

            return (
              <ExpertPlusReviewCard
                index={index + 1}
                id={item.id}
                key={key}
                name={item.suggested_name}
                rationale={item.name_rationale}
                expertReviewLabel={'Expert review:'}
                expertFeedback={item.expert_feedback}
                expertLike={true}
                expanded={!!item.id && expanded.includes(item.id)}
                setExpanded={setExpanded}
              />
            );
          })}
      </StyledCardsWrapper>
    </StyledWrapper>
  );
};
