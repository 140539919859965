import { Box, Typography } from '@mui/material';
import {
  FlexBox,
  LightText,
  QuestionExampleBlueText,
  QuestionExampleText,
  QuestionStepLabel,
  UpperCaseText,
} from 'components/atoms';
import { FadingContainer, QuestionContainer } from 'components/molecules';
import { IntroQuotedText, QuestionExampleBox, QuestionTips } from 'components/organism/Questionnaire';
import { introQuestion2Text, question2Paragraphs, question2TipItem } from 'components/questionnaire/constants';
import { FormQuestion2 } from 'components/questionnaire/forms';
import { QuestionIntroduction } from 'components/questionnaire/QuestionIntroduction';
import { Question2Props } from 'components/questionnaire/types';
import React, { useState } from 'react';

type Props = {
  phaseName: string;
  questionVisited: boolean;
  disabled?: boolean;
  isChild?: boolean;
} & Question2Props;

export const Question2: React.FC<Props> = ({
  answer,
  disabled,
  phaseName,
  formRef,
  questionVisited,
  isChild,
  requestError,
  questionError,
  onClick,
}) => {
  const [showTipSection, setShowTipSection] = useState<boolean>(false);
  const introText = (
    <Box key={'intro2Text'}>
      <QuestionStepLabel>{phaseName}</QuestionStepLabel>
      {introQuestion2Text}
    </Box>
  );

  return (
    <QuestionContainer
      tipSection={
        <FadingContainer visible={showTipSection}>
          <QuestionTips items={[question2TipItem]} defaultExpanded />
        </FadingContainer>
      }
      mainSection={
        <QuestionIntroduction
          tipSection={<QuestionTips items={[question2TipItem]} />}
          intro={
            <IntroQuotedText
              title={phaseName}
              text={`“Basic expressions of what an offering is, and what it enables, speak to one’s earliest awareness of need state.”`}
            />
          }
          paragraphsToAnimate={[introText, ...question2Paragraphs]}
          form={
            <Box sx={{ paddingTop: { xs: '30px', md: '180px' } }}>
              <QuestionExampleBox title="EXAMPLES OF WHAT GOOD ANSWERS LOOK LIKE:">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, auto)' }}>
                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText fs="15px" mobileFS="13px">
                        My
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        good
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px">
                        ...
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        charm
                      </QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText fs="15px" mobileFS="13px">
                      +
                    </QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        bracelet
                      </QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText fs="15px" mobileFS="13px">
                        My
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        service
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px">
                        ...
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        social
                      </QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText fs="15px" mobileFS="13px">
                      +
                    </QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        media
                      </QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText fs="15px" mobileFS="13px">
                        My
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        application
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px">
                        ...
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        property listings
                      </QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText fs="15px" mobileFS="13px">
                      +
                    </QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        hub
                      </QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText fs="15px" mobileFS="13px">
                        My
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        establishment
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px">
                        ...
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        pilates
                      </QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText fs="15px" mobileFS="13px">
                      +
                    </QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        studio
                      </QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: '0 0 16px 0' }} justifyContent="end">
                      <QuestionExampleText fs="15px" mobileFS="13px">
                        My
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        website
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px">
                        ...
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        speech
                      </QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText fs="15px" mobileFS="13px">
                      +
                    </QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        improvement
                      </QuestionExampleBlueText>
                    </FlexBox>
                  </Box>
                </div>
              </QuestionExampleBox>
              <FormQuestion2
                onClick={onClick}
                answer={answer}
                formRef={formRef}
                disabled={disabled}
                requestError={requestError}
                questionError={questionError}
                isChild={isChild}
              />
              <br />
              <UpperCaseText sx={{ fontSize: '10px' }}>WHEN ANSWERING, DON&lsquo;T WORRY ABOUT...</UpperCaseText>
              <br />
              <LightText>
                <Box>
                  <Typography component="span" variant="lightTextStart">
                    “Offering vs. Category:”
                  </Typography>{' '}
                  Don’t get caught up on whether a word fits better in “category” vs. “offering type”. Just find a&nbsp;
                  <b>focused</b> and <b>concise</b> way to answer the question “what is it?” As long as you do that, you
                  can’t get it wrong.
                </Box>
              </LightText>
            </Box>
          }
          fomTitle={''}
          onFormInView={(isInView) => {
            setShowTipSection(isInView);
          }}
          questionVisited={questionVisited}
        />
      }
    />
  );
};
