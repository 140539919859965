import { colorTones } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box, Tooltip } from '@mui/material';
import { Icon } from 'components/atoms';
import React, { useState } from 'react';

type CompactExpertLikeProps = {
  favorite: boolean;
};

const getStrokeColor = (favorite: boolean, hovered: boolean) => {
  if ((favorite && hovered) || (!favorite && !hovered)) {
    return colorTones.nightSky80;
  }
  return colorTones.nightSky80;
};

const StyledIconWrapper = styled(Box)({
  paddingLeft: '8px',
  paddingRight: '8px',
  minWidth: '16px',
});

export const CompactExpertLike: React.FC<CompactExpertLikeProps> = ({ favorite }) => {
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <Tooltip title="Expert Like" placement="top">
      <StyledIconWrapper
        sx={{ cursor: 'pointer' }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Icon
          type="interfaceFavoriteHeart"
          width={16}
          height={16}
          stroke={getStrokeColor(favorite, hovered)}
          fill={colorTones.nightSky80}
        />
      </StyledIconWrapper>
    </Tooltip>
  );
};
