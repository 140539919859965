import { Grid } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

export const ResultPresentationContainer: React.FC<Props> = ({ children }) => (
  <Grid
    container
    sx={{
      p: 0,
      m: 0,
      direction: 'column',
      alignItems: 'center',
      minHeight: '80vh',
      width: '100%',
    }}
  >
    <Grid item md={0} lg={1} p={0} m={0} paddingLeft={2} paddingRight={2}></Grid>
    <Grid item md={12} lg={11} p={0} m={0} paddingLeft={2} paddingRight={2}>
      {children}
    </Grid>
  </Grid>
);
