import { colorTones } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box, SxProps, Tooltip } from '@mui/material';
import { Icon } from 'components/atoms';
import React from 'react';

type ButtonVariant = 'sm' | 'md';

type Props = {
  tooltipLabel: string;
  variant?: ButtonVariant;
  sx?: SxProps;
  onClick?: () => void;
};

const StyledButtonWrapper = styled(Box)({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '16px',
  gap: '8px',
  background: colorTones.neutralsWhite,
  border: `1px solid ${colorTones.neutralsSlightGray}`,
  boxShadow: '1px 4px 10px rgba(0, 13, 46, 0.1), 0px 0px 24px rgba(0, 13, 46, 0.04), 1px 4px 4px rgba(0, 13, 46, 0.16)',
  borderRadius: '16px',
  '&:hover': {
    cursor: 'pointer',
  },
});

const StyledIconWrapper = styled(Box)({
  rotate: '90deg',
  '&:hover': {
    opacity: '60%',
  },
});

const mapButtonVariantToIconSize: Record<ButtonVariant, number> = {
  sm: 16,
  md: 24,
};

const mapButtonVariantToButtonSize: Record<ButtonVariant, number> = {
  sm: 48,
  md: 56,
};

export const ScrollButton: React.FC<Props> = ({ variant = 'sm', tooltipLabel, sx, onClick }) => {
  return (
    <StyledButtonWrapper
      onClick={onClick}
      width={mapButtonVariantToButtonSize[variant]}
      height={mapButtonVariantToButtonSize[variant]}
      sx={sx}
    >
      <Tooltip title={tooltipLabel} placement="top" arrow>
        <StyledIconWrapper>
          <Icon
            stroke={colorTones.ocean200}
            type="interfaceArrowsButtonRightDouble"
            width={mapButtonVariantToIconSize[variant]}
            height={mapButtonVariantToIconSize[variant]}
          />
        </StyledIconWrapper>
      </Tooltip>
    </StyledButtonWrapper>
  );
};
