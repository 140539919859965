import { colorTones } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { Icon } from 'components/atoms/Icons';
import React from 'react';
import { ExpertReviewStatus } from 'sdk/types';

type BadgeVariant = 'big' | 'small';

type Props = {
  status: ExpertReviewStatus;
  withIcon?: boolean;
  variant?: BadgeVariant;
};

const StyledWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '4px 12px',
  gap: '4px',
  borderRadius: '6px',
});

const StyledText = styled(Typography)({
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '16px',
  paddingLeft: '4px',
});

const mapExpertStatusToLabel: Record<ExpertReviewStatus, string> = {
  not_payed: 'Not payed',
  requested: 'Requested',
  pending: 'In review',
  completed: 'Completed!',
};

const variantToHeightInPx: Record<BadgeVariant, number> = {
  big: 32,
  small: 24,
};

const variantToWidthInPx: Record<BadgeVariant, number> = {
  big: 120,
  small: 105,
};

const variantToIconSizeInPx: Record<BadgeVariant, number> = {
  big: 16,
  small: 11,
};

export const BadgeExpertReviewStatus: React.FC<Props> = ({ status, variant = 'small', withIcon = true }) => {
  if (status === 'not_payed') {
    return null;
  }
  return (
    <StyledWrapper
      sx={{
        background: colorTones.nightSky10,
        color: colorTones.nightSky80,
        width: `${variantToWidthInPx[variant]}px`,
        height: `${variantToHeightInPx[variant]}px`,
      }}
    >
      {withIcon && (
        <Icon
          type="expert"
          width={variantToIconSizeInPx[variant]}
          height={variantToIconSizeInPx[variant]}
          stroke={colorTones.nightSky80}
          strokeWidth={3}
        />
      )}
      <StyledText sx={status === 'completed' ? { fontStyle: 'normal' } : { fontStyle: 'italic' }}>
        {mapExpertStatusToLabel[status]}
      </StyledText>
    </StyledWrapper>
  );
};
