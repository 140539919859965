import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const Gift: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 0.97365445,6.2808086 c 0,-0.3879594 0,-0.5819308 0.0300312,-0.7432396 C 1.1270091,4.8751509 1.6116743,4.3573262 2.2316738,4.2255657 2.382653,4.1934792 2.5642033,4.1934792 2.9273192,4.1934792 h 5.0795301 5.0795287 c 0.363147,0 0.544681,0 0.695661,0.032087 0.620015,0.1317606 1.10468,0.6495853 1.227996,1.3120034 0.03001,0.1613088 0.03001,0.3552801 0.03001,0.7432395 v 0 0 c 0,0.3879594 0,0.5819476 -0.03001,0.7432558 -0.123316,0.6624351 -0.607981,1.1802592 -1.227996,1.3120116 -0.15098,0.032059 -0.332514,0.032059 -0.695661,0.032059 H 8.0068493 2.9273195 c -0.363116,0 -0.5446662,0 -0.6956454,-0.032059 C 1.6116746,8.2043236 1.1270094,7.6864995 1.0036862,7.0240644 0.97365445,6.8627562 0.97365445,6.668768 0.97365445,6.2808086 Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
      id="path2"
    />
    <path
      d="m 1.7551205,8.3681366 v 3.3397274 c 0,1.574347 0,2.361521 0.457775,2.850624 0.4577671,0.489103 1.1945412,0.489103 2.6680891,0.489103 h 0.7814659 4.6887975 0.781465 c 1.473533,0 2.210299,0 2.668081,-0.489103 0.457784,-0.489103 0.457784,-1.276277 0.457784,-2.850624 V 8.3681366"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      id="path4"
    />
    <path
      d="M 8.0068493,4.1934793 V 15.047591"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      id="path6"
    />
    <path
      d="M 7.5186673,3.5415063 5.971833,1.4756681 C 5.3899143,0.69850547 4.3034968,0.63581874 3.6448068,1.3395742 2.6602144,2.3915297 3.3544845,4.1934793 4.7469085,4.1934793 H 7.225383 c 0.3149307,0 0.4900575,-0.3892201 0.2932843,-0.651973 z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      id="path8"
    />
    <path
      d="M 8.4950307,3.5415063 10.041865,1.4756681 c 0.581957,-0.77716263 1.668352,-0.83984936 2.327049,-0.1360939 0.984569,1.0519555 0.290315,2.8539051 -1.102101,2.8539051 H 8.7883143 c -0.3149307,0 -0.4900568,-0.3892201 -0.2932836,-0.651973 z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      id="path10"
    />
  </svg>
);
