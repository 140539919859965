import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const GraduateCap: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 2.2864942,5.2794382 9.4406451,1.925422 c 1.5193349,-0.7123011 3.2220089,-0.7123011 4.7413419,0 l 7.154231,3.3540697 c 1.556221,0.7295686 1.556221,3.2550829 0,3.9846517 l -7.154124,3.3539726 c -1.519333,0.712302 -3.222034,0.712302 -4.7413679,0 L 4.3269553,10.220757"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      id="path945"
    />
    <path
      d="m 1.4402977,14.53725 v -3.060478 c 0,-1.367774 0,-2.0515272 0.3531835,-2.5871972 C 2.1466647,8.353906 2.7752206,8.0844676 4.032386,7.5458581 L 5.1824977,7.0528498"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      id="path947"
    />
    <path
      d="m 19.294212,10.220445 v 5.480078 c 0,1.077753 -0.538342,2.086811 -1.481109,2.609115 -1.570067,0.869313 -4.083008,2.068207 -6.003291,2.068207 -1.9202833,0 -4.4332239,-1.198841 -6.0032108,-2.068367 -0.9428473,-0.52209 -1.4811895,-1.531202 -1.4811895,-2.608955 v -5.480078"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      id="path949"
    />
  </svg>
);
