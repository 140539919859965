import { Box, Typography } from '@mui/material';
import {
  FlexBox,
  IntroParagraphText,
  LightText,
  QuestionExampleBlueText,
  QuestionExampleText,
  QuestionStepLabel,
  UpperCaseText,
} from 'components/atoms';
import { FadingContainer, QuestionContainer } from 'components/molecules';
import { IntroQuotedText, QuestionExampleBox, QuestionTips } from 'components/organism/Questionnaire';
import { question5Paragraphs, question5TipItem } from 'components/questionnaire/constants';
import { FormQuestion5 } from 'components/questionnaire/forms';
import { QuestionIntroduction } from 'components/questionnaire/QuestionIntroduction';
import { Question5Props } from 'components/questionnaire/types';
import React, { useState } from 'react';

type Props = {
  phaseName: string;
  questionVisited: boolean;
} & Question5Props;

export const Question5: React.FC<Props> = ({
  answer,
  phaseName,
  formRef,
  questionVisited,
  requestError,
  questionError,
  onClick,
}) => {
  const [showTipSection, setShowTipSection] = useState<boolean>(false);
  const introText = (
    <Box key={'intro5Text'}>
      <QuestionStepLabel>{phaseName}</QuestionStepLabel>
      <IntroParagraphText>
        List the specific{' '}
        <Typography component="span" variant="questionInstruction">
          design feature, material, or manufacturing approach{' '}
        </Typography>
        that drives superior performance.
      </IntroParagraphText>
    </Box>
  );

  return (
    <QuestionContainer
      tipSection={
        <FadingContainer visible={showTipSection}>
          {<QuestionTips items={[question5TipItem]} defaultExpanded />}
        </FadingContainer>
      }
      mainSection={
        <QuestionIntroduction
          tipSection={<QuestionTips items={[question5TipItem]} />}
          intro={
            <IntroQuotedText
              title={phaseName}
              text={`“Celebrating how something is made sparks conversation around purposeful design and the exceptional results it enables.”`}
            />
          }
          paragraphsToAnimate={[introText, ...question5Paragraphs]}
          form={
            <Box paddingTop="80px">
              <QuestionExampleBox title="EXAMPLES OF WHAT GOOD ANSWERS LOOK LIKE:">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <FlexBox flexDirection="column" sx={{ padding: 0, margin: 0 }}>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleText>... by</QuestionExampleText>
                      <QuestionExampleBlueText>blown&nbsp;</QuestionExampleBlueText>
                      <QuestionExampleBlueText>glass</QuestionExampleBlueText>
                    </FlexBox>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleText>... by</QuestionExampleText>
                      <QuestionExampleBlueText>focused&nbsp;</QuestionExampleBlueText>
                      <QuestionExampleBlueText>creativity</QuestionExampleBlueText>
                    </FlexBox>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleText>... by</QuestionExampleText>
                      <QuestionExampleBlueText>AI-enabled&nbsp;</QuestionExampleBlueText>
                      <QuestionExampleBlueText>algorithm</QuestionExampleBlueText>
                    </FlexBox>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleText>... by</QuestionExampleText>
                      <QuestionExampleBlueText>holistic&nbsp;</QuestionExampleBlueText>
                      <QuestionExampleBlueText>perspective</QuestionExampleBlueText>
                    </FlexBox>
                    <FlexBox sx={{ padding: 0, margin: '0 0 16px 0' }}>
                      <QuestionExampleText>... by</QuestionExampleText>
                      <QuestionExampleBlueText>behavioral&nbsp;</QuestionExampleBlueText>
                      <QuestionExampleBlueText>science</QuestionExampleBlueText>
                    </FlexBox>
                  </FlexBox>
                </div>
              </QuestionExampleBox>
              <FormQuestion5
                onClick={onClick}
                answer={answer}
                formRef={formRef}
                requestError={requestError}
                questionError={questionError}
              />
              <br />
              <UpperCaseText sx={{ fontSize: '10px' }}>WHEN ANSWERING, DON&lsquo;T WORRY ABOUT...</UpperCaseText>
              <br />
              <LightText>
                <Box>
                  <Typography component="span" variant="lightTextStart">
                    Whether its “tangible”.
                  </Typography>{' '}
                  Excellence can be driven by employee behaviors, quality standards, or organizational values. As long
                  as it’s a systematic, concretely defined part of your offering or method of construction – it can be
                  classified as your hero.
                </Box>
              </LightText>
            </Box>
          }
          fomTitle={''}
          onFormInView={(isInView) => {
            setShowTipSection(isInView);
          }}
          questionVisited={questionVisited}
        />
      }
    />
  );
};
