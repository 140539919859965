import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FormQuestionText, Input } from 'components/atoms';
import { FormError, MultipleInputFormContainer, QuestionFormContainer } from 'components/molecules';
import { MultipleInputItemFormContainer } from 'components/molecules/Container/MultipleInputItemFormContainer';
import { Question6FormValues, Question9FormValues } from 'components/questionnaire/types';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { getFormikErrorByIndex } from 'utils';
import * as yup from 'yup';

type FormValues = Question6FormValues | Question9FormValues;

type Props = {
  initialValues: FormValues;
  onSubmitForm: (values: FormValues) => void;
  formRef?: React.Ref<FormikProps<FormValues>>;
  additionalTitle?: string;
  mainTitle: string;
  itemTitle?: string;
  maxRows: number;
  inputConcatenation: string;
  punctuationMark: string;
  validationSchema: yup.AnyObjectSchema;
  questionErrors?: {
    adjective?: string;
    noun?: string;
  }[];
  requestError?: string;
};

export const CommonAdjectivesAndNounsQuestion: React.FC<Props> = ({
  initialValues,
  onSubmitForm,
  formRef,
  validationSchema,
  additionalTitle,
  mainTitle,
  itemTitle,
  questionErrors,
  requestError,
  inputConcatenation = 'and',
  punctuationMark = '.',
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <QuestionFormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
        validateOnChange
        innerRef={formRef}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <MultipleInputFormContainer>
              <FormQuestionText>
                <span>{additionalTitle}</span>
                <span>{mainTitle}</span>
              </FormQuestionText>
              {values.responses.map((response, index) => {
                const key = `responses-${index}-adjective`;

                const hasErrorAdjective =
                  Boolean(getFormikErrorByIndex(errors, index)?.adjective) && touched.responses?.[index]?.adjective;
                const hasErrorNoun =
                  Boolean(getFormikErrorByIndex(errors, index)?.noun) && touched.responses?.[index]?.noun;
                return (
                  <MultipleInputItemFormContainer key={key}>
                    <Box>
                      {itemTitle}
                      <Input
                        name={`responses[${index}].adjective`}
                        value={response.adjective}
                        placeholder="Descriptor"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          (Boolean(questionErrors?.[index]?.adjective) &&
                            questionErrors?.[index]?.adjective === response.adjective) ||
                          (Boolean(getFormikErrorByIndex(errors, index)?.adjective) &&
                            touched.responses?.[index]?.adjective)
                        }
                      />
                      {inputConcatenation}
                    </Box>
                    <Box>
                      <Input
                        name={`responses[${index}].noun`}
                        value={response.noun}
                        placeholder="Word"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          (Boolean(questionErrors?.[index]?.noun) && questionErrors?.[index]?.noun === response.noun) ||
                          (Boolean(getFormikErrorByIndex(errors, index)?.noun) && touched.responses?.[index]?.noun)
                        }
                      />
                    </Box>
                    {((Boolean(questionErrors?.[index]?.adjective) &&
                      questionErrors?.[index]?.adjective === response.adjective) ||
                      (Boolean(questionErrors?.[index]?.noun) && questionErrors?.[index]?.noun === response.noun)) && (
                      <FormError>Name is not allowed</FormError>
                    )}
                    {Boolean(requestError) && <FormError>{requestError}</FormError>}
                    {hasErrorAdjective && <FormError>{getFormikErrorByIndex(errors, index)?.adjective}</FormError>}
                    {hasErrorNoun && <FormError>{getFormikErrorByIndex(errors, index)?.noun}</FormError>}
                    <p>{!isMobile && punctuationMark}</p>
                  </MultipleInputItemFormContainer>
                );
              })}
            </MultipleInputFormContainer>
          </Form>
        )}
      </Formik>
    </QuestionFormContainer>
  );
};
