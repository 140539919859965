import { colorTones, fonts } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';
import { Icon } from 'components/atoms';
import { ModalWindow } from 'components/organism';
import { useHandleModal } from 'hooks';
import React from 'react';

type Props = {
  handleBuyExpertInTheLoop: () => void;
  handleBuyExpertPlus: () => void;
  isExpertReviewPayed?: boolean;
  isExpertPlusReviewPayed?: boolean;
};

const StyledButton = styled(Button)({
  minWidth: '216px',
  height: '48px',
  marginRight: 2,
  borderRadius: '8px',
  background: colorTones.nightSky80,
  color: colorTones.neutralsWhite,
  '&:hover': {
    background: 'darkblue',
  },
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '16px',
  padding: '8px 16px',
});

const StyledTextWrapper = styled(Box)({
  minHeight: '143px',
  marginBottom: '24px',
});

const StyledModalText = styled(Box)({
  fontStyle: 'normal',
  margin: 'auto',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  fontFamily: fonts.primeFont,
  textAlign: 'center',
  color: colorTones.neutralsWhite,
  paddingBottom: '8px',
});

const StyledButtonsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: 2,
  justifyItems: 'center',
  margin: '16px',
  '@media (min-width: 600px)': {
    flexDirection: 'row',
  },
  '> button': {
    width: '100%',
  },
});

const StyledGetExpertButton = styled(StyledButton)({
  background: colorTones.neutralsWhite,
  color: colorTones.primaryNightSky,
  fontSize: '18px',
  justifyContent: 'center',
  '&:hover': {
    background: colorTones.neutralsSlightGray,
  },
});

export const GetExpertInTheLoop: React.FC<Props> = ({
  handleBuyExpertInTheLoop,
  handleBuyExpertPlus,
  isExpertReviewPayed,
  isExpertPlusReviewPayed,
}) => {
  const { openModal, handleOpen, handleClose } = useHandleModal();

  const onGetExpertPicks = () => {
    handleClose();
    handleBuyExpertInTheLoop?.();
  };
  const onGetExpertPlus = () => {
    handleClose();
    handleBuyExpertPlus?.();
  };

  const title = 'Expert In The Loop';

  return (
    <Box>
      {(!isExpertReviewPayed || !isExpertPlusReviewPayed) && (
        <StyledButton onClick={handleOpen}>
          <Icon type={'expert'} stroke={colorTones.neutralsWhite} />
          {title}
        </StyledButton>
      )}

      <ModalWindow
        open={openModal}
        onClose={handleClose}
        variant="dark"
        ariaLabelledby={title && 'modal-edit-naming-journey-title'}
        title={title}
      >
        <StyledTextWrapper>
          {!isExpertReviewPayed ? (
            <StyledModalText>
              Want some advice and ideas from a human, highly experienced naming professional? With Expert Picks, a
              real-world Naming Specialist will review your Discovery Journey, then select their top 3 favorites from
              your Results Presentation and let you know why those stood out. With Expert Plus, in addition to Expert
              Picks, the Specialist will add 3 totally new names and rationales to your Results – all with their stamp
              of approval.
            </StyledModalText>
          ) : (
            <StyledModalText>
              Want some advice and ideas from a human, highly experienced naming professional? With Expert Plus, our
              Specialist will add 3 totally new names and rationales to your Results – all with their stamp of approval.
            </StyledModalText>
          )}
        </StyledTextWrapper>
        {!isExpertReviewPayed ? (
          <StyledButtonsWrapper>
            <StyledGetExpertButton variant="outlined" color="primary" onClick={onGetExpertPicks}>
              Expert Picks - $49 USD
            </StyledGetExpertButton>
            <StyledGetExpertButton variant="outlined" color="primary" onClick={onGetExpertPlus}>
              Expert Plus - $99 USD
            </StyledGetExpertButton>
          </StyledButtonsWrapper>
        ) : (
          <StyledButtonsWrapper>
            <StyledGetExpertButton variant="outlined" color="primary" onClick={onGetExpertPlus}>
              Expert Plus - $50 USD
            </StyledGetExpertButton>
          </StyledButtonsWrapper>
        )}
      </ModalWindow>
    </Box>
  );
};
