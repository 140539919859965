import { colorTones, fonts } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { QuestionStepLabel } from 'components/atoms';
import React from 'react';

type Props = {
  title: string;
  text: string;
};

const QuestionIntroText = styled(Box)({
  fontFamily: fonts.primeFont,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '40px',
  lineHeight: '150%',
  color: colorTones.primaryNightSky,
  textAlign: 'center',
});

const normalizeText = (text: string) => {
  return (
    <>
      {text.split('\\n').map((line, index) => {
        const textKey = `${line.split(' ').join('-')}-${index}`;
        return (
          <Box key={textKey}>
            {line}
            <br />
          </Box>
        );
      })}
    </>
  );
};

export const IntroQuotedText: React.FC<Props> = ({ title, text }) => {
  return (
    <Box>
      <QuestionStepLabel>{title}</QuestionStepLabel>
      <QuestionIntroText>{normalizeText(text)}</QuestionIntroText>
    </Box>
  );
};
