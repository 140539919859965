import { colorTones, fonts } from 'constants/designSystem';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from 'components/atoms';
import React from 'react';

type Props = {
  flagNameLabel: string;
  flaggedNameLabel: string;
  unFlagNameLabel: string;
  active?: boolean;
  hover?: boolean;
  handleFeedback?: () => void;
};

const StyledErroneousBadgeWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '6px 12px',
  gap: '6px',
  minWidth: '118px',
  height: '32px',
  borderRadius: '24px',
  cursor: 'pointer',
});

const StyledErroneousBadgeLabel = styled(Typography)({
  fontFamily: fonts.primeFont,
  fontStyle: 'italic',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
});

const getLabelName = ({
  flagNameLabel,
  flaggedNameLabel,
  unFlagNameLabel,
  active,
  hover,
}: {
  flagNameLabel: string;
  flaggedNameLabel: string;
  unFlagNameLabel: string;
  active: boolean;
  hover: boolean;
}) => {
  if (active && !hover) {
    return flaggedNameLabel;
  }
  if (active && hover) {
    return unFlagNameLabel;
  }
  return flagNameLabel;
};

const getBackgroundColor = (active: boolean, hover: boolean) => {
  if (!active && hover) {
    return colorTones.primaryRed10;
  }

  return colorTones.neutralsSlightGray;
};

const getIconStrokeColor = (active: boolean, hover: boolean) => {
  if ((!active && hover) || (active && hover)) {
    return colorTones.primaryRed;
  }
  return colorTones.nightSky100;
};

const getIconFillColor = (active: boolean, hover: boolean) => {
  if (active && !hover) {
    return colorTones.nightSky100;
  }
  if (!active && hover) {
    return colorTones.primaryRed;
  }
  return 'none';
};

const getLabelColor = (active: boolean, hover: boolean) => {
  if (active && !hover) {
    return colorTones.nightSky100;
  }
  if (!active && hover) {
    return colorTones.primaryRed;
  }

  return colorTones.nightSky200;
};

export const FlagErroneousBadge: React.FC<Props> = ({
  flagNameLabel,
  flaggedNameLabel,
  unFlagNameLabel,
  active = false,
  hover = false,
  handleFeedback,
}) => (
  <StyledErroneousBadgeWrapper
    onClick={handleFeedback}
    sx={{
      background: getBackgroundColor(active, hover),
      border: active && !hover ? `1px solid ${colorTones.neutralsGray}` : 'initial',
    }}
  >
    <Box sx={{ marginLeft: '8px', marginBottom: '-4px' }}>
      <Icon
        type="travelMapFlag"
        stroke={getIconStrokeColor(active, hover)}
        fill={getIconFillColor(active, hover)}
        width={16}
        height={16}
      />
    </Box>
    <Box sx={{ marginRight: '8px' }}>
      <StyledErroneousBadgeLabel sx={{ color: getLabelColor(active, hover) }}>
        {getLabelName({
          flagNameLabel,
          flaggedNameLabel,
          unFlagNameLabel,
          active,
          hover,
        })}
      </StyledErroneousBadgeLabel>
    </Box>
  </StyledErroneousBadgeWrapper>
);
