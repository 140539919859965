import { Question8FormValues, Question8Props } from 'components/questionnaire/types';
import React from 'react';
import { isValidStringAnswer, mapDtoAnswer7ToQuestion8, mapQuestion8ToDtoAnswer7 } from 'sdk/questionnaire';
import { Question } from 'sdk/questionnaire/enums';

import { CommonAdjectivesQuestion } from './CommonAdjectivesQuestion';

type Props = Question8Props;
const maxRows = 6;

const initMaErrors = (ma?: string[]) => {
  if (!ma || ma?.length === 0) {
    return [];
  }
  const maErrors = [];
  for (let i = 0; i < ma.length; i += 2) {
    maErrors.push({ adjective1: ma?.[i], adjective2: ma?.[i + 1] });
  }

  return maErrors;
};

export const FormQuestion8: React.FC<Props> = ({ answers, formRef, requestError, questionError, onClick }) => {
  const initialValues: Question8FormValues = mapDtoAnswer7ToQuestion8(answers);
  const onSubmitForm = (values: Question8FormValues) => {
    const responses = values.responses.filter(
      ({ adjective1, adjective2 }) => isValidStringAnswer(adjective1) || isValidStringAnswer(adjective2),
    );
    onClick?.({ [Question.Question8]: mapQuestion8ToDtoAnswer7({ responses }) }, Question.Question8);
  };
  const questionErrors = initMaErrors(questionError?.MA);

  return (
    <CommonAdjectivesQuestion
      initialValues={initialValues}
      onSubmitForm={onSubmitForm}
      maxRows={maxRows}
      formRef={formRef}
      additionalTitle={'Fill in the blanks.'}
      mainTitle={'When using my offering, I want people to say:'}
      itemTitle={'Wow! I feel really'}
      inputConcatenation={'and'}
      punctuationMark={'.'}
      requestError={requestError}
      questionErrors={questionErrors}
    />
  );
};
