import { Box, styled } from '@mui/material';

const StyledFlexBoxRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  m: 0,
  p: 0,
});

export const FlexBoxRow = StyledFlexBoxRow;
