import { colorTones } from 'constants/designSystem';

import { Box, Grid, Typography } from '@mui/material';
import { FlatButton, ProgressBar } from 'components/atoms';
import { Icon } from 'components/atoms/Icons';
import { BadgeCreationDate, BadgePaymentNeeded } from 'components/molecules';
import React from 'react';
import { ContinueQuestionnaireType } from 'sdk/internalApi';

type Props = {
  completion: number;
  formattedCreateDate?: string;
  id: number;
  name: string;
  type: ContinueQuestionnaireType;
  onClick?: (id: number, itemType: ContinueQuestionnaireType) => void;
};

const arrowRight = (
  <Icon type="interfaceArrowsRight" stroke={colorTones.plant200} width={24} height={24} viewBox="0 0 16 16" />
);

export const DraftTile: React.FC<Props> = ({ completion, formattedCreateDate, id, name, type, onClick }) => {
  const handleClick = () => {
    onClick?.(id, type);
  };

  return (
    <Box>
      <ProgressBar completion={completion} />
      <Grid container spacing={0} sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 2, paddingRight: 2 }}>
        <Grid item xs={8} md={9} lg={10} display="flex" justifyContent="left" alignItems="center" p={0} m={0}>
          <Box>
            <Typography variant="h5">{name}</Typography>
            {type === 'questionnaire' && <BadgePaymentNeeded />}
            <BadgeCreationDate formattedCreateDate={formattedCreateDate} />
          </Box>
        </Grid>
        <Grid item xs={4} md={3} lg={2} display="flex" justifyContent="right" alignItems="center" p={0} m={0}>
          <FlatButton onClick={handleClick}>{arrowRight}</FlatButton>
        </Grid>
      </Grid>
    </Box>
  );
};
