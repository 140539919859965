import { PairsAdjectives, Question7FormValues } from 'components/questionnaire/types';
import { DtoAnswer6 } from 'sdk/questionnaire/types';

export const mapDtoAnswer6ToQuestion7 = (dtoAnswer6?: DtoAnswer6): Question7FormValues => {
  if ((dtoAnswer6?.EDA?.length ?? 0) === 0) {
    return { responses: [{ adjective1: '', adjective2: '' }] };
  }

  const responses: PairsAdjectives[] = [];
  const eda = dtoAnswer6?.EDA || [];

  for (let i = 0; i < eda.length; i += 2) {
    const adjective1 = eda?.[i] || '';
    const adjective2 = eda?.[i + 1] || '';
    responses.push({ adjective1, adjective2 });
  }

  return { responses };
};
