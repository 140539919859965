import { colorTones } from 'constants/designSystem';

import React, { FunctionComponent, createElement } from 'react';

import { AudioSubtitles } from './AudioSubtitles';
import { BabyFace } from './BabyFace';
import { BeachUmbrella } from './BeachUmprella';
import { ChatBubblesTherapist } from './ChatBubblesTherapist';
import { CleanerStars } from './CleanerStars';
import { Dumbbell } from './Dumbbell';
import { EntertainmentNewsPaper } from './EntertainmentNewsPaper';
import { Expert } from './Expert';
import { Gift } from './Gift';
import { GraduateCap } from './GraduateCap';
import { ImagePhotoPolaroid } from './ImagePhotoPolaroid';
import { InterfaceAdd1 } from './InterfaceAdd1';
import { InterfaceAddSquare } from './InterfaceAddSquare';
import { InterfaceAlertWarningCircle } from './InterfaceAlertWarningCircle';
import { InterfaceArrowButtonLeft } from './InterfaceArrowButtonLeft';
import { InterfaceArrowButtonUp } from './InterfaceArrowButtonUp';
import { InterfaceArrowsButtonLeftDouble } from './InterfaceArrowsButtonLeftDouble';
import { InterfaceArrowsButtonRightDouble } from './InterfaceArrowsButtonRightDouble';
import { InterfaceArrowsLeft } from './InterfaceArrowsLeft';
import { InterfaceArrowsRight } from './InterfaceArrowsRight';
import { InterfaceDelete } from './InterfaceDelete';
import { InterfaceDelete1 } from './InterfaceDelete1';
import { InterfaceDeleteCircle } from './InterfaceDeleteCircle';
import { InterfaceEditSortAscending } from './InterfaceEditSortAscending';
import { InterfaceEditSortDescending } from './InterfaceEditSortDescending';
import { InterfaceEditView } from './InterfaceEditView';
import { InterfaceEditWrite2 } from './InterfaceEditWrite2';
import { InterfaceFavoriteHeart } from './InterfaceFavoriteHeart';
import { InterfaceHelpCustomerSupport2 } from './InterfaceHelpCustomerSupport2';
import { InterfaceHome3 } from './InterfaceHome3';
import { InterfacePresentationBoard } from './InterfacePresentationBoard';
import { InterfaceTimeAlarm } from './InterfaceTimeAlarm';
import { InterfaceTimeClockCircle } from './InterfaceTimeClockCircle';
import { InterfaceUploadButton1 } from './InterfaceUploadButton1';
import { InterfaceUserEdit } from './InterfaceUserEdit';
import { InterfaceValidationCheck } from './InterfaceValidationCheck';
import { InterfaceValidationCheckCircle } from './InterfaceValidationCheckCircle';
import { LightBulbOn } from './LightBulbOn';
import { MailChatBubbleCensorSquare } from './MailChatBubbleCensorSquare';
import { MailChatBubbleOvalQuestion } from './MailChatBubbleOvalQuestion';
import { MailInboxPost } from './MailInboxPost';
import { MailSmileyFriendlyFace } from './MailSmileyFriendlyFace';
import { MailSmileyHappy } from './MailSmileyHappy';
import { MailSmileySadFace } from './MailSmileySadFace';
import { MailSmileyUnhappy } from './MailSmileyUnhappy';
import { QualityEducation } from './QualityEducation';
import { ThreeGifts } from './ThreeGifts';
import { TravelMapEarth1 } from './TravelMapEarth1';
import { TravelMapFlag } from './TravelMapFlag';
import { TwoGifts } from './TwoGifts';
import { IconType, SvgImageProps } from './types';

export type Props = SvgImageProps & {
  type: IconType;
  viewBox?: string;
};

const mapSvgIcons: Record<IconType, FunctionComponent<SvgImageProps>> = {
  audioSubtitles: AudioSubtitles,
  babyFace: BabyFace,
  beachUmbrella: BeachUmbrella,
  cleanerStars: CleanerStars,
  chatBubblesTherapist: ChatBubblesTherapist,
  dumbbell: Dumbbell,
  gift: Gift,
  twoGifts: TwoGifts,
  threeGifts: ThreeGifts,
  graduateCap: GraduateCap,
  qualityEducation: QualityEducation,
  lightBulbOn: LightBulbOn,
  validationCheck: InterfaceValidationCheck,
  interfaceDelete: InterfaceDelete,
  interfaceDelete1: InterfaceDelete1,
  interfaceDeleteCircle: InterfaceDeleteCircle,
  interfaceEditWrite2: InterfaceEditWrite2,
  interfaceArrowsLeft: InterfaceArrowsLeft,
  interfaceArrowsRight: InterfaceArrowsRight,
  interfaceAlertWarningCircle: InterfaceAlertWarningCircle,
  interfaceValidationCheckCircle: InterfaceValidationCheckCircle,
  interfaceAddSquare: InterfaceAddSquare,
  interfaceAdd1: InterfaceAdd1,
  interfaceHome3: InterfaceHome3,
  entertainmentNewsPaper: EntertainmentNewsPaper,
  interfaceHelpCustomerSupport2: InterfaceHelpCustomerSupport2,
  interfaceUserEdit: InterfaceUserEdit,
  interfaceArrowButtonLeft: InterfaceArrowButtonLeft,
  interfaceUploadButton1: InterfaceUploadButton1,
  interfaceEditView: InterfaceEditView,
  mailInboxPost: MailInboxPost,
  interfaceTimeClockCircle: InterfaceTimeClockCircle,
  interfacePresentationBoard: InterfacePresentationBoard,
  mailChatBubbleOvalQuestion: MailChatBubbleOvalQuestion,
  mailSmileyHappy: MailSmileyHappy,
  mailSmileyUnhappy: MailSmileyUnhappy,
  mailSmileySadFace: MailSmileySadFace,
  mailSmileyFriendlyFace: MailSmileyFriendlyFace,
  mailChatBubbleCensorSquare: MailChatBubbleCensorSquare,
  travelMapFlag: TravelMapFlag,
  interfaceFavoriteHeart: InterfaceFavoriteHeart,
  interfaceArrowButtonUp: InterfaceArrowButtonUp,
  interfaceEditSortDescending: InterfaceEditSortDescending,
  interfaceEditSortAscending: InterfaceEditSortAscending,
  interfaceTimeAlarm: InterfaceTimeAlarm,
  interfaceArrowsButtonLeftDouble: InterfaceArrowsButtonLeftDouble,
  interfaceArrowsButtonRightDouble: InterfaceArrowsButtonRightDouble,
  imagePhotoPolaroid: ImagePhotoPolaroid,
  travelMapEarth1: TravelMapEarth1,
  expert: Expert,
};

export const Icon: React.FC<Props> = ({
  type,
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  fill = 'none',
  stroke = colorTones.nightSky80,
  strokeWidth = '1.1',
}) => {
  const svgIcon = mapSvgIcons[type];
  const IconByType = createElement<SvgImageProps>(svgIcon, { fill, stroke, width, height, viewBox, strokeWidth });
  return <>{IconByType}</>;
};
