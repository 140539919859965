import { Box, Typography } from '@mui/material';
import { QuestionTips } from 'components/organism/Questionnaire';
import { EvaluationTips } from 'components/presentation';
import React from 'react';

type Props = {
  title: string;
  subTitle: string;
  body1: string;
  body2: string;
};

export const ResultPresentationIntro: React.FC<Props> = ({ title, subTitle, body1, body2 }) => (
  <>
    <Box maxWidth={1500} paddingTop={4}>
      <Typography sx={{ paddingBottom: 4, fontSize: '50pt', fontWeight: 'bold' }}>{title}</Typography>
      <Typography sx={{ paddingBottom: 4, fontWeight: 'bold', fontSize: '22pt', color: '#418FDE' }}>
        {subTitle}
      </Typography>
      <Typography sx={{ paddingBottom: 2, fontSize: '15pt' }}>{body1}</Typography>
      <Typography sx={{ paddingBottom: 5, fontSize: '15pt' }}>{body2}</Typography>
      <Box maxWidth={400}>
        <QuestionTips items={[EvaluationTips]} defaultExpanded />
      </Box>
    </Box>
  </>
);
