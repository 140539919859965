import { Box, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FeedbackButtonIconType } from 'components/atoms/Icons/types';
import { FeedbackSvgIconButton } from 'components/molecules';
import React, { useEffect, useState } from 'react';

type Props = {
  type: FeedbackButtonIconType;
  disabled?: boolean;
  active?: boolean;
  sx?: SxProps;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const StyledFeedbackWrapper = styled(Box)({
  '&:hover': {
    cursor: 'pointer',
  },
});

export const ResultFeedbackItem: React.FC<Props> = ({
  type,
  active = false,
  disabled = false,
  sx,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  useEffect(() => {
    setHovered(false);
  }, [active, disabled]);

  useEffect(() => {
    setHovered(false);
  }, [type, active, disabled]);

  return (
    <StyledFeedbackWrapper
      sx={{
        ...sx,
        ...(disabled
          ? {
              '&:hover': {
                cursor: 'initial',
              },
            }
          : {}),
      }}
      onClick={onClick}
      onMouseEnter={() => {
        if (!disabled) {
          onMouseEnter?.();
          !active && setHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (!disabled) {
          onMouseLeave?.();
          !active && setHovered(false);
        }
      }}
    >
      <FeedbackSvgIconButton active={!disabled && (active || hovered)} type={type} />
    </StyledFeedbackWrapper>
  );
};
