export const colorTones = {
  neutralsBlack: '#000000',
  neutralsGray: '#D6D6D6',
  neutralsSlightGray: '#F7F7F7',
  neutralsWhite: '#FFFFFF',
  primaryNightSky: '#000D2E',
  nightSky200: '#333D58',
  nightSky100: '#666E81',
  nightSky80: '#000D2E',
  nightSky10: 'rgba(0, 35, 125, 0.1)',
  ocean200: '#418FDE',
  ocean300: '#247AD1',
  ocean80: 'rgba(65, 143, 222)',
  ocean10: 'rgba(65, 143, 222, 0.1)',
  plant300: '#47A23F',
  plant200: '#47A23F',
  plant100: '#6CB565',
  plant80: 'rgba(71, 162, 63)',
  plant50: 'rgba(71, 162, 63)',
  plant30: 'rgba(71, 162, 63)',
  plant10: 'rgba(71, 162, 63,0.1)',
  sunflower200: '#F6BE00',
  sunflower300: '#F0B400',
  sunflower80: 'rgba(246, 190, 0, 0.8)',
  primaryRed: '#E34D2C',
  primaryRed80: 'rgba(227, 77, 44, 0.8)',
  primaryRed50: 'rgba(227, 77, 44, 0.5)',
  primaryRed10: 'rgba(227, 77, 44, 0.1)',
};

export const fonts = {
  primeFont: 'Work Sans',
  secondaryFont: 'Caveat',
};
