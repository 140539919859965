import styled from '@emotion/styled';
import { Box, SxProps } from '@mui/material';
import * as React from 'react';

type Props = {
  children: React.ReactNode;
  sx?: SxProps;
  variant?: 'default' | 'row';
};

const StyledText = styled(Box)({
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '24px',
  color: '#000D2E',
  opacity: '0.9',
  '@media (max-width: 600px)': {
    textAlign: 'center',
    div: {
      width: '100%',
    },
  },
});

const StyledTextRow = styled(StyledText)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
  m: 0,
  p: 0,
});

export const FormQuestionText: React.FC<Props> = ({ variant, sx, children }) => {
  if (variant === 'row') {
    return (
      <StyledTextRow p={2} component="div" sx={sx}>
        {children}
      </StyledTextRow>
    );
  }
  return (
    <StyledText p={2} component="div" sx={sx}>
      {children}
    </StyledText>
  );
};
