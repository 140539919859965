import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FormQuestionText, Input } from 'components/atoms';
import { FormError, MultipleInputFormContainer, QuestionFormContainer } from 'components/molecules';
import { MultipleInputItemFormContainer } from 'components/molecules/Container/MultipleInputItemFormContainer';
import { commonAdjectiveAndAdjectiveFormSchema } from 'components/questionnaire/constants';
import { Question7FormValues, Question8FormValues } from 'components/questionnaire/types';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { getFormikErrorByIndex } from 'utils';

type FormValues = Question7FormValues | Question8FormValues;

type Props = {
  initialValues: FormValues;
  onSubmitForm: (values: FormValues) => void;
  formRef?: React.Ref<FormikProps<FormValues>>;
  additionalTitle?: string;
  mainTitle: string;
  itemTitle: string;
  maxRows: number;
  inputConcatenation: string;
  punctuationMark?: string;
  questionErrors?: {
    adjective1?: string;
    adjective2?: string;
  }[];
  requestError?: string;
};

export const CommonAdjectivesQuestion: React.FC<Props> = ({
  initialValues,
  onSubmitForm,
  additionalTitle,
  questionErrors,
  requestError,
  formRef,
  mainTitle,
  itemTitle,
  inputConcatenation = 'and',
  punctuationMark,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <QuestionFormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={commonAdjectiveAndAdjectiveFormSchema}
        validateOnChange
        innerRef={formRef}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormQuestionText>
              <Box textAlign="left">
                {additionalTitle}
                <br />
                {mainTitle}
              </Box>
            </FormQuestionText>
            <MultipleInputFormContainer>
              {values.responses.map((response, index) => {
                const key = `responses-${index}-adjective1`;

                const hasErrorAdjective1 =
                  Boolean(getFormikErrorByIndex(errors, index)?.adjective1) && touched.responses?.[index]?.adjective1;
                const hasErrorAdjective2 =
                  Boolean(getFormikErrorByIndex(errors, index)?.adjective2) && touched.responses?.[index]?.adjective2;
                return (
                  <MultipleInputItemFormContainer key={key}>
                    <Box>
                      {itemTitle}
                      <Input
                        name={`responses[${index}].adjective1`}
                        value={response.adjective1}
                        placeholder="Descriptor"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          (Boolean(questionErrors?.[index]?.adjective1) &&
                            questionErrors?.[index]?.adjective1 === response.adjective1) ||
                          (Boolean(getFormikErrorByIndex(errors, index)?.adjective1) &&
                            touched.responses?.[index]?.adjective1)
                        }
                      />
                    </Box>
                    <Box>
                      {inputConcatenation}
                      <Input
                        name={`responses[${index}].adjective2`}
                        value={response.adjective2}
                        placeholder="Descriptor"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={
                          (Boolean(questionErrors?.[index]?.adjective2) &&
                            questionErrors?.[index]?.adjective2 === response.adjective2) ||
                          (Boolean(getFormikErrorByIndex(errors, index)?.adjective2) &&
                            touched.responses?.[index]?.adjective2)
                        }
                      />
                    </Box>
                    {((Boolean(questionErrors?.[index]?.adjective1) &&
                      questionErrors?.[index]?.adjective1 === response.adjective1) ||
                      (Boolean(questionErrors?.[index]?.adjective2) &&
                        questionErrors?.[index]?.adjective2 === response.adjective2)) && (
                      <FormError>Name is not allowed</FormError>
                    )}
                    {Boolean(requestError) && <FormError>{requestError}</FormError>}
                    {(hasErrorAdjective1 || hasErrorAdjective2) && (
                      <FormError>
                        {getFormikErrorByIndex(errors, index)?.adjective1 ||
                          getFormikErrorByIndex(errors, index)?.adjective2}
                      </FormError>
                    )}
                    <p>{!isMobile && punctuationMark}</p>
                  </MultipleInputItemFormContainer>
                );
              })}
            </MultipleInputFormContainer>
          </Form>
        )}
      </Formik>
    </QuestionFormContainer>
  );
};
