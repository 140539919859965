import { colorTones, fonts } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Icon } from 'components/atoms/Icons';
import React from 'react';

type Props = {
  label: string;
};

const StyledWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '4px 12px',
  gap: '4px',
  borderRadius: '6px',
  background: colorTones.primaryRed10,
});

const StyledTitle = styled(Typography)({
  fontFamily: fonts.primeFont,
  fontSize: '14px',
  fontStyle: 'italic',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '-0.01em',
  color: colorTones.primaryRed,
});

export const BaseErrorBadge: React.FC<Props> = ({ label }) => {
  return (
    <StyledWrapper>
      <Icon
        type="interfaceAlertWarningCircle"
        width="16"
        height="16"
        strokeWidth="1.4"
        stroke={colorTones.primaryRed}
      />
      <StyledTitle>{label}</StyledTitle>
    </StyledWrapper>
  );
};
