import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { GrayBox, StackContainer } from 'components/atoms';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

const StyledContainer = styled(Box)({
  position: 'relative',
  marginTop: -24,
});
export const QuestionFormContainer: React.FC<Props> = ({ children }) => (
  <StyledContainer>
    <GrayBox p={2}>
      <StackContainer>{children}</StackContainer>
    </GrayBox>
  </StyledContainer>
);
