import { colorTones, fonts } from 'constants/designSystem';

import { Typography, styled } from '@mui/material';
import { Icon } from 'components/atoms';
import { CompactExpertLike } from 'components/organism';
import {
  StyledBaseFlex,
  StyledButtonExpand,
  StyledCardContent,
  StyledHeaderWrapper,
  StyledIconWrapper,
  StyledIndex,
  StyledName,
  StyledRationale,
  StyledWrapper,
} from 'components/organism/RatedResults/RatedResultCard';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  index: number;
  id: number;
  name: string;
  rationale: string;
  expertReviewLabel: string;
  expertFeedback: string | null;
  expertLike: boolean | null;
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<number[]>>;
};

const StyledExpertFeedbackTitle = styled(Typography)({
  marginTop: '16px',
  color: colorTones.nightSky80,
  fontFamily: fonts.primeFont,
  fontStyle: 'italic',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '150%',
});

export const ExpertPlusReviewCard = ({
  index,
  id,
  name,
  rationale,
  expertReviewLabel,
  expertFeedback,
  expertLike,
  expanded,
  setExpanded,
}: Props) => {
  const handleExpandButtonClick = () => {
    index && setExpanded((prev) => (prev.includes(id) ? prev.filter((el) => el !== id) : [...prev, id]));
  };

  const handleExpandButtonClose = () => {
    index && setExpanded((prev) => prev.filter((el) => el !== id));
  };

  return (
    <StyledWrapper>
      <StyledHeaderWrapper
        sx={{
          background: expanded ? colorTones.neutralsSlightGray : colorTones.neutralsWhite,
        }}
      >
        <StyledBaseFlex>
          <StyledIndex>{index}.</StyledIndex>
          <StyledName>{name}</StyledName>
        </StyledBaseFlex>
        <StyledBaseFlex>
          {expertLike && <CompactExpertLike favorite={true} />}
          <StyledIconWrapper>
            <StyledButtonExpand
              data-animation={expanded ? 'expanded' : 'decrease'}
              onClick={expanded ? handleExpandButtonClose : handleExpandButtonClick}
            >
              <Icon type="interfaceArrowButtonUp" width={16} height={16} stroke={colorTones.nightSky100} />
            </StyledButtonExpand>
          </StyledIconWrapper>
        </StyledBaseFlex>
      </StyledHeaderWrapper>
      <StyledCardContent data-animation={expanded ? 'expanded' : 'decrease'}>
        <StyledRationale>{rationale}</StyledRationale>
        {expertFeedback && (
          <>
            <StyledExpertFeedbackTitle gap={1} display={'flex'} alignItems={'center'}>
              <Icon
                type="interfaceFavoriteHeart"
                width={16}
                height={16}
                stroke={colorTones.nightSky80}
                fill={colorTones.nightSky80}
              />
              {expertReviewLabel}
            </StyledExpertFeedbackTitle>
            <StyledRationale>{expertFeedback}</StyledRationale>
          </>
        )}
      </StyledCardContent>
    </StyledWrapper>
  );
};
