import { QuestionnaireState } from 'context/questionnaireForm';
import { getDraft, postDraft, putDraft } from 'sdk/internalApi';
import { mapDtoAnswersToQuestionnaireAnswers } from 'sdk/questionnaire';
import { Question } from 'sdk/questionnaire/enums/question';
import { QuestionnaireAnswers, User } from 'sdk/types';

export const initializeQuestionnaire = async (draftId: string, token: string, user: User) => {
  const data = await getDraft({ draftId, user: user.id, token });
  const questionnaireAnswers = mapDtoAnswersToQuestionnaireAnswers(data.answers);
  return {
    ...(Boolean(draftId) ? { currentQuestion: Question.Question1 } : {}),
    title: data.name,
    answers: questionnaireAnswers,
    draftId: Number(data.id),
    lastEditedQuestion: data.last_edited_question,
    parent: data.parent,
    dtoAnswers: data.answers,
    userHasPayments: data.user_has_payments,
  };
};

export const saveQuestionnaire = async (questionnaireState: QuestionnaireState, token: string, user: User) => {
  const draftId = questionnaireState.draftId;
  const answers = questionnaireState.answers;
  const name = questionnaireState.title ?? '';
  if (draftId && answers) {
    const data = await putDraft({
      draftId,
      user: user.id,
      token: token,
      answers,
      name,
      lastEditedQuestion: questionnaireState.lastEditedQuestion,
    });
    return data;
  }
};

export const newQuestionnaire = async (token: string, user: User, partialAnswers: Partial<QuestionnaireAnswers>) => {
  const { id, name, answers } = await postDraft({ user: user.id, token });
  const questionnaireAnswers = mapDtoAnswersToQuestionnaireAnswers(answers);
  const updatedAnswers = { ...(questionnaireAnswers || {}), ...partialAnswers };

  return {
    id,
    name,
    answers: updatedAnswers,
  };
};

export const getUpdatedState = ({
  questionnaireState,
  nextQuestion,
  partialAnswers,
  lastEditedQuestion,
}: {
  questionnaireState: QuestionnaireState;
  nextQuestion?: Question;
  partialAnswers: Partial<QuestionnaireAnswers>;
  lastEditedQuestion: number;
}): QuestionnaireState => ({
  ...questionnaireState,
  lastEditedQuestion,
  currentQuestion: nextQuestion,
  answers: { ...(questionnaireState.answers ?? {}), ...partialAnswers },
  userHasPayments: questionnaireState.userHasPayments,
});
