import { colorTones, fonts } from 'constants/designSystem';

import { Box, styled } from '@mui/material';
import { Button } from 'components/atoms';
import { IconRight } from 'components/organism/Navigation/SlidesControls';
import React from 'react';
import { getGiftCardSession } from 'sdk/internalApi';

type Props = {
  redirectUrl: string;
  batchesAmount: number;
  text: string;
  setRequestError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const StyledIconWrapper = styled(Box)({
  paddingTop: '12px',
});

const StyledButton = styled(Button)({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  textTransform: 'none',
  fontFamily: fonts.primeFont,
  width: '100%',
  maxHeight: '40px',
});

export const BuyGiftCardButton: React.FC<Props> = ({ redirectUrl, batchesAmount, setRequestError, text }) => (
  <StyledButton
    color="success"
    onClick={async () => {
      try {
        const { stripe_id, checkout_url } = await getGiftCardSession({
          redirect_url: redirectUrl,
          batches_amount: batchesAmount.toString(),
        });
        localStorage.setItem('stripe_id', stripe_id);
        window.location.replace(checkout_url);
      } catch (e: unknown) {
        if (e instanceof Error && 'message') {
          setRequestError(e.message);
          return;
        }
        setRequestError('Server error');
      }
    }}
  >
    {text}
    <StyledIconWrapper>
      <IconRight {...{ stroke: colorTones.neutralsWhite }} />
    </StyledIconWrapper>
  </StyledButton>
);
