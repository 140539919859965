import { colorTones } from 'constants/designSystem';

import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { DtoAnswers } from 'sdk/questionnaire/types';
import { ExpertPlusReview, JourneyResult } from 'sdk/types/result';

import Caveat from './Caveat-Regular.ttf';
import { SvgLogo } from './ExportLogos';
import { ExportQuestionnaireForm } from './ExportQuestionnaireForm';
import { ExportResultCard } from './ExportResultCard';

type Props = {
  results: JourneyResult[];
  expertPlusReviews: ExpertPlusReview[];
  answers: DtoAnswers;
  questionnaireName: string | undefined;
};

Font.register({
  family: 'Caveat',
  format: 'truetype',
  fonts: [
    {
      src: Caveat,
      fontStyle: 'italic',
      fontWeight: 400,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Work Sans',
    fontWeight: 'normal',
    padding: 20,
    backgroundColor: '#fff',
  },
  wrapperWithSpace: {
    marginTop: '20px',
    marginBottom: '10px',
    marginLeft: '50px',
    marginRight: '50px',
    maxWidth: '100%',
  },
  resultsWrapperWithSpace: {
    marginTop: '20px',
    marginBottom: '10px',
    maxWidth: '100%',
  },
  questionnaireHeader: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    maxWidth: '1000px',
    width: '100%',
    textAlign: 'center',
    paddingBottom: '5px',
    paddingTop: '20px',
    color: colorTones.primaryNightSky,
  },
  questionnaireTitle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    maxWidth: '1000px',
    width: '100%',
    textAlign: 'center',
    paddingBottom: '20px',
    color: colorTones.primaryNightSky,
  },
  resultsHeader: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    maxWidth: '1000px',
    width: '100%',
    textAlign: 'center',
    paddingBottom: '24px',
    color: colorTones.primaryNightSky,
  },
  introWrapper: {
    backgroundColor: colorTones.neutralsWhite,
    display: 'flex',
    paddingTop: '24px',
    marginBottom: '10px',
  },
  subInstructionText: {
    fontSize: '14px',
    textAlign: 'left',
  },
  subInstructionTitleText: {
    fontFamily: 'Caveat',
    fontStyle: 'italic',
    fontSize: '28px',
    paddingTop: '20px',
    paddingBottom: '20px',
    textAlign: 'center',
    color: '#418FDE',
  },
  questionInstruction: {
    fontWeight: 'bold',
  },
  subParagraphText: {
    fontSize: '14px',
    paddingTop: '20px',
    textAlign: 'left',
  },
  formLayoutUlList: {
    display: 'flex',
    flexDirection: 'row',
  },
  bulletListItem: {
    paddingTop: '20px',
    paddingBottom: '12px',
    textAlign: 'left',
    paddingRight: '15px',
  },
  logoLeft: {
    maxWidth: '150',
    maxHeight: '100px',
    position: 'absolute',
    top: '38',
    left: '70',
    right: '0',
    bottom: '0',
    margin: '0',
  },
  logoRight: {
    maxWidth: '150',
    maxHeight: '100px',
    position: 'absolute',
    top: '38',
    left: '625',
    right: '0',
    bottom: '0',
    margin: '0',
  },
});

export const ExportDocument: React.FC<Props> = ({ answers, expertPlusReviews, results, questionnaireName }) => {
  return (
    <Document>
      <Page style={styles.page} size="A4" orientation="landscape">
        <View style={styles.logoLeft}>
          <SvgLogo />
        </View>
        <Text style={styles.questionnaireHeader}>Your Aesop Discovery Journey</Text>
        <Text style={styles.questionnaireTitle}>{questionnaireName}</Text>
        <View style={styles.logoRight}>
          <SvgLogo />
        </View>
        <ExportQuestionnaireForm answers={answers} />
      </Page>
      {results.length > 0 && (
        <Page style={styles.page} size="A4" orientation="landscape">
          <View style={styles.logoLeft}>
            <SvgLogo />
          </View>
          <Text style={styles.questionnaireHeader}>Your Aesop Results</Text>
          <View style={styles.logoRight}>
            <SvgLogo />
          </View>
          <View style={styles.wrapperWithSpace}>
            <View style={styles.introWrapper}>
              <Text style={styles.subInstructionText}>
                The following are your personalized Aesop recommendations – names and rationales – designed to celebrate
                your unique offering in a range of strategic and exciting ways.
              </Text>
              <Text style={styles.subInstructionTitleText}>
                You only need one, really great name for your offering.
              </Text>
              <Text style={styles.subInstructionText}>
                Different naming approaches click with different people. But don’t worry – we explored a really wide
                range. While this probably means you won’t like everything you see, we believe at least a handful will
                really hit home.
                {'\n\n'}
                To help you find the one you love, here are four good things to keep in mind.
              </Text>
              <View style={styles.formLayoutUlList}>
                <Text style={styles.bulletListItem}>•</Text>
                <Text style={styles.subParagraphText}>
                  <Text style={styles.questionInstruction}>Sit with the unexpected. </Text>Names that feel odd or
                  offbeat often need a moment to “set in”. Give them a bit of time they have the power to really make an
                  impact.
                </Text>
              </View>
              <View style={styles.formLayoutUlList}>
                <Text style={styles.bulletListItem}>•</Text>
                <Text style={styles.subParagraphText}>
                  <Text style={styles.questionInstruction}>Deprioritize personal associations.</Text>
                  Put subjective, highly personal reactions and associations aside as they may not extend to your target
                  audience.
                </Text>
              </View>
              <View style={styles.formLayoutUlList}>
                <Text style={styles.bulletListItem}>•</Text>
                <Text style={styles.subParagraphText}>
                  <Text style={styles.questionInstruction}>Don’t expect a name to “say it all”. </Text>Prioritize the
                  singular message you want to communicate through your name, and let the rest of your messaging speak
                  to a broader set of value propositions.
                </Text>
              </View>
              <View style={styles.formLayoutUlList}>
                <Text style={styles.bulletListItem}>•</Text>
                <Text style={styles.subParagraphText}>
                  <Text style={styles.questionInstruction}>There is always a good URL. </Text>It’s very easy to find
                  productive workarounds for a great URL, social handle, or trademark/registration. Just look at
                  Peloton, an amazing spin-bike/fitness brand that uses Peloton Interactive for their company name and
                  onepeloton.com for the URL.
                </Text>
              </View>
            </View>
          </View>
        </Page>
      )}
      {expertPlusReviews.length > 0 && (
        <Page style={styles.page} size="A4" orientation="landscape">
          <View style={styles.logoLeft}>
            <SvgLogo />
          </View>
          <Text style={styles.questionnaireHeader}>Your Aesop Expert Plus Reviews</Text>
          <View style={styles.logoRight}>
            <SvgLogo />
          </View>
          <View style={styles.wrapperWithSpace}>
            <View style={styles.resultsWrapperWithSpace}>
              {expertPlusReviews.map((expertPlusReview, index) => {
                const key = `expert-plus-review-item-${index}`;
                return (
                  <ExportResultCard
                    id={index + 1}
                    key={key}
                    name={expertPlusReview.suggested_name}
                    rationale={expertPlusReview.name_rationale}
                    erroneous={false}
                    feedback={1}
                    expertFeedback={expertPlusReview.expert_feedback}
                  />
                );
              })}
            </View>
          </View>
        </Page>
      )}
      {results.length > 0 && (
        <Page style={styles.page} size="A4" orientation="landscape">
          <View style={styles.logoLeft}>
            <SvgLogo />
          </View>
          <Text style={styles.questionnaireHeader}>Your Aesop Results</Text>
          <View style={styles.logoRight}>
            <SvgLogo />
          </View>

          <View style={styles.wrapperWithSpace}>
            <View style={styles.resultsWrapperWithSpace}>
              {'\n\n'}
              {results.map((result, index) => {
                const key = `reslut-item-${index}`;
                return (
                  <ExportResultCard
                    id={index + 1}
                    key={key}
                    name={result.name}
                    rationale={result.rationale}
                    feedback={result.feedback}
                    erroneous={result.erroneous}
                    expertFeedback={result.expert_review?.expert_feedback}
                  />
                );
              })}
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
};
