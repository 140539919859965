import { colorTones, fonts } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box, SxProps, Typography } from '@mui/material';
import { Button } from 'components/atoms';
import { Icon } from 'components/atoms/Icons';
import { ModalWindow } from 'components/organism';
import { useHandleModal } from 'hooks';
import * as React from 'react';

type Props = {
  buttonLabel: string;
  modalTitle?: string;
  modalSubTitle?: string;
  modalText?: string;
  recommendationText?: string;
  buttonBuyMoreNamesLabel?: string;
  buttonGetNewNamesLabel?: string;
  disableBuyMore?: boolean;
  handleRecommendationClick?: () => void;
  handleBuyMoreNames?: () => void;
  handleGetNewNames?: () => void;
  sx?: SxProps;
};

const StyledButton = styled(Button)({
  height: '48px',
});

const StyledTextWrapper = styled(Box)({
  minHeight: '143px',
  marginBottom: '24px',
});

const StyledModalText = styled(Box)({
  fontStyle: 'normal',
  margin: 'auto',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '150%',
  fontFamily: fonts.primeFont,
  textAlign: 'center',
  color: colorTones.neutralsWhite,
  paddingBottom: '8px',
});

const StyledModalSubTitle = styled(StyledModalText)({
  fontWeight: 600,
});

const StyledRecommendation = styled(Typography)({
  paddingTop: '24px',
  fontFamily: fonts.primeFont,
  textAlign: 'center',
  color: colorTones.neutralsWhite,
  fontWeight: 400,
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '150%',
  textDecorationLine: 'underline',
  '&:hover': {
    cursor: 'pointer',
  },
});

const StyledButtonsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: 2,
  justifyItems: 'center',
  margin: '16px',
  '@media (min-width: 600px)': {
    flexDirection: 'row',
  },
  '> button': {
    width: '100%',
  },
});

const StyledGetNewNamesButton = styled(StyledButton)({
  background: colorTones.neutralsWhite,
  '&:hover': {
    background: colorTones.neutralsSlightGray,
    color: colorTones.nightSky200,
  },
});

export const GetMoreNames: React.FC<Props> = ({
  buttonLabel,
  modalTitle,
  modalText,
  modalSubTitle,
  recommendationText,
  buttonBuyMoreNamesLabel,
  buttonGetNewNamesLabel,
  disableBuyMore,
  handleRecommendationClick,
  handleBuyMoreNames,
  handleGetNewNames,
  sx,
}) => {
  const { openModal, handleOpen, handleClose } = useHandleModal();

  const onGetMore = () => {
    if (disableBuyMore) {
      return;
    }
    handleClose();
    handleBuyMoreNames?.();
  };
  const onGetNew = () => {
    handleClose();
    handleGetNewNames?.();
  };

  return (
    <Box>
      <StyledButton sx={{ ...sx, minWidth: '216px' }} onClick={handleOpen} color="primary" variant="outlined">
        <Icon type="interfaceAdd1" />
        {buttonLabel}
      </StyledButton>

      <ModalWindow
        open={openModal}
        onClose={handleClose}
        variant="dark"
        ariaLabelledby={modalTitle && 'modal-edit-naming-journey-title'}
        ariaDescribedby={modalSubTitle && 'modal-edit-naming-journey-description'}
        title={modalTitle}
      >
        <StyledTextWrapper>
          {modalSubTitle && <StyledModalSubTitle>{modalSubTitle}</StyledModalSubTitle>}
          {modalText && <StyledModalText>{modalText}</StyledModalText>}
        </StyledTextWrapper>
        <StyledButtonsWrapper>
          {!disableBuyMore && (
            <StyledButton color="success" onClick={onGetMore}>
              <Icon type="imagePhotoPolaroid" stroke={colorTones.neutralsWhite} />
              {buttonBuyMoreNamesLabel}
            </StyledButton>
          )}
          <StyledGetNewNamesButton onClick={onGetNew} variant="outlined" color="primary">
            <Icon type="interfaceAdd1" />
            {buttonGetNewNamesLabel}
          </StyledGetNewNamesButton>
        </StyledButtonsWrapper>
        {recommendationText && (
          <StyledRecommendation onClick={handleRecommendationClick}>{recommendationText}</StyledRecommendation>
        )}
      </ModalWindow>
    </Box>
  );
};
