import styled from '@emotion/styled';
import Box from '@mui/material/Box';

export const GrayBox = styled(Box)({
  display: 'flex',
  alignItems: 'row',
  background: 'rgba(64, 142, 222, 0.1)',
  borderRadius: '4px',
  minHeight: '80px',
});
