import { Box, Typography } from '@mui/material';
import {
  FlexBox,
  IntroParagraphText,
  LightText,
  QuestionExampleBlueText,
  QuestionExampleText,
  QuestionStepLabel,
  UpperCaseText,
} from 'components/atoms';
import { FadingContainer, QuestionContainer } from 'components/molecules';
import { QuestionExampleBox, QuestionTips } from 'components/organism/Questionnaire';
import { IntroQuotedText } from 'components/organism/Questionnaire/IntroQuotedText';
import { question1Paragraphs, question1TipItem } from 'components/questionnaire/constants';
import { FormQuestion1 } from 'components/questionnaire/forms';
import { QuestionIntroduction } from 'components/questionnaire/QuestionIntroduction';
import { Question1Props } from 'components/questionnaire/types';
import React, { useState } from 'react';

type Props = {
  phaseName: string;
  questionVisited: boolean;
  disabled?: boolean;
  isChild?: boolean;
} & Question1Props;

export const Question1: React.FC<Props> = ({
  answers,
  formRef,
  phaseName,
  questionVisited,
  disabled,
  isChild,
  requestError,
  questionError,
  onClick,
}) => {
  const [showTipSection, setShowTipSection] = useState<boolean>(false);

  const introText = (
    <Box key={'intro1Text'}>
      <QuestionStepLabel>{phaseName}</QuestionStepLabel>
      <IntroParagraphText>
        Pick two words to identify{' '}
        <Typography component="span" variant="questionInstruction">
          where your offering broadly fits
        </Typography>{' '}
        within the market.
      </IntroParagraphText>
    </Box>
  );

  return (
    <>
      <QuestionContainer
        tipSection={
          <FadingContainer visible={showTipSection}>
            {<QuestionTips items={[question1TipItem]} defaultExpanded />}
          </FadingContainer>
        }
        mainSection={
          <QuestionIntroduction
            tipSection={<QuestionTips items={[question1TipItem]} />}
            questionVisited={questionVisited}
            intro={
              <IntroQuotedText
                title={phaseName}
                text={`“Inventive category
                cues have a special
                magic that feels both
                fresh and familiar.”`}
              />
            }
            paragraphsToAnimate={[introText, ...question1Paragraphs]}
            form={
              <Box paddingTop="75px">
                <QuestionExampleBox title="Examples of what good answers look like:">
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, auto)' }}>
                      <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                        <QuestionExampleText>... the</QuestionExampleText>
                        <QuestionExampleBlueText>jewellery</QuestionExampleBlueText>
                      </FlexBox>
                      <QuestionExampleText> and </QuestionExampleText>
                      <FlexBox sx={{ padding: 0, margin: 0 }}>
                        <QuestionExampleBlueText>accessories</QuestionExampleBlueText>
                        <QuestionExampleText>...</QuestionExampleText>
                      </FlexBox>

                      <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                        <QuestionExampleText>... the</QuestionExampleText>
                        <QuestionExampleBlueText>advertising</QuestionExampleBlueText>
                      </FlexBox>
                      <QuestionExampleText> and </QuestionExampleText>
                      <FlexBox sx={{ padding: 0, margin: 0 }}>
                        <QuestionExampleBlueText>promotions</QuestionExampleBlueText>
                        <QuestionExampleText>...</QuestionExampleText>
                      </FlexBox>

                      <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                        <QuestionExampleText>... the</QuestionExampleText>
                        <QuestionExampleBlueText>software</QuestionExampleBlueText>
                      </FlexBox>
                      <QuestionExampleText> and </QuestionExampleText>
                      <FlexBox sx={{ padding: 0, margin: 0 }}>
                        <QuestionExampleBlueText>real estate</QuestionExampleBlueText>
                        <QuestionExampleText>...</QuestionExampleText>
                      </FlexBox>

                      <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                        <QuestionExampleText>... the</QuestionExampleText>
                        <QuestionExampleBlueText>health care</QuestionExampleBlueText>
                      </FlexBox>
                      <QuestionExampleText> and </QuestionExampleText>
                      <FlexBox sx={{ padding: 0, margin: 0 }}>
                        <QuestionExampleBlueText>fitness</QuestionExampleBlueText>
                        <QuestionExampleText>...</QuestionExampleText>
                      </FlexBox>

                      <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                        <QuestionExampleText>... the</QuestionExampleText>
                        <QuestionExampleBlueText>education</QuestionExampleBlueText>
                      </FlexBox>
                      <QuestionExampleText> and </QuestionExampleText>
                      <FlexBox sx={{ padding: 0, margin: '0 0 16px 0' }}>
                        <QuestionExampleBlueText>language</QuestionExampleBlueText>
                        <QuestionExampleText>...</QuestionExampleText>
                      </FlexBox>
                    </Box>
                  </div>
                </QuestionExampleBox>
                <FormQuestion1
                  onClick={onClick}
                  answers={answers}
                  formRef={formRef}
                  disabled={disabled}
                  requestError={requestError}
                  questionError={questionError}
                  isChild={isChild}
                />
                <br />
                <UpperCaseText sx={{ fontSize: '10px' }}>WHEN ANSWERING, DON&lsquo;T WORRY ABOUT...</UpperCaseText>
                <br />
                <LightText>
                  <Box>
                    <Typography component="span" variant="lightTextStart">
                      Keeping it “official”.
                    </Typography>{' '}
                    It doesnt matter if this is a “recognized” market segment. Just find two, low-specificity words that
                    loosely encompass what youre naming or the field it plays in.
                  </Box>
                </LightText>
              </Box>
            }
            fomTitle={''}
            onFormInView={(isInView) => {
              setShowTipSection(isInView);
            }}
          />
        }
      />
    </>
  );
};
