import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { Button } from 'components/atoms/Buttons/Button';
import { CompletedJourneyTile } from 'components/organism/Tiles';
import { TilesGrid } from 'components/organism/TilesGrid/TilesGrid';
import React from 'react';
import { CompletedJourney } from 'sdk/types';

type Props = {
  title?: string;
  completedJourneys?: CompletedJourney[];
  noJourneyPlaceHolder?: string;
  onClickJourney?: (id: number) => void;
  loadMoreLabel?: string;
  handleLoadMore?: () => void;
  loadMoreVisible?: boolean;
};

const StyledWrapper = styled(Box)({
  paddingTop: '24px',
  paddingBottom: '24px',
});

const initializeItems = (completedJourneys: CompletedJourney[], onClickJourney?: (id: number) => void) =>
  completedJourneys?.map((item, index) => {
    const journeyKey = `${index}-journey-key`;
    return (
      <CompletedJourneyTile
        key={journeyKey}
        id={item.id}
        status={item.status}
        failed={item.failed}
        name={item.name}
        seenResults={item.seenResults}
        formattedCreateDate={item.created}
        commonExpertReviewStatus={item.commonExpertReviewStatus}
        onClick={onClickJourney}
      />
    );
  });

export const DashboardCompletedJourneySection: React.FC<Props> = ({
  completedJourneys,
  title,
  noJourneyPlaceHolder,
  loadMoreVisible,
  loadMoreLabel,
  onClickJourney,
  handleLoadMore,
}) => {
  return (
    <StyledWrapper>
      <TilesGrid
        title={title || ''}
        noItemsPlaceHolder={noJourneyPlaceHolder || ''}
        items={initializeItems(completedJourneys || [], onClickJourney)}
      />
      {loadMoreVisible && (
        <StyledWrapper>
          <Button color="primary" onClick={handleLoadMore}>
            {loadMoreLabel || ''}
          </Button>
        </StyledWrapper>
      )}
    </StyledWrapper>
  );
};
