import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Alert } from 'components/atoms';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

const StyledContainer = styled(Box)({ p: 0, m: 0, marginTop: 2, width: '100%' });

export const FormWarning: React.FC<Props> = ({ children }) => (
  <StyledContainer>
    <Alert variant="warning">{children}</Alert>
  </StyledContainer>
);
