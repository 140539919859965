import { env } from 'env';
import { RequestError } from 'sdk/internalApi/request';

type Request = {
  questionnaire: number;
  token: string;
};

type Response = {
  id: number;
  questionnaire: number;
  stripe_id: string;
  checkout_url: string;
};

export const buyExpertInTheLoop = async (request: Request): Promise<Response> => {
  const url = `${env.API_URL}/expert/buy`;
  const token = request.token;

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ questionnaire: request.questionnaire, is_expert_plus: false }),
  });

  if (!(res.status >= 200 && res.status < 400)) {
    throw new RequestError(res.status, null);
  }

  return res.json();
};

export const buyExpertPlus = async (request: Request): Promise<Response> => {
  const url = `${env.API_URL}/expert/buy`;
  const token = request.token;

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ questionnaire: request.questionnaire, is_expert_plus: true }),
  });

  if (!(res.status >= 200 && res.status < 400)) {
    throw new RequestError(res.status, null);
  }

  return res.json();
};
