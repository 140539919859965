import { env } from 'env';
import { RequestError } from 'sdk/internalApi/request';
import { FeedbackResponse } from 'sdk/internalApi/types';

type PatchFeedbackRequest = {
  resultId: number;
  token: string;
  feedback?: number;
  erroneous?: boolean;
  favorite?: boolean;
};

export const patchFeedback = async (request: PatchFeedbackRequest): Promise<FeedbackResponse> => {
  const url = `${env.API_URL}/feedback/${request.resultId}/`;
  const res = await fetch(url, {
    method: 'PATCH',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.token}`,
    },
    body: JSON.stringify({
      ...(typeof request.feedback === 'number' ? { feedback: request.feedback } : {}),
      ...(typeof request.erroneous === 'boolean' ? { erroneous: request.erroneous } : {}),
      ...(typeof request.favorite === 'boolean' ? { favorite: request.favorite } : {}),
    }),
  });

  if (!(res.status >= 200 && res.status < 400)) {
    throw new RequestError(res.status, null);
  }

  return res.json();
};
