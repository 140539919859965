import { env } from 'env';
import { RequestError } from 'sdk/internalApi/request';
import { ExpertPlusReview, JourneyResult } from 'sdk/types/result';

type ResultsRequest = {
  user: number;
  token: string;
  journeyId: number;
};

type ResultItem = JourneyResult;

export type BoughtResult = {
  count: number;
  rated_results_count: number;
  not_rated_results_count: number;
  results: ResultItem[];
  expert_plus_reviews: ExpertPlusReview[];
};

export type ResultsResponse = {
  bought: BoughtResult;
  not_bought_count: number;
  batch_size?: number;
};

export const getResults = async (request: ResultsRequest): Promise<ResultsResponse> => {
  const url = `${env.API_URL}/results/${request.journeyId}/`;
  const token = request.token;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.ok) {
    throw new RequestError(res.status, null);
  }

  return res.json();
};
