import { Box } from '@mui/material';
import { Button } from 'components/atoms';
import { Input } from 'components/atoms/Forms/Input';
import { FormError } from 'components/molecules';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import * as yup from 'yup';

type FormValues = { title: string };

type Props = {
  title?: string;
  formRef?: React.Ref<FormikProps<FormValues>> | undefined;
  onClick?: (title: string) => void;
};

export const EditNamingJourneyForm: React.FC<Props> = ({ title, formRef, onClick }) => {
  const formValidationSchema = yup.object().shape({
    title: yup
      .string()
      .required('Don`t forget the title!')
      .min(1, 'Whoops! There`s minimum of 1 character')
      .max(50, 'Whoops! That`s more than 50 characters.'),
  });
  const onSubmitForm = async ({ title }: FormValues) => {
    onClick?.(title);
  };

  const initialValues = {
    title: title || '',
  };

  return (
    <Box paddingTop={2}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={formValidationSchema}
        validateOnChange
        innerRef={formRef}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <Input
              id="title"
              name="title"
              sx={{ width: '100%' }}
              value={values.title}
              error={Boolean(errors.title) && touched.title}
              placeholder="New Title"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {Boolean(errors.title) && touched.title && <FormError>{errors.title}</FormError>}
            <Button color="success" sx={{ minWidth: '40%', margin: 2 }} type="submit" disabled={!isValid}>
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
