import { PairsAdjectiveAndNoun, Question4FormValues } from 'components/questionnaire/types';
import { DtoAnswer3 } from 'sdk/questionnaire/types';
export const mapDtoAnswer3ToQuestion4 = (dtoAnswer3?: DtoAnswer3): Question4FormValues => {
  const responses: PairsAdjectiveAndNoun[] = [];
  const faLength = dtoAnswer3?.FA?.length || 0;
  const fnLength = dtoAnswer3?.FN?.length || 0;
  const count = Math.max(faLength, fnLength);

  for (let i = 0; i < count; i += 2) {
    const adjectiveAndNoun1 = { adjective: dtoAnswer3?.FA?.[i] ?? '', noun: dtoAnswer3?.FN?.[i] ?? '' };
    const adjectiveAndNoun2 = { adjective: dtoAnswer3?.FA?.[i + 1] ?? '', noun: dtoAnswer3?.FN?.[i + 1] ?? '' };
    responses.push({ adjectiveAndNoun1, adjectiveAndNoun2 });
  }

  if (responses.length > 0) {
    return { responses };
  }

  return {
    responses: [{ adjectiveAndNoun1: { adjective: '', noun: '' }, adjectiveAndNoun2: { adjective: '', noun: '' } }],
  };
};
