import { Question } from 'sdk/questionnaire/enums/question';
import { DtoAnswers } from 'sdk/questionnaire/types';
import { QuestionnaireAnswers } from 'sdk/types';

export const mapDtoAnswersToQuestionnaireAnswers = ({
  CN1,
  CN2,
  RA,
  N1,
  FA,
  FN,
  N2,
  EA,
  EN,
  DRA,
  DN,
  EDA,
  MA,
  UCA,
  UCN,
  V,
  offering,
}: DtoAnswers): QuestionnaireAnswers => {
  const V1: string = V?.[0] || '';
  const V2: string = V?.[1] || '';

  return {
    [Question.Question1]: { CN1: CN1 || '', CN2: CN2 || '' },
    [Question.Question2]: { RA, N1, offering },
    [Question.Question2Part2]: { V1, N2 },
    [Question.Question3]: { V2 },
    [Question.Question4]: { FA, FN },
    [Question.Question5]: { DRA, DN },
    [Question.Question6]: { EA, EN },
    [Question.Question7]: { EDA },
    [Question.Question8]: { MA },
    [Question.Question9]: { UCA, UCN },
  };
};
