import { colorTones } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Button } from 'components/atoms';
import { Icon, SvgLogoSmall } from 'components/atoms/Icons';
import React from 'react';

type Props = {
  handleStartJourney?: () => void;
  startJourneyLabel?: string;
  text?: React.ReactNode | string;
  title?: string;
};

const StyledWrapper = styled(Box)({
  m: 2,
  marginBottom: 4,
  marginTop: '120px',
  maxWidth: '700px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const DashboardWelcomeSection: React.FC<Props> = ({ handleStartJourney, startJourneyLabel, text, title }) => (
  <StyledWrapper>
    <Box m={0} marginBottom={4}>
      <SvgLogoSmall width={100} height={64} />
    </Box>
    <Typography variant="h1">{title || ''}</Typography>
    <Typography variant="body1" marginTop={2} marginBottom={6}>
      {text || ''}
    </Typography>
    <Button color="success" onClick={handleStartJourney}>
      <Box m={0} p={0} marginTop={1}>
        <Icon type="interfaceAddSquare" stroke={colorTones.neutralsWhite} viewBox="0 0 18 18" />
      </Box>
      {startJourneyLabel || ''}
    </Button>
  </StyledWrapper>
);
