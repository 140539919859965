import { QuestionnaireDtoValues } from 'components/questionnaire/types';
import { getPreviousQuestion } from 'components/questionnaire/utils';
import { QuestionnaireContextType, QuestionnaireState } from 'context/questionnaireForm';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { questionToStepNumber } from 'sdk/constants';
import { RequestError, postTrackQuestionnaire } from 'sdk/internalApi';
import { getQuestionnaire } from 'sdk/internalApi/questionnaire/getQuestionnaire';
import {
  getUpdatedState,
  initializeQuestionnaire,
  mapDtoAnswersToQuestionnaireAnswers,
  newQuestionnaire,
  saveQuestionnaire,
} from 'sdk/questionnaire';
import { Question } from 'sdk/questionnaire/enums/question';
import { PaymentStatus, QuestionError, QuestionnaireAnswers, User } from 'sdk/types';
import { getDecreasedCompletionByQuestion } from 'utils';

type InitUseQuestionnaire = {
  token: string;
  user: User;
  draftId?: string;
  paymentStatus?: PaymentStatus;
} & QuestionnaireContextType;

const getUpdateVisitedQuestions = (questionnaireState: QuestionnaireState) => {
  const completedQuestion = questionnaireState.currentQuestion;
  const questions = Object.values(Question);
  const visitedQuestions = questions.slice(
    0,
    questions.findIndex((q) => q === completedQuestion),
  );

  if (!completedQuestion) {
    return visitedQuestions;
  }

  if (visitedQuestions.length + 1 > questionnaireState.visitedQuestions.length) {
    return [...visitedQuestions, completedQuestion];
  }

  return questionnaireState.visitedQuestions;
};

const getQuestionnaireData = async ({ token, id }: { token: string; id: string }) => {
  const data = await getQuestionnaire({ token, id: Number(id) });
  const questionnaireAnswers = mapDtoAnswersToQuestionnaireAnswers(data.answers);
  const questionnaire = {
    title: data.name,
    answers: questionnaireAnswers,
    questionnaireId: Number(data.id),
    dtoAnswers: data.answers,
    userHasPayments: data.user_has_payments,
  };
  return questionnaire;
};

export const useQuestionnaire = ({
  token,
  draftId,
  user,
  updateQuestionnaireState,
  questionnaireState,
  paymentStatus,
}: InitUseQuestionnaire) => {
  const [progress, setProgress] = useState<number>(0);
  const [loader, setLader] = useState<boolean>(false);
  const [initializer, setInitializer] = useState<boolean>(false);
  const [questionnaireErrors, setQuestionnaireErrors] = useState<QuestionError>({});
  const [requestError, setRequestError] = useState<string>();
  const [startNewQuestionnaire, setStartNewQuestionnaire] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!draftId && !startNewQuestionnaire) {
      setLader(false);
      setStartNewQuestionnaire(true);
    }
  }, [draftId, startNewQuestionnaire]);

  const getQuestionFromId = (id: number): Question => {
    const res = Object.entries(questionToStepNumber).find((entry) => {
      return entry[1] === id;
    });
    if (res?.[0]) {
      return res[0] as Question;
    }
    return Question.Question1;
  };

  useEffect(() => {
    if (loader || questionnaireState.draftId || questionnaireState.questionnaireId || !draftId) {
      return;
    }
    setLader(true);
    (async () => {
      if (!paymentStatus) {
        const questionnaireDraft = await initializeQuestionnaire(draftId, token, user);
        updateQuestionnaireState({
          ...questionnaireState,
          ...questionnaireDraft,
          currentQuestion: getQuestionFromId(questionnaireDraft?.lastEditedQuestion ?? 1),
          parent: questionnaireDraft.parent,
        });
        setLader(false);
        return;
      }
      if (paymentStatus === 'success') {
        navigate(`/journey/${draftId}?result=${paymentStatus}`);
        return;
      }
      setProgress(100);
      const questionnaire = await getQuestionnaireData({ token, id: draftId });

      updateQuestionnaireState({
        ...questionnaireState,
        ...questionnaire,
        currentQuestion: Question.Question9,
        formCompleted: true,
        paymentStatus,
        visitedQuestions: Object.values(Question),
      });
      setLader(false);
    })();
  }, [draftId, loader, questionnaireState, updateQuestionnaireState, user, navigate, token, paymentStatus]);

  const saveState = async (questionnaireState: QuestionnaireState) => {
    const newState = {
      ...questionnaireState,
      ...(questionnaireState.currentQuestion
        ? { lastEditedQuestion: questionToStepNumber[questionnaireState.currentQuestion] }
        : {}),
    };

    try {
      setRequestError(undefined);
      setQuestionnaireErrors({});
      const data = await saveQuestionnaire(newState, token, user);
      updateQuestionnaireState({
        ...newState,
        userHasPayments: data?.user_has_payments,
      });
    } catch (e: unknown) {
      if (e instanceof Error && 'status' in e && 'body' in e) {
        const customError: RequestError = e as RequestError;
        const questionError = customError.body as QuestionError;
        setQuestionnaireErrors(questionError);
        return;
      }
      if (e instanceof Error && 'message') {
        setRequestError(e.message);
        return;
      }
      setRequestError('Api error');
    }
  };

  const handleConfirmEndQuestionnaireModal = async ({
    question,
    values,
  }: {
    question: Question;
    values: QuestionnaireDtoValues | undefined;
  }) => {
    const updatedAnswers = {
      ...(questionnaireState.answers ?? {}),
      ...(values ? { [question]: values } : {}),
    };

    await saveState({
      ...questionnaireState,
      answers: updatedAnswers,
    });

    updateQuestionnaireState({
      visitedQuestions: [],
    });
    setProgress(0);
    navigate('/dashboard');
  };

  const handleSubmitEditTitleModal = async (title: string) => {
    await saveState({
      ...questionnaireState,
      title,
    });
  };

  const onCompleteQuestion = async (values: {
    completion: number;
    nextQuestion?: Question;
    partialAnswers: Partial<QuestionnaireAnswers>;
  }) => {
    if (values.completion > 0 && values.completion > progress) {
      setProgress(values.completion);
    }
    const formCompleted = Boolean(
      questionnaireState.currentQuestion === Question.Question9 &&
        values.nextQuestion === Question.Question9 &&
        questionnaireState.answers,
    );
    const visitedQuestions = getUpdateVisitedQuestions(questionnaireState);
    const updatedState = getUpdatedState({
      questionnaireState: { ...questionnaireState, visitedQuestions, formCompleted: formCompleted },
      partialAnswers: values.partialAnswers,
      nextQuestion: values.nextQuestion,
      lastEditedQuestion: questionToStepNumber?.[questionnaireState?.currentQuestion ?? Question.Question1],
    });

    if (!questionnaireState.draftId) {
      return;
    }
    await Promise.all([
      saveState(updatedState),
      postTrackQuestionnaire({
        user: user.id,
        token,
        data: {
          draft: Number(questionnaireState.draftId),
          type: 'questionnaire_next',
        },
      }),
    ]);
  };

  const onPrevClick = async ({
    question,
    values,
  }: {
    question: Question;
    values: QuestionnaireDtoValues | undefined;
  }) => {
    if (!questionnaireState.draftId || !question) {
      return;
    }
    const visitedQuestions = getUpdateVisitedQuestions(questionnaireState);
    const updatedAnswers = {
      ...(questionnaireState.answers ?? {}),
      ...(values ? { [question]: values } : {}),
    };
    const previousQuestion = questionnaireState.formCompleted ? Question.Question9 : getPreviousQuestion(question);
    const completion = getDecreasedCompletionByQuestion(previousQuestion);
    if (completion < progress) {
      setProgress(completion);
    }

    await Promise.all([
      saveState({
        ...questionnaireState,
        answers: updatedAnswers,
        currentQuestion: previousQuestion,
        formCompleted: false,
        visitedQuestions,
      }),
      postTrackQuestionnaire({
        user: user.id,
        token,
        data: {
          draft: questionnaireState.draftId,
          type: 'questionnaire_prev',
        },
      }),
    ]);
  };

  const onCompleteIntro = async () => {
    setInitializer(true);

    const { id, name, answers } = await newQuestionnaire(token, user, {});
    const newQuestionnaireState = {
      ...questionnaireState,
      draftId: Number(id),
      title: name,
      answers,
      currentQuestion: Question.Question1,
      lastEditedQuestion: questionToStepNumber[Question.Question1],
    };
    updateQuestionnaireState(newQuestionnaireState);
    setTimeout(() => {
      navigate(`/questionnaire/${id}`);
      setInitializer(false);
    }, 1000);
  };

  return {
    loader,
    progress,
    setLader,
    handleSubmitEditTitleModal,
    handleConfirmEndQuestionnaireModal,
    onCompleteQuestion,
    onPrevClick,
    onCompleteIntro,
    initializer,
    startNewQuestionnaire,
    questionnaireErrors,
    setQuestionnaireErrors,
    requestError,
    setRequestError,
  };
};
