import { InitiationScreen } from 'components/questionnaire/Phase1';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { redirectDefaultPath } from 'utils';

export const MainPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(redirectDefaultPath);
  }, [navigate]);

  return (
    <>
      <InitiationScreen />
    </>
  );
};
