import { questionToStepNumber } from 'sdk/constants';
import { Question } from 'sdk/questionnaire/enums/question';

import { isLastQuestion } from './isLastQuestion';

export const getNextQuestionName = (question = Question.Question1): Question | undefined => {
  if (isLastQuestion(question)) {
    return Question.Question9;
  }
  const index = questionToStepNumber[question] + 1;
  const result = Object.entries(questionToStepNumber).find((entry) => entry[1] === index);
  const nextQuestion = result?.[0] as Question | undefined;
  if (!nextQuestion) {
    return;
  }

  return nextQuestion;
};
