import { env } from 'env';
import { RequestError } from 'sdk/internalApi/request';
import { ExpertPlusReview, JourneyResult } from 'sdk/types/result';

type PresentationRequest = {
  user: number;
  token: string;
  journeyId: number;
};

type PresentationItem = JourneyResult;

export type PresentationResponse = {
  results: PresentationItem[];
  expert_plus_reviews: ExpertPlusReview[];
};

export const getPresentation = async (request: PresentationRequest): Promise<PresentationResponse> => {
  const url = `${env.API_URL}/presentation/${request.journeyId}/`;
  const token = request.token;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.ok) {
    throw new RequestError(res.status, null);
  }

  return res.json();
};
