import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const Expert: React.FC<Props> = ({ stroke, strokeWidth, width, height, fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.75 -0.75 48 48" height={height} width={width} fill={fill}>
    <defs />
    <title>{'smiley-glasses'}</title>
    <path
      d="M2 24a22 22 0 1 0 44 0 22 22 0 1 0 -44 0"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 28s4 8 12 8 12 -8 12 -8"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 14a8 8 0 1 0 16 0 8 8 0 1 0 -16 0"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26 14a8 8 0 1 0 16 0 8 8 0 1 0 -16 0"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6 14H0m48 0h-6" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
