import { colorTones } from 'constants/designSystem';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { ProgressBar } from 'components/atoms/Progress/ProgressBar';
import { EditNamingJourney, EndButtonWithConfirmation } from 'components/organism/Modal';
import React from 'react';

import { QuestionnaireCompletedSection } from './QuestionnaireCompletedSection';

type Props = {
  handleSubmitEditTitleModal?: (title: string) => void;
  handleConfirmEndQuestionnaireModal?: () => Promise<void>;
  title: string;
  questionnaireCompletion?: number;
  questionnaireCompletedText?: string;
  modalTitle?: string;
  modalSubTitle?: string;
  openEditTitleModal?: boolean;
  confirmEndNamingJourneyButtonText: string;
  endNamingJourneyText: string;
  endNamingJourneySubText: string;
  endNamingJourneyModalTitle: string;
  endNamingJourneyModalSubTitle: string;
  completed?: boolean;
  disableEditName?: boolean;
  hideEditName?: boolean;
};

const StyledGrid = styled(Grid)({
  backgroundColor: colorTones.neutralsSlightGray,
  m: 0,
  p: 0,
});

const StyleContainer = styled(Box)({
  zIndex: 11,
  position: 'sticky',
  maxHeight: '55px',
  top: `56px`,
  p: 0,
  m: 0,
});

export const QuestionnaireNavigation: React.FC<Props> = ({
  modalSubTitle,
  title,
  modalTitle,
  questionnaireCompletion,
  questionnaireCompletedText,
  confirmEndNamingJourneyButtonText,
  endNamingJourneyText,
  endNamingJourneySubText,
  endNamingJourneyModalTitle,
  endNamingJourneyModalSubTitle,
  handleSubmitEditTitleModal,
  handleConfirmEndQuestionnaireModal,
  completed,
  disableEditName = false,
  hideEditName = false,
}) => (
  <>
    <StyleContainer>
      <StyledGrid container spacing={0} maxHeight={{ xs: '60px', sm: '46px' }}>
        <Grid item xs={4} md={3} lg={2} display="flex" justifyContent="left" alignItems="center" p={0} m={0}>
          {!hideEditName && (
            <EditNamingJourney
              sx={{ paddingTop: 1, paddingLeft: 2 }}
              disableEditName={disableEditName}
              title={title}
              modalTitle={modalTitle}
              modalSubTitle={modalSubTitle}
              handleSubmit={handleSubmitEditTitleModal}
            />
          )}
        </Grid>
        <Grid item xs={4} md={6} lg={8} display="flex" justifyContent="center" p={0} m={0}>
          <QuestionnaireCompletedSection text={questionnaireCompletedText || ''} completed={completed} />
        </Grid>
        <Grid item xs={4} md={3} lg={2} display="flex" justifyContent="right" alignItems="center" p={0} m={0}>
          <EndButtonWithConfirmation
            modalTitle={endNamingJourneyModalTitle}
            modalSubTitle={endNamingJourneyModalSubTitle}
            handleConfirmEnd={handleConfirmEndQuestionnaireModal}
            confirmButtonText={confirmEndNamingJourneyButtonText}
            text={endNamingJourneyText}
            subText={endNamingJourneySubText}
          />
        </Grid>
      </StyledGrid>

      <ProgressBar
        completion={questionnaireCompletion || 0}
        colorBar={(completed && colorTones.plant200) || colorTones.ocean80}
      />
    </StyleContainer>
  </>
);
