import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  FlexBox,
  IntroParagraphText,
  LightText,
  QuestionExampleBlueText,
  QuestionExampleText,
  QuestionStepLabel,
  UpperCaseText,
} from 'components/atoms';
import { FadingContainer, QuestionContainer } from 'components/molecules';
import { IntroQuotedText, QuestionExampleBox, QuestionTips } from 'components/organism/Questionnaire';
import { question4Paragraphs, question4TipItem } from 'components/questionnaire/constants/questionContent';
import { FormQuestion4 } from 'components/questionnaire/forms';
import { QuestionIntroduction } from 'components/questionnaire/QuestionIntroduction';
import { Question4Props } from 'components/questionnaire/types';
import React, { useState } from 'react';

type Props = {
  phaseName: string;
  questionVisited: boolean;
} & Question4Props;

export const Question4: React.FC<Props> = ({
  answers,
  phaseName,
  formRef,
  questionVisited,
  requestError,
  questionError,
  onClick,
}) => {
  const [showTipSection, setShowTipSection] = useState<boolean>(false);
  const introText = (
    <Box key={'intro4Text'}>
      <QuestionStepLabel>{phaseName}</QuestionStepLabel>
      <IntroParagraphText>
        List your two most{' '}
        <Typography component="span" variant="questionInstruction">
          useful and desirable end benefits.
        </Typography>
      </IntroParagraphText>
    </Box>
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <QuestionContainer
      tipSection={
        <FadingContainer visible={showTipSection}>
          <QuestionTips items={[question4TipItem]} defaultExpanded />
        </FadingContainer>
      }
      mainSection={
        <QuestionIntroduction
          intro={
            <IntroQuotedText
              title={phaseName}
              text={`“Showcasing tangible benefits sets expectations around quality, value and performance.”`}
            />
          }
          paragraphsToAnimate={[introText, ...question4Paragraphs]}
          form={
            <Box paddingTop="100px">
              <QuestionExampleBox title="Examples of what good answers look like:">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, auto)' }}>
                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      {!isMobile && (
                        <QuestionExampleText fs="15px" mobileFS="13px">
                          ...
                        </QuestionExampleText>
                      )}
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        unique
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px" desktopMargin="5px">
                        +
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        designs
                      </QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText fs="15px" mobileFS="13px">
                      and
                    </QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        vibrant
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px" desktopMargin="5px">
                        +
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        colors
                      </QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      {!isMobile && (
                        <QuestionExampleText fs="15px" mobileFS="13px">
                          ...
                        </QuestionExampleText>
                      )}
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        increased
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px" desktopMargin="5px">
                        +
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        exposure
                      </QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText fs="15px" mobileFS="13px">
                      and
                    </QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        revenue
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px" desktopMargin="5px">
                        +
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        growth
                      </QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      {!isMobile && (
                        <QuestionExampleText fs="15px" mobileFS="13px">
                          ...
                        </QuestionExampleText>
                      )}
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        up-to-date
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px" desktopMargin="5px">
                        +
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        inventory
                      </QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText fs="15px" mobileFS="13px">
                      and
                    </QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        simplified
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px" desktopMargin="5px">
                        +
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        interface
                      </QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      {!isMobile && (
                        <QuestionExampleText fs="15px" mobileFS="13px">
                          ...
                        </QuestionExampleText>
                      )}
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        functional
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px" desktopMargin="5px">
                        +
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        strength
                      </QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText fs="15px" mobileFS="13px">
                      and
                    </QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        vital
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px" desktopMargin="5px">
                        +
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        flexibility
                      </QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: '0 0 16px 0' }} justifyContent="end">
                      {!isMobile && (
                        <QuestionExampleText fs="15px" mobileFS="13px">
                          ...
                        </QuestionExampleText>
                      )}
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        personalized
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px" desktopMargin="5px">
                        +
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        lessons
                      </QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText fs="15px" mobileFS="13px">
                      and
                    </QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        expert
                      </QuestionExampleBlueText>
                      <QuestionExampleText fs="15px" mobileFS="13px" desktopMargin="5px">
                        +
                      </QuestionExampleText>
                      <QuestionExampleBlueText fs="15px" mobileFS="13px">
                        resources
                      </QuestionExampleBlueText>
                    </FlexBox>
                  </Box>
                </div>
              </QuestionExampleBox>
              <FormQuestion4
                onClick={onClick}
                answers={answers}
                formRef={formRef}
                requestError={requestError}
                questionError={questionError}
              />
              <br />
              <UpperCaseText sx={{ fontSize: '10px' }}>WHEN ANSWERING, DON&lsquo;T WORRY ABOUT...</UpperCaseText>
              <br />
              <LightText>
                <Box>
                  <Typography component="span" variant="lightTextStart">
                    The things that get left out.
                  </Typography>{' '}
                  Picking just two benefits may feel like some really important stuff is being swept aside. And that’s
                  totally ok! Go with your gut when it comes to selecting the outcomes you want to highlight. And
                  remember - the rest of your messaging and communications will provide plenty of airtime to everything
                  else.
                </Box>
              </LightText>
            </Box>
          }
          fomTitle={''}
          onFormInView={(isInView) => {
            setShowTipSection(isInView);
          }}
          questionVisited={questionVisited}
        />
      }
    />
  );
};
