import { colorTones } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Icon } from 'components/atoms';
import React, { useState } from 'react';

type CompactFavoriteFeedbackType = {
  favorite: boolean;
  onClickFavorite?: (favorite: boolean) => void;
};

const getStrokeColor = (favorite: boolean, hovered: boolean) => {
  if ((favorite && hovered) || (!favorite && !hovered)) {
    return colorTones.nightSky80;
  }

  return colorTones.primaryRed;
};

const getFillColor = (favorite: boolean, hovered: boolean) => {
  if (favorite && !hovered) {
    return colorTones.primaryRed;
  }
  if (!favorite && hovered) {
    return colorTones.primaryRed;
  }
  return 'none';
};

const StyledIconWrapper = styled(Box)({
  paddingLeft: '8px',
  paddingRight: '8px',
  minWidth: '16px',
});

export const CompactFavoriteFeedback: React.FC<CompactFavoriteFeedbackType> = ({ onClickFavorite, favorite }) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const handleClick = () => {
    onClickFavorite?.(!favorite);
  };

  return (
    <StyledIconWrapper
      sx={{ cursor: 'pointer' }}
      onClick={handleClick}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <Icon
        type="interfaceFavoriteHeart"
        width={16}
        height={16}
        stroke={getStrokeColor(favorite, hovered)}
        fill={getFillColor(favorite, hovered)}
      />
    </StyledIconWrapper>
  );
};
