import { colorTones, fonts } from 'constants/designSystem';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Alert } from 'components/atoms';
import { Icon } from 'components/atoms/Icons';
import { ResultFeedbackItem } from 'components/molecules';
import React, { useEffect, useState } from 'react';
import { JourneyResultFeedback } from 'sdk/types/result';

type Props = {
  ratingLabel?: string;
  positiveLabel?: string;
  neutralLabel?: string;
  negativeLabel?: string;
  showFeedbackName?: boolean;
  feedback?: JourneyResultFeedback;
  disabled?: boolean;
  handleFeedback?: (feedback: JourneyResultFeedback) => void;
};

const StyledRatingWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: '10px',
});

const StyledRatingLabel = styled(Typography)({
  paddingLeft: '8px',
  fontStyle: 'italic',
  color: colorTones.nightSky200,
});

const StyledFeedbackLabel = styled(Typography)({
  fontFamily: fonts.primeFont,
  fontStyle: 'italic',
  fontWeight: 400,
  fontSize: '17px',
  lineHeight: '180%',
  letterSpacing: '-0.02em',
});

const StyleBoxWithLeftMargin = styled(Box)({
  paddingLeft: '16px',
});

export const ResultRatingFeedback: React.FC<Props> = ({
  ratingLabel,
  positiveLabel,
  neutralLabel,
  negativeLabel,
  showFeedbackName = true,
  feedback = JourneyResultFeedback.NoFeedback,
  disabled,
  handleFeedback,
}) => {
  const [isRated, setIsRated] = useState(false);
  const [feedbackLabel, setFeedbackLabel] = useState<JourneyResultFeedback>(JourneyResultFeedback.NoFeedback);
  useEffect(() => {
    setFeedbackLabel(feedback);
    setIsRated(false);
  }, [feedback]);

  const mapJourneyResultToLabelConfig = {
    [JourneyResultFeedback.Positive]: {
      label: positiveLabel || '',
      color: colorTones.plant80,
    },
    [JourneyResultFeedback.Neutral]: {
      label: neutralLabel || '',
      color: colorTones.ocean80,
    },
    [JourneyResultFeedback.Negative]: {
      label: negativeLabel || '',
      color: colorTones.sunflower80,
    },
  };

  const handleOnMouseLeave = () => {
    setFeedbackLabel(feedback);
  };

  return (
    <Box>
      {ratingLabel && (
        <StyledRatingWrapper
          sx={{ opacity: disabled ? '25%' : '100%', paddingTop: '10px', paddingBottom: '10px', marginTop: '80px' }}
        >
          <Icon type="mailChatBubbleOvalQuestion" stroke={colorTones.sunflower200} width={22} height={22} />
          <StyledRatingLabel variant="h5">{ratingLabel}</StyledRatingLabel>
        </StyledRatingWrapper>
      )}
      <StyledRatingWrapper>
        <ResultFeedbackItem
          disabled={disabled}
          type="positive"
          active={feedback === JourneyResultFeedback.Positive}
          onMouseEnter={() => {
            setFeedbackLabel(JourneyResultFeedback.Positive);
          }}
          onMouseLeave={handleOnMouseLeave}
          onClick={() => {
            handleFeedback?.(JourneyResultFeedback.Positive);
            setIsRated(false);
          }}
        />
        <StyleBoxWithLeftMargin>
          <ResultFeedbackItem
            disabled={disabled}
            type="neutral"
            active={feedback === JourneyResultFeedback.Neutral}
            onMouseEnter={() => {
              setFeedbackLabel(JourneyResultFeedback.Neutral);
            }}
            onClick={() => {
              handleFeedback?.(JourneyResultFeedback.Neutral);
              setIsRated(false);
            }}
            onMouseLeave={handleOnMouseLeave}
          />
        </StyleBoxWithLeftMargin>
        <StyleBoxWithLeftMargin>
          <ResultFeedbackItem
            disabled={disabled}
            type="negative"
            active={feedback === JourneyResultFeedback.Negative}
            onMouseEnter={() => {
              setFeedbackLabel(JourneyResultFeedback.Negative);
            }}
            onClick={() => {
              handleFeedback?.(JourneyResultFeedback.Negative);
              setIsRated(false);
            }}
            onMouseLeave={handleOnMouseLeave}
          />
        </StyleBoxWithLeftMargin>
        {showFeedbackName && feedbackLabel !== JourneyResultFeedback.NoFeedback && (
          <StyleBoxWithLeftMargin sx={{ paddingTop: '4px' }}>
            <StyledFeedbackLabel sx={{ color: mapJourneyResultToLabelConfig[feedbackLabel].color }}>
              {!disabled && mapJourneyResultToLabelConfig[feedbackLabel].label}
            </StyledFeedbackLabel>
          </StyleBoxWithLeftMargin>
        )}
      </StyledRatingWrapper>
      {isRated && feedback === 0 && <Alert variant="error">Provide feedback first please</Alert>}
    </Box>
  );
};
