import { colorTones } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box, Modal as MuiModal, Typography } from '@mui/material';
import { Icon } from 'components/atoms/Icons';
import { FlatButtonWithIcon } from 'components/molecules';
import * as React from 'react';

type ColorVariant = 'light' | 'dark';

type Props = {
  title?: string;
  ariaLabelledby?: string;
  ariaDescribedby?: string;
  children: React.ReactNode;
  variant?: ColorVariant;
  open?: boolean;
  onClose?: () => void;
};

const StyledContainer = styled(Box)({
  position: 'absolute',
  boxShadow: '0px 4px 36px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '24px',
  maxWidth: '670px',
  width: 'calc(100% - 30px)',
});

const StyledCloseWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const StyledInnerContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
});

const modalVariantToStyle: Record<ColorVariant, { bgcolor: string; color: string }> = {
  dark: { bgcolor: colorTones.primaryNightSky, color: colorTones.neutralsWhite },
  light: { bgcolor: colorTones.neutralsWhite, color: colorTones.nightSky80 },
};

export const ModalWindow: React.FC<Props> = ({
  title,
  ariaLabelledby,
  ariaDescribedby,
  open = false,
  variant = 'light',
  onClose,
  children,
}) => {
  const sxVariant = modalVariantToStyle[variant];
  return (
    <MuiModal open={open} onClose={onClose} aria-labelledby={ariaLabelledby} aria-describedby={ariaDescribedby}>
      <StyledContainer sx={{ ...sxVariant }}>
        <StyledCloseWrapper>
          <FlatButtonWithIcon
            onClick={onClose}
            icon={
              <Icon
                type="interfaceDelete1"
                stroke={sxVariant.color}
                strokeWidth="1.1"
                width={25}
                height={25}
                viewBox="0 0 25 25"
              />
            }
          />
        </StyledCloseWrapper>
        <StyledInnerContainer>
          {title && (
            <Box paddingBottom={2} sx={{ width: '100%' }}>
              <Typography variant="h1" id={ariaLabelledby}>
                {title}
              </Typography>
            </Box>
          )}
          <Box paddingBottom={2} paddingTop={2} sx={{ width: '100%' }}>
            {children}
          </Box>
        </StyledInnerContainer>
      </StyledContainer>
    </MuiModal>
  );
};
