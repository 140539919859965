import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceValidationCheckCircle: React.FC<Props> = ({
  stroke,
  strokeWidth,
  width,
  height,
  viewBox,
  fill,
}) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9998 23.1429C18.1538 23.1429 23.1426 18.154 23.1426 12C23.1426 5.84597 18.1538 0.857147 11.9998 0.857147C5.84576 0.857147 0.856934 5.84597 0.856934 12C0.856934 18.154 5.84576 23.1429 11.9998 23.1429Z"
      stroke={fill !== 'none' ? fill : stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.85693 13.7143L10.3712 16.5257C10.4656 16.6034 10.5761 16.6592 10.6947 16.6888C10.8133 16.7185 10.9371 16.7213 11.0569 16.6971C11.1779 16.6744 11.2926 16.626 11.3932 16.555C11.4938 16.484 11.5779 16.3921 11.6398 16.2857L17.1426 6.85715"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
