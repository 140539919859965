import { LogoutOptions, User, useAuth0 } from '@auth0/auth0-react';
import { InitiationScreen } from 'components/questionnaire/Phase1';
import { MainTemplate } from 'components/templates/MainTemplate';
import { NavigationItem } from 'components/templates/types';
import {
  BuyGiftCardPage,
  DashboardPage,
  JourneyPage,
  LoginPage,
  MainPage,
  NotFoundPage,
  QuestionnairePage,
  ResultsDetailPage,
  UserPage,
} from 'pages';
import { Route, Routes, useLocation } from 'react-router-dom';
import { SessionUser } from 'sdk/types';

const privatePages: NavigationItem[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    newTab: false,
    iconType: 'interfaceHome3',
  },
  {
    name: 'Questionnaire',
    url: '/questionnaire',
    newTab: false,
    iconType: 'interfaceAddSquare',
  },
];

const publicPages: NavigationItem[] = [
  {
    name: 'Gift Cards',
    url: '/gift-cards/buy',
    newTab: false,
    iconType: 'gift',
  },
  {
    name: 'Blog',
    url: 'https://www.namesbyaesop.com/blog',
    newTab: true,
    iconType: 'entertainmentNewsPaper',
  },
];

const getNavigationPages = (isAuthenticated: boolean): NavigationItem[] => {
  if (!isAuthenticated) {
    return publicPages;
  }
  return privatePages.concat(publicPages);
};

const supportPage: NavigationItem = {
  name: 'Help',
  url: 'https://www.namesbyaesop.com/help',
  iconType: 'interfaceHelpCustomerSupport2',
  newTab: true,
};

const getUserContextMenuItems = ({
  user,
  logout,
}: {
  user?: SessionUser;
  logout: (options?: LogoutOptions) => void;
}) => {
  if (!user) {
    return [];
  }

  return [
    {
      label: 'User Settings',
      onClick: () => (window.location.href = '/user'),
    },
    {
      label: 'Log Out',
      onClick: () =>
        logout({
          logoutParams: { returnTo: window.location.origin },
        }),
    },
  ];
};

const getAccessiblePages = (
  isAuthenticated: boolean,
): { accessiblePages: React.ReactNode[]; skipAuthentication: boolean } => {
  const publiclyAccessible: Record<string, React.ReactNode> = {
    '/gift-cards/buy': <BuyGiftCardPage isAuthenticated={isAuthenticated} />,
    '*': <NotFoundPage />,
  };
  const requireAuthentication: Record<string, React.ReactNode> = {
    '/': <MainPage />,
    '/login': <LoginPage />,
    '/questionnaire': <QuestionnairePage />,
    '/questionnaire/:draftId': <QuestionnairePage />,
    '/results/:journeyId': <ResultsDetailPage />,
    '/user': <UserPage />,
    '/dashboard': <DashboardPage />,
    '/journey/:journeyId': <JourneyPage />,
    '/notfound': <NotFoundPage />,
    '*': <NotFoundPage />,
  };

  const accessiblePages: React.ReactNode[] = [];
  let skipAuthentication = false;

  Object.keys(publiclyAccessible).forEach((url) => {
    accessiblePages.push(<Route path={url} element={publiclyAccessible[url]} />);
    if (location.pathname.includes(url)) {
      skipAuthentication = true;
    }
  });

  if (isAuthenticated) {
    Object.keys(requireAuthentication).forEach((url) => {
      accessiblePages.push(<Route path={url} element={requireAuthentication[url]} />);
    });
  }

  return { accessiblePages, skipAuthentication };
};

const getSessionUser = (user?: User): SessionUser | undefined => {
  if (!user) {
    return;
  }

  return { userName: user.name, image: user.picture };
};

export const App = () => {
  const { isLoading, isAuthenticated, user, logout, loginWithRedirect } = useAuth0();
  const sessionUser = getSessionUser(user);
  const location = useLocation();

  if (isLoading) {
    return <InitiationScreen />;
  }

  if (location.pathname.includes('signup')) {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
    return null;
  }

  const { accessiblePages, skipAuthentication } = getAccessiblePages(isAuthenticated);

  if (!isAuthenticated && !skipAuthentication) {
    const returnTo = window.location.search
      ? `${window.location.pathname}${window.location.search}`
      : window.location.pathname;
    loginWithRedirect({ appState: { returnTo } });
    return null;
  }

  return (
    <MainTemplate
      pagesWithNoMenu={['/questionnaire', '/results']}
      pagesWithoutLogoBackground={['/questionnaire', '/results']}
      user={sessionUser}
      pages={getNavigationPages(isAuthenticated)}
      supportPage={supportPage}
      userContextMenuItems={getUserContextMenuItems({ user: sessionUser, logout })}
    >
      <Routes>{accessiblePages}</Routes>
    </MainTemplate>
  );
};
