import { Box, Typography } from '@mui/material';
import {
  FlexBox,
  LightText,
  QuestionExampleBlueText,
  QuestionExampleText,
  QuestionStepLabel,
  UpperCaseText,
} from 'components/atoms';
import { FadingContainer, QuestionContainer } from 'components/molecules';
import { QuestionExampleBox, QuestionTips } from 'components/organism/Questionnaire';
import {
  introQuestion2Part2Text,
  question2Part2Paragraphs,
  question2TipItem,
} from 'components/questionnaire/constants';
import { FormQuestion2Part2 } from 'components/questionnaire/forms';
import { QuestionIntroduction } from 'components/questionnaire/QuestionIntroduction';
import { Question2Part2Props } from 'components/questionnaire/types';
import React, { useState } from 'react';

type Props = {
  phaseName: string;
  questionVisited: boolean;
} & Question2Part2Props;

export const Question2Part2: React.FC<Props> = ({
  answer,
  phaseName,
  formRef,
  questionVisited,
  requestError,
  questionError,
  onClick,
}) => {
  const [showTipSection, setShowTipSection] = useState<boolean>(false);
  const introText = (
    <Box key={'intro2PartText'}>
      <QuestionStepLabel>{phaseName}</QuestionStepLabel>
      {introQuestion2Part2Text}
    </Box>
  );

  return (
    <QuestionContainer
      tipSection={
        <FadingContainer visible={showTipSection}>
          <QuestionTips items={[question2TipItem]} defaultExpanded />
        </FadingContainer>
      }
      mainSection={
        <QuestionIntroduction
          tipSection={<QuestionTips items={[question2TipItem]} />}
          paragraphsToAnimate={[introText, ...question2Part2Paragraphs]}
          form={
            <Box paddingTop="80px">
              <QuestionExampleBox title="EXAMPLES OF WHAT GOOD ANSWERS LOOK LIKE:">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <FlexBox flexDirection="column" sx={{ padding: 0, margin: 0 }}>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleText>... to</QuestionExampleText>
                      <QuestionExampleBlueText>style</QuestionExampleBlueText>
                      <QuestionExampleText>+</QuestionExampleText>
                      <QuestionExampleBlueText>outfits</QuestionExampleBlueText>
                    </FlexBox>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleText>... to</QuestionExampleText>
                      <QuestionExampleBlueText>influence</QuestionExampleBlueText>
                      <QuestionExampleText>an</QuestionExampleText>
                      <QuestionExampleBlueText>audience</QuestionExampleBlueText>
                    </FlexBox>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleText>... to</QuestionExampleText>
                      <QuestionExampleBlueText>coordinate</QuestionExampleBlueText>
                      <QuestionExampleText>with</QuestionExampleText>
                      <QuestionExampleBlueText>vendors</QuestionExampleBlueText>
                    </FlexBox>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleText>... to</QuestionExampleText>
                      <QuestionExampleBlueText>build</QuestionExampleBlueText>
                      <QuestionExampleText>+</QuestionExampleText>
                      <QuestionExampleBlueText>physical mobility</QuestionExampleBlueText>
                    </FlexBox>
                    <FlexBox sx={{ padding: 0, margin: '0 0 16px 0' }}>
                      <QuestionExampleText>... to</QuestionExampleText>
                      <QuestionExampleBlueText>improve</QuestionExampleBlueText>
                      <QuestionExampleText>+</QuestionExampleText>
                      <QuestionExampleBlueText>pronunciation</QuestionExampleBlueText>
                    </FlexBox>
                  </FlexBox>
                </div>
              </QuestionExampleBox>
              <FormQuestion2Part2
                onClick={onClick}
                answer={answer}
                formRef={formRef}
                requestError={requestError}
                questionError={questionError}
              />
              <br />
              <UpperCaseText sx={{ fontSize: '10px' }}>WHEN ANSWERING, DON&lsquo;T WORRY ABOUT...</UpperCaseText>
              <br />
              <LightText>
                <Box>
                  <Typography component="span" variant="lightTextStart">
                    Being obvious:
                  </Typography>{' '}
                  In this case, the best answer is often quite plain. Don’t worry if your instincts point to something
                  that feels a little basic or “no duh”. Just go with what feels like it hits the bullseye.
                </Box>
              </LightText>
            </Box>
          }
          fomTitle={''}
          onFormInView={(isInView) => {
            setShowTipSection(isInView);
          }}
          questionVisited={questionVisited}
        />
      }
    />
  );
};
