import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { Question } from 'sdk/questionnaire/enums';
import { DtoAnswers } from 'sdk/questionnaire/types';
import { PaymentStatus, QuestionnaireAnswers } from 'sdk/types';

export type QuestionnaireState = {
  title?: string;
  draftId?: number;
  questionnaireId?: number;
  currentQuestion?: Question;
  answers?: QuestionnaireAnswers;
  formCompleted?: boolean;
  visitedQuestions: Question[];
  paymentStatus?: PaymentStatus;
  userHasPayments?: boolean;
  lastEditedQuestion?: number;
  parent?: number;
  dtoAnswers?: DtoAnswers;
};

type Props = {
  children: (props: QuestionnaireContextType) => React.ReactElement;
};

export type QuestionnaireContextType = {
  questionnaireState: QuestionnaireState;
  updateQuestionnaireState: (questionnaireState: QuestionnaireState) => void;
};

export const QuestionnaireContext = createContext<QuestionnaireContextType>({
  updateQuestionnaireState: () => null,
  questionnaireState: {
    visitedQuestions: [],
  },
});

const getInitialQuestionnaireState = () => {
  return {
    visitedQuestions: [],
  };
};

export const QuestionnaireContextProvider: React.FC<Props> = ({ children }) => {
  const [questionnaireState, setQuestionnaireState] = useState<QuestionnaireState>({
    visitedQuestions: [],
  });

  const updateQuestionnaireState = useCallback((questionnaireState: QuestionnaireState) => {
    setQuestionnaireState({ ...questionnaireState });
  }, []);

  useEffect(() => {
    const newState = getInitialQuestionnaireState();
    setQuestionnaireState(newState);
  }, []);

  const contextValue = useMemo(
    () => ({ questionnaireState, updateQuestionnaireState }),
    [questionnaireState, updateQuestionnaireState],
  );

  return (
    <QuestionnaireContext.Provider value={contextValue}>
      {children({ questionnaireState, updateQuestionnaireState })}
    </QuestionnaireContext.Provider>
  );
};
