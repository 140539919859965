import { DtoAnswers } from 'sdk/questionnaire/types';
import { QuestionnaireAnswers } from 'sdk/types';

export const mapQuestionnaireAnswersToDtoAnswers = (questionnaireAnswers: QuestionnaireAnswers): DtoAnswers => {
  if (
    !questionnaireAnswers.question1 ||
    !questionnaireAnswers.question2 ||
    !questionnaireAnswers.question2Part2 ||
    !questionnaireAnswers.question3 ||
    !questionnaireAnswers.question4 ||
    !questionnaireAnswers.question5 ||
    !questionnaireAnswers.question6 ||
    !questionnaireAnswers.question7 ||
    !questionnaireAnswers.question8 ||
    !questionnaireAnswers.question9
  ) {
    throw new Error('Invalid questionnaire answers');
  }

  const V = [questionnaireAnswers.question2Part2.V1 ?? '', questionnaireAnswers.question3.V2 ?? ''];

  return {
    ...questionnaireAnswers.question1,
    ...questionnaireAnswers.question4,
    ...questionnaireAnswers.question5,
    ...questionnaireAnswers.question6,
    ...questionnaireAnswers.question7,
    ...questionnaireAnswers.question8,
    ...questionnaireAnswers.question9,
    V,
    RA: questionnaireAnswers.question2?.RA ?? '',
    N1: questionnaireAnswers.question2?.N1 ?? '',
    N2: questionnaireAnswers.question2Part2?.N2 ?? '',
    offering: questionnaireAnswers.question2?.offering ?? '',
  };
};
