export interface Environment {
  API_URL: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_AUDIENCE: string;
}

declare global {
  interface Window {
    env: Environment;
  }
}

const env = window.env;

export { env };
