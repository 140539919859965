import { colorTones } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { Icon } from 'components/atoms/Icons';
import React from 'react';
import { JourneyStatus } from 'sdk/types';

type BadgeVariant = 'big' | 'small';

type Props = {
  status: JourneyStatus;
  withIcon?: boolean;
  variant?: BadgeVariant;
};

const StyledWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '4px 12px',
  gap: '4px',
  borderRadius: '6px',
});

const StyledText = styled(Typography)({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '16px',
});

const mapStatusToLabel: Record<JourneyStatus, string> = {
  pending: 'Results pending',
  completed: 'Results ready!',
};

const mapStatusToColor: Record<JourneyStatus, string> = {
  pending: colorTones.ocean300,
  completed: colorTones.plant300,
};

const mapStatusToBackground: Record<JourneyStatus, string> = {
  pending: colorTones.ocean10,
  completed: colorTones.plant10,
};

const variantToHeightInPx: Record<BadgeVariant, number> = {
  big: 32,
  small: 24,
};

const variantToWidthInPx: Record<BadgeVariant, number> = {
  big: 120,
  small: 105,
};

const variantToIconSizeInPx: Record<BadgeVariant, number> = {
  big: 16,
  small: 11,
};

const mapStatusToIconType: Record<JourneyStatus, 'interfaceTimeClockCircle' | 'mailInboxPost'> = {
  pending: 'interfaceTimeClockCircle',
  completed: 'mailInboxPost',
};

export const BadgeJourneyStatus: React.FC<Props> = ({ status, variant = 'small', withIcon = false }) => (
  <StyledWrapper
    sx={{
      background: mapStatusToBackground[status],
      color: mapStatusToColor[status],
      width: `${variantToWidthInPx[variant]}px`,
      height: `${variantToHeightInPx[variant]}px`,
    }}
  >
    {withIcon && (
      <Icon
        type={mapStatusToIconType[status]}
        width={variantToIconSizeInPx[variant]}
        height={variantToIconSizeInPx[variant]}
        stroke={mapStatusToColor[status]}
      />
    )}
    <StyledText>{mapStatusToLabel[status]}</StyledText>
  </StyledWrapper>
);
