import { Box, Typography } from '@mui/material';
import {
  FlexBox,
  IntroParagraphText,
  LightText,
  QuestionExampleBlueText,
  QuestionExampleText,
  QuestionStepLabel,
  UpperCaseText,
} from 'components/atoms';
import { FadingContainer, QuestionContainer } from 'components/molecules';
import { IntroQuotedText, QuestionExampleBox, QuestionTips } from 'components/organism/Questionnaire';
import { question8Paragraphs, question8TipItem } from 'components/questionnaire/constants';
import { FormQuestion8 } from 'components/questionnaire/forms';
import { QuestionIntroduction } from 'components/questionnaire/QuestionIntroduction';
import { Question8Props } from 'components/questionnaire/types';
import React, { useState } from 'react';

type Props = {
  phaseName: string;
  questionVisited: boolean;
} & Question8Props;

export const Question8: React.FC<Props> = ({
  answers,
  phaseName,
  formRef,
  questionVisited,
  requestError,
  questionError,
  onClick,
}) => {
  const [showTipSection, setShowTipSection] = useState<boolean>(false);
  const introText = (
    <Box key={'intro8Text'}>
      <QuestionStepLabel>{phaseName}</QuestionStepLabel>
      <IntroParagraphText>
        Pick two words that best describe the{' '}
        <Typography component="span" variant="questionInstruction">
          specific good feelings
        </Typography>{' '}
        your offering brings out in people.
      </IntroParagraphText>
    </Box>
  );

  return (
    <QuestionContainer
      tipSection={
        <FadingContainer visible={showTipSection}>
          {<QuestionTips items={[question8TipItem]} defaultExpanded />}
        </FadingContainer>
      }
      mainSection={
        <QuestionIntroduction
          tipSection={<QuestionTips items={[question8TipItem]} />}
          intro={
            <IntroQuotedText
              title={phaseName}
              text={`“Promising to make people feel good creates desirability and drives demand.”`}
            />
          }
          paragraphsToAnimate={[introText, ...question8Paragraphs]}
          form={
            <Box paddingTop="130px">
              <QuestionExampleBox title="Examples of what good answers look like:">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, auto)' }}>
                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>… really</QuestionExampleText>
                      <QuestionExampleBlueText>confident</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>and</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>attractive</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>… really</QuestionExampleText>
                      <QuestionExampleBlueText>excited</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>and</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>empowered</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>… really</QuestionExampleText>
                      <QuestionExampleBlueText>productive</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>and</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>satisfied</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>… really</QuestionExampleText>
                      <QuestionExampleBlueText>energized</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>and</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>relaxed</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: '0 0 16px 0' }} justifyContent="end">
                      <QuestionExampleText>… really</QuestionExampleText>
                      <QuestionExampleBlueText>self-improved</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>and</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>capable</QuestionExampleBlueText>
                    </FlexBox>
                  </Box>
                </div>
              </QuestionExampleBox>
              <FormQuestion8
                onClick={onClick}
                answers={answers}
                formRef={formRef}
                requestError={requestError}
                questionError={questionError}
              />
              <br />
              <UpperCaseText sx={{ fontSize: '10px' }}>WHEN ANSWERING, DON&lsquo;T WORRY ABOUT...</UpperCaseText>
              <br />
              <LightText>
                <Box>
                  <Typography component="span" variant="lightTextStart">
                    The “all the time”.
                  </Typography>{' '}
                  This is about identifying a desirable mindset your offering is likely to produce. It’s totally okay if
                  you can think of circumstances that will create an exception to your answer. Just solve for “most of
                  the time” and you’ve got it.
                </Box>
              </LightText>
            </Box>
          }
          fomTitle={''}
          onFormInView={(isInView) => {
            setShowTipSection(isInView);
          }}
          questionVisited={questionVisited}
        />
      }
    />
  );
};
