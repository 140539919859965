import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const MailSmileyFriendlyFace: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9998 23.1429C18.1539 23.1429 23.1426 18.1541 23.1426 12C23.1426 5.84598 18.1539 0.857147 11.9998 0.857147C5.84576 0.857147 0.856934 5.84598 0.856934 12C0.856934 18.1541 5.84576 23.1429 11.9998 23.1429Z"
      stroke={!fill || fill === 'none' ? stroke : fill}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 16C9 16.5 10.5 17 12 17C13.5 17 15 16.5 16 16"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7713 9.34286C15.5347 9.34286 15.3428 9.15098 15.3428 8.91429C15.3428 8.6776 15.5347 8.48572 15.7713 8.48572"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7715 9.34286C16.0082 9.34286 16.2001 9.15098 16.2001 8.91429C16.2001 8.6776 16.0082 8.48572 15.7715 8.48572"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.22838 9.34286C7.99168 9.34286 7.7998 9.15098 7.7998 8.91429C7.7998 8.6776 7.99168 8.48572 8.22838 8.48572"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.22852 9.34286C8.46521 9.34286 8.65709 9.15098 8.65709 8.91429C8.65709 8.6776 8.46521 8.48572 8.22852 8.48572"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
