import { colorTones } from 'constants/designSystem';

import { Box, Typography } from '@mui/material';
import { Button } from 'components/atoms';
import { Icon } from 'components/atoms/Icons';
import { FlatButtonWithIcon } from 'components/molecules';
import { useHandleModal } from 'hooks';
import * as React from 'react';

import { ModalWindow } from './ModalWindow';

type Props = {
  handleConfirmEnd?: () => Promise<void>;
  confirmButtonText: string;
  text: string;
  subText: string;
  modalTitle?: string;
  modalSubTitle?: string;
};

const iconClose = (
  <Box sx={{ paddingTop: '1px' }}>
    <Icon
      type="interfaceDelete"
      stroke={colorTones.nightSky80}
      strokeWidth="1.1"
      width={16}
      height={16}
      viewBox="0 0 16 16"
    />
  </Box>
);

export const EndButtonWithConfirmation: React.FC<Props> = ({
  modalTitle,
  modalSubTitle,
  confirmButtonText,
  text,
  subText,
  handleConfirmEnd,
}) => {
  const { openModal, handleOpen, handleClose } = useHandleModal();
  const handleConfirm = () => {
    handleConfirmEnd?.();
    handleClose();
  };

  return (
    <Box>
      <Box sx={{ paddingRight: 2, paddingBottom: 1, paddingTop: 1 }}>
        <FlatButtonWithIcon onClick={handleOpen} text="Exit" icon={iconClose} />
      </Box>
      <ModalWindow
        open={openModal}
        onClose={handleClose}
        title={modalTitle}
        variant="dark"
        ariaLabelledby={modalTitle && 'modal-end-naming-journey-title'}
        ariaDescribedby={modalSubTitle && 'modal-end-naming-journey-description'}
      >
        {modalSubTitle && (
          <Typography variant="body1" id="modal-end-naming-journey-description">
            <b>{modalSubTitle}</b>
          </Typography>
        )}

        <Typography variant="body1">{text}</Typography>

        <Box sx={{ p: 4 }}>
          <Button color="success" onClick={handleConfirm}>
            {confirmButtonText}
            <Box m={0} p={0} marginTop={1}>
              <Icon type="interfaceDeleteCircle" stroke={colorTones.neutralsWhite} width={22} height={22} />
            </Box>
          </Button>
        </Box>

        <Typography
          sx={{ '&: hover': { opacity: '80%' }, cursor: 'pointer' }}
          onClick={handleClose}
          variant="lightLink"
        >
          {subText}
        </Typography>
      </ModalWindow>
    </Box>
  );
};
