import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const ImagePhotoPolaroid: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.57087 16.2857H14.5709C15.5176 16.2857 16.2852 15.5182 16.2852 14.5714V2.57142C16.2852 1.62465 15.5176 0.857134 14.5709 0.857134L2.57087 0.857134C1.6241 0.857134 0.856585 1.62465 0.856585 2.57142L0.856585 14.5714C0.856585 15.5182 1.6241 16.2857 2.57087 16.2857Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5713 8.05713L21.9084 8.4857C22.1241 8.55551 22.3239 8.66743 22.496 8.81497C22.6682 8.96252 22.8094 9.14275 22.9115 9.34524C23.0135 9.54772 23.0744 9.76844 23.0905 9.99461C23.1067 10.2208 23.0778 10.4479 23.0055 10.6628L19.2684 21.96C19.1272 22.3917 18.8204 22.7498 18.4154 22.9555C18.0104 23.1612 17.5403 23.1977 17.1084 23.0571L9.53125 20.5714"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.856934 12H16.2855"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
