import { colorTones } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FlexBox } from 'components/atoms';
import * as React from 'react';

type ColorVariant = 'default' | 'light' | 'none';

type Props = {
  onClick?: () => void;
  text?: string;
  icon?: React.ReactNode;
  iconPlacement?: 'left' | 'right';
  colorVariant?: ColorVariant;
  disabled?: boolean;
  wrapperProps?: React.CSSProperties;
};

const StyledIconWrapper = styled(Box)({
  paddingLeft: '8px',
});

const mapColorVariantToColor = {
  default: colorTones.nightSky80,
  light: colorTones.nightSky100,
};

export const FlatButtonWithIcon: React.FC<Props> = ({
  disabled = false,
  text,
  icon,
  iconPlacement = 'right',
  colorVariant = 'none',
  onClick,
  wrapperProps,
}) => {
  const iconStyle =
    colorVariant === 'none'
      ? undefined
      : {
          '& path': {
            stroke: mapColorVariantToColor[colorVariant],
          },
        };
  const textStyle = colorVariant === 'none' ? undefined : { color: mapColorVariantToColor[colorVariant] };
  const wrapperStyle = {
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? '50%' : '100%',
    '&: hover': disabled ? {} : { opacity: '80%' },
  };

  return (
    <FlexBox
      marginTop={0}
      marginLeft={0}
      marginRight={0}
      marginBottom={0}
      padding={0}
      onClick={() => {
        !disabled && onClick?.();
      }}
      flexDirection="row"
      alignItems="flex-start"
      sx={{ ...wrapperStyle, ...wrapperProps }}
    >
      {icon && iconPlacement === 'left' && <StyledIconWrapper sx={iconStyle}>{icon}</StyledIconWrapper>}
      {text && (
        <Typography
          variant="body2"
          sx={{
            ...textStyle,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: { xs: '80px', sm: '120px', md: '150px' },
          }}
        >
          {text}
        </Typography>
      )}
      {icon && iconPlacement === 'right' && <StyledIconWrapper sx={iconStyle}>{icon}</StyledIconWrapper>}
    </FlexBox>
  );
};
