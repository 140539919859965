import PersonIcon from '@mui/icons-material/Person';
import { CircularProgress, Avatar as MuiAvatar } from '@mui/material';
import { UserContext } from 'context/user/UserContext';
import React, { useContext } from 'react';

type Props = {
  userName?: string;
  image?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const Avatar: React.FC<Props> = ({ userName, image, onClick }) => {
  const { userInitials, isLoading } = useContext(UserContext);

  if (!userName && !image) {
    return (
      <div onClick={onClick}>
        <MuiAvatar>
          <PersonIcon />
        </MuiAvatar>
      </div>
    );
  }

  return (
    <div onClick={onClick}>
      {isLoading ? (
        <CircularProgress sx={{ color: 'white' }} />
      ) : (
        <MuiAvatar src={!userInitials ? image : ''}>{userInitials}</MuiAvatar>
      )}
    </div>
  );
};
