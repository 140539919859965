import { env } from 'env';
import { RequestError } from 'sdk/internalApi/request';
import { DtoAnswers } from 'sdk/questionnaire/types';
import { ExpertReviewStatus } from 'sdk/types';

type Request = {
  token: string;
  id: number;
};

export type QuestionnaireResponse = {
  id: number;
  user: number;
  name: string;
  answers: DtoAnswers;
  created?: string;
  failed?: boolean;
  has_results: boolean;
  modified?: string;
  seen_results?: boolean;
  parent?: number;
  parent_name?: string;
  related?: { id: number; name: string }[];
  expert_review_payed?: boolean;
  expert_plus_payed?: boolean;
  common_expert_review_status?: ExpertReviewStatus;
  user_has_payments?: boolean;
};

export const getQuestionnaire = async (request: Request): Promise<QuestionnaireResponse> => {
  const url = `${env.API_URL}/questionnaire/${request.id}/`;
  const token = request.token;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.ok) {
    throw new RequestError(res.status, null);
  }

  return res.json();
};
