import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Alert, FlexBox, PageContainer, StackContainer } from 'components/atoms';
import { Button } from 'components/atoms/Buttons/Button';
import { TextFormControl } from 'components/molecules/FormControls/TextFormControl';
import { UserContext } from 'context/user/UserContext';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { getUser, putUser } from 'sdk/internalApi';
import { User } from 'sdk/types';
import * as yup from 'yup';

type FormValues = Omit<User, 'id'>;

export const UserPage: React.FC = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [user, setUser] = useState<User>();
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const formValidationSchema = yup.object().shape({
    first_name: yup
      .string()
      .required('This field is required')
      .min(2, 'Oops! Your input needs to be a minimum of 2 characters.')
      .max(150, 'Whoops! That`s is more then 160 characters'),
    middle_name: yup
      .string()
      .min(2, 'Oops! Your input needs to be a minimum of 2 characters.')
      .max(150, 'Whoops! That`s is more then 160 characters'),
    last_name: yup
      .string()
      .required('This field is required')
      .min(2, 'Oops! Your input needs to be a minimum of 2 characters.')
      .max(150, 'Must be up to 160 characters'),
  });

  useEffect(() => {
    if (user) {
      setShowLoader(false);
      return;
    }
    if (isLoading || user) {
      return;
    }
    (async () => {
      setShowLoader(true);
      const token = await getAccessTokenSilently();
      const userData = await getUser({ token });
      setUser(userData);
      setShowLoader(false);
    })();
  }, [isLoading, getAccessTokenSilently, setUser, user, showLoader]);

  const { setUserInitials } = useContext(UserContext);

  if (!isAuthenticated || !user) {
    return null;
  }

  const onSubmitForm = async (values: FormValues) => {
    setShowLoader(true);
    const token = await getAccessTokenSilently();
    const updatedUser = await putUser({ token, user: values });
    setUser(updatedUser);
    setShowSuccessMessage(true);
    setShowLoader(false);
    setUserInitials?.((values?.first_name[0] as string) + values?.last_name[0]);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000);
  };

  const initialValues = {
    first_name: user?.first_name || '',
    middle_name: user?.middle_name || '',
    last_name: user?.last_name || '',
  };

  return (
    <PageContainer>
      <FlexBox flexDirection={'column'} alignItems={'center'}>
        <Typography variant="specialHeader">User settings</Typography>
        <Box sx={{ mt: 1 }}>
          <Grid container>
            {(isLoading && <>Loading...</>) || (
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmitForm}
                validationSchema={formValidationSchema}
                validateOnChange
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
                  <Form onSubmit={handleSubmit}>
                    <TextFormControl
                      id="first_name"
                      name="first_name"
                      value={values.first_name}
                      error={Boolean(errors.first_name) && touched.first_name}
                      label="First name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errorText={errors.first_name}
                    />

                    <TextFormControl
                      id="middle_name"
                      name="middle_name"
                      value={values.middle_name}
                      error={Boolean(errors.middle_name) && touched.middle_name}
                      label="Middle name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errorText={errors.middle_name}
                    />
                    <TextFormControl
                      id="last_name"
                      name="last_name"
                      value={values.last_name}
                      error={Boolean(errors.last_name) && touched.last_name}
                      label="Last name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errorText={errors.last_name}
                    />
                    <Button type="submit" disabled={!isValid}>
                      Save
                    </Button>
                  </Form>
                )}
              </Formik>
            )}
          </Grid>
          {showSuccessMessage && (
            <Box sx={{ padding: 1 }}>
              <Alert variant="success">
                <StackContainer direction="column">
                  <Grid> User data was updated</Grid>
                </StackContainer>
              </Alert>
            </Box>
          )}
        </Box>
      </FlexBox>
    </PageContainer>
  );
};
