import { env } from 'env';
import { RequestError } from 'sdk/internalApi/request';

type Request = {
  user: number;
  token: string;
  data: {
    type: 'questionnaire_next' | 'questionnaire_prev';
    draft: number;
  };
};

export const postTrackQuestionnaire = async (request: Request) => {
  const url = `${env.API_URL}/tracker/events/questionnaire`;
  const token = request.token;
  const timestamp = new Date();
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      ...request.data,
      timestamp: timestamp.toISOString(),
    }),
  });

  if (!(res.status >= 200 && res.status < 400)) {
    throw new RequestError(res.status, null);
  }
};
