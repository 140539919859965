import { Box, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { CenteredContentBox } from 'components/atoms';
import React from 'react';

type Props = {
  content?: React.ReactNode;
};

export const InitiationScreen: React.FC<Props> = ({ content }) => (
  <Grid container p={0} m={0}>
    <Grid item xs={12} p={0} m={0}>
      <CenteredContentBox>
        <Box>{content}</Box>
        <CircularProgress thickness={6.5} />
      </CenteredContentBox>
    </Grid>
  </Grid>
);
