import { Question } from 'sdk/questionnaire/enums/question';

export const questionToStepNumber = {
  [Question.Question1]: 1,
  [Question.Question2]: 2,
  [Question.Question2Part2]: 3,
  [Question.Question3]: 4,
  [Question.Question4]: 5,
  [Question.Question5]: 6,
  [Question.Question6]: 7,
  [Question.Question7]: 8,
  [Question.Question8]: 9,
  [Question.Question9]: 10,
};
