import { colorTones } from 'constants/designSystem';

import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import {
  DtoAnswer0,
  DtoAnswer1,
  DtoAnswer1Part2,
  DtoAnswer2,
  DtoAnswer3,
  DtoAnswer4,
  DtoAnswer5,
  DtoAnswer6,
  DtoAnswer7,
  DtoAnswer8,
  DtoAnswers,
} from 'sdk/questionnaire/types';

type Props = {
  answers: DtoAnswers;
};

const styles = StyleSheet.create({
  introWrapper: {
    backgroundColor: colorTones.neutralsWhite,
    maxWidth: '654px',
    display: 'flex',
    paddingTop: '24px',
    width: '95%',
  },
  questionWrapper: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  introTitle: {
    fontStyle: 'normal',
    fontSize: '14px',
    paddingTop: '30px',
    paddingBottom: '14px',
    color: colorTones.ocean300,
    textAlign: 'center',
  },
  paragraphWrapper: {
    paddingTop: '14px',
    backgroundColor: colorTones.neutralsWhite,
  },
  paragraphText: {
    fontStyle: 'normal',
    color: colorTones.nightSky200,
    fontSize: '14px',
    textAlign: 'left',
    paddingBottom: '14px',

    marginLeft: '50px',
    marginRight: '50px',
  },
  subParagraphText: {
    fontSize: '12px',
    color: colorTones.nightSky200,
    paddingTop: '20px',
    textAlign: 'left',
    paddingRight: '100px',
  },
  subInstructionText: {
    fontSize: '12px',
    color: colorTones.nightSky200,
    paddingTop: '20px',
    textAlign: 'left',
    marginLeft: '100px',
    paddingRight: '100px',
  },
  questionInstruction: {
    fontWeight: 'bold',
    color: colorTones.ocean200,
  },
  bulletListItem: {
    paddingTop: '20px',
    paddingBottom: '12px',
    textAlign: 'left',
    marginLeft: '100px',
    paddingRight: '15px',
  },
  formLayout: {
    padding: '16px',
    gap: '16px',
    minHeight: '60px',
    backgroundColor: 'rgba(64, 142, 222, 0.1)',
    borderRadius: '4px',
    marginTop: '12px',
    marginBottom: '20px',
    maxWidth: '1100px',
    marginLeft: '60px',
    marginRight: '60px',
  },
  formLayoutRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  formText: {
    fontStyle: 'normal',
    fontSize: '14px',
    color: colorTones.nightSky200,
  },
  formInputBox: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colorTones.neutralsWhite,
    boxShadow: `0px 2px 1px rgba(0, 0, 0, 0.08)`,
    borderRadius: '4px',
    marginLeft: '8px',
    marginRight: '8px',
    maxWidth: '250px',
  },
  formLayoutUlList: {
    display: 'flex',
    flexDirection: 'row',
  },
  formInputText: {
    fontStyle: 'italic',
    fontSize: '14px',
    padding: '7px',
    color: colorTones.sunflower300,
    textAlign: 'center',
  },
  formUpperCaseText: {
    fontSize: '8px',
    color: colorTones.primaryNightSky,
  },
  lightText: {
    marginTop: '14px',
    fontStyle: 'normal',
    fontSize: '10px',
    color: colorTones.nightSky100,
  },
  lightTextStart: {
    fontWeight: 'bold',
  },
  quoteText: {
    fontStyle: 'normal',
    fontSize: '25px',
    textAlign: 'center',
    maxWidth: '700px',
    marginLeft: '50',
    marginRight: '50px',
  },
});

const renderQuestion1 = ({ CN1, CN2 }: DtoAnswer0) => {
  return (
    <View>
      <Text style={styles.paragraphText}>
        The following is a summary of your Aesop Discovery Journey. This is great to share with your colleagues, and can
        aid in all internal alignments and external briefings related to things like purpose, benefits, positioning, and
        target audience. {'\n'}
        {'\n'}
        Take some time to reflect on this document. Consider whether the language provided truly represents the unique
        essence at the core of your offering. Over time, you may wish to modify some of your answers and see how that
        changes your results. To do so, simply click Buy more names” in your results summary page and select the option
        to “Modify responses” to your Discovery Journey.{'\n'}
        {'\n'}
      </Text>
      <Text style={styles.introTitle}>Step 1</Text>
      <Text style={styles.quoteText}>
        “Inventive category cues have a special magic that feels both fresh and familiar.”
      </Text>

      <View style={styles.formLayout}>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>Fill in the blanks: </Text>
        </View>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>My offering is part of the</Text>
          <View style={styles.formInputBox}>
            <Text style={styles.formInputText}>{CN1}</Text>
          </View>
        </View>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>and</Text>
          <View style={styles.formInputBox}>
            <Text style={styles.formInputText}>{CN2}</Text>
          </View>
          <Text style={styles.formText}>category.</Text>
        </View>
      </View>
      <View style={styles.introWrapper}>
        <Text style={styles.subInstructionText}>
          These are called <Text style={styles.questionInstruction}>Category Labels.</Text> They identify{' '}
          <Text style={styles.questionInstruction}>where your offering broadly fits</Text> within the market.{'\n'}
          {'\n'}
        </Text>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            A Category Label is a market segment big enough to encompass your offering, its direct competitors, and
            non-competing offerings with a similar purpose.
          </Text>
        </View>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            Language related to Category Labels establishes a frame of reference and can effectively anchor more
            imaginative words in the right context.
          </Text>
        </View>
      </View>
    </View>
  );
};

const renderQuestion2 = ({ RA, N1, offering }: DtoAnswer1) => {
  return (
    <View>
      <Text style={styles.introTitle}>Step 2</Text>
      <Text style={styles.quoteText}>
        “Basic expressions of what an offering is, and what it enables, speak to one’s earliest awareness of need
        state.”
      </Text>
      <View style={styles.formLayout}>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>Fill in the blanks:</Text>
        </View>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}> My </Text>
          <View style={styles.formInputBox}>
            <Text style={styles.formInputText}>{offering}</Text>
          </View>
          <Text style={styles.formText}> is (a/an/focused on) </Text>
          <View style={styles.formInputBox}>
            <Text style={styles.formInputText}>{RA}</Text>
          </View>
          <Text style={styles.formText}>+</Text>
        </View>
        <View style={styles.formLayoutRow}>
          <View style={{ ...styles.formInputBox, marginLeft: '0px' }}>
            <Text style={styles.formInputText}>{N1}</Text>
          </View>
          <Text style={styles.formText}>.</Text>
        </View>
      </View>
      <View style={{ marginTop: 40 }}>
        <View style={styles.introWrapper}>
          <Text style={styles.subInstructionText}>
            This is called an&nbsp; <Text style={styles.questionInstruction}>Offering Statement. </Text> This represents
            the{' '}
            <Text style={styles.questionInstruction}>
              simplest definition of your offering. First what it is and then what it allows people to do.{' '}
            </Text>
          </Text>
          <View style={styles.formLayoutUlList}>
            <Text style={styles.bulletListItem}>•</Text>
            <Text style={styles.subParagraphText}>
              An Offering Statement is the most direct and objective way to describe what you’ve made.
            </Text>
          </View>
          <View style={styles.formLayoutUlList}>
            <Text style={styles.bulletListItem}>•</Text>z
            <Text style={styles.subParagraphText}>
              Language related to an Offering Statement is simple and transparent, allowing it to stand out and build
              trust through its honesty and approachability.
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const renderQuestion2Part2 = ({ V1, N2 }: DtoAnswer1Part2) => {
  return (
    <View>
      <View style={styles.formLayout}>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>Fill in the blanks: </Text>
        </View>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>This offering allows people to </Text>
          <View style={styles.formInputBox}>
            <Text style={styles.formInputText}>{V1}</Text>
          </View>
        </View>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}> (for/with/to/a/on/in/about/the right) </Text>
          <View style={styles.formInputBox}>
            <Text style={styles.formInputText}>{N2}</Text>
          </View>
          <Text style={styles.formText}>.</Text>
        </View>
      </View>
      <View style={{ marginBottom: 100 }}>
        <Text style={styles.subInstructionText}>
          These words clarify what your offering enables using language that is simple, crisp and impartial.
        </Text>
      </View>
    </View>
  );
};

const renderQuestion3 = ({ V1, V2 }: DtoAnswer2 & { V1: string }) => {
  return (
    <View>
      <Text style={styles.introTitle}>Step 3</Text>
      <Text style={styles.quoteText}>
        “Verbs are highly immersive because they often get internalized from a first person perspective.”
      </Text>
      <View style={styles.formLayout}>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>Without using the word {V1}, fill in the blank: </Text>
        </View>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>
            The other task, action or activity that takes place when a user interacts with my offering is
          </Text>
        </View>
        <View style={styles.formLayoutRow}>
          <View style={{ ...styles.formInputBox, marginLeft: '0px' }}>
            <Text style={styles.formInputText}>{V2} </Text>
          </View>
          <Text style={styles.formText}>.</Text>
        </View>
      </View>
      <View style={styles.introWrapper}>
        <Text style={styles.subInstructionText}>
          This is called an&nbsp; <Text style={styles.questionInstruction}>Key Activity.</Text>
          This <Text style={styles.questionInstruction}>further defines “what happens” </Text>when your offering gets
          used.
        </Text>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            A Key Activity can be an action executed by the user’s body or mind, or performed by the offering on or for
            the user. Therefore, from the users’ perspective it can be external or internal, visible or invisible,
            active or passive.
          </Text>
        </View>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            Language related to Key Activities highlights what is actually getting done when people use your offering –
            painting a motivating picture of achievement, progress, and the act of “living life”.
          </Text>
        </View>
      </View>
    </View>
  );
};

const renderQuestion4 = ({ FA, FN }: DtoAnswer3) => {
  return (
    <View style={{ marginTop: 50 }}>
      <Text style={styles.introTitle}>Step 4</Text>
      <Text style={[styles.quoteText, { marginTop: '20px' }]}>
        “Showcasing tangible benefits sets expectations around quality, value and performance.”
      </Text>

      <View style={styles.formLayout}>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>Fill in the blanks. </Text>
        </View>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>I made my offering because it will give people:</Text>
        </View>
        <View style={styles.formLayoutRow}>
          <View style={{ ...styles.formInputBox, marginLeft: '0px' }}>
            <Text style={styles.formInputText}>{FA?.[0] ?? ''} </Text>
          </View>
          <Text style={styles.formText}>+</Text>
          <View style={{ ...styles.formInputBox, marginRight: '0px' }}>
            <Text style={styles.formInputText}>{FN?.[0] ?? ''} </Text>
          </View>
        </View>
        <View style={{ marginLeft: '220px' }}>
          <Text style={styles.formText}>and</Text>
        </View>
        <View style={styles.formLayoutRow}>
          <View style={{ ...styles.formInputBox, marginLeft: '0px' }}>
            <Text style={styles.formInputText}>{FA?.[1] ?? ''} </Text>
          </View>
          <Text style={styles.formText}>+</Text>
          <View style={{ ...styles.formInputBox, marginRight: '0px' }}>
            <Text style={styles.formInputText}>{FN?.[1] ?? ''} </Text>
          </View>
        </View>
      </View>
      <View style={styles.introWrapper}>
        <Text style={styles.subInstructionText}>
          These are called&nbsp; <Text style={styles.questionInstruction}>Valued Outcomes.</Text>
          These are your two most <Text style={styles.questionInstruction}>useful and desirable end benefits.</Text>
        </Text>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            Valued Outcomes communicate exactly what your offering delivers to meet peoples’ current wants or needs in a
            uniquely attractive way. They describe what people lack, and identify the gaps left by your direct
            competitors that you will successfully fill.
          </Text>
        </View>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            Language related to Valued Outcomes highlights the primary utility of your offering - making it a very
            effective way to signal your target audience: “this is what you are looking for”.
          </Text>
        </View>
      </View>
    </View>
  );
};

const renderQuestion5 = ({ DRA, DN }: DtoAnswer4) => {
  return (
    <View style={{ marginTop: 0 }}>
      <Text style={styles.introTitle}>Step 5</Text>
      <Text style={[styles.quoteText, { marginTop: '20px' }]}>
        “Celebrating how something is made sparks conversation around purposeful design and the exceptional results it
        enables.”
      </Text>
      <View style={styles.formLayout}>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>Fill in the blanks. </Text>
        </View>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>My offering is powered by</Text>
        </View>
        <View style={styles.formLayoutRow}>
          <View style={{ ...styles.formInputBox, marginLeft: '0px' }}>
            <Text style={styles.formInputText}>{DRA?.[0] ?? ''} </Text>
          </View>
          <View style={{ ...styles.formInputBox, marginRight: '0px' }}>
            <Text style={styles.formInputText}>{DN?.[0] ?? ''} </Text>
          </View>
          <Text style={styles.formText}>.</Text>
        </View>
      </View>
      <View style={styles.introWrapper}>
        <Text style={styles.subInstructionText}>
          This is called&nbsp; <Text style={styles.questionInstruction}>Hero Input.</Text>
          This is the specific{' '}
          <Text style={styles.questionInstruction}>design feature, material, or manufacturing approach</Text> that
          drives superior performance.
        </Text>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            A Hero Input is a unique ingredient, component, technology or standardized process that allows your offering
            to exceed “the norm”. Think of it as your turbo-pack – the thing that powers your offering to do what it
            does with excellence.
          </Text>
        </View>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            Language related to Hero Inputs speaks to your offering’s “x factor” – the force behind what makes it
            special – and is therefore great for piquing curiosity and building excitement.
          </Text>
        </View>
      </View>
    </View>
  );
};

const renderQuestion6 = ({ EA, EN }: DtoAnswer5) => {
  return (
    <View style={{ marginTop: 40 }}>
      <Text style={styles.introTitle}>Step 6</Text>
      <Text style={[styles.quoteText, { marginTop: '20px' }]}>
        “Meeting ‘the need behind the need’ creates deep and differentiated emotional bonds.”
      </Text>
      <View style={{ marginTop: '50px' }}>
        <View style={styles.formLayout}>
          <View style={styles.formLayoutRow}>
            <Text style={styles.formText}>Fill in the blanks. </Text>
          </View>
          <View style={styles.formLayoutRow}>
            <Text style={styles.formText}>My offering will change people’s life by giving them:</Text>
          </View>
          <View style={styles.formLayoutRow}>
            <View style={{ ...styles.formInputBox, marginLeft: '0px' }}>
              <Text style={styles.formInputText}>{EA?.[0] ?? ''} </Text>
            </View>
            <Text style={styles.formText}>+</Text>
            <View style={{ ...styles.formInputBox, marginRight: '0px' }}>
              <Text style={styles.formInputText}>{EN?.[0] ?? ''} </Text>
            </View>
            <Text style={styles.formText}>.</Text>
          </View>
        </View>
      </View>
      <View style={styles.introWrapper}>
        <Text style={styles.subInstructionText}>
          This is called&nbsp; <Text style={styles.questionInstruction}>The Bigger Experience.</Text>
          This expresses <Text style={styles.questionInstruction}>the more meaningful aspiration</Text> connected to
          your end benefits.
        </Text>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            The Bigger Experience is the “why” behind the act of seeking an offering’s Valued Outcomes. It speaks to
            deeper needs, and reflects ambitions around social fulfillment, self-esteem, personal growth or life
            experience. It’s something that – if achieved – can have a fundamental impact on someone’s happiness. ess.
          </Text>
        </View>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            Language related to The Bigger Experience associates what you’ve made with something far more consequential
            than a ”solution for today’s problem”. Thus, it can be highly motivating because it surfaces feelings
            associated with profound satisfaction and longer-term psychological rewards.
          </Text>
        </View>
      </View>
    </View>
  );
};

const renderQuestion7 = ({ EDA }: DtoAnswer6) => {
  return (
    <View>
      <Text style={styles.introTitle}>Step 7</Text>
      <Text style={styles.quoteText}>“Establishing the right ‘aura’ builds anticipation around usage experience.”</Text>
      <View style={styles.formLayout}>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>Fill in the blanks. </Text>
        </View>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>When using my offering, I want people to say:</Text>
        </View>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>Wow! This is really: </Text>
          <View style={{ ...styles.formInputBox, marginLeft: '0px' }}>
            <Text style={styles.formInputText}>{EDA?.[0] ?? ''} </Text>
          </View>
          <Text style={styles.formText}> and </Text>
          <View style={{ ...styles.formInputBox, marginRight: '0px' }}>
            <Text style={styles.formInputText}>{EDA?.[1] ?? ''} </Text>
          </View>
          <Text style={styles.formText}> . </Text>
        </View>
      </View>
      <View style={styles.introWrapper}>
        <Text style={styles.subInstructionText}>
          These are called
          <Text style={styles.questionInstruction}> Engagement Characteristics. </Text>
          These two words really “sum up” the{' '}
          <Text style={styles.questionInstruction}> mood, tone or atmosphere set by your offering. </Text>
        </Text>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            An Engagement Characteristic is not about performance. It’s about how your offering “comes across” or
            “appears” to those on the other side.
          </Text>
        </View>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            Language related to Engagement Characteristics describes the impression your offering leaves in the room –
            making it easier for people to envision interacting with it.
          </Text>
        </View>
      </View>
    </View>
  );
};

const renderQuestion8 = ({ MA }: DtoAnswer7) => {
  return (
    <View style={{ marginTop: 60 }}>
      <Text style={styles.introTitle}>Step 8</Text>
      <Text style={[styles.quoteText, { marginTop: '30px' }]}>
        “Promising to make people feel good creates desirability and drives demand.”
      </Text>
      <View style={styles.formLayout}>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>Fill in the blanks. </Text>
        </View>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>When using my offering, I want people to say:</Text>
        </View>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>Wow! I feel really </Text>
          <View style={{ ...styles.formInputBox, marginLeft: '0px' }}>
            <Text style={styles.formInputText}>{MA?.[0] ?? ''} </Text>
          </View>
          <Text style={styles.formText}> and </Text>
          <View style={{ ...styles.formInputBox, marginRight: '0px' }}>
            <Text style={styles.formInputText}>{MA?.[1] ?? ''} </Text>
          </View>
          <Text style={styles.formText}> . </Text>
        </View>
      </View>
      <View style={styles.introWrapper}>
        <Text style={styles.subInstructionText}>
          These are called&nbsp;
          <Text style={styles.questionInstruction}> Mindset Descriptors.</Text>
          These two words best describe the <Text style={styles.questionInstruction}> specific good feelings </Text>your
          offering brings out in
        </Text>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            A Mindset Descriptor labels the psychological landscape that’s produced when the mental or emotional
            challenges surrounding your offering’s need state are removed. Thus, it often goes beyond an “emotion” to
            touch on a person’s attitude or state of mind.
          </Text>
        </View>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            Language related to Engagement Characteristics describes the impression your offering leaves in the room –
            making it easier for people to envision interacting with it.
          </Text>
        </View>
      </View>
    </View>
  );
};

const renderQuestion9 = ({ UCA, UCN }: DtoAnswer8) => {
  return (
    <View style={{ marginTop: 60, marginBottom: 60 }}>
      <Text style={styles.introTitle}>Step 9</Text>
      <Text style={[styles.quoteText, { marginTop: '30px' }]}>
        “Reflecting collective identity helps people feel seen and understood.”
      </Text>
      <View style={styles.formLayout}>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>Fill in the blanks. </Text>
        </View>
        <View style={styles.formLayoutRow}>
          <Text style={styles.formText}>My users identify as:</Text>
        </View>
        <View style={styles.formLayoutRow}>
          <View style={{ ...styles.formInputBox, marginLeft: '0px' }}>
            <Text style={styles.formInputText}>{UCA?.[0] ?? ''} </Text>
          </View>
          <Text style={styles.formText}>+</Text>
          <View style={{ ...styles.formInputBox, marginRight: '0px' }}>
            <Text style={styles.formInputText}>{UCN?.[0] ?? ''} </Text>
          </View>
          <Text style={styles.formText}> . </Text>
        </View>
      </View>
      <View style={styles.introWrapper}>
        <Text style={styles.subInstructionText}>
          This is called your &nbsp;
          <Text style={styles.questionInstruction}>Collective Identity.</Text>
          This is the label that best{' '}
          <Text style={styles.questionInstruction}> captures your users’ chosen identity.</Text>
        </Text>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            Your Collective Identity is not about “demographics”. It’s about mirroring how users would express who they
            are and what gives them a sense of individuality. It describes the section of society in which they feel
            most integrated and accepted.
          </Text>
        </View>
        <View style={styles.formLayoutUlList}>
          <Text style={styles.bulletListItem}>•</Text>
          <Text style={styles.subParagraphText}>
            Language related to your Collective Identity builds a sense of community and demonstrates respect for
            peoples’ lifestyle choices. This reflection of self-concept is a great way to make target users feel like
            they’ve found home.
          </Text>
        </View>
      </View>
    </View>
  );
};

export const ExportQuestionnaireForm: React.FC<Props> = ({ answers }) => {
  const { CN1, CN2, RA, N1, N2, V, offering, FA, FN, DRA, DN, EA, EN, EDA, MA, UCA, UCN } = answers;

  return (
    <View>
      <View style={styles.questionWrapper}>{renderQuestion1({ CN1, CN2 })}</View>
      <View style={styles.questionWrapper}>{renderQuestion2({ RA, N1, offering })}</View>
      <View style={styles.questionWrapper}>{renderQuestion2Part2({ N2, V1: V?.[0] ?? '' })}</View>
      <View style={styles.questionWrapper}>{renderQuestion3({ V1: V?.[0] ?? '', V2: V?.[1] ?? '' })}</View>
      <View style={styles.questionWrapper}>{renderQuestion4({ FA, FN })}</View>
      <View style={styles.questionWrapper}>{renderQuestion5({ DRA, DN })}</View>
      <View style={styles.questionWrapper}>{renderQuestion6({ EA, EN })}</View>
      <View style={styles.questionWrapper}>{renderQuestion7({ EDA })}</View>
      <View style={styles.questionWrapper}>{renderQuestion8({ MA })}</View>
      <View style={styles.questionWrapper}>{renderQuestion9({ UCA, UCN })}</View>
    </View>
  );
};
