import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const UpperCaseText = styled(Typography)({
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '150%',
  letterSpacing: '0.04em',
  textTransform: 'uppercase',
});
