import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceArrowButtonLeft: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 2L7.24045 11.4615C7.16454 11.5306 7.10405 11.614 7.06269 11.7066C7.02134 11.7992 7 11.8991 7 12C7 12.1009 7.02134 12.2008 7.06269 12.2934C7.10405 12.386 7.16454 12.4694 7.24045 12.5385L17 22"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
