import { colorTones, fonts } from 'constants/designSystem';

import { Box, CircularProgress, Typography, styled } from '@mui/material';
import { Icon } from 'components/atoms';
import { RatedResultCard } from 'components/organism';
import React, { useEffect, useState } from 'react';
import { JourneyResult, JourneyResultFeedback } from 'sdk/types/result';

type Props = {
  title: string;
  results: JourneyResult[];
  favoriteDefaultLabel: string;
  favoredLabel: string;
  removeAsFavoriteLabel: string;
  loading?: boolean;
  onUserFeedback?: (id: number, uerFeedback: { feedback?: JourneyResultFeedback; favorite?: boolean }) => void;
};

export const StyledWrapper = styled(Box)({
  paddingTop: '64px',
  width: '100%',
});

export const StyledTitle = styled(Typography)({
  paddingTop: 2,
  paddingBottom: 2,
  color: colorTones.neutralsBlack,
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '150%',
});

export const StyledCardsWrapper = styled(Box)({
  paddingTop: '24px',
  maxWidth: '880px',
  width: '100%',
});

const StyledBaseFlexBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  verticalAlign: 'center',
  gap: '10px',
});

export const StyledLoadingWrapper = styled(StyledBaseFlexBox)({
  alignSelf: 'center',
  justifyContent: 'center',
  minHeight: '50dvh',
});

export const StyledHeaderBox = styled(StyledBaseFlexBox)({
  justifyContent: 'space-between',
  maxWidth: '880px',
});

const StyledSortingBox = styled(StyledBaseFlexBox)({
  justifyContent: 'flex-end',
  padding: '6px 6px 6px 14px',
  gap: '10px',
});

const StyledSortedByLabel = styled(Typography)({
  fontFamily: fonts.primeFont,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
  color: colorTones.nightSky100,
});

const StyledSortedByValueLabel = styled(StyledSortedByLabel)({
  color: colorTones.nightSky80,
});

export const RatedResults: React.FC<Props> = ({
  title,
  results,
  favoriteDefaultLabel,
  favoredLabel,
  removeAsFavoriteLabel,
  loading = false,
  onUserFeedback,
}) => {
  const [localResults, setLocalResults] = useState<JourneyResult[]>([]);
  const [expanded, setExpanded] = React.useState<number[]>([]);

  useEffect(() => {
    setLocalResults([...results]);
    const initialExpanded = results
      .filter((item) => item.expert_review?.expert_like)
      .map((item) => item.id)
      .filter((id): id is number => !!id);
    setExpanded(initialExpanded);
  }, [results]);

  return (
    <StyledWrapper>
      {loading && (
        <StyledLoadingWrapper>
          <CircularProgress thickness={6.5} />
        </StyledLoadingWrapper>
      )}
      {!loading && localResults.length > 0 && (
        <StyledHeaderBox>
          <StyledTitle variant="h3">{title}</StyledTitle>
          <StyledSortingBox>
            <Box>
              <Icon type="interfaceEditSortAscending" stroke={colorTones.nightSky100} width={16} height={16} />
            </Box>
            <StyledSortedByLabel>Sorted by:</StyledSortedByLabel>
            <Box>
              <Icon type="mailSmileyHappy" stroke={colorTones.nightSky80} width={16} height={16} />
            </Box>
            <StyledSortedByValueLabel>Rating</StyledSortedByValueLabel>
          </StyledSortingBox>
        </StyledHeaderBox>
      )}
      <StyledCardsWrapper>
        {!loading &&
          localResults.map((item, index) => {
            const key = `reslut-item-${index}`;

            const handleClickFavorite = async (favorite: boolean) => {
              if (item.id && onUserFeedback) {
                await onUserFeedback(item.id, { favorite });
              }
            };

            const handleClickRating = async (id: number, feedback: JourneyResultFeedback) => {
              if (onUserFeedback) {
                await onUserFeedback(id, { feedback });
              }
            };

            return (
              <RatedResultCard
                index={index + 1}
                id={item.id}
                key={key}
                name={item.name}
                rationale={item.rationale}
                feedback={item?.feedback}
                expertReviewLabel={'Expert review:'}
                expertFeedback={item.expert_review?.expert_feedback}
                expertLike={item.expert_review?.expert_like}
                erroneous={item.erroneous}
                favorite={item.favorite}
                ratingLabel="Your rating:"
                onClickRating={handleClickRating}
                favoriteDefaultLabel={favoriteDefaultLabel}
                favoredLabel={favoredLabel}
                removeAsFavoriteLabel={removeAsFavoriteLabel}
                onClickFavorite={handleClickFavorite}
                expanded={!!item.id && expanded.includes(item.id)}
                setExpanded={setExpanded}
              />
            );
          })}
      </StyledCardsWrapper>
    </StyledWrapper>
  );
};
