import { Question6FormValues } from 'components/questionnaire/types';
import { DtoAnswer5 } from 'sdk/questionnaire/types';
import { Question6Answer } from 'sdk/types';

export const mapQuestion6ToDtoAnswer5 = ({ responses }: Question6FormValues): DtoAnswer5 => {
  const ea: string[] = [];
  const en: string[] = [];

  responses.forEach(({ adjective, noun }: Question6Answer) => {
    ea.push(adjective);
    en.push(noun);
  });

  return {
    EA: ea,
    EN: en,
  };
};
