import { colorTones } from 'constants/designSystem';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, styled } from '@mui/material';
import { FlexBoxRow } from 'components/atoms';
import { QuestionTipItem } from 'components/questionnaire/types';
import React from 'react';

type Props = {
  items: QuestionTipItem[];
  defaultExpanded?: boolean;
};

const StyledContainer = styled(Box)({
  borderTop: `4px solid ${colorTones.sunflower200}`,
  background: colorTones.neutralsSlightGray,
  boxShadow: '0px 2px 6px rgba(0, 13, 46, 0.14)',
  minWidth: '150px',
  marginTop: '50px',
  top: `118px`,
  marginBottom: '56px',
  '@media (min-width: 600px)': {
    position: 'sticky',
    marginTop: '2px',
  },
});

const StyledBaseTextContainer = styled(Box)({
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  textAlign: 'left',
});

const StyledAccordionTitle = styled(StyledBaseTextContainer)({
  justifyContent: 'center',
  fontSize: '18px',
  lineHeight: '24px',
});
const StyledAccordionText = styled(StyledBaseTextContainer)({
  justifyContent: 'right',
  fontSize: '12px',
  lineHeight: '150%',
});

const StyledCard = styled(Card)({
  marginTop: 2,
  marginBottom: 2,
});

const StyledCardTitle = styled(StyledBaseTextContainer)({
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '150%',
  letterSpacing: '0.04em',
  textTransform: 'uppercase',
});

const StyledCardText = styled(StyledBaseTextContainer)({
  fontWeight: 400,
  fontSize: '12px',
  fontStyle: 'italic',
  lineHeight: '150%',
});

export const QuestionTips: React.FC<Props> = ({ items, defaultExpanded }) => {
  return (
    <StyledContainer>
      {items.map((item) => {
        const itemHeaderKey = `${item.id}-header`;
        return (
          <Accordion
            key={itemHeaderKey}
            defaultExpanded={defaultExpanded}
            sx={{ backgroundColor: '#F7F7F7', boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${item.id}-content`}
              id={`${item.id}-header-id`}
            >
              <FlexBoxRow>
                {item.titleIcon && (
                  <Box sx={{ marginRight: 2, width: '40px', height: '40px', borderRadius: '50%', background: '#fff' }}>
                    <Box sx={{ m: 1 }}> {item.titleIcon}</Box>
                  </Box>
                )}
                <Box sx={{ marginTop: 1 }}>
                  <StyledAccordionTitle>{item.title}</StyledAccordionTitle>
                </Box>
              </FlexBoxRow>
            </AccordionSummary>
            <AccordionDetails>
              {item?.boxContents?.map((boxContent) => {
                const boxContentKey = `${boxContent.id}-box-content`;
                return (
                  <StyledCard key={boxContentKey} sx={{ marginTop: 2, marginBottom: 2 }}>
                    <CardContent>
                      <FlexBoxRow>
                        {boxContent?.titleIcon && (
                          <Box p={1} sx={{ width: '20%' }}>
                            {boxContent?.titleIcon}
                          </Box>
                        )}

                        {boxContent?.title && (
                          <Box sx={{ paddingTop: 1, paddingBottom: 1, width: boxContent?.titleIcon ? '80%' : '100%' }}>
                            <StyledCardTitle>{boxContent?.title}</StyledCardTitle>
                          </Box>
                        )}
                      </FlexBoxRow>
                      {boxContent?.text && <StyledCardText>{boxContent?.text}</StyledCardText>}
                    </CardContent>
                  </StyledCard>
                );
              })}
              {item?.textItems?.map((textItem) => {
                const textIemKey = `${textItem.id}-text-item`;
                return <StyledAccordionText key={textIemKey}>{textItem.text}</StyledAccordionText>;
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </StyledContainer>
  );
};
