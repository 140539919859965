import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceAlertWarningCircle: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9998 23.1429C18.1538 23.1429 23.1426 18.154 23.1426 12C23.1426 5.84597 18.1538 0.857147 11.9998 0.857147C5.84576 0.857147 0.856934 5.84597 0.856934 12C0.856934 18.154 5.84576 23.1429 11.9998 23.1429Z"
        stroke={fill !== 'none' ? fill : stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9997 17.1429C12.4731 17.1429 12.8569 16.7591 12.8569 16.2857C12.8569 15.8123 12.4731 15.4286 11.9997 15.4286C11.5263 15.4286 11.1426 15.8123 11.1426 16.2857C11.1426 16.7591 11.5263 17.1429 11.9997 17.1429Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 6V11.1429" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
