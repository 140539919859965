import { useMediaQuery, useTheme } from '@mui/material';
import { FormQuestionText, Input } from 'components/atoms';
import { FormError, FormWarning, QuestionFormContainer } from 'components/molecules';
import { Question2FormValues, Question2Props } from 'components/questionnaire/types';
import { Form, Formik } from 'formik';
import React from 'react';
import { Question } from 'sdk/questionnaire/enums';
import { assertMaxWordCount } from 'utils';
import * as yup from 'yup';

type Props = {
  disabled?: boolean;
  isChild?: boolean;
} & Question2Props;

const verbMaxWordsCount = 2;
const nounMaxWordsCount = 2;

export const FormQuestion2: React.FC<Props> = ({
  answer,
  formRef,
  disabled,
  isChild,
  requestError,
  questionError,
  onClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const initialValues: Question2FormValues = {
    RA: answer?.RA || '',
    N1: answer?.N1 || '',
    offering: answer?.offering || '',
  };

  const formSchema = yup.object({
    offering: yup.string().required('Offering is required'),
    N1: yup
      .string()

      .required('Word is required')
      .test('Max noun words count', `Must be ${nounMaxWordsCount} words or fewer in first blank`, (val) =>
        assertMaxWordCount(val || '', nounMaxWordsCount),
      ),
    RA: yup
      .string()
      .test('Max adjective words count', `Must be ${verbMaxWordsCount} words or fewer in second blank`, (val) =>
        assertMaxWordCount(val || '', verbMaxWordsCount),
      )
      .required('Descriptor is required'),
  });

  const onSubmitForm = ({ N1, RA, offering }: Question2FormValues) => {
    onClick?.(
      {
        [Question.Question2]: {
          RA,
          N1,
          offering,
        },
      },
      Question.Question2,
    );
  };

  return (
    <QuestionFormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={formSchema}
        validateOnChange
        innerRef={formRef}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormQuestionText>
              <p style={{ textAlign: 'left' }}>Fill in the blanks:</p>
              My{' '}
              <Input
                disabled={disabled}
                name="offering"
                value={values.offering}
                placeholder="Offering"
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (Boolean(questionError?.offering) && questionError?.offering?.[0] === values.offering) ||
                  (Boolean(errors.offering) && touched.offering)
                }
              />{' '}
              is (a/an/focused on)
              <Input
                disabled={disabled}
                name="RA"
                value={values.RA}
                placeholder="Descriptor"
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (Boolean(questionError?.RA) && questionError?.RA?.[0] === values.RA) ||
                  (Boolean(errors.RA) && touched.RA)
                }
              />
              +
              <Input
                disabled={disabled}
                name="N1"
                value={values.N1}
                placeholder="Word"
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (Boolean(questionError?.N1) && questionError?.N1?.[0] === values.N1) ||
                  (Boolean(errors.N1) && touched.N1)
                }
              />
              {!isMobile && '.'}
            </FormQuestionText>
            {Boolean(errors.N1) && touched.N1 && <FormError>{errors.N1}</FormError>}
            {Boolean(errors.RA) && touched.RA && <FormError>{errors.RA}</FormError>}
            {Boolean(errors.offering) && touched.offering && <FormError>{errors.offering}</FormError>}
            {((Boolean(questionError?.N1) && questionError?.N1?.[0] === values.N1) ||
              (Boolean(questionError?.RA) && questionError?.RA?.[0] === values.RA) ||
              (Boolean(questionError?.offering) && questionError?.offering?.[0] === values.offering)) && (
              <FormError>Name is not allowed</FormError>
            )}
            {Boolean(requestError) && <FormError>{requestError}</FormError>}
            {isChild && (
              <FormWarning>
                Just a heads up, we had to lock in your responses to Steps 1 and 2a. But don’t worry – you’re free to
                change everything else.
              </FormWarning>
            )}
          </Form>
        )}
      </Formik>
    </QuestionFormContainer>
  );
};
