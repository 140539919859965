import { colorTones } from 'constants/designSystem';

import { Box, Tooltip } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Icon } from 'components/atoms/Icons/Icon';
import { MenuItem } from 'components/organism/Navigation/types/menuItem';
import React from 'react';

type Props = {
  menuItems: MenuItem[];
  menuSupport?: MenuItem;
  turnOffMenu: boolean;
};

const MenuListItem: React.FC<MenuItem> = ({ iconType, onClick, isActive, name }) => {
  return (
    <Tooltip title={name} placement="right" arrow>
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          sx={{
            height: '40px',
            width: '40px',
            justifyContent: 'center',
            px: 2.5,
            ...(isActive ? { background: colorTones.neutralsWhite } : {}),
            borderRadius: '50%',
          }}
          onClick={onClick}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 'auto',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ marginTop: '8px', marginLeft: '2px' }}>
              <Icon
                type={iconType}
                stroke={isActive ? colorTones.plant200 : colorTones.nightSky200}
                viewBox="0 0 18 18"
              />
            </Box>
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};
export const MainNavigation: React.FC<Props> = ({ menuSupport, menuItems, turnOffMenu }) => {
  return (
    (!turnOffMenu && (
      <Box>
        <MuiDrawer
          variant="permanent"
          PaperProps={{
            sx: {
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '8px',
              gap: '8px',
              width: '56px',
              top: '56px',
              background: colorTones.neutralsSlightGray,
              border: `1px solid ${colorTones.neutralsSlightGray}`,
            },
          }}
        >
          <List>
            {menuItems.map(({ iconType, name, onClick, isActive }, index) => {
              const key = `${index}${name}`;
              return <MenuListItem key={key} iconType={iconType} onClick={onClick} isActive={isActive} name={name} />;
            })}
          </List>
          {menuSupport && (
            <List style={{ marginTop: `auto`, marginBottom: '36px' }}>
              <MenuListItem
                name={menuSupport.name}
                iconType={menuSupport.iconType}
                onClick={menuSupport.onClick}
                isActive={menuSupport.isActive}
              />
            </List>
          )}
        </MuiDrawer>
      </Box>
    )) ||
    null
  );
};
