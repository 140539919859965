import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const TwoGifts: React.FC<Props> = ({ stroke, strokeWidth, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(0,300) scale(0.1,-0.1)" fill={stroke} stroke="none" strokeWidth={strokeWidth}>
      <path
        d="M1064 2646 c-88 -39 -147 -155 -126 -251 5 -27 13 -57 17 -66 5 -14
-3 -17 -54 -21 -75 -5 -129 -39 -168 -105 -26 -45 -28 -55 -28 -163 0 -101 3
-119 23 -153 l22 -39 -21 -29 c-11 -16 -22 -29 -23 -29 -2 0 -36 43 -75 95
-78 103 -108 125 -174 125 -105 0 -172 -102 -144 -217 8 -32 8 -33 -25 -33
-48 0 -100 -31 -125 -76 -18 -31 -23 -55 -23 -120 0 -74 3 -85 30 -125 l30
-44 0 -200 c1 -239 9 -272 82 -305 40 -18 68 -20 423 -20 355 0 383 2 423 20
49 22 81 79 82 143 l0 37 393 0 c439 0 470 4 530 63 55 56 60 83 64 395 l5
282 34 51 c19 28 34 57 34 64 0 7 3 15 8 17 4 2 5 50 2 108 -4 87 -10 113 -30
150 -37 65 -96 103 -171 108 -51 4 -59 7 -53 21 67 158 -32 331 -190 331 -100
-1 -133 -26 -266 -207 -41 -56 -77 -102 -80 -102 -3 0 -39 46 -80 102 -101
137 -126 165 -171 188 -45 22 -129 25 -175 5z m134 -102 c22 -13 169 -197 180
-226 2 -4 -51 -8 -117 -8 -139 0 -181 12 -205 59 -57 108 45 233 142 175z
m681 2 c41 -21 61 -59 61 -111 0 -55 -22 -93 -67 -111 -32 -14 -273 -21 -273
-9 0 9 146 199 166 216 37 32 71 36 113 15z m-439 -507 l0 -169 -164 0 -164 0
-23 47 c-30 61 -77 93 -136 93 -49 0 -75 -11 -115 -47 l-26 -25 -7 55 c-8 64
1 132 22 165 30 47 58 51 346 51 l267 0 0 -170z m670 154 c48 -25 70 -72 70
-153 0 -81 -22 -128 -70 -153 -31 -15 -64 -17 -300 -15 l-265 3 -3 167 -2 167
267 1 c237 0 272 -2 303 -17z m-1613 -268 c20 -14 123 -146 123 -159 0 -11
-168 -6 -193 5 -54 25 -63 104 -16 143 35 30 56 32 86 11z m504 -14 c44 -44
35 -116 -18 -140 -28 -13 -193 -15 -193 -2 0 13 98 138 122 155 33 23 56 20
89 -13z m439 -441 l0 -301 -112 3 -113 3 -3 108 c-3 107 -2 110 28 155 27 41
30 53 30 125 0 123 -40 180 -139 197 -31 5 10 8 132 9 l177 1 0 -300z m651
143 c1 -371 -3 -402 -58 -425 -18 -7 -113 -13 -260 -16 l-233 -4 0 301 0 301
275 0 275 0 1 -157z m-1494 67 l73 0 0 -115 0 -115 -199 0 c-110 0 -207 3
-216 6 -40 16 -60 129 -32 184 23 45 51 52 181 46 66 -3 152 -6 193 -6z m574
-17 c15 -14 23 -38 27 -72 6 -61 -2 -95 -28 -121 -19 -19 -34 -20 -225 -20
l-205 0 0 115 0 114 118 4 c240 7 289 4 313 -20z m-503 -505 l-3 -213 -172 0
c-232 0 -217 -16 -221 235 l-4 190 201 0 201 0 -2 -212z m470 22 c-4 -250 12
-233 -223 -238 l-175 -4 0 216 0 216 201 0 201 0 -4 -190z"
      />
    </g>
  </svg>
);
