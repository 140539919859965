import React from 'react';

import { BaseErrorBadge } from './BaseErrorBadge';

type Props = {
  label?: string;
};

export const BadgeFailed: React.FC<Props> = ({ label = 'Failed' }) => {
  return <BaseErrorBadge label={label} />;
};
