import { CommonAnswer, Question9FormValues } from 'components/questionnaire/types';
import { DtoAnswer8 } from 'sdk/questionnaire/types';

export const mapDtoAnswer8ToQuestion9 = (dtoAnswer8?: DtoAnswer8): Question9FormValues => {
  if (!dtoAnswer8?.UCA?.length) {
    return { responses: [{ adjective: '', noun: '' }] };
  }

  const responses: CommonAnswer[] =
    dtoAnswer8?.UCA.map((adjective, index) => {
      const noun = dtoAnswer8?.UCN?.[index];
      return { adjective, noun };
    }) || [];

  return { responses };
};
