import { FormQuestionText, Input } from 'components/atoms';
import { FormError, FormWarning, QuestionFormContainer } from 'components/molecules';
import { Question1FormValues, Question1Props } from 'components/questionnaire/types';
import { Form, Formik } from 'formik';
import React from 'react';
import { Question } from 'sdk/questionnaire/enums';
import { assertMaxWordCount } from 'utils';
import * as yup from 'yup';

type Props = {
  disabled?: boolean;
  isChild?: boolean;
} & Question1Props;

const maxWordInNoun = 2;

export const FormQuestion1: React.FC<Props> = ({
  answers,
  formRef,
  disabled,
  requestError,
  questionError,
  isChild,
  onClick,
}) => {
  const initialValues: Question1FormValues = {
    CN1: answers?.CN1 || '',
    CN2: answers?.CN2 || '',
  };

  const formSchema = yup.object({
    CN1: yup
      .string()
      .required('First word is required')
      .test('First noun words count', `Must be ${maxWordInNoun} words or fewer in first blank`, (val) =>
        assertMaxWordCount(val || '', maxWordInNoun),
      ),
    CN2: yup
      .string()
      .required('Second word is required')
      .test('Second noun words count', `Must be ${maxWordInNoun} words or fewer in second blank`, (val) =>
        assertMaxWordCount(val || '', maxWordInNoun),
      ),
  });

  const onSubmitForm = ({ CN1, CN2 }: Question1FormValues) => {
    onClick?.({ [Question.Question1]: { CN1, CN2 } }, Question.Question1);
  };

  return (
    <QuestionFormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={formSchema}
        validateOnChange
        innerRef={formRef}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormQuestionText>
              <p style={{ textAlign: 'left' }}>Fill in the blanks:</p>
              <p>My offering is part of the</p>
              <Input
                disabled={disabled}
                name="CN1"
                value={values.CN1}
                placeholder="Word"
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (Boolean(questionError?.CN1) && questionError?.CN1?.[0] === values.CN1) ||
                  (Boolean(errors.CN1) && touched.CN1)
                }
              />
              and
              <Input
                disabled={disabled}
                name="CN2"
                value={values.CN2}
                placeholder="Word"
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (Boolean(questionError?.CN2) && questionError?.CN2?.[0] === values.CN2) ||
                  (Boolean(errors.CN2) && touched.CN2)
                }
              />
              category.
            </FormQuestionText>
            {Boolean(errors.CN1) && touched.CN1 && <FormError>{errors.CN1}</FormError>}
            {Boolean(errors.CN2) && touched.CN2 && <FormError>{errors.CN2}</FormError>}
            {((Boolean(questionError?.CN1) && questionError?.CN1?.[0] === values.CN1) ||
              (Boolean(questionError?.CN2) && questionError?.CN2?.[0] === values.CN2)) && (
              <FormError>Name is not allowed</FormError>
            )}
            {Boolean(requestError) && <FormError>{requestError}</FormError>}
            {isChild && (
              <FormWarning>
                Just a heads up, we had to lock in your responses to Steps 1 and 2a. But don’t worry – you’re free to
                change everything else.
              </FormWarning>
            )}
          </Form>
        )}
      </Formik>
    </QuestionFormContainer>
  );
};
