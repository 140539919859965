import { colorTones } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { Icon } from 'components/atoms/Icons';
import React from 'react';

type Props = {
  formattedCreateDate?: string;
};

const StyledWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '4px 8px',
  gap: '4px',
  height: '24px',
  marginTop: '8px',
  background: colorTones.neutralsSlightGray,
  borderRadius: '12px',
  width: '146px',
});

const StyledText = styled(Typography)({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '16px',
  color: colorTones.nightSky100,
});

const iconEdit = <Icon type="interfaceUserEdit" stroke={colorTones.neutralsGray} width={12} height={12} />;

export const BadgeCreationDate: React.FC<Props> = ({ formattedCreateDate = 'dd / mm / yyyy' }) => (
  <StyledWrapper>
    {iconEdit} <StyledText>{formattedCreateDate}</StyledText>
  </StyledWrapper>
);
