import { AdjectiveAndNounFormSchema } from 'components/questionnaire/types';
import { assertMaxWordCount } from 'utils';
import * as yup from 'yup';

const maxWordsCountAdjective = 2;
const maxWordsCountNoun = 2;

export const getAdjectiveFormSchema = ({
  maxWordsCount = maxWordsCountAdjective,
  fieldDescription = 'descriptor blank',
}: {
  maxWordsCount?: number;
  fieldDescription?: string;
}) => {
  return yup
    .string()
    .required('Descriptor is required')
    .test('Adjective words count', `Must be ${maxWordsCount} words or fewer in ${fieldDescription}`, (val) =>
      assertMaxWordCount(val || '', maxWordsCount),
    );
};

export const getNounFormSchema = ({
  maxWordsCount = maxWordsCountNoun,
  fieldDescription = 'word blank',
}: {
  maxWordsCount?: number;
  fieldDescription?: string;
}) => {
  return yup
    .string()
    .required('Word is required')
    .test('Noun words count', `Must be ${maxWordsCount} words or fewer in ${fieldDescription}`, (val) =>
      assertMaxWordCount(val || '', maxWordsCount),
    );
};

export const getAdjectiveAndNounFormSchema = ({
  adjectiveSchema = getAdjectiveFormSchema({}),
  nounSchema = getNounFormSchema({}),
}: AdjectiveAndNounFormSchema) => {
  return yup.object().shape({
    adjective: adjectiveSchema,
    noun: nounSchema,
  });
};

export const getPairAdjectiveAndNounFormSchema = ({
  adjectiveSchema = getAdjectiveFormSchema({}),
  nounSchema = getNounFormSchema({}),
}: AdjectiveAndNounFormSchema) => {
  return yup.object().shape({
    adjectiveAndNoun1: getAdjectiveAndNounFormSchema({ adjectiveSchema, nounSchema }),
    adjectiveAndNoun2: getAdjectiveAndNounFormSchema({ adjectiveSchema, nounSchema }),
  });
};
