import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Menu, MenuItem } from 'components/atoms';
import { Avatar } from 'components/atoms/Avatar';
import React from 'react';
import { ContextMenuItem, SessionUser } from 'sdk/types';

type Props = {
  user?: SessionUser;
  items?: ContextMenuItem[];
};

const StyledNotifications = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
});

export const UserMenu: React.FC<Props> = ({ user, items }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    Boolean(items?.length) && setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledNotifications>
      {user && <Avatar userName={user?.userName} image={user?.image} onClick={handleClick} />}
      <Menu
        id="user-context-menu"
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorEl={anchorEl}
      >
        {items?.map(({ label, onClick }, index) => {
          const menuLabelItemKey = `${index}menu-label-item`;
          return (
            <MenuItem
              key={menuLabelItemKey}
              onClick={() => {
                handleClose();
                onClick();
              }}
            >
              {label}
            </MenuItem>
          );
        })}
      </Menu>
    </StyledNotifications>
  );
};
