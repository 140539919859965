import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const ThreeGifts: React.FC<Props> = ({ stroke, strokeWidth, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(0,300) scale(0.1,-0.1)" fill={stroke} stroke="none" strokeWidth={strokeWidth}>
      <path
        d="M1064 2646 c-88 -39 -147 -155 -126 -251 5 -27 13 -57 17 -66 5 -14
-3 -17 -54 -21 -75 -5 -129 -39 -168 -105 -26 -45 -28 -55 -28 -163 0 -101 3
-119 23 -153 l22 -39 -21 -29 c-11 -16 -22 -29 -23 -29 -2 0 -36 43 -75 95
-78 103 -108 125 -174 125 -105 0 -172 -102 -144 -217 8 -32 8 -33 -25 -33
-48 0 -100 -31 -125 -76 -18 -31 -23 -55 -23 -120 0 -74 3 -85 30 -125 l30
-44 0 -200 c1 -239 9 -272 82 -305 40 -18 68 -20 423 -20 355 0 383 2 423 20
49 22 81 79 82 143 l0 37 274 0 274 0 7 -49 c8 -57 33 -103 72 -128 25 -17 59
-18 434 -18 399 0 408 0 436 21 62 47 68 68 71 298 4 207 4 210 30 249 38 58
43 163 10 227 -29 56 -78 90 -131 90 -33 0 -38 3 -32 18 19 45 17 101 -4 146
-28 58 -73 86 -136 86 -66 0 -94 -20 -172 -126 -37 -52 -70 -93 -73 -93 -3 0
-13 11 -23 25 -18 24 -18 26 7 76 23 45 26 65 26 148 0 85 -3 103 -27 151 -38
74 -93 111 -174 117 -51 4 -59 7 -53 21 67 158 -32 331 -190 331 -100 -1 -133
-26 -266 -207 -41 -56 -77 -102 -80 -102 -3 0 -39 46 -80 102 -101 137 -126
165 -171 188 -45 22 -129 25 -175 5z m134 -102 c22 -13 169 -197 180 -226 2
-4 -51 -8 -117 -8 -139 0 -181 12 -205 59 -57 108 45 233 142 175z m681 2 c41
-21 61 -59 61 -111 0 -55 -22 -93 -67 -111 -32 -14 -273 -21 -273 -9 0 9 146
199 166 216 37 32 71 36 113 15z m-439 -507 l0 -169 -164 0 -164 0 -23 47
c-30 61 -77 93 -136 93 -49 0 -75 -11 -115 -47 l-26 -25 -7 55 c-8 64 1 132
22 165 30 47 58 51 346 51 l267 0 0 -170z m670 154 c53 -27 72 -72 68 -165 -2
-42 -7 -80 -11 -84 -4 -4 -23 7 -42 26 -28 28 -44 34 -90 38 -73 6 -124 -25
-151 -92 l-19 -46 -160 2 -160 3 -3 167 -2 167 267 1 c237 0 272 -2 303 -17z
m-1613 -268 c20 -14 123 -146 123 -159 0 -11 -168 -6 -193 5 -54 25 -63 104
-16 143 35 30 56 32 86 11z m504 -14 c44 -44 35 -116 -18 -140 -28 -13 -193
-15 -193 -2 0 13 98 138 122 155 33 23 56 20 89 -13z m1067 12 c18 -16 98
-120 116 -150 5 -10 -15 -13 -88 -13 -86 0 -98 2 -123 24 -22 19 -28 32 -28
66 0 34 6 47 28 66 33 28 67 31 95 7z m484 6 c11 -6 26 -26 34 -45 12 -30 12
-39 0 -69 -9 -21 -26 -39 -41 -45 -27 -10 -195 -14 -195 -4 0 10 120 162 132
167 22 9 48 7 70 -4z m-1112 -459 l0 -301 -112 3 -113 3 -3 108 c-3 107 -2
110 28 155 27 41 30 53 30 125 0 123 -40 180 -139 197 -31 5 10 8 132 9 l177
1 0 -300z m408 291 c-93 -13 -148 -87 -148 -196 0 -59 25 -135 49 -149 7 -5
11 -50 11 -127 l0 -119 -110 0 -110 0 0 300 0 300 178 -1 c104 -1 158 -4 130
-8z m-1251 -81 l73 0 0 -115 0 -115 -199 0 c-110 0 -207 3 -216 6 -40 16 -60
129 -32 184 23 45 51 52 181 46 66 -3 152 -6 193 -6z m574 -17 c15 -14 23 -38
27 -72 6 -61 -2 -95 -28 -121 -19 -19 -34 -20 -225 -20 l-205 0 0 115 0 114
118 4 c240 7 289 4 313 -20z m1059 -95 l0 -118 -204 0 -205 0 -20 26 c-17 21
-21 41 -21 93 1 116 13 121 257 118 l193 -2 0 -117z m504 93 c23 -22 26 -32
26 -91 0 -53 -4 -73 -21 -94 l-20 -26 -205 0 -204 0 0 118 0 117 199 0 c197 0
200 0 225 -24z m-2066 -503 l-3 -213 -172 0 c-232 0 -217 -16 -221 235 l-4
190 201 0 201 0 -2 -212z m470 22 c-4 -250 12 -233 -223 -238 l-175 -4 0 216
0 216 201 0 201 0 -4 -190z m1092 -26 l0 -216 -167 4 c-93 3 -176 8 -186 13
-31 13 -37 50 -37 236 l0 179 195 0 195 0 0 -216z m470 36 c0 -182 -7 -222
-39 -236 -9 -4 -91 -9 -183 -12 l-168 -4 0 216 0 216 195 0 195 0 0 -180z"
      />
    </g>
  </svg>
);
