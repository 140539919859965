import { FormQuestionText, Input } from 'components/atoms';
import { FormError, MultipleInputFormContainer, QuestionFormContainer } from 'components/molecules';
import { MultipleInputItemFormContainer } from 'components/molecules/Container/MultipleInputItemFormContainer';
import { PairsAdjectiveAndNoun, Question4FormValues, Question4Props } from 'components/questionnaire/types';
import {
  getAdjectiveFormSchema,
  getNounFormSchema,
  getPairAdjectiveAndNounFormSchema,
} from 'components/questionnaire/utils';
import { Form, Formik, FormikErrors, FormikHandlers, FormikTouched } from 'formik';
import React from 'react';
import { mapDtoAnswer3ToQuestion4, mapQuestion4ToDtoAnswer3 } from 'sdk/questionnaire';
import { Question } from 'sdk/questionnaire/enums';
import { getFormikErrorByIndex } from 'utils';
import * as yup from 'yup';

type Props = Question4Props;

type Errors = {
  adjectiveAndNoun1: { adjective?: string; noun?: string };
  adjectiveAndNoun2: { adjective?: string; noun?: string };
}[];

const initializeQuestionError = (errors: { FA?: string[]; FN?: string[] }): Errors => {
  const responses: Errors = [];
  const faLength = errors?.FA?.length ?? 0;
  const fnLength = errors?.FN?.length ?? 0;
  const count = Math.max(faLength, fnLength);
  for (let i = 0; i < count; i += 2) {
    const adjectiveAndNoun1 = { adjective: errors?.FA?.[i] ?? '', noun: errors?.FN?.[i] ?? '' };
    const adjectiveAndNoun2 = { adjective: errors?.FA?.[i + 1] ?? '', noun: errors?.FN?.[i + 1] ?? '' };
    responses.push({ adjectiveAndNoun1, adjectiveAndNoun2 });
  }

  return responses;
};

const renderErrorsSection = ({
  index,
  touched,
  errors,
  questionErrors,
  requestError,
  response,
}: {
  index: number;
  touched: FormikTouched<Question4FormValues>;
  errors: FormikErrors<Question4FormValues>;
  questionErrors: Errors;
  requestError?: string;
  response: PairsAdjectiveAndNoun;
}) => {
  const hasNounError =
    Boolean(
      touched.responses?.[index]?.adjectiveAndNoun2?.noun &&
        getFormikErrorByIndex(errors, index)?.adjectiveAndNoun2?.noun,
    ) ||
    Boolean(
      touched.responses?.[index]?.adjectiveAndNoun1?.noun &&
        getFormikErrorByIndex(errors, index)?.adjectiveAndNoun1?.noun,
    );
  const hasAdjectiveError =
    Boolean(
      touched.responses?.[index]?.adjectiveAndNoun2?.adjective &&
        getFormikErrorByIndex(errors, index)?.adjectiveAndNoun2?.adjective,
    ) ||
    Boolean(
      touched.responses?.[index]?.adjectiveAndNoun1?.adjective &&
        getFormikErrorByIndex(errors, index)?.adjectiveAndNoun1?.adjective,
    );
  return (
    <>
      {hasNounError && (
        <FormError>
          {getFormikErrorByIndex(errors, index)?.adjectiveAndNoun1?.noun ??
            getFormikErrorByIndex(errors, index)?.adjectiveAndNoun2?.noun}
        </FormError>
      )}
      {hasAdjectiveError && (
        <FormError>
          {getFormikErrorByIndex(errors, index)?.adjectiveAndNoun1?.adjective ??
            getFormikErrorByIndex(errors, index)?.adjectiveAndNoun2?.adjective}
        </FormError>
      )}

      {(Boolean(questionErrors?.[index]?.adjectiveAndNoun1?.adjective) &&
        questionErrors?.[index]?.adjectiveAndNoun1?.adjective === response.adjectiveAndNoun1.adjective) ||
        (Boolean(questionErrors?.[index]?.adjectiveAndNoun2?.adjective) &&
          questionErrors?.[index]?.adjectiveAndNoun2?.adjective === response.adjectiveAndNoun2.adjective) ||
        (Boolean(questionErrors?.[index]?.adjectiveAndNoun1?.noun) &&
          questionErrors?.[index]?.adjectiveAndNoun1?.noun === response.adjectiveAndNoun1.noun) ||
        (Boolean(questionErrors?.[index]?.adjectiveAndNoun2?.noun) &&
          questionErrors?.[index]?.adjectiveAndNoun2?.noun === response.adjectiveAndNoun2.noun && (
            <FormError>Name is not allowed</FormError>
          ))}
      {Boolean(requestError) && <FormError>{requestError}</FormError>}
    </>
  );
};

const renderInputs = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  questionErrors,
  requestError,
}: {
  touched: FormikTouched<Question4FormValues>;
  errors: FormikErrors<Question4FormValues>;
  values: Question4FormValues;
  questionErrors: Errors;
  requestError?: string;
} & Pick<FormikHandlers, 'handleChange' | 'handleBlur'>) => {
  return (
    <>
      {values.responses.map((response, index) => {
        const key = `responses-${index}-adjectivesAndNouns`;
        return (
          <MultipleInputItemFormContainer key={key}>
            <FormQuestionText>
              <Input
                name={`responses[${index}].adjectiveAndNoun1.adjective`}
                value={response.adjectiveAndNoun1.adjective}
                placeholder="Descriptor"
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (Boolean(questionErrors?.[index]?.adjectiveAndNoun1?.adjective) &&
                    questionErrors?.[index]?.adjectiveAndNoun1?.adjective === response.adjectiveAndNoun1.adjective) ||
                  Boolean(
                    getFormikErrorByIndex(errors, index)?.adjectiveAndNoun1?.adjective &&
                      touched.responses?.[index]?.adjectiveAndNoun1?.adjective,
                  )
                }
              />
              +
              <Input
                name={`responses[${index}].adjectiveAndNoun1.noun`}
                value={response.adjectiveAndNoun1.noun}
                placeholder="Word"
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (Boolean(questionErrors?.[index]?.adjectiveAndNoun1?.noun) &&
                    questionErrors?.[index]?.adjectiveAndNoun1?.noun === response.adjectiveAndNoun1.noun) ||
                  Boolean(
                    getFormikErrorByIndex(errors, index)?.adjectiveAndNoun1?.noun &&
                      touched.responses?.[index]?.adjectiveAndNoun1?.noun,
                  )
                }
              />
              <p style={{ width: '100%', textAlign: 'center' }}>and</p>
              <Input
                name={`responses[${index}].adjectiveAndNoun2.adjective`}
                value={response.adjectiveAndNoun2.adjective}
                placeholder="Descriptor"
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (Boolean(questionErrors?.[index]?.adjectiveAndNoun2?.adjective) &&
                    questionErrors?.[index]?.adjectiveAndNoun2?.adjective === response.adjectiveAndNoun2?.adjective) ||
                  Boolean(
                    getFormikErrorByIndex(errors, index)?.adjectiveAndNoun2?.adjective &&
                      touched.responses?.[index]?.adjectiveAndNoun2?.adjective,
                  )
                }
              />
              +
              <Input
                name={`responses[${index}].adjectiveAndNoun2.noun`}
                value={response.adjectiveAndNoun2.noun}
                placeholder="Word"
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (Boolean(questionErrors?.[index]?.adjectiveAndNoun2?.noun) &&
                    questionErrors?.[index]?.adjectiveAndNoun2?.noun === response.adjectiveAndNoun2.noun) ||
                  Boolean(
                    touched.responses?.[index]?.adjectiveAndNoun2?.noun &&
                      getFormikErrorByIndex(errors, index)?.adjectiveAndNoun2?.noun,
                  )
                }
              />
              {renderErrorsSection({ index, touched, errors, questionErrors, requestError, response })}
            </FormQuestionText>
          </MultipleInputItemFormContainer>
        );
      })}
    </>
  );
};

export const FormQuestion4: React.FC<Props> = ({ answers, formRef, requestError, questionError, onClick }) => {
  const initialValues: Question4FormValues = mapDtoAnswer3ToQuestion4(answers);
  const questionErrors = initializeQuestionError({ FA: questionError?.FA, FN: questionError?.FN });
  const onSubmitForm = (values: Question4FormValues) => {
    const dtoAnswer3 = mapQuestion4ToDtoAnswer3(values);
    onClick?.({ [Question.Question4]: dtoAnswer3 }, Question.Question4);
  };

  const formSchema = yup.object().shape({
    responses: yup
      .array()
      .min(1, 'Must be at least one inputs row')
      .of(
        getPairAdjectiveAndNounFormSchema({
          nounSchema: getNounFormSchema({ fieldDescription: 'word field' }),
          adjectiveSchema: getAdjectiveFormSchema({ fieldDescription: 'descriptor field' }),
        }),
      )
      .required(),
  });

  return (
    <QuestionFormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={formSchema}
        validateOnChange
        innerRef={formRef}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormQuestionText>
              <p style={{ textAlign: 'left' }}>Fill in the blanks.</p>I made my offering because it will give people:
            </FormQuestionText>
            <MultipleInputFormContainer>
              {renderInputs({ values, errors, touched, handleChange, handleBlur, questionErrors, requestError })}
            </MultipleInputFormContainer>
          </Form>
        )}
      </Formik>
    </QuestionFormContainer>
  );
};
