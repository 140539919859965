import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const ChatBubblesTherapist: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 3.6956202,6.5135886 c -0.5753067,0 -1.0460716,-0.4707648 -1.0460716,-1.0461203 0,-0.5753569 0.4707649,-1.0460716 1.0460716,-1.0460716 0.5753569,0 1.0461206,0.4707147 1.0461206,1.0460716 0,0.5753569 -0.4533159,1.0461203 -1.0461206,1.0461203 z m 0,-1.6039806 c -0.2963778,0 -0.5578589,0.2440815 -0.5578589,0.5578589 0,0.2964269 0.2615293,0.557908 0.5578589,0.557908 0.2963778,0 0.557908,-0.2615293 0.557908,-0.557908 C 4.2535775,5.1536903 4.0094958,4.909608 3.6956202,4.909608 Z"
      id="path6"
      stroke={stroke}
      fill={stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="m 6.7292926,6.5135886 c -0.5753569,0 -1.0460713,-0.4707648 -1.0460713,-1.0461203 0,-0.5927557 0.4707144,-1.0460716 1.0460713,-1.0460716 0.5927559,0 1.0460715,0.4707147 1.0460715,1.0460716 0,0.5753569 -0.4707143,1.0461203 -1.0460715,1.0461203 z m 0,-1.6039806 c -0.746134,0 -0.7259181,1.1157669 0,1.1157669 0.712325,0 0.712325,-1.1157669 0,-1.1157669 z"
      id="path8"
      stroke={stroke}
      fill={stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="m 9.7455387,6.5135886 c -0.5753593,0 -1.0460715,-0.4707648 -1.0460715,-1.0461203 0,-0.5753569 0.4707146,-1.0460716 1.0460715,-1.0460716 0.5753593,0 1.0460733,0.4707147 1.0460733,1.0460716 0,0.5753569 -0.470714,1.0461203 -1.0460733,1.0461203 z m 0,-1.6039806 c -0.296374,0 -0.557908,0.2440815 -0.557908,0.5578589 0,0.2964269 0.2615348,0.557908 0.557908,0.557908 0.2963743,0 0.5579083,-0.2615293 0.5579083,-0.557908 C 10.285991,5.1536903 10.041917,4.909608 9.7455387,4.909608 Z"
      id="path10"
      stroke={stroke}
      fill={stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="m 0.80154715,11.447616 c -0.18748555,0 -0.31951052,-0.20909 -0.22668222,-0.383569 L 1.8650199,8.7801236 C 0.87124515,7.8560432 0.3133309,6.6705267 0.3133309,5.4500769 c 0,-2.8069913 2.8767346,-5.0909149 6.4159732,-5.0909149 3.5217869,0 6.3985219,2.2839236 6.3985219,5.0909149 0,2.8069414 -2.876685,5.1083661 -6.3985219,5.1083661 -0.9763272,0 -1.9004051,-0.156888 -2.7546871,-0.488164 l -3.06847514,1.359898 c -0.034903,0 -0.0697431,0.01746 -0.10459591,0.01746 z M 2.3880763,8.8672556 1.3943016,10.663014 3.835163,9.5820463 c 0.1045971,-0.052354 0.209235,-0.034904 0.2963779,0.017456 0.8020394,0.3137754 1.6737239,0.4707137 2.5977994,0.4707137 3.2602532,0 5.8929057,-2.0572937 5.8929057,-4.5853498 0,-2.5280062 -2.6501039,-4.62010138 -5.8929559,-4.62010138 -3.2603033,0 -5.91035673,2.05724258 -5.91035673,4.58531128 0,1.1332147 0.52306023,2.1967791 1.49938463,3.0510741 0.1436903,0.096423 0.1513518,0.2435933 0.069742,0.3661174 z m 1.6562726,1.1507184 c 0,0.01746 0,0 0,0 z M 3.835114,9.9830705 c 0,0 0,0 0,0 z m 0,0 z m 0,0 c 0,0 0,0 0,0 z m 0,0 c 0,0 0,0 0,0 z M 2.3357797,8.5360287 c 0,0 0,0 0,0 z m -0.052343,-0.052354 c 0,0 0,0 0,0 z"
      id="path12"
      stroke={stroke}
      fill={stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="m 18.803566,17.288752 c -0.71101,0 -1.292816,-0.581744 -1.292816,-1.292816 0,-0.711074 0.581806,-1.292878 1.292816,-1.292878 0.711072,0 1.292878,0.581804 1.292878,1.292878 0,0.711072 -0.560237,1.292816 -1.292878,1.292816 z m 0,-1.982383 c -0.366281,0 -0.689443,0.323225 -0.689443,0.689505 0,0.36622 0.323225,0.689505 0.689443,0.689505 0.366282,0 0.689505,-0.323285 0.689505,-0.689505 0,-0.366286 -0.301593,-0.689505 -0.689505,-0.689505 z"
      id="path14"
      stroke={stroke}
      fill={stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="m 15.076048,17.288752 c -0.711074,0 -1.292817,-0.581744 -1.292817,-1.292816 0,-0.711074 0.581743,-1.292878 1.292817,-1.292878 0.711072,0 1.292815,0.581804 1.292815,1.292878 0,0.711072 -0.581743,1.292816 -1.292815,1.292816 z m 0,-1.982383 c -0.366282,0 -0.689444,0.323225 -0.689444,0.689505 0,0.36622 0.323225,0.689505 0.689444,0.689505 0.366281,0 0.689505,-0.323285 0.689505,-0.689505 0,-0.366283 -0.323161,-0.689505 -0.689505,-0.689505 z"
      id="path16"
      stroke={stroke}
      fill={stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="m 11.348373,17.288752 c -0.711073,0 -1.292816,-0.581744 -1.292816,-1.292816 0,-0.711074 0.581743,-1.292878 1.292816,-1.292878 0.711073,0 1.292878,0.581804 1.292878,1.292878 0,0.711072 -0.581805,1.292816 -1.292878,1.292816 z m 0,-1.982383 c -0.896231,0 -0.920731,1.37901 0,1.37901 0.880342,0 0.880342,-1.37901 0,-1.37901 z"
      id="path18"
      stroke={stroke}
      fill={stroke}
      strokeWidth={strokeWidth}
    />
    <path
      d="m 22.401988,23.407978 c -0.04313,0 -0.08626,0 -0.129252,-0.02158 l -3.792315,-1.702229 c -1.077355,0.409356 -2.219335,0.603373 -3.404451,0.603373 -4.352491,0 -7.9077784,-2.822706 -7.9077784,-6.291737 0,-3.469093 3.5552874,-6.2917382 7.9077784,-6.2917382 4.374121,0 7.929345,2.8226452 7.929345,6.2917382 0,1.529845 -0.689505,2.97348 -1.939247,4.115462 l 1.594471,2.844213 c 0.09636,0.166166 0.0057,0.452475 -0.25852,0.452475 z m -3.921567,-2.370171 c 0.0647,0 0.107681,0.02158 0.172388,0.04313 l 3.016601,1.357441 -1.249742,-2.219334 c -0.08969,-0.137477 -0.06098,-0.314845 0.0647,-0.430986 4.334354,-3.859502 0.625857,-9.437607 -5.429845,-9.437607 -4.029331,0 -7.2828991,2.542559 -7.2828991,5.666875 0,3.124363 3.2751371,5.666921 7.2828991,5.666921 2.05666,0 3.228153,-0.646369 3.425956,-0.646369 z m -0.08628,0.603311 z m 2.305545,-1.616038 z m 0.215461,-0.193956 c 0,0 0.02158,0.02158 0.02158,0.02158 0,6.1e-5 -0.02158,6.1e-5 -0.02158,-0.02158 z m 0,0 c 0,0 0,0 0,0 z m 0,0 c 0,0 0,0 0,0 z m 0,0 c 0,0 0,0 0,0 z m 0,0 c 0,0 0,0 0,0 z"
      id="path20"
      stroke={stroke}
      fill={stroke}
      strokeWidth={strokeWidth}
    />
  </svg>
);
