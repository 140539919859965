import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceEditView: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.6801 10.7079C22.9643 11.0623 23.1217 11.5227 23.1217 12.0001C23.1217 12.4775 22.9643 12.9379 22.6801 13.2923C20.8801 15.4716 16.7829 19.7144 12.0001 19.7144C7.21723 19.7144 3.12009 15.4716 1.32008 13.2923C1.03579 12.9379 0.878418 12.4775 0.878418 12.0001C0.878418 11.5227 1.03579 11.0623 1.32008 10.7079C3.12009 8.52861 7.21723 4.28571 12.0001 4.28571C16.7829 4.28571 20.8801 8.52861 22.6801 10.7079Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9999 15.4286C13.8934 15.4286 15.4284 13.8936 15.4284 12C15.4284 10.1065 13.8934 8.57144 11.9999 8.57144C10.1063 8.57144 8.57129 10.1065 8.57129 12C8.57129 13.8936 10.1063 15.4286 11.9999 15.4286Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
