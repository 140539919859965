import { Box, Typography } from '@mui/material';
import {
  FlexBox,
  IntroParagraphText,
  LightText,
  QuestionExampleBlueText,
  QuestionExampleText,
  QuestionStepLabel,
  UpperCaseText,
} from 'components/atoms';
import { FadingContainer, QuestionContainer } from 'components/molecules';
import { IntroQuotedText, QuestionExampleBox, QuestionTips } from 'components/organism/Questionnaire';
import { question6Paragraphs, question6TipItem } from 'components/questionnaire/constants';
import { FormQuestion6 } from 'components/questionnaire/forms';
import { QuestionIntroduction } from 'components/questionnaire/QuestionIntroduction';
import { Question6Props } from 'components/questionnaire/types';
import React, { useState } from 'react';

type Props = {
  phaseName: string;
  questionVisited: boolean;
} & Question6Props;

export const Question6: React.FC<Props> = ({
  answers,
  phaseName,
  formRef,
  questionVisited,
  requestError,
  questionError,
  onClick,
}) => {
  const [showTipSection, setShowTipSection] = useState<boolean>(false);
  const introText = (
    <Box key={'intro6Text'}>
      <QuestionStepLabel>{phaseName}</QuestionStepLabel>
      <IntroParagraphText>
        Select two words that express the{' '}
        <Typography component="span" variant="questionInstruction">
          {' '}
          more meaningful aspiration
        </Typography>{' '}
        connected to your end benefits.
      </IntroParagraphText>
    </Box>
  );

  return (
    <QuestionContainer
      tipSection={
        <FadingContainer visible={showTipSection}>
          {<QuestionTips items={question6TipItem} defaultExpanded />}
        </FadingContainer>
      }
      mainSection={
        <QuestionIntroduction
          tipSection={<QuestionTips items={question6TipItem} />}
          intro={
            <IntroQuotedText
              title={phaseName}
              text={`“Meeting ‘the need behind the need’ creates deep and differentiated emotional bonds.”`}
            />
          }
          paragraphsToAnimate={[introText, ...question6Paragraphs]}
          form={
            <Box paddingTop="110px">
              <QuestionExampleBox title="Examples of what good answers look like:">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, auto)' }}>
                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>... them</QuestionExampleText>
                      <QuestionExampleBlueText>self</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>+</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>expression</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>... them</QuestionExampleText>
                      <QuestionExampleBlueText>career</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>+</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>independence</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>... them</QuestionExampleText>
                      <QuestionExampleBlueText>feel good</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>+</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>success</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>... them</QuestionExampleText>
                      <QuestionExampleBlueText>unrestricted</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>+</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>movement</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: '0 0 16px 0' }} justifyContent="end">
                      <QuestionExampleText>... them</QuestionExampleText>
                      <QuestionExampleBlueText>empowered</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>+</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>voice</QuestionExampleBlueText>
                    </FlexBox>
                  </Box>
                </div>
              </QuestionExampleBox>
              <FormQuestion6
                onClick={onClick}
                answers={answers}
                formRef={formRef}
                requestError={requestError}
                questionError={questionError}
              />
              <br />
              <UpperCaseText sx={{ fontSize: '10px' }}>WHEN ANSWERING, DON&lsquo;T WORRY ABOUT...</UpperCaseText>
              <br />
              <LightText>
                <Box>
                  <Typography component="span" variant="lightTextStart">
                    Going too far.
                  </Typography>{' '}
                  This is not the place to be humble. In fact, be arrogant. Find the most ambitious, inspiring idea you
                  can – even if it feels a bit high and mighty. Remember all things – no matter how simple or common –
                  contribute to a loftier purpose.{' '}
                </Box>
              </LightText>
            </Box>
          }
          fomTitle={' '}
          onFormInView={(isInView) => {
            setShowTipSection(isInView);
          }}
          questionVisited={questionVisited}
        />
      }
    />
  );
};
