import { PairsAdjectives, Question8FormValues } from 'components/questionnaire/types';
import { DtoAnswer7 } from 'sdk/questionnaire/types';

export const mapDtoAnswer7ToQuestion8 = (dtoAnswer7?: DtoAnswer7): Question8FormValues => {
  if ((dtoAnswer7?.MA?.length ?? 0) === 0) {
    return { responses: [{ adjective1: '', adjective2: '' }] };
  }

  const responses: PairsAdjectives[] = [];
  const ma = dtoAnswer7?.MA || [];

  for (let i = 0; i < ma.length; i += 2) {
    const adjective1 = ma?.[i] || '';
    const adjective2 = ma?.[i + 1] || '';
    responses.push({ adjective1, adjective2 });
  }

  return { responses };
};
