import { useAuth0 } from '@auth0/auth0-react';
import { InitiationScreen } from 'components/questionnaire/Phase1';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { redirectDefaultPath } from 'utils';

export const LoginPage: React.FC = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!isAuthenticated) {
      loginWithRedirect();
      return;
    }
    navigate(redirectDefaultPath);
  }, [isLoading, isAuthenticated, loginWithRedirect, navigate]);

  return (
    <>
      <InitiationScreen />
    </>
  );
};
