import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceUserEdit: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.71408 21.4286H0.856934V20.4992C0.870581 19.1924 1.21518 17.9107 1.85854 16.7733C2.50191 15.636 3.42305 14.6802 4.53591 13.9954C5.64879 13.3105 6.91709 12.9189 8.22233 12.8571C8.33869 12.8516 8.45503 12.8488 8.57122 12.8485C8.68741 12.8488 8.80376 12.8516 8.92013 12.8571C10.0348 12.9099 11.1225 13.2032 12.1095 13.7143"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.1427 14.5714L15.0684 22.6457L11.417 23.1429L11.9313 19.4914L19.9884 11.4171L23.1427 14.5714Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.57136 9.42858C10.9383 9.42858 12.8571 7.50979 12.8571 5.14286C12.8571 2.77593 10.9383 0.857147 8.57136 0.857147C6.20443 0.857147 4.28564 2.77593 4.28564 5.14286C4.28564 7.50979 6.20443 9.42858 8.57136 9.42858Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
