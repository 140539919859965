import { Grid, SxProps } from '@mui/material';
import { CenteredContentBox } from 'components/atoms';
import React from 'react';

type Props = {
  tipSection?: React.ReactNode;
  sxMainSection?: SxProps;
  mainSection?: React.ReactNode;
};

export const QuestionContainer: React.FC<Props> = ({ tipSection, mainSection, sxMainSection = {} }) => (
  <Grid container p={0} m={0}>
    <Grid item xs={0} md={4} lg={3} p={0} m={0} paddingLeft={2} paddingRight={2}>
      {tipSection}
    </Grid>
    <Grid item xs={12} md={8} lg={9} p={0} m={0} paddingLeft={2} paddingRight={2}>
      <CenteredContentBox sx={sxMainSection}>{mainSection}</CenteredContentBox>
    </Grid>
  </Grid>
);
