import { colorTones, fonts } from 'constants/designSystem';

import { Box, Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { Button, PageContainer } from 'components/atoms';
import { FlatButtonWithIcon } from 'components/molecules';
import { BuyGiftCardButton, CustomAlert, ExampleResults, GiftCardPaymentInfo } from 'components/organism';
import { GiftCardPaySection } from 'components/organism/GiftCard/GiftCardPaySection';
import { IconLeft } from 'components/organism/Navigation/SlidesControls';
import { Testimonials } from 'components/organism/ResultPresentation';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const StyledMainTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: '48px',
  lineHeight: '56.3px',
  paddingBottom: '24px',
  paddingTop: '15px',
  '@media (min-width: 600px)': {
    paddingTop: '0px',
  },
});

const StyledAdditionalTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '30px',
  color: '#408EDE',
});

const StyledTextWrapper = styled(Box)({
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '27px',
  paddingBottom: '24px',
  width: '99%',
});

const StyledBoldText = styled(Typography)({
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '24px',
});

const StyledButton = styled(Button)({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  marginTop: '0px',
  textTransform: 'none',
  textAlign: 'center',
  color: colorTones.neutralsWhite,
  fontFamily: fonts.primeFont,
});

const StyledGrid = styled(Grid)({
  display: 'grid',
  justifyItems: 'center',
  '@media (max-width: 600px)': {
    gridTemplateRows: '1fr 1fr 1fr',
  },
  '@media (min-width: 600px)': {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
});

export const BuyGiftCardPage: React.FC<{ isAuthenticated: boolean }> = ({ isAuthenticated }) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [requestError, setRequestError] = useState<string>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const backButtonText = isMobile ? 'Aesop' : 'Back to Aesop';

  const redirectUrlParam = params.get('redirect_url');
  let redirectUrl = redirectUrlParam === null ? document.referrer : redirectUrlParam;
  if (redirectUrl === '') {
    redirectUrl = '/';
  }

  const paymentResult = params.get('result');
  let pageContent: React.ReactNode;
  if (paymentResult === 'cancelled') {
    pageContent = (
      <Box>
        <StyledTextWrapper>
          Sorry to see you go!
          <br />
          <br />
          You can go back to Aesop using the button at the bottom.
          <br />
          Or, if you changed you mind, take another look at our gift card program:
        </StyledTextWrapper>
        <StyledButton
          color="success"
          onClick={() => {
            navigate(`/gift-cards/buy?redirect_url=${redirectUrl}`);
          }}
        >
          I changed my mind!
        </StyledButton>
      </Box>
    );
  } else if (paymentResult === 'success') {
    pageContent = <GiftCardPaymentInfo redirectUrl={redirectUrl} setRequestError={setRequestError} />;
  } else {
    pageContent = (
      <Grid item sm={12} md={8} lg={9} p={0} m={0} paddingLeft={2} paddingRight={2}>
        <StyledTextWrapper>
          <StyledMainTitle>Aesop Gift Cards</StyledMainTitle>
          <StyledAdditionalTitle>Give the Gift of a Great Name</StyledAdditionalTitle>
          <br />
          The perfect gift for anyone launching a brand. Starting a business. Creating something cool. Or just playing
          around with the idea of it. The Aesop Gift Card gives someone you love the ability to find a standout,
          strategically powerful name for their idea or endeavor – a name they wouldn’t have otherwise found on their
          own.
          <br />
          <br />
          <StyledBoldText>Choose your Gift</StyledBoldText>
          <br />
          <StyledGrid>
            <GiftCardPaySection
              giftIcon="gift"
              iconWidth={30}
              title="Gift One Round"
              price={19}
              items={['Discovery Journey', '24 names with rationales', 'Let the fun begin!']}
            >
              <BuyGiftCardButton
                redirectUrl={redirectUrl}
                batchesAmount={1}
                text="Buy 24 names"
                setRequestError={setRequestError}
              />
            </GiftCardPaySection>
            <GiftCardPaySection
              giftIcon="twoGifts"
              iconWidth={40}
              title="Gift Two Rounds"
              price={35}
              items={['Discovery Journey', '48 names with rationales', 'Twice the excitement!']}
            >
              <BuyGiftCardButton
                redirectUrl={redirectUrl}
                batchesAmount={2}
                text="Buy 48 names"
                setRequestError={setRequestError}
              />
            </GiftCardPaySection>
            <GiftCardPaySection
              giftIcon="threeGifts"
              iconWidth={40}
              title="Gift Three Rounds"
              price={49}
              items={['Discovery Journey', '72 names with rationales', 'Enough to really play around!']}
            >
              <BuyGiftCardButton
                redirectUrl={redirectUrl}
                batchesAmount={3}
                text="Buy 72 names"
                setRequestError={setRequestError}
              />
            </GiftCardPaySection>
          </StyledGrid>
          <br />
          <br />
          <br />
          <Testimonials />
          <br />
          <br />
          <StyledTextWrapper>
            <StyledBoldText sx={{ color: colorTones.ocean300 }}>
              It&apos;s the distinctiveness of Aesop&apos;s names that makes them stand out.
            </StyledBoldText>
            <br />
            While there are lots of online name generators out there, Aesop was built to deliver the names they
            can&apos;t. Take a minute to check out some real-life examples created by Aesop below.
            <br />
            <br />
            Because now that you&apos;ve completed your Discovery Journey, Aesop is ready to get to work on creating
            these kinds of names tailored just for you.
          </StyledTextWrapper>
          <ExampleResults />
          <br />
          <StyledBoldText>About the Gift</StyledBoldText>
          <br />
          Designed by naming experts with decades of experience, Aesop takes the receiver on an agency-inspired Ideation
          Journey. This helps them think through all the amazing aspects of their aspiring venture, and identify the
          best things a name can say about it.
          <br />
          <br />
          Next, Aesop applies the power of generative AI to its proprietary software encoded with the global branding
          industry’s best naming practices and generates names. Names that are personalized to the receiver and unique
          to what they’ve made. Aesop then delivers these names, and the stories behind them, straight to the receiver’s
          Aesop Dashboard.
          <br />
          <br />
          And then the fun really starts. The receiver gets to see the names, read their stories, highlight their
          favorites, and get inspired all over again. About what they’re trying to do and why people will love it.
          They’ll get a glimpse of how it could start to take shape. And how it could show up as something really
          special.
          <br />
          <br />
          So why not try something a little different this year? Surprise those entrepreneurial friends or family
          members with the gift of a great name and the delight that goes along with discovering it.
        </StyledTextWrapper>
      </Grid>
    );
  }

  return (
    <PageContainer>
      <Box>
        <Grid
          container
          p={0}
          m={0}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'calc(100vh - 130px)',
            width: '100%',
          }}
        >
          {requestError ? (
            <Box sx={{ maxWidth: '720px', paddingTop: { xs: 2, md: 8 } }}>
              <CustomAlert
                variant="error"
                title={requestError}
                text="Oops something went wrong! Please, contact us at support@namesbyaesop.com."
              />
            </Box>
          ) : null}
          {pageContent}
        </Grid>
      </Box>
      <FlatButtonWithIcon
        icon={<IconLeft />}
        text={backButtonText}
        iconPlacement="left"
        onClick={() => {
          if (isAuthenticated) {
            navigate('/dashboard');
          } else {
            window.location.replace('https://namesbyaesop.com');
          }
        }}
      />
    </PageContainer>
  );
};
