import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceEditWrite2: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M0.571533 15.4286H15.422"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.42559 11.4304L3.99854 12.0472L4.56971 8.5745L12.2577 0.909346C12.364 0.802276 12.4902 0.717292 12.6294 0.659297C12.7686 0.601301 12.918 0.571442 13.0688 0.571442C13.2196 0.571442 13.3689 0.601301 13.5081 0.659297C13.6473 0.717292 13.7737 0.802276 13.8798 0.909346L15.0907 2.12023C15.1978 2.22644 15.2828 2.35278 15.3408 2.49198C15.3988 2.63119 15.4286 2.78049 15.4286 2.9313C15.4286 3.0821 15.3988 3.23142 15.3408 3.37062C15.2828 3.50983 15.1978 3.63617 15.0907 3.74237L7.42559 11.4304Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
