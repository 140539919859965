import styled from '@emotion/styled';

import { PrimaryText } from './PrimaryText';

export const IntroParagraphText = styled(PrimaryText)({
  fontSize: '20px',
  letterSpacing: '-0.01em',
  marginBottom: '1em',
  color: '#333D58',
});
