import { PairsAdjectiveAndNoun, Question4FormValues } from 'components/questionnaire/types';
import { DtoAnswer3 } from 'sdk/questionnaire/types';

export const mapQuestion4ToDtoAnswer3 = ({ responses }: Question4FormValues): DtoAnswer3 => {
  const fa: string[] = [];
  const fn: string[] = [];
  responses.forEach((question4Answer: PairsAdjectiveAndNoun) => {
    const an1 = question4Answer.adjectiveAndNoun1;
    const an2 = question4Answer.adjectiveAndNoun2;
    fa.push(an1.adjective ?? '');
    fa.push(an2.adjective ?? '');
    fn.push(an1?.noun ?? '');
    fn.push(an2?.noun ?? '');
  });

  return {
    FA: fa,
    FN: fn,
  };
};
