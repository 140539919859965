import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const AudioSubtitles: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.71439 0.837053C1.08321 0.837053 0.571533 1.34873 0.571533 1.97991V10.7194C0.571533 11.3506 1.08321 11.8623 1.71439 11.8623H3.125V15.1625L7.41687 11.8623H14.2858C14.917 11.8623 15.4287 11.3506 15.4287 10.7194V1.97991C15.4287 1.34873 14.917 0.837053 14.2858 0.837053H1.71439Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
