import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const Dumbbell: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 7.4192,6.94144 C 7.4192,6.2430182 6.7659162,5.6768 5.96,5.6768 c -0.8058918,0 -1.4592,0.5662182 -1.4592,1.26464 v 10.11712 c 0,0.698422 0.6533082,1.26464 1.4592,1.26464 0.8059162,0 1.4592,-0.566218 1.4592,-1.26464 z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
      id="path1198"
    />
    <path
      d="m 20.2192,6.94144 c 0,-0.6984218 -0.653284,-1.26464 -1.4592,-1.26464 -0.805916,0 -1.4592,0.5662182 -1.4592,1.26464 v 10.11712 c 0,0.698422 0.653284,1.26464 1.4592,1.26464 0.805916,0 1.4592,-0.566218 1.4592,-1.26464 z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
      id="path1200"
    />
    <path
      d="M 4.5192,9.4058829 C 4.5192,8.689513 3.8658918,8.1088 3.06,8.1088 c -0.8058918,0 -1.4592,0.580713 -1.4592,1.2970829 v 5.1882341 c 0,0.71637 0.6533082,1.297083 1.4592,1.297083 0.8058918,0 1.4592,-0.580713 1.4592,-1.297083 z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
      id="path1202"
    />
    <path
      d="M 23.2192,9.4058829 C 23.2192,8.689513 22.565916,8.1088 21.76,8.1088 c -0.805916,0 -1.4592,0.580713 -1.4592,1.2970829 v 5.1882341 c 0,0.71637 0.653284,1.297083 1.4592,1.297083 0.805916,0 1.4592,-0.580713 1.4592,-1.297083 z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
      id="path1204"
    />
    <rect
      width="9.7279997"
      height="2.9184"
      transform="scale(1,-1)"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
      id="rect1206"
      x="7.4958"
      y="-13.459199"
    />
  </svg>
);
