import '@fontsource/overpass';
import '@fontsource/overpass/300.css';
import '@fontsource/overpass/500.css';
import '@fontsource/rasa';

import { colorTones, fonts } from 'constants/designSystem';

import { createTheme } from '@mui/material/styles';
import React from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    specialHeader: React.CSSProperties;
    questionTitle: React.CSSProperties;
    questionSecondaryTitle: React.CSSProperties;
    questionInstruction: React.CSSProperties;
    lightTextStart: React.CSSProperties;
    lightLink: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    specialHeader?: React.CSSProperties;
    questionTitle: React.CSSProperties;
    questionSecondaryTitle: React.CSSProperties;
    questionInstruction: React.CSSProperties;
    lightTextStart: React.CSSProperties;
    lightLink: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    specialHeader: true;
    questionTitle: true;
    questionSecondaryTitle: true;
    questionInstruction: true;
    lightTextStart: true;
    lightLink: true;
  }
}
export const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          '&:before': {
            border: `1px solid ${colorTones.neutralsSlightGray}`,
          },
          fontSize: '20px',
          color: colorTones.neutralsSlightGray,
        },
        tooltip: {
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '20px',
          letterSpacing: '0em',
          textAlign: 'left',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '8px 12px',
          gap: '2px',
          maxHeight: '36px',
          fontFamily: fonts.primeFont,
          background: colorTones.neutralsSlightGray,
          border: `1px solid ${colorTones.neutralsSlightGray}`,
          borderRadius: '12px',
          color: colorTones.primaryNightSky,
        },
      },
    },
  },
  typography: {
    htmlFontSize: 18,
    fontFamily: [fonts.primeFont, 'Arial'].join(','),
    fontWeightRegular: 400,
    fontWeightBold: 700,
    lightLink: {
      fontFamily: fonts.primeFont,
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '150%',
      textAlign: 'center',
      textDecorationLine: 'underline',
      color: colorTones.nightSky100,
    },
    h1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '48px',
      lineHeight: '56px',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '150%',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '150%',
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '150%',
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '150%',
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
    },
    button: {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '150%',
      textTransform: 'none',
    },
    specialHeader: {
      fontSize: '2.25rem',
      lineHeight: 1,
    },
    questionTitle: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '48px',
    },
    questionSecondaryTitle: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '150%',
      letterSpacing: '0.06em',
      textTransform: 'uppercase',
      color: '#C4C4C4',
    },
    questionInstruction: {
      fontWeight: 'bold',
      color: colorTones.ocean200,
    },
    lightTextStart: {
      fontWeight: 'bold',
    },
  },
  palette: {
    primary: {
      main: colorTones.primaryNightSky,
      contrastText: colorTones.neutralsGray,
    },
    secondary: {
      main: colorTones.nightSky80,
      contrastText: colorTones.neutralsGray,
    },
    success: {
      main: colorTones.plant200,
      contrastText: colorTones.neutralsWhite,
    },
    error: {
      main: colorTones.primaryRed,
    },
    text: {
      primary: colorTones.nightSky80,
    },
    info: {
      main: colorTones.nightSky200,
      contrastText: colorTones.neutralsWhite,
    },
  },
});
