import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import { ListItemBox } from 'components/atoms';
import * as React from 'react';
import { GridTailsSizes, getGridTailsSizesByTailsCount } from 'utils';

type Props = {
  title: string;
  noItemsPlaceHolder: string;
  items: React.ReactNode[];
};

export const StyledWrapper = styled(Box)({
  flexDirection: 'column',
  alignItems: 'flex-start',
  m: 0,
  p: 0,
  width: '100%',
});

const StyledTitleWrapper = styled(Box)({
  paddingTop: '48px',
  paddingBottom: '8px',
});

const StyledNoItemsWrapper = styled(Box)({
  paddingTop: '8px',
  paddingBottom: '48px',
});

const Tail: React.FC<{ children: React.ReactNode; tailsSizes: GridTailsSizes }> = ({ children, tailsSizes }) => (
  <Grid item {...tailsSizes}>
    <ListItemBox>
      <StyledWrapper>{children}</StyledWrapper>
    </ListItemBox>
  </Grid>
);

export const TilesGrid: React.FC<Props> = ({ title, noItemsPlaceHolder, items }) => {
  const gridTailsSizes = getGridTailsSizesByTailsCount(items.length || 1);

  return (
    <StyledWrapper>
      <StyledTitleWrapper>
        <Typography variant="h3">{title || ''}</Typography>
      </StyledTitleWrapper>
      {items.length ? (
        <Grid container spacing={2}>
          {items.map((item, index) => {
            const draftKey = `tile-item-${index}`;
            return (
              <Tail tailsSizes={gridTailsSizes} key={draftKey}>
                {item}
              </Tail>
            );
          })}
        </Grid>
      ) : (
        <StyledNoItemsWrapper>
          <Typography variant="body1">{noItemsPlaceHolder}</Typography>
        </StyledNoItemsWrapper>
      )}
    </StyledWrapper>
  );
};
