import { colorTones, fonts } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { Button } from 'components/atoms/Buttons/Button';
import { Icon } from 'components/atoms/Icons';
import { QuestionContainer } from 'components/molecules';
import React, { useEffect } from 'react';

type Props = {
  onClick?: () => void;
};

const StyledBaseText = styled(Typography)({
  fontFamily: fonts.primeFont,
  fontStyle: 'normal',
  color: colorTones.primaryNightSky,
});

const StyledMainTitle = styled(StyledBaseText)({
  fontWeight: 700,
  fontSize: '40px',
  lineHeight: '58px',
  textAlign: 'left',
  letterSpacing: '0em',
  marginBottom: '16px',
});

const StyledSubTitle = styled(StyledBaseText)({
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '150%',
  paddingBottom: '32px',
});

const StyledSubText = styled(Box)({
  fontFamily: fonts.primeFont,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  color: colorTones.primaryNightSky,
  paddingBottom: '32px',
});

const StyledFlexWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left',
});

const StyledTextEstimatedTime = styled(StyledBaseText)({
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '150%',
  textAlign: 'center',
});

export const Intro: React.FC<Props> = ({ onClick }) => {
  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  return (
    <QuestionContainer
      sxMainSection={{
        maxWidth: '740px',
      }}
      mainSection={
        <Box>
          <StyledMainTitle>Ready to Go?</StyledMainTitle>
          <StyledSubTitle>
            Our Discovery Journey helps you nail down exactly what makes your creation special using the kind of
            language most suitable for inspiring great names.
          </StyledSubTitle>
          <StyledSubText>
            <b>The Road Ahead</b> <br />
            What follows will be like your own ideation workshop – similar to how a professional branding agency
            kicks-off a naming project. The answers you provide in the next 9 steps will form a unique foundation upon
            which Aesop will craft names and stories specifically tailored to you.
            <br />
            <br />
            Try to take at least 15 minutes for this exercise. This is a great opportunity to think deeply about your
            offering, what truly makes it different, and what you really want the story behind it to be.
            <br />
            <br />
            Even better - get together and make a longer, real-life workshop of it!
          </StyledSubText>
          <StyledFlexWrapper paddingBottom={'24px'}>
            <StyledTextEstimatedTime></StyledTextEstimatedTime>
            <Box p={1} paddingTop={0}>
              <Icon type="interfaceTimeAlarm" stroke={colorTones.primaryNightSky} width={24} height={24} />
            </Box>
            <StyledTextEstimatedTime>15 min.</StyledTextEstimatedTime>
          </StyledFlexWrapper>
          <Button
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '24px',
              marginTop: '40px',
              textTransform: 'none',
              textAlign: 'center',
              color: colorTones.neutralsWhite,
              fontFamily: fonts.primeFont,
            }}
            onClick={onClick}
          >
            Let’s Begin!
            <Box sx={{ marginBottom: '-15px', marginLeft: '10px' }}>
              {' '}
              <Icon type="interfaceArrowsRight" stroke={colorTones.neutralsWhite} />
            </Box>
          </Button>
        </Box>
      }
    />
  );
};
