import { Box, Typography } from '@mui/material';
import {
  FlexBox,
  IntroParagraphText,
  LightText,
  QuestionExampleBlueText,
  QuestionExampleText,
  QuestionStepLabel,
  UpperCaseText,
} from 'components/atoms';
import { FadingContainer, QuestionContainer } from 'components/molecules';
import { IntroQuotedText, QuestionExampleBox, QuestionTips } from 'components/organism/Questionnaire';
import { question3Paragraphs, question3TipItem } from 'components/questionnaire//constants';
import { FormQuestion3 } from 'components/questionnaire/forms';
import { QuestionIntroduction } from 'components/questionnaire/QuestionIntroduction';
import { Question3Props } from 'components/questionnaire/types';
import React, { useState } from 'react';

type Props = {
  phaseName: string;
  inputVerbFromQuestion2: string;
  questionVisited: boolean;
} & Question3Props;

export const Question3: React.FC<Props> = ({
  answer,
  phaseName,
  inputVerbFromQuestion2,
  formRef,
  questionVisited,
  requestError,
  questionError,
  onClick,
}) => {
  const [showTipSection, setShowTipSection] = useState<boolean>(false);
  const introText = (
    <Box key={'intro3Text'}>
      <QuestionStepLabel>{phaseName}</QuestionStepLabel>
      <IntroParagraphText>
        Pick one word to{' '}
        <Typography component="span" variant="questionInstruction">
          further define “what happens”
        </Typography>{' '}
        when your offering gets used.
      </IntroParagraphText>
    </Box>
  );

  return (
    <QuestionContainer
      tipSection={
        <FadingContainer visible={showTipSection}>
          <QuestionTips items={[question3TipItem]} defaultExpanded />
        </FadingContainer>
      }
      mainSection={
        <QuestionIntroduction
          tipSection={<QuestionTips items={[question3TipItem]} />}
          intro={
            <IntroQuotedText
              title={phaseName}
              text={`“Verbs are highly immersive because they often get internalized from a first person perspective.”`}
            />
          }
          paragraphsToAnimate={[introText, ...question3Paragraphs]}
          form={
            <Box paddingTop="110px">
              <QuestionExampleBox title="EXAMPLES OF WHAT GOOD ANSWERS LOOK LIKE:">
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                  <FlexBox flexDirection="column" sx={{ padding: 0, margin: 0 }}>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleText sx={{ paddingLeft: '10px' }}>... is</QuestionExampleText>
                      <QuestionExampleBlueText>wearing</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleText sx={{ paddingLeft: '10px' }}>... is</QuestionExampleText>
                      <QuestionExampleBlueText>growing</QuestionExampleBlueText>
                    </FlexBox>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleText sx={{ paddingLeft: '10px' }}>... is</QuestionExampleText>
                      <QuestionExampleBlueText>connecting</QuestionExampleBlueText>
                    </FlexBox>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleText sx={{ paddingLeft: '10px' }}>... is</QuestionExampleText>
                      <QuestionExampleBlueText>exercising</QuestionExampleBlueText>
                    </FlexBox>
                    <FlexBox sx={{ padding: 0, margin: '0 0 16px 0' }}>
                      <QuestionExampleText sx={{ paddingLeft: '10px' }}>... is</QuestionExampleText>
                      <QuestionExampleBlueText>practicing</QuestionExampleBlueText>
                    </FlexBox>
                  </FlexBox>
                </div>
              </QuestionExampleBox>
              <FormQuestion3
                onClick={onClick}
                answer={answer}
                formRef={formRef}
                inputVerbFromQuestion2={inputVerbFromQuestion2}
                requestError={requestError}
                questionError={questionError}
              />
              <br />
              <UpperCaseText sx={{ fontSize: '10px' }}>WHEN ANSWERING, DON&lsquo;T WORRY ABOUT...</UpperCaseText>
              <br />
              <LightText>
                <Box>
                  <Typography component="span" variant="lightTextStart">
                    Whether the activity is “obvious”.
                  </Typography>{' '}
                  This is all about what can be done because your offering is around. Maybe you can see it (like
                  “jumping” or “cleaning”) or maybe you can’t (like “calculating” or “thinking”). As long as someone or
                  something is doing it because your offering is there - you’ve found a great word.
                </Box>
              </LightText>
            </Box>
          }
          fomTitle={''}
          onFormInView={(isInView) => {
            setShowTipSection(isInView);
          }}
          questionVisited={questionVisited}
        />
      }
    />
  );
};
