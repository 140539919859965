import { ButtonProps, Button as MuiButton } from '@mui/material';
import React from 'react';

type Props = {
  children?: React.ReactNode;
} & ButtonProps;

export const Button: React.FC<Props> = ({ children, sx, ...rest }) => (
  <MuiButton
    type="button"
    variant="contained"
    sx={{
      padding: '8px 16px',
      gap: '12px',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)',
      borderRadius: '8px',
      fontSize: '16px',
      lineHeight: '150%',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </MuiButton>
);
