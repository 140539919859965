import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { getUser } from 'sdk/internalApi';
import { User } from 'sdk/types';

export const useAuthUser = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string>();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    (async () => {
      if (isAuthenticated && (!token || !user)) {
        const accessToken = await getAccessTokenSilently();
        const userData = await getUser({ token: accessToken });
        setToken(accessToken);
        setUser(userData);
        setIsLoading(false);
      }
    })();
  }, [isAuthenticated, token, user, setToken, setUser, getAccessTokenSilently]);

  return {
    isLoading,
    isAuthenticated,
    token,
    user,
  };
};
