import { PresentationSection } from 'components/presentation';
import { ResultsDetailsProvider } from 'context/resultsDetail/resultsDetailContext';
import { useAuthUser } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPresentation } from 'sdk/internalApi/presentation/getPresentation';
import { getQuestionnaire } from 'sdk/internalApi/questionnaire/getQuestionnaire';
import { User } from 'sdk/types';
import { JourneyResult } from 'sdk/types/result';

type PresentationData = {
  journeyName: string;
  results: JourneyResult[];
};

const fetchData = async (token: string, user: User, journeyId: string) => {
  const id = Number(journeyId);
  const [resPresentation, resQuestionnaire] = await Promise.all([
    getPresentation({
      user: user.id,
      token,
      journeyId: id,
    }),
    getQuestionnaire({
      token,
      id,
    }),
  ]);
  const resultsExperts = (resPresentation.expert_plus_reviews || []).map((item, index) => {
    return {
      name: item.suggested_name,
      rationale: item.name_rationale,
      type: 'expert',
      expert_review: {
        expert_feedback: item.expert_feedback,
        expert_like: true,
      },
      feedback: 1,
      erroneous: false,
      favorite: false,
      slideNumber: `Expert plus name ${index + 1}`,
    };
  });
  const generalResults = (resPresentation.results || []).map((item, index) => ({
    ...item,
    slideNumber: `${index + 1}`,
  }));

  return {
    results: [...resultsExperts, ...generalResults],
    journeyName: resQuestionnaire.name || '',
  };
};

export const ResultsDetailPage: React.FC = () => {
  const [data, setData] = useState<PresentationData>();
  const { isAuthenticated, token, user } = useAuthUser();
  const params = useParams();

  useEffect(() => {
    (async () => {
      if (isAuthenticated && token && user && params.journeyId) {
        const res = await fetchData(token, user, params.journeyId);
        setData(res);
      }
    })();
  }, [isAuthenticated, params, token, user]);

  let notRatedNameIndex = data && data.results.findIndex((item) => item.feedback === 0 && !item.erroneous);

  const changeRatedNameIndex = (activeResultIndex: number | undefined) => {
    if (data && data.results.length - 1 === activeResultIndex) {
      notRatedNameIndex = -1;
    }
  };

  return (
    <ResultsDetailsProvider
      initialResultsDetailsState={{
        results: data?.results || [],
      }}
    >
      {({ resultsDetailsState, updateResultItem }) => {
        return (
          <PresentationSection
            token={token}
            journeyName={data?.journeyName}
            journeyId={params.journeyId}
            resultsDetailsState={resultsDetailsState}
            updateResultItem={updateResultItem}
            notRatedNameIndex={notRatedNameIndex === -1 ? 0 : notRatedNameIndex}
            changeRatedNameIndex={changeRatedNameIndex}
          />
        );
      }}
    </ResultsDetailsProvider>
  );
};
