import Hotjar from '@hotjar/browser';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Auth0ProviderWithHistory } from 'context';
import { UserProvider } from 'context/user/UserContext';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import { theme } from './theme';

const siteId = 3897888;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </UserProvider>
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
