import { env } from 'env';
import { RequestError } from 'sdk/internalApi/request';
import { BaseListResponse, BaseRequest } from 'sdk/internalApi/types';
import { BaseQuestionnaire } from 'sdk/questionnaire/types';

type ContinueRequest = BaseRequest & {
  limit?: number;
  offset?: number;
};

export type ContinueQuestionnaireType = 'draft' | 'questionnaire';

type ContinueResult = BaseQuestionnaire & {
  type: ContinueQuestionnaireType;
};

type ContinueResponse = BaseListResponse<ContinueResult>;

export const getContinue = async (request: ContinueRequest): Promise<ContinueResponse> => {
  const limit = request?.limit || 15;
  const offset = request?.offset || 0;

  const url = request.next || `${env.API_URL}/continue/?limit=${limit}&offset=${offset}`;
  const token = request.token;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.ok) {
    throw new RequestError(res.status, null);
  }

  return res.json();
};
