import { Alert as MuiAlert, SxProps } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode;
  variant?: 'success' | 'info' | 'warning' | 'error';
  sx?: SxProps;
};

export const Alert: React.FC<Props> = ({ children, variant, sx }) => (
  <MuiAlert severity={variant} sx={{ ...sx }}>
    {children}
  </MuiAlert>
);
