import { Box, Typography } from '@mui/material';
import {
  FlexBox,
  IntroParagraphText,
  LightText,
  QuestionExampleBlueText,
  QuestionExampleText,
  QuestionStepLabel,
  UpperCaseText,
} from 'components/atoms';
import { FadingContainer, QuestionContainer } from 'components/molecules';
import { IntroQuotedText, QuestionExampleBox, QuestionTips } from 'components/organism/Questionnaire';
import { question9Paragraphs, question9TipItem } from 'components/questionnaire/constants';
import { FormQuestion9 } from 'components/questionnaire/forms';
import { QuestionIntroduction } from 'components/questionnaire/QuestionIntroduction';
import { Question9Props } from 'components/questionnaire/types';
import React, { useState } from 'react';

type Props = {
  phaseName: string;
  questionVisited: boolean;
} & Question9Props;

export const Question9: React.FC<Props> = ({
  answers,
  phaseName,
  formRef,
  questionVisited,
  requestError,
  questionError,
  onClick,
}) => {
  const [showTipSection, setShowTipSection] = useState<boolean>(false);
  const introText = (
    <Box key={'intro9Text'}>
      <QuestionStepLabel>{phaseName}</QuestionStepLabel>
      <IntroParagraphText>
        Identify the two word label that best{' '}
        <Typography component="span" variant="questionInstruction">
          {' '}
          captures your users’ chosen identity.
        </Typography>
      </IntroParagraphText>
    </Box>
  );

  return (
    <QuestionContainer
      tipSection={
        <FadingContainer visible={showTipSection}>
          {<QuestionTips items={[question9TipItem]} defaultExpanded />}
        </FadingContainer>
      }
      mainSection={
        <QuestionIntroduction
          tipSection={<QuestionTips items={[question9TipItem]} />}
          intro={
            <IntroQuotedText
              title={phaseName}
              text={`“Reflecting collective identity helps people feel seen and understood.”`}
            />
          }
          paragraphsToAnimate={[introText, ...question9Paragraphs]}
          form={
            <Box paddingTop="80px">
              <QuestionExampleBox title="Examples of what good answers look like:">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, auto)' }}>
                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>… as</QuestionExampleText>
                      <QuestionExampleBlueText>fashionable</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>+</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>experimenters</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>… as</QuestionExampleText>
                      <QuestionExampleBlueText>driven</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>+</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>creators</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>… as</QuestionExampleText>
                      <QuestionExampleBlueText>community</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>+</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>pioneers</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>… as</QuestionExampleText>
                      <QuestionExampleBlueText>wellness</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>+</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>fundamentalists</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: '0 0 16px 0' }} justifyContent="end">
                      <QuestionExampleText>… as</QuestionExampleText>
                      <QuestionExampleBlueText>proactive</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>+</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>optimizers</QuestionExampleBlueText>
                    </FlexBox>
                  </Box>
                </div>
              </QuestionExampleBox>
              <FormQuestion9
                onClick={onClick}
                answers={answers}
                formRef={formRef}
                requestError={requestError}
                questionError={questionError}
              />
              <br />
              <UpperCaseText sx={{ fontSize: '10px' }}>WHEN ANSWERING, DON&lsquo;T WORRY ABOUT...</UpperCaseText>
              <br />
              <LightText>
                <Box>
                  <Typography component="span" variant="lightTextStart">
                    Getting a bit “bizarre”.
                  </Typography>{' '}
                  Identities are unique and often quite quirky. Don’t shy away from a response that sounds off-beat -
                  even a little weird. Most people see themselves as unconventional on at least some dimension.
                </Box>
              </LightText>
            </Box>
          }
          fomTitle={''}
          onFormInView={(isInView) => {
            setShowTipSection(isInView);
          }}
          questionVisited={questionVisited}
        />
      }
    />
  );
};
