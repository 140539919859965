import { colorTones } from 'constants/designSystem';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FlexBox } from 'components/atoms';
import { Icon } from 'components/atoms/Icons/Icon';
import React from 'react';

type Props = {
  text: string;
  completed?: boolean;
};

const iconCheck = (
  <Icon
    type="validationCheck"
    stroke={colorTones.plant200}
    strokeWidth="1.1"
    width={16}
    height={16}
    viewBox="0 0 16 16"
  />
);

const StyledIconContainer = styled(Box)({ marginLeft: 2, paddingTop: '4px' });

const StyledCompletedSection = styled(Box)({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  textAlign: 'center',
});

export const QuestionnaireCompletedSection: React.FC<Props> = ({ text, completed = false }) => (
  <FlexBox
    flexDirection="row"
    alignItems="center"
    padding={0}
    marginLeft={0}
    marginRight={0}
    marginTop={0}
    marginBottom={0}
  >
    <StyledCompletedSection>{text}</StyledCompletedSection>
    {completed && <StyledIconContainer>{iconCheck}</StyledIconContainer>}
  </FlexBox>
);
