export type GridTailsSizes = {
  xs: number;
  sm: number;
  md: number;
};

export const getGridTailsSizesByTailsCount = (tailsCount: number): GridTailsSizes => {
  if (tailsCount === 0 || tailsCount === 1) {
    return {
      xs: 12,
      sm: 6,
      md: 4,
    };
  }

  if (tailsCount === 2) {
    return {
      xs: 12,
      sm: 6,
      md: 4,
    };
  }

  return {
    xs: 12,
    sm: 6,
    md: 4,
  };
};
