import { colorTones } from 'constants/designSystem';

import { Box, Typography } from '@mui/material';
import { IntroParagraphText, IntroSubParagraphText } from 'components/atoms';
import { Icon } from 'components/atoms/Icons/Icon';
import { QuestionTipItem } from 'components/questionnaire/types';

const lightBulbOnIcon = (
  <Icon
    type="lightBulbOn"
    stroke={colorTones.sunflower200}
    strokeWidth="1.1"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  />
);

const validationCheckCircleIcon = (
  <Icon
    type="interfaceValidationCheckCircle"
    stroke={colorTones.primaryNightSky}
    strokeWidth="1.1"
    width={16}
    height={16}
    fill="none"
  />
);

const travelMapEarth1Icon = (
  <Icon
    type="travelMapEarth1"
    stroke={colorTones.primaryNightSky}
    strokeWidth="1.1"
    width={16}
    height={16}
    fill="none"
  />
);

export const question1Paragraphs = [
  <Box style={{ marginBottom: '1em' }} key={`1-question-1-paragraph`}>
    <IntroSubParagraphText>
      These are called&nbsp;
      <Typography component="span" variant="questionInstruction">
        Category Labels.
      </Typography>
    </IntroSubParagraphText>
  </Box>,
  <IntroSubParagraphText key={`2-question-1-paragraph`}>
    <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '15px' }}>
      <Box component="li">
        <Box component="span">
          A Category Label is a market segment big enough to encompass your offering, its direct competitors, and
          non-competing offerings with a similar purpose.
        </Box>
      </Box>
      <br />
      <Box component="li">
        <Box component="span">
          Language related to Category Labels establishes a frame of reference and can effectively anchor more
          imaginative words in the right context.
        </Box>
      </Box>
    </Box>
  </IntroSubParagraphText>,
  <IntroSubParagraphText key={`3-question-1-paragraph`}></IntroSubParagraphText>,
];

export const introQuestion2Text = (
  <IntroParagraphText>
    In Step 2, use five words (three in Part a and two in Part b) to create the{' '}
    <Typography component="span" variant="questionInstruction">
      simplest definition of your offering. First what it is and then what it allows people to do.
    </Typography>
  </IntroParagraphText>
);

export const introQuestion2Part2Text = (
  <IntroParagraphText>
    Continuing in Step 2, now use two words to clarify what your offering enables.
  </IntroParagraphText>
);

export const question2Paragraphs = [
  <Box style={{ marginBottom: '1em' }} key={`1-question-2-paragraph`}>
    <IntroSubParagraphText>
      This is called an&nbsp;
      <Typography component="span" variant="questionInstruction">
        Offering Statement.
      </Typography>
    </IntroSubParagraphText>
  </Box>,
  <IntroSubParagraphText key={`2-question-2-paragraph`}>
    <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '15px' }}>
      <Box component="li">
        <Box component="span">
          An Offering Statement is the most direct and objective way to describe what you’ve made.
        </Box>
      </Box>
      <br />
      <Box component="li">
        <Box component="span">
          Language related to an Offering Statement is simple and transparent, allowing it to stand out and build trust
          through its honesty and approachability.
        </Box>
      </Box>
    </Box>
  </IntroSubParagraphText>,
  <IntroSubParagraphText key={`3-question-2-paragraph`}></IntroSubParagraphText>,
];

export const question2Part2Paragraphs = [
  <Box style={{ marginBottom: '1em' }} key={`1-question-2-paragraph`}>
    <IntroSubParagraphText>
      Remember, an&nbsp;
      <Typography component="span" variant="questionInstruction">
        Offering Statement&nbsp;
      </Typography>
      is all about being as crisp and impartial as possible. So, stay focused on simplicity and accuracy.
    </IntroSubParagraphText>
  </Box>,
  <IntroSubParagraphText key={`3-question-2-paragraph`}></IntroSubParagraphText>,
];

export const question3Paragraphs = [
  <Box style={{ marginBottom: '1em' }} key={`1-question-3-paragraph`}>
    <IntroSubParagraphText>
      This is called a&nbsp;
      <Typography component="span" variant="questionInstruction">
        Key Activity.
      </Typography>
    </IntroSubParagraphText>
  </Box>,
  <IntroSubParagraphText key={`2-question-3-paragraph`}>
    <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '15px' }}>
      <Box component="li">
        <Box component="span">
          A Key Activity can be an action executed by the user’s body or mind, or performed by the offering on or for
          the user. Therefore, from the users’ perspective it can be external or internal, visible or invisible, active
          or passive.
        </Box>
      </Box>
      <br />
      <Box component="li">
        <Box component="span">
          Language related to Key Activities highlights what is actually getting done when people use your offering –
          painting a motivating picture of achievement, progress, and the act of “living life”.
        </Box>
      </Box>
    </Box>
  </IntroSubParagraphText>,
  <IntroSubParagraphText key={`3-question-3-paragraph`}></IntroSubParagraphText>,
];

export const question4Paragraphs = [
  <Box style={{ marginBottom: '1em' }} key={`1-question-4-paragraph`}>
    <IntroSubParagraphText>
      These are called&nbsp;
      <Typography component="span" variant="questionInstruction">
        Valued Outcomes.
      </Typography>
    </IntroSubParagraphText>
  </Box>,
  <IntroSubParagraphText key={`2-question-4-paragraph`}>
    <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '15px' }}>
      <Box component="li">
        <Box component="span">
          Valued Outcomes communicate exactly what your offering delivers to meet peoples’ current wants or needs in a
          uniquely attractive way. They describe what people lack, and identify the gaps left by your direct competitors
          that you will successfully fill.
        </Box>
      </Box>
      <br />
      <Box component="li">
        <Box component="span">
          Language related to Valued Outcomes highlights the primary utility of your offering - making it a very
          effective way to signal your target audience: “this is what you are looking for”.
        </Box>
      </Box>
    </Box>
  </IntroSubParagraphText>,
  <IntroSubParagraphText key={`3-question-4-paragraph`}></IntroSubParagraphText>,
];

export const question5Paragraphs = [
  <Box style={{ marginBottom: '1em' }} key={`1-question-5-paragraph`}>
    <IntroSubParagraphText>
      This is called a&nbsp;
      <Typography component="span" variant="questionInstruction">
        Hero Input.
      </Typography>
    </IntroSubParagraphText>
  </Box>,
  <IntroSubParagraphText key={`2-question-5-paragraph`}>
    <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '15px' }}>
      <Box component="li">
        <Box component="span">
          A Hero Input is a unique ingredient, component, technology or standardized process that allows your offering
          to exceed “the norm”. Think of it as your turbo-pack – the thing that powers your offering to do what it does
          with excellence.
        </Box>
      </Box>
      <br />
      <Box component="li">
        <Box component="span">
          Language related to Hero Inputs speaks to your offering’s “x factor” – the force behind what makes it special
          – and is therefore great for piquing curiosity and building excitement.
        </Box>
      </Box>
    </Box>
  </IntroSubParagraphText>,
  <IntroSubParagraphText key={`3-question-5-paragraph`}></IntroSubParagraphText>,
];

export const question6Paragraphs = [
  <Box style={{ marginBottom: '1em' }} key={`1-question-6-paragraph`}>
    <IntroSubParagraphText>
      This is called&nbsp;
      <Typography component="span" variant="questionInstruction">
        The Bigger Experience.
      </Typography>
    </IntroSubParagraphText>
  </Box>,
  <IntroSubParagraphText key={`2-question-6-paragraph`}>
    <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '15px' }}>
      <Box component="li">
        <Box component="span">
          The Bigger Experience is the “why” behind the act of seeking an offering’s Valued Outcomes. It speaks to
          deeper needs, and reflects ambitions around social fulfillment, self-esteem, personal growth or life
          experience. It’s something that – if achieved – can have a fundamental impact on someone’s happiness.
        </Box>
      </Box>
      <br />
      <Box component="li">
        <Box component="span">
          Language related to The Bigger Experience associates what you’ve made with something far more consequential
          than a “solution for today’s problem”. Thus, it can be highly motivating because it surfaces feelings
          associated with profound satisfaction and longer-term psychological rewards.
        </Box>
      </Box>
    </Box>
  </IntroSubParagraphText>,
  <IntroSubParagraphText key={`3-question-6-paragraph`}></IntroSubParagraphText>,
];

export const question7Paragraphs = [
  <Box style={{ marginBottom: '1em' }} key={`1-question-7-paragraph`}>
    <IntroSubParagraphText>
      These are called&nbsp;
      <Typography component="span" variant="questionInstruction">
        Engagement Characteristics.
      </Typography>
    </IntroSubParagraphText>
  </Box>,
  <IntroSubParagraphText key={`2-question-7-paragraph`}>
    <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '15px' }}>
      <Box component="li">
        <Box component="span">
          An Engagement Characteristic is not about performance. It’s about how your offering “comes across” or
          “appears” to those on the other side.
        </Box>
      </Box>
      <br />
      <Box component="li">
        <Box component="span">
          Language related to Engagement Characteristics describes the impression your offering leaves in the room –
          making it easier for people to envision interacting with it.
        </Box>
      </Box>
    </Box>
  </IntroSubParagraphText>,
  <IntroSubParagraphText key={`3-question-7-paragraph`}></IntroSubParagraphText>,
];

export const question8Paragraphs = [
  <Box style={{ marginBottom: '1em' }} key={`1-question-8-paragraph`}>
    <IntroSubParagraphText>
      These are called&nbsp;
      <Typography component="span" variant="questionInstruction">
        Mindset Descriptors.
      </Typography>
    </IntroSubParagraphText>
  </Box>,
  <IntroSubParagraphText key={`2-question-8-paragraph`}>
    <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '15px' }}>
      <Box component="li">
        <Box component="span">
          A Mindset Descriptor labels the psychological landscape that’s produced when the mental or emotional
          challenges surrounding your offering’s need state are removed. Thus, it often goes beyond an “emotion” to
          touch on a person’s attitude or state of mind.
        </Box>
      </Box>
      <br />
      <Box component="li">
        <Box component="span">
          Language related to Mindset Descriptors speaks to enabling a kinder internal monologue and more desirable
          headspace, which encourages interaction by making people think: “I want to feel that way”.
        </Box>
      </Box>
    </Box>
  </IntroSubParagraphText>,
  <IntroSubParagraphText key={`3-question-8-paragraph`}></IntroSubParagraphText>,
];

export const question9Paragraphs = [
  <Box style={{ marginBottom: '1em' }} key={`1-question-9-paragraph`}>
    <IntroSubParagraphText>
      This is called your&nbsp;
      <Typography component="span" variant="questionInstruction">
        Collective Identity.
      </Typography>
    </IntroSubParagraphText>
  </Box>,
  <IntroSubParagraphText key={`2-question-9-paragraph`}>
    <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '15px' }}>
      <Box component="li">
        <Box component="span">
          Your Collective Identity is not about “demographics”. It’s about mirroring how users would express who they
          are and what gives them a sense of individuality. It describes the section of society in which they feel most
          integrated and accepted.
        </Box>
      </Box>
      <br />
      <Box component="li">
        <Box component="span">
          Language related to your Collective Identity builds a sense of community and demonstrates respect for peoples’
          lifestyle choices. This reflection of self-concept is a great way to make target users feel like they’ve found
          home.
        </Box>
      </Box>
    </Box>
  </IntroSubParagraphText>,
  <IntroSubParagraphText key={`3-question-9-paragraph`}></IntroSubParagraphText>,
];

export const question1TipItem: QuestionTipItem = {
  id: 'tip-for-step',
  title: 'Tips for This Step',
  titleIcon: lightBulbOnIcon,
  textItems: [
    {
      id: 'heck-out',
      text: <></>,
    },
  ],
  boxContents: [
    {
      id: 'tips-on-language-selection',
      title: 'HELPFUL HINTS FOR SELECTING LANGUAGE',
      titleIcon: validationCheckCircleIcon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Keep it broad.
              </Box>
              {'\n'}
              Identify market segments big enough to include substitutes as well as compliments to your offerings
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                In this step, don`t enable an exact visualization.
              </Box>
              {'\n'}
              For instance, “jewelry” lets people picture many different things, while “charm bracelet” consistently
              brings the same image to mind. Keep things at the “jewelry” level.
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Stretch your legs.
              </Box>
              {'\n'}
              Maintain accuracy but select a label wide enough to accommodate growth in adjacent areas.
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      id: 'real-world-examples',
      title: 'AESOP`S TAKE ON REAL WORLD EXAMPLES',
      titleIcon: travelMapEarth1Icon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              Looking to order medical equipment for prompt delivery? Even though it leads with a metaphor for speed and
              rescue – you immediately know
              <Box component="span" fontWeight="bold">
                &nbsp;Parachute Health&nbsp;
              </Box>
              is part of the therapeutic space.
            </Box>
            <br />
            <Box component="li">
              In need of some tunes to complement your creative output? With its clear category reference,
              <Box component="span" fontWeight="bold">
                &nbsp;Epidemic Sound&nbsp;
              </Box>
              lets you know they’re here to spread magnetic music and audio between those that make it and the content
              creators who want it.
            </Box>
          </Box>
        </Box>
      ),
    },
  ],
};

export const question2TipItem: QuestionTipItem = {
  id: 'tip-for-step',
  title: 'Tips for This Step',
  titleIcon: lightBulbOnIcon,
  boxContents: [
    {
      id: 'tips-on-language-selection',
      title: 'HELPFUL HINTS FOR SELECTING LANGUAGE',
      titleIcon: validationCheckCircleIcon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Define, don’t describe.
              </Box>
              {'\n'}
              Keep language related to benefits, performance and selling points <b>out of the equation.</b>
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Find something that hits the SPOT.
              </Box>
              {'\n'}
              Be <b>S</b>traightforward, <b>P</b>recise, <b>O</b>bjective and <b>T</b>angible.
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Mirror peoples’ very first thoughts on what they need.
              </Box>
              {'\n'}
              Find inspiration by completing sentences such as:
              <Box>“I really need a/an/some good ________.”</Box>
              <Box>“I really need someone/something that is a/an ________.”</Box>
              <Box>“I really need to know more about _________.”</Box>
              <Box>“I really need to be able to _________.”</Box>
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      id: 'real-world-examples',
      title: 'AESOP`S TAKE ON REAL WORLD EXAMPLES',
      titleIcon: travelMapEarth1Icon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              Interested in finding a cool spot to discover and share recipes? to start looking.
              <Box component="span" fontWeight="bold">
                &nbsp;Pepper&nbsp;
              </Box>
              comes across like an easy place.
            </Box>
            <br />
            <Box component="li">
              In need of some on-demand mechanic services?
              <Box component="span" fontWeight="bold">
                &nbsp;Wrench&nbsp;
              </Box>
              let’s you know they’re ready to make your repairs without the “BS”.
            </Box>
          </Box>
        </Box>
      ),
    },
  ],
};

export const question3TipItem: QuestionTipItem = {
  id: 'tip-for-step3',
  title: 'Tips for This Step',
  titleIcon: lightBulbOnIcon,
  textItems: [
    {
      id: 'heck-out',
      text: <></>,
    },
  ],
  boxContents: [
    {
      id: 'tips-on-language-selection',
      title: 'HELPFUL HINTS FOR SELECTING LANGUAGE',
      titleIcon: validationCheckCircleIcon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Go with your gut
              </Box>
              {'\n'}
              Select verbs that feel obviously and intuitively connected to your usage experience.
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Be playful.
              </Box>
              {'\n'}
              Verbs are a lively and outgoing type of word so don’t be afraid to have some fun.
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Think from the users’ POV.
              </Box>
              {'\n'}
              Define how they are occupying their time, focusing their attention, or expending their energy in this
              moment.
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      id: 'real-world-examples',
      title: 'AESOP`S TAKE ON REAL WORLD EXAMPLES',
      titleIcon: travelMapEarth1Icon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              Hoping people seek out some midday rejuvenation by renting a hotel room by the minute?<br></br>
              <Box component="span" fontWeight="bold">
                Recharge&nbsp;
              </Box>
              compels them to give it a go.
            </Box>
            <br />
            <Box component="li">
              Want people to become “responsible nomads” by roaming the world through a work-friendly network of smart
              homes?
              <Box component="span" fontWeight="bold">
                &nbsp;Wander&nbsp;
              </Box>
              lights that fire in their imagination.
            </Box>
          </Box>
        </Box>
      ),
    },
  ],
};

export const question4TipItem: QuestionTipItem = {
  id: 'tip-for-step4',
  title: 'Tips for This Step',
  titleIcon: lightBulbOnIcon,
  textItems: [
    {
      id: 'heck-out',
      text: <></>,
    },
  ],
  boxContents: [
    {
      id: 'tips-on-language-selection',
      title: 'HELPFUL HINTS FOR SELECTING LANGUAGE',
      titleIcon: validationCheckCircleIcon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Make a clear promise.
              </Box>
              {'\n'}
              Select verbs that feel obviously and intuitively connected to your usage experience.
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Move beyond everyday vocabulary.
              </Box>
              {'\n'}
              Instead of “fast”, maybe it’s “nimble”? Instead of “easy”, perhaps try out “effortless”?
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Share the excitement.
              </Box>
              {'\n'}
              Think about the most thrilling things your offering delivers. What makes it truly amazing?
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Don’t rank or compare.
              </Box>
              {'\n'}
              Avoid adjectives that are comparative (e.g. “cleaner”) or superlative (e.g. “smallest”).
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      id: 'real-world-examples',
      title: 'AESOP`S TAKE ON REAL WORLD EXAMPLES',
      titleIcon: travelMapEarth1Icon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              Have to send a money?
              <Box component="span" fontWeight="bold">
                &nbsp;Flywire&nbsp;
              </Box>
              suggests you can make a fast, direct payment.
            </Box>
            <br />
            <Box component="li">
              Need to virtually put people’s heads together?
              <Box component="span" fontWeight="bold">
                &nbsp;Mural&nbsp;
              </Box>
              signals their platform will deliver a collaborative, visual workshop.
            </Box>
            <br />
            <Box component="li">
              Want to upskill yourself?
              <Box component="span" fontWeight="bold">
                &nbsp;Jolt&nbsp;
              </Box>
              lets you know they’re here with short, impactful, energizing classes.
            </Box>
          </Box>
        </Box>
      ),
    },
  ],
};

export const question5TipItem: QuestionTipItem = {
  id: 'tip-for-step5',
  title: 'Tips for This Step',
  titleIcon: lightBulbOnIcon,
  textItems: [
    {
      id: 'heck-out',
      text: <></>,
    },
  ],
  boxContents: [
    {
      id: 'tips-on-language-selection',
      title: 'HELPFUL HINTS FOR SELECTING LANGUAGE',
      titleIcon: validationCheckCircleIcon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Stick to the differentiators.
              </Box>
              {'\n'}
              Select an input that is unique enough to be a true point of interest within your category.
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Remember inputs aren’t outcomes.
              </Box>
              {'\n'}
              They are not what the user ”receives”. Rather, they are definitive parts of your offering or regimented
              steps in how it’s made.
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Keep it objective
              </Box>
              {'\n'}
              State what the input is (e.g. “golden turmeric”) and not the benefits it delivers (e.g. “restorative
              turmeric”).
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      id: 'real-world-examples',
      title: 'AESOP`S TAKE ON REAL WORLD EXAMPLES',
      titleIcon: travelMapEarth1Icon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              Gone all in on the power of a certain single-origin spice?
              <Box component="span" fontWeight="bold">
                &nbsp;Golde&nbsp;
              </Box>
              creates interest around golden turmeric and how it can brighten the results of wellness essentials.
            </Box>
            <br />
            <Box component="li">
              Do you use an array of unexpected ingredients statistically proven to be the most nutrient-dense?
              <Box component="span" fontWeight="bold">
                &nbsp;Four Sigmatic&nbsp;
              </Box>
              encourages people to investigate the benefits of consuming something “four standard deviations above”
              average food.
            </Box>
          </Box>
        </Box>
      ),
    },
  ],
};

export const question6TipItem: QuestionTipItem[] = [
  {
    id: 'tip-for-step-6',
    title: 'Tips for This Step',
    titleIcon: lightBulbOnIcon,
    textItems: [],
    boxContents: [
      {
        id: 'tips-on-selection',
        title: 'HELPFUL HINTS FOR SELECTING LANGUAGE',
        titleIcon: validationCheckCircleIcon,
        text: (
          <Box sx={{ marginBottom: 2 }}>
            <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
              <Box component="li">
                <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                  Think really, really big.
                </Box>
                {'\n'}
                Select ideas that are progressive, hopeful and weighty.
              </Box>
              <br />
              <Box component="li">
                <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                  Find the real intention.
                </Box>
                {'\n'}
                Think about the reason people are willing to spend time, money and energy to obtain your Valued
                Outcomes. Why are those outcomes worthwhile? What do they help a person achieve?
              </Box>
              <br />
              <Box component="li">
                <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                  Broaden the story space.
                </Box>
                {'\n'}
                Maintain accuracy but move away from your Valued Outcomes and stretch into a new sphere.
              </Box>
            </Box>
          </Box>
        ),
      },
      {
        id: 'real-world-examples',
        title: 'AESOP`S TAKE ON REAL WORLD EXAMPLES',
        titleIcon: travelMapEarth1Icon,
        text: (
          <Box sx={{ marginBottom: 2 }}>
            <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
              <Box component="li">
                Want your active wear to stand for the endless enjoyment that comes with moving your body vs. the
                temporary high of “victory”?
                <span style={{ margin: '0 4px' }}>
                  <Box component="span" fontWeight="bold">
                    Outdoor Voices
                  </Box>
                </span>
                has the right narrative.
              </Box>
              <br />
              <Box component="li">
                Rather your vitamins be equated with the sacred, life-long practice of self-care vs. the daily task of
                meeting nutrient needs?
                <Box component="span" fontWeight="bold">
                  &nbsp;Ritual&nbsp;
                </Box>
                supports this elevated storyline.
              </Box>
            </Box>
          </Box>
        ),
      },
    ],
  },
];

export const question7TipItem: QuestionTipItem = {
  id: 'tip-for-step-7',
  title: 'Tips for This Step',
  titleIcon: lightBulbOnIcon,
  textItems: [
    {
      id: 'heck-out',
      text: <></>,
    },
  ],
  boxContents: [
    {
      id: 'tips-on-language-selection',
      title: 'HELPFUL HINTS FOR SELECTING LANGUAGE',
      titleIcon: validationCheckCircleIcon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Think about user perception.
              </Box>
              {'\n'}
              This is is all about how the offering should be perceived and characterized by users. How will they
              interpret the environment it creates?
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Think outside performance.
              </Box>
              {'\n'}
              Identify distinguishing characteristics unassociated with performance. Lots of premium cars are “fast”,
              but their engagement attributes tend to be things like “luxurious”, “modern” and “elegant” – language
              unrelated to what the car does but completely related to how the car is experienced.
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Focus on “This is…” or “They are…”.
              </Box>
              {'\n'}
              Look for words typically used to describe an environment or someone else’s demeanor. Things like
              “approachable”, “festive”, “exciting”, “trustworthy”, “knowledgeable”, “honest” and “accommodating” are
              all additional examples of good answers.
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      id: 'real-world-examples',
      title: 'AESOP`S TAKE ON REAL WORLD EXAMPLES',
      titleIcon: travelMapEarth1Icon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              Do you believe the internet should feel bright, positive and full of endless possibilities?
              <Box component="span" fontWeight="bold">
                &nbsp;Starry
              </Box>{' '}
              sets the stage for the kind of access you’re about to deliver.
            </Box>
            <Box component="li">
              Do you think the best customer service is enthusiastic, productive and satisfying?
              <Box component="span" fontWeight="bold">
                &nbsp;Gladly
              </Box>{' '}
              lets everyone know they can expect this kind of experience from your platform.
            </Box>
          </Box>
        </Box>
      ),
    },
  ],
};

export const question8TipItem: QuestionTipItem = {
  id: 'tip-for-step-8',
  title: 'Tips for This Step',
  titleIcon: lightBulbOnIcon,
  textItems: [
    {
      id: 'heck-out',
      text: <></>,
    },
  ],
  boxContents: [
    {
      id: 'tips-on-language-selection',
      title: 'HELPFUL HINTS FOR SELECTING LANGUAGE',
      titleIcon: validationCheckCircleIcon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Don’t compare.
              </Box>
              {'\n'}
              Avoid selecting words in their comparative form (e.g. “bolder”, “happier”, etc.) in favor of impactful,
              standalone adjectives.
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Include (but explore beyond) emotions.
              </Box>
              {'\n'}
              Look at emotional labels (e.g. “ecstatic”), but don’t forget to consider moods (e.g. “whimsical”) and
              mental states (e.g. “reflective”) that could be a more accurate answer.
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Focus on “I feel___”.
              </Box>
              {'\n'}
              Look for words typically used to describe how someone feels in their own skin. Things like “grounded”,
              “relaxed”, “ inspired”, “energized” “respected”, and “satisfied” are all additional examples of good
              answers.
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      id: 'real-world-examples',
      title: 'AESOP`S TAKE ON REAL WORLD EXAMPLES',
      titleIcon: travelMapEarth1Icon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              Want to let people know you can help them feel empowered, productive and extraordinary through better
              email management?
              <Box component="span" fontWeight="bold">
                &nbsp;Superhuman&nbsp;
              </Box>
              paints a vivid image of that transformation.
            </Box>
            <br />
            <Box component="li">
              Need to foreshadow the feeling of a relaxed and peaceful mind?
              <Box component="span" fontWeight="bold">
                &nbsp;Calm&nbsp;
              </Box>
              lets people picture how your app will help them “turn off” to meditate, rest, or simply sleep their way
              there.
            </Box>
          </Box>
        </Box>
      ),
    },
  ],
};

export const question9TipItem: QuestionTipItem = {
  id: 'tip-for-step-9',
  title: 'Tips for This Step',
  titleIcon: lightBulbOnIcon,
  textItems: [
    {
      id: 'heck-out',
      text: <></>,
    },
  ],
  boxContents: [
    {
      id: 'tips-on-language-selection',
      title: 'HELPFUL HINTS FOR SELECTING LANGUAGE',
      titleIcon: validationCheckCircleIcon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Hit hard and hit home.
              </Box>
              {'\n'}
              Identities are all about passion, and how we label them should reflect that power. Select language that is
              vivid, bold and unexpected.
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Make it personal.
              </Box>
              {'\n'}
              Look for a phrase that captures the unique persona of your target audience.
            </Box>
            <br />
            <Box component="li">
              <Box component="span" sx={{ fontWeight: 'bold', color: '#418FDE' }}>
                Avoid broad generalizations.
              </Box>
              {'\n'}
              There are all kinds of “college students”. Are your users “committed bookworms” or “curious butterflies”?
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      id: 'real-world-examples',
      title: 'AESOP`S TAKE ON REAL WORLD EXAMPLES',
      titleIcon: travelMapEarth1Icon,
      text: (
        <Box sx={{ marginBottom: 2 }}>
          <Box component="ul" style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            <Box component="li">
              Want those with a more humble, “swim against the tide” philosophy to feel recognized and embraced?
              <Box component="span" fontWeight="bold">
                &nbsp;Minor Figures&nbsp;
              </Box>
              let’s them know your line of vegan milks validates and celebrates them.
            </Box>
            <br />
            <Box component="li">
              Looking for serious, textile-focused creatives to feel like they’ve arrived where they belong?
              <Box component="span" fontWeight="bold">
                &nbsp;The Fabricant&nbsp;
              </Box>
              suggests a digital fashion house that really “gets” and appreciates how they work.
            </Box>
          </Box>
        </Box>
      ),
    },
  ],
};
