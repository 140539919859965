import { Box, Typography } from '@mui/material';
import {
  FlexBox,
  IntroParagraphText,
  LightText,
  QuestionExampleBlueText,
  QuestionExampleText,
  QuestionStepLabel,
  UpperCaseText,
} from 'components/atoms';
import { FadingContainer, QuestionContainer } from 'components/molecules';
import { IntroQuotedText, QuestionExampleBox, QuestionTips } from 'components/organism/Questionnaire';
import { question7Paragraphs, question7TipItem } from 'components/questionnaire//constants';
import { FormQuestion7 } from 'components/questionnaire/forms';
import { QuestionIntroduction } from 'components/questionnaire/QuestionIntroduction';
import { Question7Props } from 'components/questionnaire/types';
import React, { useState } from 'react';

type Props = {
  phaseName: string;
  questionVisited: boolean;
} & Question7Props;

export const Question7: React.FC<Props> = ({
  answers,
  phaseName,
  formRef,
  questionVisited,
  requestError,
  questionError,
  onClick,
}) => {
  const [showTipSection, setShowTipSection] = useState<boolean>(false);
  const introText = (
    <Box key={'intro7Text'}>
      <QuestionStepLabel>{phaseName}</QuestionStepLabel>
      <IntroParagraphText>
        Use two words to “sum up” the{' '}
        <Typography component="span" variant="questionInstruction">
          {' '}
          mood, tone or atmosphere set by your offering.
        </Typography>
      </IntroParagraphText>
    </Box>
  );

  return (
    <QuestionContainer
      tipSection={
        <FadingContainer visible={showTipSection}>
          {<QuestionTips items={[question7TipItem]} defaultExpanded />}
        </FadingContainer>
      }
      mainSection={
        <QuestionIntroduction
          tipSection={<QuestionTips items={[question7TipItem]} />}
          intro={
            <IntroQuotedText
              title={phaseName}
              text={`“Establishing the right ‘aura’ builds anticipation around usage experience.”`}
            />
          }
          paragraphsToAnimate={[introText, ...question7Paragraphs]}
          form={
            <Box paddingTop="120px">
              <QuestionExampleBox title="Examples of what good answers look like:">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, auto)' }}>
                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>… really</QuestionExampleText>
                      <QuestionExampleBlueText>lively</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>and</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>original</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>… really</QuestionExampleText>
                      <QuestionExampleBlueText>purposeful</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>and</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>unexpected</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>… really</QuestionExampleText>
                      <QuestionExampleBlueText>seamless</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>and</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>powerful</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: 0 }} justifyContent="end">
                      <QuestionExampleText>… really</QuestionExampleText>
                      <QuestionExampleBlueText>refreshing</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>and</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>welcoming</QuestionExampleBlueText>
                    </FlexBox>

                    <FlexBox sx={{ padding: 0, margin: '0 0 16px 0' }} justifyContent="end">
                      <QuestionExampleText>… really</QuestionExampleText>
                      <QuestionExampleBlueText>engaging</QuestionExampleBlueText>
                    </FlexBox>
                    <QuestionExampleText>and</QuestionExampleText>
                    <FlexBox sx={{ padding: 0, margin: 0 }}>
                      <QuestionExampleBlueText>comprehensive</QuestionExampleBlueText>
                    </FlexBox>
                  </Box>
                </div>
              </QuestionExampleBox>
              <FormQuestion7
                onClick={onClick}
                answers={answers}
                formRef={formRef}
                requestError={requestError}
                questionError={questionError}
              />
              <br />
              <UpperCaseText sx={{ fontSize: '10px' }}>WHEN ANSWERING, DON&lsquo;T WORRY ABOUT...</UpperCaseText>
              <br />
              <LightText>
                <Box>
                  <Typography component="span" variant="lightTextStart">
                    The user’s feelings.
                  </Typography>{' '}
                  This is about the user’s opinion – not their emotional response. But don’t overthink it. Just find
                  characteristics that capture how you want your offering to “appear” and be described by someone who
                  uses it.{' '}
                </Box>
              </LightText>
            </Box>
          }
          fomTitle={''}
          onFormInView={(isInView) => {
            setShowTipSection(isInView);
          }}
          questionVisited={questionVisited}
        />
      }
    />
  );
};
