import { env } from 'env';
import { RequestError } from 'sdk/internalApi/request';

type Response = {
  email_with_promo_codes: string;
};

type Request = {
  stripe_id: string;
};

export const getGiftCardEmailWithPromoCodes = async (request: Request): Promise<Response> => {
  const url = `${env.API_URL}/gift-cards/email?` + new URLSearchParams(request);
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (!(res.status >= 200 && res.status < 400)) {
    throw new RequestError(res.status, null);
  }
  return res.json();
};
