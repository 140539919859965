import { SelectChangeEvent } from '@mui/material';
import { Select } from 'components/molecules';
import React from 'react';
import { SelectItems } from 'sdk/types';

type Props = {
  items: SelectItems;
  selectLabel: string;
  name?: string;
  onSelect?: (id: string | number) => void;
};

export const SelectRelateJourneys: React.FC<Props> = ({ items, selectLabel, name = 'relateJourneys', onSelect }) => {
  if (items.length === 0) {
    return null;
  }

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    onSelect?.(event.target.value);
  };

  return (
    <Select
      helperText=""
      sx={{ maxWidth: '450px', width: '100%' }}
      name={name}
      label={selectLabel}
      items={[...items]}
      onChange={handleChange}
    />
  );
};
