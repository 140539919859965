import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceTimeAlarm: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9999 23.1428C17.2071 23.1428 21.4284 18.9215 21.4284 13.7143C21.4284 8.50702 17.2071 4.28571 11.9999 4.28571C6.7926 4.28571 2.57129 8.50702 2.57129 13.7143C2.57129 18.9215 6.7926 23.1428 11.9999 23.1428Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.42871 10.2857L12.0001 13.7143H16.2859"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5713 3.42853L22.2856 5.14281"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.42864 3.42853L1.71436 5.14281"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 0.857117V4.28569"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.42871 0.857117H14.5716"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
