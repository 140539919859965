import styled from '@emotion/styled';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

type Props = {
  children: React.ReactNode;
  sx?: SxProps;
};

const StyledCenteredContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '80dvh',
  width: '100%',
});

export const CenteredContentBox: React.FC<Props> = ({ sx, children }) => (
  <StyledCenteredContentBox sx={sx}>{children}</StyledCenteredContentBox>
);
