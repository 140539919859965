import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const MailSmileyUnhappy: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.145 23.29C18.3003 23.29 23.2899 18.3002 23.2899 12.145C23.2899 5.98979 18.3003 1 12.145 1C5.98978 1 1 5.98979 1 12.145C1 18.3002 5.98978 23.29 12.145 23.29Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0399 14.9996C16.4162 14.8299 15.4018 15.076 14.5608 15.5616C13.7197 16.0472 12.9994 16.8026 12.8345 17.4275"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4663 9.11955C16.577 9.11955 16.6667 9.02985 16.6667 8.91917C16.6667 8.80852 16.577 8.71881 16.4663 8.71881"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4655 9.11955C16.3548 9.11955 16.2651 9.02985 16.2651 8.91917C16.2651 8.80852 16.3548 8.71881 16.4655 8.71881"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.37305 9.11948C7.48368 9.11948 7.57339 9.02979 7.57339 8.91914C7.57339 8.8085 7.48368 8.71881 7.37305 8.71881"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.3722 9.11948C7.26156 9.11948 7.17188 9.02979 7.17188 8.91914C7.17188 8.8085 7.26156 8.71881 7.3722 8.71881"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
