import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FormQuestionText, Input } from 'components/atoms';
import { FormError, QuestionFormContainer } from 'components/molecules';
import { Question3FormValues, Question3Props } from 'components/questionnaire/types';
import { Form, Formik } from 'formik';
import React from 'react';
import { Question } from 'sdk/questionnaire/enums';
import { assertMaxWordCount } from 'utils';
import * as yup from 'yup';

type Props = Question3Props;

const verbMaxWordsCount = 2;

export const FormQuestion3: React.FC<Props> = ({
  answer,
  inputVerbFromQuestion2,
  formRef,
  requestError,
  questionError,
  onClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const initialValues: Question3FormValues = { V2: answer?.V2 || '' };
  const formSchema = yup.object({
    V2: yup
      .string()
      .test('Max verb words count', `Must be ${verbMaxWordsCount} words or fewer in blank`, (val) =>
        assertMaxWordCount(val || '', verbMaxWordsCount),
      )
      .required('Action is required'),
  });

  const onSubmitForm = (values: Question3FormValues) => {
    onClick?.({ [Question.Question3]: values }, Question.Question3);
  };

  return (
    <QuestionFormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={formSchema}
        validateOnChange
        innerRef={formRef}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormQuestionText>
              <Box textAlign="left">Without using the word {inputVerbFromQuestion2}, fill in the blank:</Box>
              <br />
              <br />
              The other task, action or activity that takes place when a user interacts with my offering is
              <Input
                name="V2"
                value={values.V2}
                placeholder="Action"
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (Boolean(questionError?.V?.[1]) && questionError?.V?.[1] === values.V2) ||
                  (Boolean(errors.V2) && touched.V2)
                }
              />
              {!isMobile && '.'}
            </FormQuestionText>
            {Boolean(errors.V2) && touched.V2 && <FormError>{errors.V2}</FormError>}
            {Boolean(questionError?.V) && questionError?.V?.[1] === values.V2 && (
              <FormError>Name is not allowed</FormError>
            )}
            {Boolean(requestError) && <FormError>{requestError}</FormError>}
          </Form>
        )}
      </Formik>
    </QuestionFormContainer>
  );
};
