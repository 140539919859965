import TextField from '@mui/material/TextField';
import React from 'react';

import { FormControlProps } from './types/FormControlProps';

type Props = FormControlProps;

export const TextFormControl: React.FC<Props> = ({ error, errorText, label, ...rest }) => (
  <TextField
    margin="normal"
    fullWidth
    label={label}
    error={Boolean(error)}
    helperText={errorText}
    type="text"
    {...rest}
  />
);
