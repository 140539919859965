import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const TravelMapFlag: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.7142 12.8571H4.28564V0.857147H19.7142L14.5714 6.85715L19.7142 12.8571Z"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.28564 0.857147V23.1429"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
