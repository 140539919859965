import { useState } from 'react';

type UseHandleModalProps = {
  openModal: boolean;
  handleOpen: () => void;
  handleClose: () => void;
};

export const useHandleModal = (): UseHandleModalProps => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  return {
    openModal,
    handleOpen,
    handleClose,
  };
};
