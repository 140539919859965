import { useMediaQuery, useTheme } from '@mui/material';
import { FormQuestionText, Input } from 'components/atoms';
import { FormError, QuestionFormContainer } from 'components/molecules';
import { Question2Part2FormValues, Question2Part2Props } from 'components/questionnaire/types';
import { Form, Formik } from 'formik';
import React from 'react';
import { Question } from 'sdk/questionnaire/enums';
import { assertMaxWordCount } from 'utils';
import * as yup from 'yup';

type Props = Question2Part2Props;

const verbMaxWordsCount = 2;
const nounMaxWordsCount = 2;

export const FormQuestion2Part2: React.FC<Props> = ({ answer, formRef, requestError, questionError, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const initialValues: Question2Part2FormValues = {
    N2: answer?.N2 || '',
    V1: answer?.V1 || '',
  };

  const formSchema = yup.object({
    N2: yup
      .string()
      .required('Word is required')
      .test('Max noun words count', `Must be ${nounMaxWordsCount} words or fewer in word blank`, (val) =>
        assertMaxWordCount(val || '', nounMaxWordsCount),
      ),
    V1: yup
      .string()
      .test('Max verb words count', `Must be ${verbMaxWordsCount} words or fewer in action blank`, (val) =>
        assertMaxWordCount(val || '', verbMaxWordsCount),
      )
      .required('Action is required'),
  });

  const onSubmitForm = ({ N2, V1 }: Question2Part2FormValues) => {
    onClick?.(
      {
        [Question.Question2Part2]: {
          V1,
          N2,
        },
      },
      Question.Question2Part2,
    );
  };

  return (
    <QuestionFormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={formSchema}
        validateOnChange
        innerRef={formRef}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormQuestionText>
              <p style={{ textAlign: 'left' }}>Fill in the blanks:</p>
              This offering allows people to
              <Input
                name="V1"
                value={values.V1}
                placeholder="Action"
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (Boolean(questionError?.V?.[0]) && questionError?.V?.[0] === values.V1) ||
                  (Boolean(errors.V1) && touched.V1)
                }
              />{' '}
              (for/with/to/a/on/in/about/the right)
              <Input
                name="N2"
                value={values.N2}
                placeholder="Word"
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (Boolean(questionError?.N2) && questionError?.N2?.[0] === values.N2) ||
                  (Boolean(errors.N2) && touched.N2)
                }
              />
              {!isMobile && '.'}
            </FormQuestionText>
            {Boolean(errors.N2) && touched.N2 && <FormError>{errors.N2}</FormError>}
            {Boolean(errors.V1) && touched.V1 && <FormError>{errors.V1}</FormError>}
            {((Boolean(questionError?.V?.[0]) && questionError?.V?.[0] === values.V1) ||
              (Boolean(questionError?.N2) && questionError?.N2?.[0] === values.N2)) && (
              <FormError>Name is not allowed</FormError>
            )}
            {Boolean(requestError) && <FormError>{requestError}</FormError>}
          </Form>
        )}
      </Formik>
    </QuestionFormContainer>
  );
};
