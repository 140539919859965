import {
  getAdjectiveFormSchema,
  getNounFormSchema,
  getPairAdjectiveAndNounFormSchema,
} from 'components/questionnaire/utils';
import * as yup from 'yup';

export const commonAnswerFormSchema = yup.object().shape({
  noun: getNounFormSchema({}),
  adjective: getAdjectiveFormSchema({}),
});

export const pairAdjectiveAndAdjectiveFormSchema = yup.object().shape({
  adjective1: getAdjectiveFormSchema({}),
  adjective2: getAdjectiveFormSchema({}),
});

export const commonAnswersFormSchema = yup.object().shape({
  responses: yup.array().min(1, 'Must be at least one inputs row').of(commonAnswerFormSchema).required(),
});

export const commonAnswersWithOptionalResponseFormSchema = yup.object().shape({
  responses: yup.array().of(commonAnswerFormSchema).required(),
});

export const commonAdjectiveAndNounFormSchema = yup.object().shape({
  responses: yup.array().min(1, 'Must be at least one inputs row').of(getPairAdjectiveAndNounFormSchema({})).required(),
});

export const commonAdjectiveAndAdjectiveFormSchema = yup.object().shape({
  responses: yup.array().min(1, 'Must be at least one inputs row').of(pairAdjectiveAndAdjectiveFormSchema).required(),
});
