import { colorTones, fonts } from 'constants/designSystem';

import { Typography, styled } from '@mui/material';
import { Box } from '@mui/system';
import { Font } from '@react-pdf/renderer';
import { Button, PageContainer } from 'components/atoms';
import { Icon } from 'components/atoms/Icons';
import { BadgeExpertReviewStatus, BadgeFailed, SelectRelateJourneys } from 'components/molecules';
import { BadgeJourneyStatus } from 'components/molecules/Badges/BadgeJourneyStatus';
import {
  CustomAlert,
  GetExpertInTheLoop,
  GetMoreNames,
  NamingJourneyNavigation,
  RatedResults,
} from 'components/organism';
import { ExpertPlusReviews } from 'components/organism/ExpertPlusReviews';
import { ExportPdf } from 'components/questionnaire';
import { useAuthUser, useQuery } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getResults, postPaymentsBuyMore } from 'sdk/internalApi';
import { buyExpertInTheLoop, buyExpertPlus } from 'sdk/internalApi/expert';
import { patchFeedback } from 'sdk/internalApi/feedback';
import { QuestionnaireResponse, getQuestionnaire, postQuestionnaireCreateChild } from 'sdk/internalApi/questionnaire';
import { SelectItems, User } from 'sdk/types';
import { ExpertPlusReview, JourneyResult, JourneyResultFeedback } from 'sdk/types/result';
import { getGetPaymentStatusFromQueryParams } from 'utils';

const StyledWrapper = styled(Box)({
  m: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
});

const StyledButton = styled(Button)({
  minWidth: '216px',
  height: '48px',
  marginRight: 2,
});

const WaitingResultsText = styled(Typography)({
  fontFamily: fonts.secondaryFont,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '27px',
  lineHeight: '30px',
  letterSpacing: '0.02em',
  color: colorTones.ocean200,
  paddingTop: '15px',
});

const sortResultsByFeedback = (results: JourneyResult[]) => {
  const sortAsc = (current: JourneyResult, next: JourneyResult) => {
    if (current.feedback < next.feedback) return -1;
    if (current.feedback > next.feedback) return 1;
    if (current.favorite < next.favorite) return 1;
    if (current.favorite > next.favorite) return -1;
    if (current.name < next.name) return -1;
    if (current.name > next.name) return 1;

    return 0;
  };

  return results.sort(sortAsc);
};

const getRatedResults = async (req: {
  user: number;
  token: string;
  journeyId: number;
}): Promise<{
  batchSize: number;
  results: JourneyResult[];
  expertPlusReviews: ExpertPlusReview[];
  ratedResultsCount: number;
  notRatedResultsCount: number;
  notBoughtCount: number;
  boughtCount: number;
}> => {
  const res = await getResults(req);
  const results: JourneyResult[] = res?.bought?.results || [];
  const filteredResults = results.filter(
    (item) => !(item.erroneous || item.feedback === JourneyResultFeedback.NoFeedback),
  );
  const sortedResults = sortResultsByFeedback(filteredResults);
  return {
    results: [...sortedResults],
    expertPlusReviews: res?.bought.expert_plus_reviews || [],
    notBoughtCount: res.not_bought_count || 0,
    notRatedResultsCount: res.bought.not_rated_results_count,
    ratedResultsCount: res.bought.rated_results_count,
    boughtCount: res.bought.count,
    batchSize: res?.batch_size ?? 24,
  };
};

type AddUserFeedbackRequest = {
  token?: string;
  id?: number;
  userFeedback: {
    feedback?: JourneyResultFeedback;
    favorite?: boolean;
  };
};

const addUserFeedback = async ({ token, id, userFeedback }: AddUserFeedbackRequest) => {
  if (!token || !id) {
    return;
  }
  await patchFeedback({
    resultId: id,
    token,
    ...userFeedback,
  });
};

const updateResultState = (
  results: JourneyResult[],
  id: number,
  userFeedback: { feedback?: JourneyResultFeedback; favorite?: boolean },
) => {
  const newResults: JourneyResult[] = results.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        ...userFeedback,
      };
    }
    return item;
  });
  const sortResults = sortResultsByFeedback(newResults);
  return [...sortResults];
};

type FetchQuestionnaireWithResultRequest = {
  token: string;
  user: User;
  id: string;
};

const fetchQuestionnaireWithResult = async ({ token, user, id }: FetchQuestionnaireWithResultRequest) => {
  const [resQuestionnaire, resResults] = await Promise.all([
    getQuestionnaire({
      token,
      id: Number(id),
    }),
    getRatedResults({
      user: user.id,
      token,
      journeyId: Number(id),
    }),
  ]);

  const results: JourneyResult[] = resQuestionnaire?.seen_results ? resResults.results : [];
  const expertPlusReviews: ExpertPlusReview[] = resResults?.expertPlusReviews || [];

  return {
    questionnaire: resQuestionnaire,
    parent: resQuestionnaire.parent,
    parentName: resQuestionnaire.parent_name,
    results,
    expertPlusReviews: expertPlusReviews,
    notBoughtCount: resResults.notBoughtCount,
    ratedResultsCount: resResults.ratedResultsCount,
    notRatedResultsCount: resResults.notRatedResultsCount,
    boughtCount: resResults.boughtCount,
    batchSize: resResults.batchSize,
    commonExpertReviewStatus: resQuestionnaire.common_expert_review_status,
  };
};

const StyledNamingJourneyTitle = styled(Typography)({
  fontFamily: fonts.primeFont,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '24px',
  color: colorTones.primaryNightSky,
  marginBottom: '24px',
});

type FetchConsistRelatedJourneysResponse = {
  id: number;
  name: string;
}[];

const consistJourneysToSelectItems = (
  fetchConsistRelatedJourneysResponse: FetchConsistRelatedJourneysResponse,
): SelectItems => {
  return fetchConsistRelatedJourneysResponse.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

Font.register({
  family: 'Work Sans',
  fonts: [
    { src: '/fonts/workSans/WorkSans-Regular.ttf', fontStyle: 'normal', fontWeight: 'normal' },
    { src: '/fonts/workSans/WorkSans-Bold.ttf', fontStyle: 'normal', fontWeight: 'bold' },
    { src: '/fonts/workSans/WorkSans-Italic.ttf', fontStyle: 'italic', fontWeight: 'normal' },
  ],
});

export const JourneyPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [questionnaire, setQuestionnaire] = useState<QuestionnaireResponse>();
  const [notBoughtCount, setNotBoughtCount] = useState<number>(0);
  const [ratedResultsCount, setRatedResultsCount] = useState<number>(0);
  const [notRatedResultsCount, setNotRatedResultsCount] = useState<number>(0);
  const [parent, setParent] = useState<{ id?: number; name?: string } | undefined>(undefined);
  const [results, setResults] = useState<JourneyResult[]>([]);
  const [expertPlusReviews, setExpertPlusReviews] = useState<ExpertPlusReview[]>([]);
  const [relatedSelectItems, setRelatedSelectItems] = useState<SelectItems>([]);
  const navigate = useNavigate();
  const query = useQuery();
  const paymentStatus = getGetPaymentStatusFromQueryParams(query);
  const { isAuthenticated, token, user } = useAuthUser();
  const params = useParams();

  useEffect(() => {
    if (!token || !params.journeyId || !user) {
      return;
    }
    (async () => {
      const res = await fetchQuestionnaireWithResult({ token, id: params.journeyId ?? '', user });
      setResults(res.results);
      setExpertPlusReviews(res.expertPlusReviews || []);
      setNotBoughtCount(res.notBoughtCount);
      setQuestionnaire(res.questionnaire);
      setRatedResultsCount(res.ratedResultsCount);
      setNotRatedResultsCount(res.notRatedResultsCount);
      setParent(res.parent && res.parentName ? { id: res.parent, name: res.parentName } : undefined);
      const related = res.questionnaire?.related ?? [];
      setRelatedSelectItems(consistJourneysToSelectItems(related));
      setLoading(false);
    })();
  }, [token, params, user]);

  const onClickViewResults = async () => {
    params.journeyId && navigate?.(`/results/${params.journeyId}`);
  };

  const handleUserFeedback = async (
    id: number,
    uerFeedback: { feedback?: JourneyResultFeedback; favorite?: boolean },
  ) => {
    setLoading(true);
    await addUserFeedback({ token, id, userFeedback: uerFeedback });
    setResults(updateResultState(results, id, uerFeedback));
    setLoading(false);
  };

  const handleBuyMoreNames = async () => {
    if (!token || !questionnaire || !user) {
      return;
    }
    const { checkout_url } = await postPaymentsBuyMore({
      questionnaireId: questionnaire.id,
      token,
    });
    window.location.replace(checkout_url);
  };

  const handleGetNewNames = async () => {
    if (!token || !questionnaire || !user) {
      return;
    }
    const idQuestion2part2 = 3;
    const res = await postQuestionnaireCreateChild({
      questionnaireId: questionnaire.id,
      user: user.id,
      token,
      answers: questionnaire.answers,
      lastEditedQuestion: idQuestion2part2,
    });
    res.id && navigate?.(`/questionnaire/${res?.id}`);
  };

  const handleBuyExpertInTheLoop = async () => {
    if (!token || !questionnaire || !user) {
      return;
    }

    const { checkout_url } = await buyExpertInTheLoop({
      questionnaire: questionnaire.id,
      token,
    });
    window.location.replace(checkout_url);
  };

  const handleBuyExpertPlus = async () => {
    if (!token || !questionnaire || !user) {
      return;
    }

    const { checkout_url } = await buyExpertPlus({
      questionnaire: questionnaire.id,
      token,
    });
    window.location.replace(checkout_url);
  };

  return (
    <>
      {isAuthenticated && questionnaire && (
        <Box p={2}>
          <NamingJourneyNavigation
            exportButton={
              questionnaire.seen_results && (
                <ExportPdf
                  expertPlusReviews={expertPlusReviews}
                  results={results}
                  answers={questionnaire.answers}
                  questionnaireName={questionnaire.name}
                />
              )
            }
            backButtonLabel="Back to Dashboard"
            onClickBackButton={() => {
              navigate('/dashboard');
            }}
          />
          <PageContainer>
            <StyledWrapper>
              <StyledNamingJourneyTitle>{questionnaire.name}</StyledNamingJourneyTitle>
              {paymentStatus === 'success' && <CustomAlert variant="success" text="Payment Successful" />}
              {paymentStatus === 'cancelled' && (
                <CustomAlert
                  variant="error"
                  title="Incomplete payment."
                  text="Oops something went wrong! Don't worry, just complete your payment to recieve your results."
                />
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <Typography variant="h1">Results</Typography>
                <Box
                  sx={{
                    marginTop: 2,
                    marginLeft: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  {!questionnaire.failed && !questionnaire.seen_results && (
                    <BadgeJourneyStatus
                      status={questionnaire.has_results ? 'completed' : 'pending'}
                      variant="big"
                      withIcon
                    />
                  )}
                  {questionnaire.failed && <BadgeFailed />}
                  {!questionnaire.failed && questionnaire.common_expert_review_status && (
                    <BadgeExpertReviewStatus status={questionnaire.common_expert_review_status} variant="big" />
                  )}
                </Box>
              </Box>

              <StyledWrapper>
                <Typography variant="body1" marginTop={2} marginBottom={2}>
                  {questionnaire.has_results ? (
                    <></>
                  ) : questionnaire.failed ? (
                    <>
                      We’re sorry that our algorithm failed to complete this time.
                      <br />
                      <br />
                      We are looking into the issue and will reach out to you shortly.
                      <br />
                      <br />
                      Thank you for your patience!
                    </>
                  ) : (
                    <>
                      Next up: your results!
                      <br />
                      <WaitingResultsText>Go refill your coffee, we’ll be back in a few minutes...</WaitingResultsText>
                      <br />
                      Thanks for your payment! Now it’s time for Aesop to get to work. We will begin crafting your
                      personalized Aesop recommendations - names and rationales - designed to celebrate your unique
                      offering in a range of strategic and exciting ways.
                      <br />
                      <br />
                      We want to make sure no stone is left unturned, so it may take up to few minutes to finish
                      designing and compiling your results. But don’t worry, we’ll send you an email when your results
                      are ready to be accessed from the Completed Journeys section of your Aesop Dashboard.
                    </>
                  )}
                </Typography>
              </StyledWrapper>

              {questionnaire.has_results && (
                <>
                  <Typography variant="h5" marginTop={2} marginBottom={2}>
                    Get ready to love naming as much as we do!
                  </Typography>
                  {notRatedResultsCount > 0 && (
                    <Typography variant="h5" marginBottom={2}>
                      You have rated {ratedResultsCount} names. Click View Presentation to reveal and rate{' '}
                      {notRatedResultsCount} more names.
                    </Typography>
                  )}
                  <Box
                    marginTop={2}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: 2,
                      '@media (min-width: 600px)': {
                        flexDirection: 'row',
                      },
                    }}
                  >
                    <StyledButton color="success" onClick={onClickViewResults}>
                      <Icon
                        type={questionnaire.seen_results ? 'interfacePresentationBoard' : 'interfaceEditView'}
                        stroke={colorTones.neutralsWhite}
                      />
                      {'View Presentation'}
                    </StyledButton>
                    <GetMoreNames
                      buttonLabel="Buy more names"
                      modalTitle="Buy more names"
                      modalText={
                        (notBoughtCount ?? 0) === 0
                          ? 'You can take the opportunity to modify certain responses, create a whole new set of names and explore different narrative spaces.'
                          : 'Ready to view another round of names? You can continue with your current responses to the Discovery Journey. Or, you can modify some of your answers to explore different narrative spaces.'
                      }
                      recommendationText=""
                      buttonBuyMoreNamesLabel="Use current responses"
                      buttonGetNewNamesLabel="Modify responses"
                      handleGetNewNames={handleGetNewNames}
                      handleBuyMoreNames={handleBuyMoreNames}
                      disableBuyMore={(notBoughtCount ?? 0) === 0}
                    />
                    <GetExpertInTheLoop
                      handleBuyExpertInTheLoop={handleBuyExpertInTheLoop}
                      handleBuyExpertPlus={handleBuyExpertPlus}
                      isExpertReviewPayed={questionnaire.expert_review_payed}
                      isExpertPlusReviewPayed={questionnaire.expert_plus_payed}
                    />
                  </Box>
                </>
              )}
              {relatedSelectItems.length > 0 && (
                <Box
                  marginTop={2}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <SelectRelateJourneys
                    onSelect={(id) => {
                      navigate(`/journey/${id}`);
                    }}
                    selectLabel="Related Journeys"
                    items={[...relatedSelectItems]}
                  />
                </Box>
              )}
              {parent && (
                <Box
                  marginTop={2}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  <SelectRelateJourneys
                    onSelect={(id) => {
                      navigate(`/journey/${id}`);
                    }}
                    selectLabel="Related Journeys"
                    items={[{ value: parent.id || '', label: parent.name || '' }]}
                  />
                </Box>
              )}
              {expertPlusReviews.length > 0 && (
                <ExpertPlusReviews
                  loading={loading}
                  title="Expert Plus names"
                  expertPlusReviews={expertPlusReviews || []}
                />
              )}
              <RatedResults
                loading={loading}
                title="Rated names"
                results={results || []}
                onUserFeedback={handleUserFeedback}
                favoriteDefaultLabel="Mark as favorite"
                favoredLabel="favorite"
                removeAsFavoriteLabel="Remove as favorite"
              />
            </StyledWrapper>
          </PageContainer>
        </Box>
      )}
    </>
  );
};
