import { env } from 'env';
import { RequestError } from 'sdk/internalApi/request';
import { DtoAnswers } from 'sdk/questionnaire/types';

export type PostQuestionnaireCreateChildRequest = {
  questionnaireId: number;
  name?: string;
  user: number;
  token: string;
  lastEditedQuestion?: number;
  answers: DtoAnswers;
};

type PostQuestionnaireCreateChildResponse = {
  id: number;
  user: number;
  name: string;
  answers: DtoAnswers;
  created: string;
  modified: string;
  parent: number;
  last_edited_question: number;
};

const defaultLastEditedQuestion = 3;

export const postQuestionnaireCreateChild = async (
  request: PostQuestionnaireCreateChildRequest,
): Promise<PostQuestionnaireCreateChildResponse> => {
  const url = `${env.API_URL}/questionnaire/create_child/${request.questionnaireId}/`;
  const answers = request.answers;
  const user = request.user;
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${request.token}`,
    },
    body: JSON.stringify({
      user,
      answers,
      ...(request.name ? { name: request.name } : {}),
      last_edited_question: request.lastEditedQuestion ?? defaultLastEditedQuestion,
    }),
  });

  if (!(res.status >= 200 && res.status < 400)) {
    throw new RequestError(res.status, null);
  }

  return res.json();
};
