import { colorTones } from 'constants/designSystem';

import { Path, Svg } from '@react-pdf/renderer';

const viewBox = '0 0 24 24';
const strokeWidth = '1.1';

export const InterfaceFavoriteHeart: React.FC = () => (
  <Svg width="16" height="16" viewBox={viewBox}>
    <Path
      d="M12 21.3429L2.53719 12.7714C-2.60567 7.62859 4.95433 -2.2457 12 5.74287C19.0458 -2.2457 26.5715 7.66287 21.4629 12.7714L12 21.3429Z"
      fill={colorTones.nightSky80}
      stroke={colorTones.nightSky80}
      strokeWidth={strokeWidth}
      strokeLineCap="round"
    />
  </Svg>
);
