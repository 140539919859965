import { colorTones } from 'constants/designSystem';

import { IconButton, styled } from '@mui/material';

export const StyledRoot = styled('div')({
  position: 'relative',
  overflow: 'hidden',
});

export const StyledItem = styled('div')({
  position: 'absolute',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

export const StyledItemWrapper = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
});

export const StyledIconButton = styled(IconButton)({
  margin: '0 10px',
  position: 'relative',
  backgroundColor: '#494949',
  top: 'calc(50% - 20px) !important',
  color: 'white',
  fontSize: '30px',
  transition: '200ms',
  cursor: 'pointer',
  '&:hover': {
    opacity: '0.6 !important',
  },
});

export const StyledDotsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
});

export const StyledDot = styled('div')({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  backgroundColor: '#ccc',
  margin: '0 5px',
  cursor: 'pointer',
  transition: 'background-color 200ms',
});

export const ActiveDot = styled(StyledDot)({
  backgroundColor: colorTones.ocean200,
});

export const StyledButtonWrapper = styled('div', {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{ $next: boolean; $prev: boolean }>(({ $next, $prev }) => ({
  position: 'absolute',
  height: '100px',
  backgroundColor: 'transparent',
  zIndex: 1,
  top: 'calc(50% - 70px)',
  '&:hover': {
    '& button': {
      backgroundColor: 'black',
      filter: 'brightness(120%)',
      opacity: '0.4',
    },
  },
  ...($next ? { right: 0 } : undefined),
  ...($prev ? { left: 0 } : undefined),
}));
