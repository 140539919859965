import { useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';

type Props = {
  visible: boolean;
  children: ReactNode;
};

export const FadingContainer: React.FC<Props> = ({ visible, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: !isMobile && visible ? 1 : 0,
        display: !isMobile && visible ? 'unset' : 'none',
      }}
      transition={{
        delay: 0.1,
      }}
    >
      {children}
    </motion.div>
  );
};
