import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceDeleteCircle: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.6343 8.36572L8.36572 15.6343"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.36572 8.36572L15.6343 15.6343"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9998 23.1429C18.1538 23.1429 23.1426 18.154 23.1426 12C23.1426 5.84597 18.1538 0.857147 11.9998 0.857147C5.84576 0.857147 0.856934 5.84597 0.856934 12C0.856934 18.154 5.84576 23.1429 11.9998 23.1429Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
