import { colorTones } from 'constants/designSystem';

import { Box, Card, CardContent, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FlexBoxRow } from 'components/atoms';
import { Icon } from 'components/atoms/Icons';
import { Carousel } from 'components/molecules';
import React from 'react';

type ExampleJourneyResult = {
  name: string;
  rationale: string;
};

type ExampleCaseStudyData = {
  icon: React.ReactNode;
  caseStudy: string;
  examples: ExampleJourneyResult[];
};

const exampleData: ExampleCaseStudyData[] = [
  {
    icon: <Icon type="beachUmbrella" stroke={colorTones.primaryNightSky} />,
    caseStudy: 'A beachwear boutique',
    examples: [
      {
        name: 'Wavelength',
        rationale:
          'Wavelength is evocative of the relaxed vibes and carefree attitude that ' +
          'customers can expect when wearing this clothing. The word also implies that ' +
          'customers can find items that match their own personal vibe - like how ' +
          'different wavelengths make up a sound. Furthermore, Wavelength reflects ' +
          'vintage style and its connection to surfers who seek cool and breezy styles.',
      },
      {
        name: 'Sunlit',
        rationale:
          'Sunlit is an ideal name for this beachy clothing offering because it reflects the ' +
          'feeling of a carefree, relaxed beach day. The name captures the essence of ' +
          'sun-soaked days spent on the beach with friends and family. The word Sunlit ' +
          'implies positivity and happiness, which is exactly what customers will ' +
          'experience when they shop this collection. Additionally, this name is unique and ' +
          'memorable, making it easy for customers to recall.',
      },
      {
        name: 'Slung',
        rationale:
          'Slung is a great name for this offering because it conveys a laid-back, ' +
          'carefree vibe. The word has a hip, youthful sound and can evoke images of ' +
          'clothing casually draped over the body, which is perfect for beachy styles. ' +
          'The name also has an element of playfulness and fun that will be attractive to ' +
          'customers looking for casual yet stylish clothing.',
      },
      {
        name: 'Phoebus',
        rationale:
          'Phoebus is a great name for this offering because it evokes the warmth and ' +
          'brightness of the beach. The name comes from Greek mythology, which tells the ' +
          'story of Phoebus, the sun god who drove his shining chariot across the sky each ' +
          'day. This metaphor perfectly captures the special feeling of being at the beach, ' +
          'where one can take in the warmth and beauty of nature.',
      },
    ],
  },
  {
    icon: <Icon type="babyFace" stroke={colorTones.primaryNightSky} strokeWidth={0.4} />,
    caseStudy: 'A child care facility',
    examples: [
      {
        name: 'Cherish',
        rationale:
          'Cherish is a great name for this offering because it reflects the care and ' +
          'dependability customers can expect when they use the service. The word ' +
          'cherish evokes feelings of trust, comfort and security. It is also a reminder of ' +
          'the special bond between parents and their children. The name Cherish will ' +
          'remind customers of the importance of giving their children the best care and ' +
          'protection so they can grow up with peace and happiness.',
      },
      {
        name: 'Unwound Valley',
        rationale:
          'Unwound Valley speaks to peace and assurance. Unwound implies a sense of ' +
          'relaxation and relief for parents because their children are being taken care of ' +
          'with compassion, dependability, and professionalism. The word valley ' +
          'reinforces these feelings by suggesting a place of security and safety. ' +
          'Together, these words signify a tranquil environment where parents can trust ' +
          'their children will be in good hands.',
      },
      {
        name: 'Hugful',
        rationale:
          'Hugful conveys a strong sense of warmth and security. It speaks to the ' +
          'essential element that parents are looking for in childcare, namely that their ' +
          'children will receive excellent care with compassion and dependability. The ' +
          'word “-ful" also implies that the offering is full of these positive traits, giving ' +
          'parents the peace of mind they need when trusting someone else with their ' +
          'child’s safety.',
      },
      {
        name: 'Faithful Heart',
        rationale:
          'Faithful Heart encapsulates the values of dependability, professionalism, and ' +
          'compassion at the core of this offering. It emphasizes the trustworthiness and ' +
          'reliability of the service, as well as its heartwarming care for children. The ' +
          'name also speaks to concerned parents who want peace of mind when ' +
          'entrusting their children to professional care services. The combination of ' +
          "'faithful' and 'heart' is a powerful one that conveys both warmth and security.",
      },
    ],
  },
  {
    icon: <Icon type="cleanerStars" stroke={colorTones.primaryNightSky} />,
    caseStudy: 'An organic, multi-purpose cleaner',
    examples: [
      {
        name: 'Homegrown',
        rationale:
          'The name Homegrown evokes the idea of natural, organic ingredients that ' +
          'are locally sourced. It implies natural cleaning efficiency without harsh ' +
          'chemicals or artificial ingredients. The name instills a sense of trust and ' +
          'reliability, as if you were using something made with time-tested family ' +
          'recipes. Additionally, it speaks to environmental responsibility which is ' +
          'increasingly important to today’s consumer.',
      },
      {
        name: 'Pearly',
        rationale:
          'The name Pearly is a great fit for this offering because it speaks to the special ' +
          'qualities of the surfaces that this product is intended to clean. Pearly evokes ' +
          'thoughts of smooth, shiny surfaces that are clean and inviting. This ' +
          'encompasses what the product is designed to do: leave surfaces looking and ' +
          'feeling like polished pearls. Additionally, the name Pearly provides a ' +
          'memorable and unique moniker for the product, helping it stand out from its ' +
          'competitors.',
      },
      {
        name: 'Blue Spigot',
        rationale:
          'The name Blue Spigot symbolizes a powerful, efficient, and refreshing ' +
          'experience. Blue represents the freshness and purity of the cleaner, while ' +
          'Spigot symbolizes powerful and effortless cleansing. Together, they create ' +
          'an image of a sparkling clean surface that will leave users feeling satisfied ' +
          'and relaxed. Furthermore, Blue Spigot has a fun and lively tone that speaks ' +
          'to this offering’s powerful shine.',
      },
      {
        name: 'Seaglass',
        rationale:
          'Seaglass is the perfect name for this cleaning product because it evokes a ' +
          'sense of calmness and clarity. Just like how the ocean can be soothing and ' +
          'refreshing, Seaglass will have the same effect on surfaces. The name also ' +
          'implies that surfaces will be left sparkling clean and free of dirt, grime, and ' +
          'other debris. Additionally, it has a modern and hip sound that appeals to a ' +
          'wide range of customers.',
      },
    ],
  },
  {
    icon: <Icon type="chatBubblesTherapist" stroke={colorTones.primaryNightSky} strokeWidth={0.2} />,
    caseStudy: 'A speech therapist',
    examples: [
      {
        name: 'Phrased',
        rationale:
          'The name Phrased speaks to putting thoughts and ideas into words to express ' +
          'oneself clearly. The name captures the essence of speech therapy by ' +
          'emphasizing the importance of using words to communicate and how doing ' +
          'so can free one from feeling misunderstood. Moreover, Phrased suggests that ' +
          'one has the ability to shape their thoughts into meaningful sentences, which ' +
          'can result in successful communication.',
      },
      {
        name: 'Syllable',
        rationale:
          'Syllable captures the essence of speech therapy. Speech therapy is all about ' +
          'breaking down language into basic components in order to build a stronger, ' +
          'more effective foundation of communication. Syllable refers to the smallest ' +
          'unit of sound in a language, making it an excellent metaphor for the purpose ' +
          'and goals of speech therapy. Plus, syllables are also associated with rhythm ' +
          'and flow, which emphasizes the importance of speaking clearly and ' +
          'effectively.',
      },
      {
        name: 'ThirtyPebbles',
        rationale:
          'ThirtyPebbles conveys the idea that speech therapy is about small but ' +
          'meaningful steps. Each step taken toward improving communication is its ' +
          'own small accomplishment, and the accumulation can lead to much bigger ' +
          'change. This metaphor also implies that, with enough effort and dedication, ' +
          'any individual can make positive progress. Ultimately, ThirtyPebbles ' +
          'suggests that even seemingly insignificant actions can lead to great leaps ' +
          'forward.',
      },
      {
        name: 'Audible Aura',
        rationale:
          'Audible Aura conveys a sense of confidence and pride, which is what users ' +
          'will experience when they use the service. The word Aura suggests a feeling ' +
          'of power, beauty, and grace - all qualities that are associated with successful ' +
          'speech. The word Audible indicates clarity and accuracy in pronunciation, ' +
          'Put together, Audible Aura implies that customers will be able to achieve ' +
          'effective communication with clarity and success.',
      },
    ],
  },
  {
    icon: <Icon type="graduateCap" stroke={colorTones.primaryNightSky} />,
    caseStudy: 'An online philosophy course',
    examples: [
      {
        name: 'Woken Future',
        rationale:
          'Woken Future encapsulates the idea of education and transformation. The ' +
          'word Woken implies awakening new understanding, while Future suggests ' +
          'progressing to new horizons. Together, the name conveys that customers will ' +
          'gain an enlightened perspective and the tools to look to the Future with ' +
          'clarity and knowledge. This name also evokes a sense of excitement and ' +
          'motivation.',
      },
      {
        name: 'Amethyst',
        rationale:
          'The name Amethyst is an excellent choice to represent a philosophy course ' +
          'because it represents the idea of having tools to help students gain wisdom ' +
          'and knowledge. Amethysts are gems known for their spiritual properties and ' +
          'represent the belief that wisdom can be attained through contemplation and ' +
          'study. Amethyst is also known for its calming properties, which is invaluable ' +
          'when studying complex philosophical concepts.',
      },
      {
        name: 'Studium',
        rationale:
          'Studium conveys the idea of a passionate pursuit of knowledge. The Latin ' +
          'root of the word, studere, means to devote oneself to study or to have an ' +
          'eagerness for learning, which is what a philosophy course is all about. By ' +
          'using the metaphor of passionate learning, Studium emphasizes that this ' +
          "course is not only about gaining knowledge, but also about challenging one's " +
          'own beliefs to gain new perspectives.',
      },
      {
        name: 'Geode',
        rationale:
          'The name Geode is a great fit for this philosophy course because it captures ' +
          'the essence of what makes this course special - its ability to open up the ' +
          'minds of its students and reveal hidden truths. Just like a Geode, which on ' +
          'the outside appears ordinary, but on the inside contains a hidden gem, a ' +
          'philosophy course allows students to dig deeper into their own thought ' +
          'processes and uncover fundamental truths.',
      },
    ],
  },
  {
    icon: <Icon type="dumbbell" stroke={colorTones.primaryNightSky} />,
    caseStudy: 'A fitness trainer',
    examples: [
      {
        name: 'Kinetics',
        rationale:
          'Kinetics conveys motion and activity – two key components of fitness. The ' +
          'name implies momentum and growth, suggesting the program will empower ' +
          'users to reach their goals. Additionally, it evokes a sense of energy and ' +
          'vibrancy, which will motivate users to stay engaged and invested in their ' +
          'fitness journey. Finally, the word is easy to remember and has a modern feel, ' +
          'making it attractive to those looking for an up-to-date approach to health and ' +
          'wellness.',
      },
      {
        name: 'Pounce',
        rationale:
          'Pounce implies the swiftness and agility that is so important to improving ' +
          'performance. It invokes a feeling of strength, speed, and determination, which is ' +
          'exactly what someone needs to reach their fitness goals. Pounce also implies a ' +
          'sense of urgency, which is key to any successful training program. Finally, ' +
          'Pounce reflects confidence and accomplishment - two things that everyone strives ' +
          'for when looking to improve their physical performance.',
      },
      {
        name: 'Grown Impact',
        rationale:
          'Grown Impact is a great name for this fitness and nutrition offering because ' +
          'it speaks to the idea of experiencing greater potential and peak performance. ' +
          'The word Grown implies reaching greater heights – higher levels of energy, ' +
          'strength, and endurance – while the word Impact conveys the notion of ' +
          'making an Impact in terms of achieving better results. Through Grown ' +
          'Impact, customers can look forward to maximizing their performance and ' +
          'achieving elite levels of success.',
      },
      {
        name: 'Mightily',
        rationale:
          'Mightily is an ideal name for a fitness offering because it captures the idea that ' +
          'with hard work and dedication, it is possible to become stronger and more ' +
          'powerful. The word Mightily implies that users of the product can achieve great ' +
          'results with effort and determination, inspiring them to push their limits and be ' +
          'the best versions of themselves. Additionally, Mightily has a fun and positive ' +
          'connotation, making it an appealing name for a fitness offering.',
      },
    ],
  },
];

const StyledContainer = styled(Box)({
  borderTop: `4px solid ${colorTones.sunflower200}`,
  background: colorTones.neutralsSlightGray,
  boxShadow: '0px 2px 6px rgba(0, 13, 46, 0.14)',
  minWidth: '150px',
  marginTop: '50px',
  padding: '20px',
  top: `118px`,
  marginBottom: '56px',
  '@media (min-width: 600px)': {
    position: 'sticky',
    marginTop: '2px',
  },
});

const StyledBaseTextContainer = styled(Box)({
  fontFamily: 'Work Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  textAlign: 'left',
});

const StyledMainTitle = styled(StyledBaseTextContainer)({
  justifyContent: 'center',
  fontSize: '18px',
  lineHeight: '24px',
});

const StyledCard = styled(Card)({
  marginTop: '20px',
  marginBottom: 2,
});

const StyledCardTitle = styled(StyledBaseTextContainer)({
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '150%',
  letterSpacing: '0.04em',
  textTransform: 'uppercase',
});

const StyledCardText = styled(StyledBaseTextContainer)({
  fontWeight: 400,
  fontSize: '12px',
  fontStyle: 'italic',
  lineHeight: '150%',
});

const StyledGrid = styled(Grid)({
  '@media (min-width: 600px)': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    columnGap: '20px',
  },
});

export const ExampleResults: React.FC = () => {
  return (
    <Carousel
      navButtonsProps={{
        style: {
          backgroundColor: 'unset',
          borderRadius: '8px',
          padding: '8px 8px 0px 16px',
        },
      }}
      navButtonsWrapperProps={{
        style: {
          width: 'fit-content',
          height: 'fit-content',
          bottom: '8px',
          top: 'unset',
        },
      }}
      sx={{
        paddingBottom: '65px',
        margin: '10px',
        '@media (min-width: 600px)': {
          paddingBottom: '18px',
        },
      }}
    >
      {exampleData.map((item) => (
        <StyledContainer
          key={item.caseStudy}
          sx={{
            paddingBottom: '40px',
          }}
        >
          <FlexBoxRow>
            <Box sx={{ marginRight: 2, width: '40px', height: '40px', borderRadius: '50%', background: '#fff' }}>
              <Box sx={{ m: 1 }}>{item.icon}</Box>
            </Box>
            <Box sx={{ marginTop: 1 }}>
              <StyledMainTitle>{item.caseStudy}</StyledMainTitle>
            </Box>
          </FlexBoxRow>
          <StyledGrid>
            {item.examples.map((example) => (
              <StyledCard key={example.name}>
                <CardContent>
                  <FlexBoxRow>
                    <Box sx={{ paddingTop: 1, paddingBottom: 1, width: '100%' }}>
                      <StyledCardTitle>{example.name}</StyledCardTitle>
                    </Box>
                  </FlexBoxRow>
                  <StyledCardText>{example.rationale}</StyledCardText>
                </CardContent>
              </StyledCard>
            ))}
          </StyledGrid>
        </StyledContainer>
      ))}
    </Carousel>
    // </>
  );
};
