import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceAddSquare: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M8 4.57143V11.4286"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.57153 8H11.4287"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 0.571426H4.0001C2.10656 0.571426 0.571533 2.10645 0.571533 4V12C0.571533 13.8935 2.10656 15.4286 4.0001 15.4286H12.0001C13.8937 15.4286 15.4287 13.8935 15.4287 12V4C15.4287 2.10645 13.8937 0.571426 12.0001 0.571426Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
