import { Grid } from '@mui/material';
import { FormQuestionText } from 'components/atoms';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

export const MultipleInputItemFormContainer: React.FC<Props> = ({ children }) => (
  <Grid item>
    <FormQuestionText variant="row">{children}</FormQuestionText>
  </Grid>
);
