import { colorTones, fonts } from 'constants/designSystem';

import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Icon } from 'components/atoms';
import React, { useState } from 'react';

type Props = {
  favoriteDefaultLabel: string;
  favoredLabel: string;
  removeAsFavoriteLabel: string;
  favorite: boolean;
  onClickFavorite?: (favorite: boolean) => void;
};

const StyledWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '6px 12px',
  gap: '6px',
  borderRadius: '24px',
  '&:hover': {
    cursor: 'pointer',
  },
});

const StyledLabel = styled(Typography)({
  fontFamily: fonts.primeFont,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
});

const getBackgroundColor = (favorite: boolean, hovered: boolean) => {
  if ((!favorite && !hovered) || (favorite && hovered)) {
    return colorTones.neutralsSlightGray;
  }
  if (!favorite && hovered) {
    return colorTones.plant10;
  }
  return colorTones.neutralsWhite;
};

type GetLabelRequest = {
  favoriteDefaultLabel: string;
  favoredLabel: string;
  removeAsFavoriteLabel: string;
  favorite: boolean;
  hovered: boolean;
};

const getLabel = ({
  hovered,
  favorite,
  favoriteDefaultLabel,
  favoredLabel,
  removeAsFavoriteLabel,
}: GetLabelRequest) => {
  if (!favorite) {
    return favoriteDefaultLabel;
  }

  if (favorite && hovered) {
    return removeAsFavoriteLabel;
  }
  return favoredLabel;
};

const getFontColor = (favorite: boolean, hovered: boolean) => {
  if (!favorite && hovered) {
    return colorTones.plant200;
  }
  if (favorite && hovered) {
    return colorTones.primaryNightSky;
  }
  return colorTones.nightSky200;
};

export const FavoriteFeedback: React.FC<Props> = ({
  favorite,
  favoriteDefaultLabel,
  favoredLabel,
  removeAsFavoriteLabel,
  onClickFavorite,
}) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const handleClick = () => {
    onClickFavorite?.(!favorite);
  };
  return (
    <StyledWrapper
      onClick={handleClick}
      sx={{
        background: getBackgroundColor(favorite, hovered),
        ...(favorite && hovered ? { border: `1px solid ${colorTones.neutralsSlightGray}` } : {}),
      }}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <Icon
        width={16}
        height={16}
        type="interfaceFavoriteHeart"
        stroke={colorTones.primaryRed80}
        fill={favorite ? colorTones.primaryRed80 : 'none'}
      />
      <StyledLabel
        sx={{
          color: getFontColor(favorite, hovered),
        }}
      >
        {getLabel({ favorite, favoriteDefaultLabel, favoredLabel, removeAsFavoriteLabel, hovered })}
      </StyledLabel>
    </StyledWrapper>
  );
};
