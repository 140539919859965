import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceUploadButton1: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.0769 8.92308H18.6154C18.8194 8.92308 19.0151 9.00412 19.1593 9.14838C19.3036 9.29264 19.3846 9.4883 19.3846 9.69231V21.2308C19.3846 21.4348 19.3036 21.6304 19.1593 21.7747C19.0151 21.919 18.8194 22 18.6154 22H4.76923C4.56522 22 4.36956 21.919 4.2253 21.7747C4.08104 21.6304 4 21.4348 4 21.2308V9.69231C4 9.4883 4.08104 9.29264 4.2253 9.14838C4.36956 9.00412 4.56522 8.92308 4.76923 8.92308H6.30769"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6924 12.7692V2"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.61523 5.07692L11.6922 2L14.7691 5.07692"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
