import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const TravelMapEarth1: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox }) => (
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9998 23.1428C18.1538 23.1428 23.1426 18.154 23.1426 12C23.1426 5.84594 18.1538 0.857117 11.9998 0.857117C5.84576 0.857117 0.856934 5.84594 0.856934 12C0.856934 18.154 5.84576 23.1428 11.9998 23.1428Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.71387 16.2857H4.71387C5.50952 16.2857 6.27258 15.9696 6.83519 15.407C7.3978 14.8444 7.71387 14.0813 7.71387 13.2857V10.7143C7.71387 9.91861 8.02994 9.15554 8.59255 8.59293C9.15516 8.03033 9.91822 7.71425 10.7139 7.71425C11.5095 7.71425 12.2726 7.39818 12.8352 6.83558C13.3978 6.27297 13.7139 5.5099 13.7139 4.71425V0.977112"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.1424 11.8285C22.2841 11.3832 21.3323 11.1482 20.3653 11.1428H16.7139C15.9182 11.1428 15.1552 11.4589 14.5925 12.0215C14.0299 12.5841 13.7139 13.3472 13.7139 14.1428C13.7139 14.9385 14.0299 15.7015 14.5925 16.2641C15.1552 16.8268 15.9182 17.1428 16.7139 17.1428C17.2822 17.1428 17.8272 17.3686 18.2291 17.7705C18.631 18.1723 18.8567 18.7174 18.8567 19.2857V20.7771"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
