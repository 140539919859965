import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const InterfaceValidationCheck: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.571533 9.77142L3.69153 13.7829C3.79681 13.9196 3.93171 14.0308 4.0861 14.108C4.24049 14.1852 4.41036 14.2264 4.58296 14.2286C4.75278 14.2305 4.92091 14.1946 5.07511 14.1235C5.22931 14.0523 5.36571 13.9476 5.47439 13.8171L15.4287 1.77142"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
