import { colorTones, fonts } from 'constants/designSystem';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon, SvgLogoSmall } from 'components/atoms/Icons';
import React from 'react';

type Props = {
  title: string;
  tipType: 'expert' | 'strategy';
};

const StyledStrategyInnerBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  paddingBottom: 2,
});

const StyledStrategyTitle = styled(Typography)({
  fontFamily: fonts.primeFont,
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '10px',
  lineHeight: '150%',
  letterSpacing: '0.04em',
  textTransform: 'uppercase',
});

const StyledTitleWrapper = styled(Box)({
  width: 'calc(100% - 38px)',
  paddingLeft: '8px',
});

export const TipTitleSection: React.FC<Props> = ({ title, tipType }) => (
  <StyledStrategyInnerBox>
    {tipType === 'expert' ? (
      <Icon type="interfaceFavoriteHeart" fill={colorTones.nightSky200} stroke={colorTones.nightSky200} />
    ) : (
      <SvgLogoSmall width={37.79} height={24} />
    )}
    <StyledTitleWrapper>
      <StyledStrategyTitle>{title}</StyledStrategyTitle>
    </StyledTitleWrapper>
  </StyledStrategyInnerBox>
);
