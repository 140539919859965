import { Grid } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

export const MultipleInputFormContainer: React.FC<Props> = ({ children }) => (
  <Grid container direction="row" alignItems="center">
    {children}
  </Grid>
);
