import 'moment-timezone';

import { useAuth0 } from '@auth0/auth0-react';
import { PageContainer } from 'components/atoms';
import { DashboardCompletedJourneySection, DashboardDraftSection, DashboardWelcomeSection } from 'components/organism';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContinueQuestionnaireType, getContinue, getQuestionnaires, getUser } from 'sdk/internalApi';
import { isValidStringAnswer, isValidStringAnswers } from 'sdk/questionnaire';
import {
  DtoAnswer0,
  DtoAnswer1,
  DtoAnswer1Part2,
  DtoAnswer2,
  DtoAnswer3,
  DtoAnswer4,
  DtoAnswer5,
  DtoAnswer6,
  DtoAnswer7,
  DtoAnswer8,
  DtoAnswers,
} from 'sdk/questionnaire/types';
import { Draft, Journey, JourneyStatus, User } from 'sdk/types';
import { getFormattedDate } from 'utils';

const isCompletedAnswer0 = ({ CN1, CN2 }: DtoAnswer0) => isValidStringAnswer(CN1) && isValidStringAnswer(CN2);
const isCompletedAnswer1 = ({ RA, N1 }: DtoAnswer1) => isValidStringAnswer(N1) && isValidStringAnswer(RA);
const isCompletedAnswer1_2 = ({ V1, N2 }: DtoAnswer1Part2) => isValidStringAnswer(V1) && isValidStringAnswer(N2);
const isCompletedAnswer2 = ({ V2 }: DtoAnswer2) => isValidStringAnswer(V2);
const isCompletedAnswer3 = ({ FA, FN }: DtoAnswer3) => isValidStringAnswers(FA) && isValidStringAnswers(FN);
const isCompletedAnswer4 = ({ DRA, DN }: DtoAnswer4) => isValidStringAnswers(DRA) && isValidStringAnswers(DN);
const isCompletedAnswer5 = ({ EA, EN }: DtoAnswer5) => isValidStringAnswers(EA) && isValidStringAnswers(EN);
const isCompletedAnswer6 = ({ EDA }: DtoAnswer6) => isValidStringAnswers(EDA);
const isCompletedAnswer7 = ({ MA }: DtoAnswer7) => isValidStringAnswers(MA);
const isCompletedAnswer8 = ({ UCA, UCN }: DtoAnswer8) => isValidStringAnswers(UCA) && isValidStringAnswers(UCN);
const calculateCompletion = ({
  CN1,
  CN2,
  RA,
  N1,
  N2,
  FA,
  FN,
  DRA,
  DN,
  EA,
  EN,
  EDA,
  V,
  MA,
  UCA,
  UCN,
  offering,
}: DtoAnswers): number => {
  const V1: string = V?.[0] || '';
  const V2: string = V?.[1] || '';
  const validAmount = [
    isCompletedAnswer0({ CN1, CN2 }),
    isCompletedAnswer1({ RA, N1, offering }),
    isCompletedAnswer1_2({ V1, N2 }),
    isCompletedAnswer2({ V2 }),
    isCompletedAnswer3({ FA, FN }),
    isCompletedAnswer4({ DRA, DN }),
    isCompletedAnswer5({ EA, EN }),
    isCompletedAnswer6({ EDA }),
    isCompletedAnswer7({ MA }),
    isCompletedAnswer8({ UCA, UCN }),
  ].filter((item) => item).length;

  return Math.round((validAmount / 10) * 100);
};

const getUserTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const createDate = (dateString: string, timeZone: string): Date => {
  const formattedDate = moment.tz(dateString, timeZone).format('YYYY-MM-DD HH:mm:ss');
  return new Date(formattedDate);
};

const getViewDraftsList = async (token: string, next?: string) => {
  const userTimeZone = getUserTimeZone();
  const draftResponse = await getContinue({ token, next });

  const drafts: Draft[] = draftResponse.results.map(({ answers, id, name, modified, created, type }): Draft => {
    const modifiedDateWithTimezone = createDate(modified, userTimeZone);
    const createdDateWithTimezone = createDate(created, userTimeZone);
    const formattedModifiedDate = getFormattedDate(modifiedDateWithTimezone);
    const formattedCreatedDate = getFormattedDate(createdDateWithTimezone, true);
    return {
      id,
      name,
      completion: calculateCompletion(answers),
      modified: formattedModifiedDate,
      created: formattedCreatedDate,
      type,
    };
  });

  return {
    drafts,
    next: draftResponse.next,
  };
};

const getJourneyStatus = (hasResults?: boolean | null): JourneyStatus | undefined => {
  if (typeof hasResults === 'boolean') {
    return hasResults ? 'completed' : 'pending';
  }
};

const getViewQuestionnairesList = async (token: string, next?: string) => {
  const userTimeZone = getUserTimeZone();
  const res = await getQuestionnaires({ token, payed: true, next });
  const journeys = res?.results?.map(
    ({ id, name, has_results, modified, created, failed, seen_results, common_expert_review_status }) => {
      const status = getJourneyStatus(has_results);
      const modifiedDateWithTimezone = createDate(modified, userTimeZone);
      const createdDateWithTimezone = createDate(created, userTimeZone);

      return {
        id,
        name,
        ...(status ? { status } : {}),
        failed,
        seenResults: seen_results,
        commonExpertReviewStatus: common_expert_review_status,
        modified: getFormattedDate(modifiedDateWithTimezone),
        created: getFormattedDate(createdDateWithTimezone),
      };
    },
  );
  return {
    next: res?.next,
    journeys,
  };
};

const getGreetingBasedOnTime = (date: Date): string => {
  const hour = date.getHours();

  if (hour >= 5 && hour < 12) {
    return 'Good morning,';
  }

  if (hour >= 12 && hour < 17) {
    return 'Good afternoon,';
  }

  return 'Good evening,';
};

const dashboardStateToIntroText = {
  emptyState: (
    <>
      Welcome to your dashboard! <br />
      Think of this as homebase for all your naming projects. Start a new project. Continue a currently active project.
      Revisit the outputs from a completed project. Everything will be automatically stored right here for you to access
      and review at any time.
    </>
  ),
  activeState: (
    <>
      Welcome to your dashboard! <br />
      Think of this as homebase for all your naming projects. Start a new project. Continue a currently active project.
      Revisit the outputs from a completed project. Everything will be automatically stored right here for you to access
      and review at any time.
    </>
  ),
};
export const DashboardPage: React.FC = () => {
  const startJourneyLabel = 'Start an Aesop Journey';
  const navigate = useNavigate();
  const handleNewJourney = () => {
    navigate('/questionnaire');
  };
  const [introText, setIntroText] = useState(<></>);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [user, setUser] = useState<User>();
  const [draftsResult, setDraftsResult] = useState<Draft[]>([]);
  const [journeysResult, setJourneysResult] = useState<Journey[]>([]);
  const [nextDraft, setNextDraft] = useState<string | undefined>();
  const [nextJourneys, setNextJourneys] = useState<string | undefined>();
  const [token, setToken] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      const accessToken = token || (await getAccessTokenSilently());
      if (!token) {
        setToken(accessToken);
        return;
      }
      const [draftsList, questionnairesList, userData] = await Promise.all([
        getViewDraftsList(accessToken),
        getViewQuestionnairesList(accessToken),
        getUser({ token }),
      ]);
      setJourneysResult(questionnairesList.journeys);
      setNextJourneys(questionnairesList.next);
      setDraftsResult(draftsList.drafts);
      setNextDraft(draftsList.next);
      setUser(userData);
      const isActiveState = questionnairesList.journeys.length > 0 || draftsList.drafts.length > 0;
      setIntroText(isActiveState ? dashboardStateToIntroText.activeState : dashboardStateToIntroText.emptyState);
    })();
  }, [setDraftsResult, getAccessTokenSilently, setToken, token, setJourneysResult]);

  const onClickItem = (id: number, itemType: ContinueQuestionnaireType) => {
    const baseUrl = `/questionnaire/${id}`;
    const redirectUrl = itemType === 'draft' ? baseUrl : `${baseUrl}?result=cancelled`;
    navigate(redirectUrl);
  };

  const handleLoadMoreJourneys = async () => {
    if (!nextJourneys || !token) {
      return;
    }
    const { journeys, next } = await getViewQuestionnairesList(token, nextJourneys);
    setJourneysResult([...journeysResult, ...journeys]);
    setNextJourneys(next);
  };

  const handleLoadMoreDrafts = async () => {
    if (!nextDraft || !token) {
      return;
    }
    const { drafts, next } = await getViewDraftsList(token, nextDraft);
    setDraftsResult([...draftsResult, ...drafts]);
    setNextDraft(next);
  };

  const isJourneyHasNotSeenResults = (journey: Journey | undefined) => {
    return journey && !journey.seenResults && !journey.failed && journey.status === 'completed';
  };

  const onClickJourney = (id: number) => {
    const journey = journeysResult.find((journey) => journey.id === id);

    if (isJourneyHasNotSeenResults(journey)) {
      navigate(`/results/${id}`);
      return;
    }

    navigate(`/journey/${id}`);
  };

  return (
    (isAuthenticated && (
      <PageContainer>
        <DashboardWelcomeSection
          text={introText}
          title={`${getGreetingBasedOnTime(new Date())} ${user?.first_name || ''}`}
          startJourneyLabel={startJourneyLabel}
          handleStartJourney={handleNewJourney}
        />
        <DashboardDraftSection
          title="In Progress Journeys"
          drafts={draftsResult}
          startJourneyLabel={startJourneyLabel}
          noDraftsPlaceHolder="You currently have no Journeys in progress. In the future, all In Progress Journeys will appear here."
          handleStartJourney={handleNewJourney}
          onClickItem={onClickItem}
          loadMoreLabel="Load more"
          handleLoadMore={handleLoadMoreDrafts}
          loadMoreVisible={Boolean(nextDraft)}
        />
        <DashboardCompletedJourneySection
          noJourneyPlaceHolder="You currently have no Completed Journeys. In the future, all Completed Journeys will appear here."
          title="Completed Journeys"
          completedJourneys={journeysResult}
          onClickJourney={onClickJourney}
          loadMoreLabel="Load more"
          handleLoadMore={handleLoadMoreJourneys}
          loadMoreVisible={Boolean(nextJourneys)}
        />
      </PageContainer>
    )) ||
    null
  );
};
