import * as React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const MailChatBubbleCensorSquare: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.71408 21.4286L0.856934 23.1429L2.57122 18V2.57143C2.57122 2.11677 2.75183 1.68074 3.07332 1.35925C3.39481 1.03776 3.83085 0.857143 4.28551 0.857143H21.4284C21.883 0.857143 22.3191 1.03776 22.6405 1.35925C22.962 1.68074 23.1426 2.11677 23.1426 2.57143V19.7143C23.1426 20.1689 22.962 20.605 22.6405 20.9265C22.3191 21.248 21.883 21.4286 21.4284 21.4286H7.71408Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4282 7.28572L10.7139 15"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.57122 7.28572L6.85693 15"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.57129 13.2857H12.857"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6742 13.6559C17.7692 13.5923 17.8808 13.558 17.995 13.5572C18.1505 13.5604 18.2988 13.6236 18.4088 13.7336C18.5189 13.8436 18.5821 13.9919 18.5853 14.1474C18.5844 14.2617 18.5501 14.3732 18.4866 14.4683C18.4222 14.5646 18.3308 14.6397 18.2237 14.684C18.1167 14.7283 17.9989 14.7399 17.8853 14.7173C17.7717 14.6947 17.6673 14.6389 17.5854 14.557C17.5035 14.4751 17.4477 14.3707 17.4251 14.2571C17.4025 14.1435 17.4141 14.0257 17.4585 13.9187C17.5028 13.8117 17.5779 13.7202 17.6742 13.6559Z"
      fill={stroke}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.42822 9H13.7139"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M18 6V10.2857" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
