import React from 'react';

import { SvgImageProps } from './types';

type Props = SvgImageProps;

export const EntertainmentNewsPaper: React.FC<Props> = ({ stroke, strokeWidth, width, height, viewBox, fill }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4287 5.14286V12.5714C15.4287 12.9503 15.2782 13.3137 15.0103 13.5816C14.7423 13.8495 14.379 14 14.0001 14M14.0001 14C13.6212 14 13.2579 13.8495 12.99 13.5816C12.722 13.3137 12.5715 12.9503 12.5715 12.5714V2.57143C12.5715 2.41988 12.5113 2.27453 12.4042 2.16737C12.297 2.0602 12.1517 2 12.0001 2H1.14296C0.99141 2 0.846064 2.0602 0.738901 2.16737C0.631737 2.27453 0.571533 2.41988 0.571533 2.57143V12.8571C0.571533 13.1602 0.691941 13.4509 0.906268 13.6653C1.1206 13.8796 1.41129 14 1.71439 14H14.0001Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 11.1429H9.14286"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.14286 4.85712H4V7.71426H9.14286V4.85712Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
