import { colorTones } from 'constants/designSystem';

import { Box, SxProps, Typography } from '@mui/material';
import { Icon } from 'components/atoms/Icons';
import { FlatButtonWithIcon } from 'components/molecules';
import { ModalWindow } from 'components/organism';
import { EditNamingJourneyForm } from 'components/questionnaire/forms';
import { useHandleModal } from 'hooks';
import * as React from 'react';

type Props = {
  title: string;
  modalTitle?: string;
  modalSubTitle?: string;
  handleSubmit?: (title: string) => void;
  disableEditName?: boolean;
  sx?: SxProps;
};

const iconEdit = (
  <Box sx={{ paddingTop: '4px' }}>
    <Icon
      type="interfaceEditWrite2"
      stroke={colorTones.plant200}
      strokeWidth="1.1"
      width={24}
      height={24}
      viewBox="0 0 24 24"
    />
  </Box>
);

export const EditNamingJourney: React.FC<Props> = ({
  title,
  modalTitle,
  modalSubTitle,
  disableEditName = false,
  sx,
  handleSubmit,
}) => {
  const { openModal, handleOpen, handleClose } = useHandleModal();

  const onSubmitForm = (title: string) => {
    handleClose();
    handleSubmit?.(title);
  };

  return (
    <Box sx={{ ...sx }}>
      <FlatButtonWithIcon disabled={disableEditName} onClick={handleOpen} text={title} icon={iconEdit} />

      <ModalWindow
        open={openModal}
        onClose={handleClose}
        variant="dark"
        ariaLabelledby={modalTitle && 'modal-edit-naming-journey-title'}
        ariaDescribedby={modalSubTitle && 'modal-edit-naming-journey-description'}
        title={modalTitle}
      >
        {modalSubTitle && <Typography variant="h5">{modalSubTitle}</Typography>}
        <EditNamingJourneyForm title={title} onClick={onSubmitForm} />
      </ModalWindow>
    </Box>
  );
};
