import { colorTones } from 'constants/designSystem';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledLinearProgress = styled(LinearProgress)({
  maxHeight: 4,
  width: '100%',
  p: 0,
  m: 0,
  minWidth: '100%',
});

type Props = {
  completion: number;
  colorBar?: string;
  colorPrimary?: string;
};

export const ProgressBar: React.FC<Props> = ({
  completion,
  colorPrimary = colorTones.neutralsGray,
  colorBar = colorTones.ocean80,
}) => (
  <StyledLinearProgress
    variant="determinate"
    value={completion}
    sx={{
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: colorPrimary,
      },
      [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: colorBar,
      },
    }}
  />
);
