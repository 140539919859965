import { useMediaQuery, useTheme } from '@mui/material';
import { FormQuestionText, Input } from 'components/atoms';
import { FormError, QuestionFormContainer } from 'components/molecules';
import { commonAnswerFormSchema } from 'components/questionnaire/constants';
import { Question5FormValues, Question5Props } from 'components/questionnaire/types';
import { Form, Formik } from 'formik';
import React from 'react';
import { mapDtoAnswer4ToQuestion5, mapQuestion5ToDtoAnswer4 } from 'sdk/questionnaire';
import { Question } from 'sdk/questionnaire/enums';

type Props = Question5Props;

export const FormQuestion5: React.FC<Props> = ({ answer, formRef, requestError, questionError, onClick }) => {
  const initialValues: Question5FormValues = mapDtoAnswer4ToQuestion5(answer);
  const onSubmitForm = (values: Question5FormValues) => {
    onClick?.({ [Question.Question5]: mapQuestion5ToDtoAnswer4(values) }, Question.Question5);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <QuestionFormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={commonAnswerFormSchema}
        validateOnChange
        innerRef={formRef}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormQuestionText>
              <p style={{ textAlign: 'left' }}>Fill in the blanks:</p>
              My offering is powered by
              <Input
                name="adjective"
                value={values.adjective}
                placeholder="Descriptor"
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (Boolean(questionError?.DRA?.[0]) && questionError?.DRA?.[0] === values.adjective) ||
                  (Boolean(errors.adjective) && touched.adjective)
                }
              />
              <Input
                name="noun"
                value={values.noun}
                placeholder="Word"
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  (Boolean(questionError?.DRA?.[0]) && questionError?.DN?.[0] === values.noun) ||
                  (Boolean(errors.noun) && touched.noun)
                }
              />
              {!isMobile && '.'}
            </FormQuestionText>

            {Boolean(errors.adjective) && touched.adjective && <FormError>{errors.adjective}</FormError>}
            {Boolean(errors.noun) && touched.noun && <FormError>{errors.noun}</FormError>}
            {(Boolean(questionError?.DN?.[0]) && questionError?.DN?.[0] === values.noun) ||
              (Boolean(questionError?.DRA?.[0]) && questionError?.DRA?.[0] === values.adjective && (
                <FormError>Name is not allowed</FormError>
              ))}
            {Boolean(requestError) && <FormError>{requestError}</FormError>}
          </Form>
        )}
      </Formik>
    </QuestionFormContainer>
  );
};
