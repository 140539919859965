import { colorTones } from 'constants/designSystem';

import * as React from 'react';

import { SvgRatingButtonsProps } from './types';

type Props = SvgRatingButtonsProps;

export const SmileyButtonNeutral: React.FC<Props> = ({
  strokeLineColor = colorTones.neutralsWhite,
  strokeWidth = '1.4',
  width = 40,
  height = 40,
  backgroundColor,
  borderColor,
  viewBox = '0 0 40 40',
  withRect = true,
}) => (
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    {withRect && (
      <rect
        width={width}
        height={height}
        rx={Number(width) / 2}
        fill={borderColor || backgroundColor}
        fillOpacity="1"
      />
    )}
    <path
      d="M20 34.8571C28.2055 34.8571 34.8571 28.2055 34.8571 20C34.8571 11.7946 28.2055 5.14282 20 5.14282C11.7946 5.14282 5.14282 11.7946 5.14282 20C5.14282 28.2055 11.7946 34.8571 20 34.8571Z"
      fill={backgroundColor}
      stroke={backgroundColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6667 25.3333C16 25.9999 18 26.6666 20 26.6666C22 26.6666 24 25.9999 25.3333 25.3333"
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.0285 16.4571C24.7129 16.4571 24.4571 16.2012 24.4571 15.8856C24.4571 15.57 24.7129 15.3142 25.0285 15.3142"
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.0285 16.4571C25.3441 16.4571 25.6 16.2012 25.6 15.8856C25.6 15.57 25.3441 15.3142 25.0285 15.3142"
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9714 16.4571C14.6558 16.4571 14.4 16.2012 14.4 15.8856C14.4 15.57 14.6558 15.3142 14.9714 15.3142"
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9714 16.4571C15.287 16.4571 15.5429 16.2012 15.5429 15.8856C15.5429 15.57 15.287 15.3142 14.9714 15.3142"
      stroke={strokeLineColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
