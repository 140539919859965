import { colorTones } from 'constants/designSystem';

import styled from '@emotion/styled';
import Box from '@mui/material/Box';

const StyledBox = styled(Box)({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  background: colorTones.neutralsWhite,
  border: '1px solid #F7F7F7',
  boxShadow: '0px 2px 6px rgba(0, 13, 46, 0.06)',
  order: 0,
  flexGrow: 1,
});

export const ListItemBox = StyledBox;
