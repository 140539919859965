import { useAuthUser } from 'hooks/useAuthUser';
import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useEffect, useState } from 'react';

interface UserStateType {
  userInitials: string | undefined;
  setUserInitials?: Dispatch<SetStateAction<string | undefined>>;
  isLoading: boolean;
}

const initialState: UserStateType = {
  userInitials: undefined,
  isLoading: true,
};

export const UserContext = createContext<UserStateType>(initialState);

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [userInitials, setUserInitials] = useState<string | undefined>(undefined);
  const { user, isLoading } = useAuthUser();

  useEffect(() => {
    setUserInitials((user?.first_name[0] as string) + user?.last_name[0]);
  }, [user]);

  return <UserContext.Provider value={{ userInitials, setUserInitials, isLoading }}>{children}</UserContext.Provider>;
};
