import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';
import { Icon } from 'components/atoms/Icons';
import { FlatButtonWithIcon } from 'components/molecules';
import React from 'react';

type Props = {
  exportButton?: React.ReactNode;
  backButtonLabel: string;
  onClickBackButton: () => void;
};

const StyledGrid = styled(Grid)({
  m: 0,
  p: 0,
  paddingBottom: 2,
});

export const NamingJourneyNavigation: React.FC<Props> = ({ exportButton, backButtonLabel, onClickBackButton }) => {
  return (
    <StyledGrid container spacing={0}>
      <Grid item xs={4} md={3} lg={2} display="flex" justifyContent="left" alignItems="center" p={0} m={0}>
        <FlatButtonWithIcon
          text={backButtonLabel}
          icon={
            <Box sx={{ paddingRight: '8px' }}>
              <Icon type="interfaceArrowButtonLeft" strokeWidth="1.1" width={16} height={16} viewBox="0 0 24 24" />
            </Box>
          }
          iconPlacement="left"
          colorVariant="light"
          onClick={onClickBackButton}
        />
      </Grid>
      <Grid item xs={4} md={6} lg={8} display="flex" justifyContent="center" p={0} m={0}></Grid>
      <Grid item xs={4} md={3} lg={2} display="flex" justifyContent="right" alignItems="center" p={0} m={0}>
        {exportButton}
      </Grid>
    </StyledGrid>
  );
};
