import { env } from 'env';
import { CommonPostQuestionnaireRequest, CommonPostQuestionnaireResponse, commonPostQuestionnaire } from 'sdk/common';

type PostDraftCompleteRequest = { id: number } & CommonPostQuestionnaireRequest;

type PostDraftCompleteResponse = CommonPostQuestionnaireResponse;

export const postDraftComplete = async (request: PostDraftCompleteRequest): Promise<PostDraftCompleteResponse> => {
  const url = `${env.API_URL}/draft/complete/${request.id}`;
  return commonPostQuestionnaire(request, url);
};
