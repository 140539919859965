import { colorTones } from 'constants/designSystem';

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Icon } from 'components/atoms/Icons';
import { FlatButtonWithIcon } from 'components/molecules';
import React from 'react';

type Props = {
  handleEndPresentation?: () => void;
  title: string;
  endPresentationButtonLabel: string;
};

const StyledGrid = styled(Grid)({
  maxHeight: '42px',
  m: 0,
  p: 0,
});

const StyleContainer = styled(Box)({
  zIndex: 10,
  marginTop: '20px',
  top: `88px`,
  p: 0,
  m: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  '@media (min-width: 600px)': {
    position: 'sticky',
    marginTop: 0,
  },
});

const iconPresentation = (
  <Box sx={{ paddingRight: 1 }}>
    <Icon
      type="interfacePresentationBoard"
      stroke={colorTones.nightSky80}
      strokeWidth="1.1"
      width={24}
      height={24}
      viewBox="0 0 24 24"
    />
  </Box>
);

const iconClose = (
  <Box sx={{ paddingTop: '1px' }}>
    <Icon
      type="interfaceDelete"
      stroke={colorTones.nightSky80}
      strokeWidth="1.1"
      width={16}
      height={16}
      viewBox="0 0 16 16"
    />
  </Box>
);
const StyledTitleBox = styled(Box)({
  m: 0,
  p: 0,
  paddingTop: 1,
  paddingLeft: 2,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
});

const StyledGridItem = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  p: 0,
  m: 0,
});

const StyledLeftGridItem = styled(StyledGridItem)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: { justifyContent: 'right' },
  [theme.breakpoints.up('sm')]: { justifyContent: 'right' },
  [theme.breakpoints.up('md')]: { justifyContent: 'right' },
  [theme.breakpoints.up('lg')]: { justifyContent: 'center' },
}));

const StyledRightGridItem = styled(StyledGridItem)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: { justifyContent: 'left' },
  [theme.breakpoints.up('sm')]: { justifyContent: 'left' },
  [theme.breakpoints.up('md')]: { justifyContent: 'left' },
  [theme.breakpoints.up('lg')]: { justifyContent: 'center' },
}));

export const PresentationTopNavigation: React.FC<Props> = ({
  title,
  endPresentationButtonLabel,
  handleEndPresentation,
}) => (
  <StyleContainer>
    <StyledGrid container spacing={0}>
      <StyledRightGridItem item xs={5} md={3} lg={2}>
        <StyledTitleBox sx={{ paddingTop: 1, paddingLeft: 2 }}>
          {iconPresentation}
          <Typography variant="body2">{title}</Typography>
        </StyledTitleBox>
      </StyledRightGridItem>
      <StyledGridItem item xs={2} md={6} lg={8}></StyledGridItem>
      <StyledLeftGridItem item xs={5} md={3} lg={2}>
        <Box sx={{ paddingRight: 2, paddingBottom: 1, paddingTop: 1 }}>
          <FlatButtonWithIcon onClick={handleEndPresentation} text={endPresentationButtonLabel} icon={iconClose} />
        </Box>
      </StyledLeftGridItem>
    </StyledGrid>
  </StyleContainer>
);
